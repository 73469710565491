import { Component, EventEmitter, forwardRef, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
	selector: 'app-radio',
	templateUrl: './radio.component.html',
	styleUrls: ['./radio.component.scss'],
	host: {
		"[class.form-check]": 'true',
	},
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => RadioComponent),
			multi: true
		}
	],
	encapsulation: ViewEncapsulation.Emulated
})
export class RadioComponent implements OnInit {

	disabled: boolean;
	onChange: any = () => { };
	onTouched: any = () => { };

	private _selectedValue: any;
	public get selectedValue(): any {
		return this._selectedValue;
	}
	public set selectedValue(value: any) {
		this._selectedValue = value;
		this.onChange(value);
		this.onTouched(value);
	}

	@Input() checked: boolean;
	@Input() value: any;
	@Input() name: string;

	@Output() onChanged = new EventEmitter<any>();

	isChecked: boolean;


	constructor() { }

	ngOnInit(): void { }

	writeValue(value: any) {
		this._selectedValue = value
		this.isChecked = this.value == this.selectedValue;
	}
	registerOnChange(fn: (rating: number) => void): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: () => void): void {
		this.onTouched = fn;
	}

	setDisabledState(isDisabled: boolean): void {
		this.disabled = isDisabled;
	}
}
