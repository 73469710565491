// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host .expenses-chart .base-chart {
  width: 106px;
  overflow: hidden;
  position: relative;
}
:host .expenses-chart .base-chart .chart-text {
  position: absolute;
  top: 56%;
  left: 50%;
  transform: translate(-50%, -50%);
}
:host .border-left {
  border-left: 1px solid #e5e7eb;
}
:host .border-right {
  border-right: 1px solid #e5e7eb;
}
:host .icon-small-truck {
  font-size: 74px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/main/settings/expenses/truck-expenses/truck-expenses-list.component.scss"],"names":[],"mappings":"AAGQ;EACI,YAAA;EACA,gBAAA;EACA,kBAAA;AAFZ;AAGY;EACI,kBAAA;EACA,QAAA;EACA,SAAA;EACA,gCAAA;AADhB;AAKI;EACI,8BAAA;AAHR;AAKI;EACI,+BAAA;AAHR;AAKI;EACI,eAAA;AAHR","sourcesContent":["@import \"var\";\n:host {\n    .expenses-chart {\n        .base-chart {\n            width: 106px;\n            overflow: hidden;\n            position: relative;\n            .chart-text {\n                position: absolute;\n                top: 56%;\n                left: 50%;\n                transform: translate(-50%, -50%);\n            }\n        }\n    }\n    .border-left{\n        border-left: 1px solid $divider;        \n    }\n    .border-right{\n        border-right: 1px solid $divider;        \n    }\n    .icon-small-truck{\n        font-size: 74px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
