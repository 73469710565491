import { Component, Input } from '@angular/core';
import { Order } from '@app/models/order.model';
import { Route } from '@models/route.model';

@Component({
    selector: 'app-order-routes',
    templateUrl: './order-routes.component.html',
    styleUrls: ['./order-routes.component.scss'],
})
export class OrderRoutesComponent {
    @Input() routes: Route[];

    ngOnInit() {
    }

}
