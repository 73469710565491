// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host .alert {
  display: flex;
  padding: 10px;
  align-items: center;
  margin-bottom: 20px;
  border-radius: 5px;
}
:host .alert.alert-danger {
  color: #842029;
  background-color: #f8d7da;
  border-color: #f5c2c7;
}
:host .alert.alert-success {
  color: #0f5132;
  background-color: #d1e7dd;
  border-color: #badbcc;
}
:host .messages {
  font-weight: 400;
  flex-shrink: 1;
  width: 100%;
}
:host .close-buttion {
  flex-shrink: 0;
}
:host .close-buttion .btn-outline {
  min-height: 10px;
  padding: 4px;
}
:host .close-buttion .btn-outline .icon-close {
  font-size: 10px;
}
:host .alert.active {
  display: block;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/http-message/http-message.component.scss"],"names":[],"mappings":"AACI;EACI,aAAA;EACA,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,kBAAA;AAAR;AACQ;EACI,cAAA;EACA,yBAAA;EACA,qBAAA;AACZ;AACQ;EACI,cAAA;EACA,yBAAA;EACA,qBAAA;AACZ;AAEI;EACI,gBAAA;EACA,cAAA;EACA,WAAA;AAAR;AAEI;EACI,cAAA;AAAR;AACQ;EACI,gBAAA;EACA,YAAA;AACZ;AAAY;EACI,eAAA;AAEhB;AAEI;EACI,cAAA;AAAR","sourcesContent":[":host {\n    .alert {\n        display: flex;\n        padding: 10px;\n        align-items: center;\n        margin-bottom: 20px;\n        border-radius: 5px;\n        &.alert-danger {\n            color: #842029;\n            background-color: #f8d7da;\n            border-color: #f5c2c7;\n        }\n        &.alert-success {\n            color: #0f5132;\n            background-color: #d1e7dd;\n            border-color: #badbcc;\n        }\n    }\n    .messages {\n        font-weight: 400;\n        flex-shrink: 1;\n        width: 100%;\n    }\n    .close-buttion {\n        flex-shrink: 0;\n        .btn-outline {\n            min-height: 10px;\n            padding: 4px;\n            .icon-close {\n                font-size: 10px;\n            }\n        }\n    }\n    .alert.active {\n        display: block;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
