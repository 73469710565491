// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
  width: 100%;
  position: relative;
}
:host .clear-selection-button {
  position: absolute;
  right: 4px;
  top: 50%;
  transform: translateY(-50%);
}
:host .form-control {
  padding-right: 40px;
}`, "",{"version":3,"sources":["webpack://./src/app/@shared/typeahead-select/typeahead-select/typeahead-select.component.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,WAAA;EACA,kBAAA;AACF;AACE;EACE,kBAAA;EACA,UAAA;EACA,QAAA;EACA,2BAAA;AACJ;AAEE;EACE,mBAAA;AAAJ","sourcesContent":[":host {\n  display: block;\n  width: 100%;\n  position: relative;\n\n  .clear-selection-button {\n    position: absolute;\n    right: 4px;\n    top: 50%;\n    transform: translateY(-50%);\n  }\n\n  .form-control {\n    padding-right: 40px;\n  }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
