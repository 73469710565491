import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrderStopItemComponent } from './order-stop-item/order-stop-item.component';
import { FormsModule } from '@angular/forms';
import { RepeaterModule } from '@app/@shared/repeater/repeater.module';
import { AttachmentsModule } from '@app/@shared/attachments/attachments.module';
import { QtySelectModule } from '@app/@shared/qty-select/qty-select.module';
import { SharedModule } from '@app/shared/shared.module';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { FileUploadModule } from '@app/@shared/file-upload/file-upload.module';

@NgModule({
  declarations: [
    OrderStopItemComponent,
  ],
  exports: [
    OrderStopItemComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    RepeaterModule,
    SharedModule,
    AttachmentsModule,
    QtySelectModule,
    NgbDropdownModule,
    FileUploadModule
  ]
})
export class OrderStopItemModule { }
