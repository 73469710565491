import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@app/shared/shared.module';
import { ProfileComponent } from './profile.component';
import { ProfileRoutingModule } from './profile-routing.module';
import { NgxSelectModule } from '@app/@shared/ngx-select';
import { DialogsModule } from '@app/@shared/dialogs/dialogs.module';
import { FormValidateModule } from '@app/@shared/form-validate/form-validate.module';
import { AddEditPaymentMethodDialogComponent } from './add-edit-payment-method-dialog/add-edit-payment-method-dialog/add-edit-payment-method-dialog.component';
import { CardInputsModule } from '@app/@shared/card-inputs/card-inputs.module';
import { FormPhoneInputModule } from '@app/@shared/forms';

@NgModule({
  declarations: [
    ProfileComponent,
    AddEditPaymentMethodDialogComponent
  ],
  imports: [
    CommonModule,
    ProfileRoutingModule,
    SharedModule,
    NgxSelectModule,
    DialogsModule,
    FormValidateModule,
    CardInputsModule,
    FormPhoneInputModule
  ]
})
export class ProfileModule { }
