import { Component, Input, OnInit } from '@angular/core';
import { Vehicle } from '@models/samsara/vehicle.model';
import { Truck } from '@models/truck.model';
import { TruckService } from '@app/shared/services/truck.service';
import { HelperService } from '@app/shared/services/helper.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { number, object, string } from 'yup';
import { removeBinding } from '@app/@shared/utils';
@Component({
    selector: 'app-add-edit-truck',
    templateUrl: './add-edit-truck.component.html',
    styleUrls: ['./add-edit-truck.component.scss'],
})
export class AddEditTruckComponent implements OnInit {

    title = "Fleet";
    activeId: any;
    truck: Truck = {
        name: '',
        samsaraId: null
    }
    vehicle = {
        vin: ''
    }

    /*
	* Getter & Setter for Truck
	*/
	private _item: any;
	get item(): any {
		return this._item;
	}
	@Input() set item(value: any) {
		this._item = removeBinding(value);
        this.truck = removeBinding(value.truck);
        this.vehicle = removeBinding(value.vehicle);
	}



    vehicles: Vehicle[];
    loading: boolean = true;
    saveLoading = false;

    validationSchema = object().shape({
        name: string().label('Truck Name').nullable().required(),
        plateNumber: string().label('License Plate Number').nullable().required(),
        samsaraId: string().label('Truck Type').nullable().required('Please Select Truck Type'),
        insurancePrice: number().label('Insurance Price').nullable(),
    });

    constructor(
        private ngbActiveModal: NgbActiveModal,
        private truckService: TruckService,
        private helperService: HelperService,
    ) {
    }

    ngOnInit() {
        this.truckService.getSamsaraFleets()
            .then(res => {
                this.vehicles = res;
                this.loading = false;
            }).catch((error) => {
                this.loading = false;
            })
    }

    close() {
        this.ngbActiveModal.close()
    }

    truckType(item) {
        this.vehicle = this.vehicles?.find((e) => e.id == item) || null
    }

    async save(f) {
        if (f.isValid) {
            this.saveLoading = true;
            try {
                let resp: any;
                if (this.truck?.truckId) {
                    resp = await this.truckService.update(this.truck?.truckId, this.truck)
                } else {
                    resp = await this.truckService.create(this.truck)
                }
                this.helperService.successMessage(`${this.title} successfully ${this.truck?.truckId ? 'updated' : 'created'}`);
                this.ngbActiveModal.close(resp);
                this.saveLoading = false;
            } catch (error) {
                this.saveLoading = false;
                this.helperService.errorMessage(error);
            }
        }
    }
}
