// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  width: 100%;
}
:host .form-check-label {
  display: flex;
  align-items: center;
  width: 100%;
}
:host .form-check-input {
  margin-right: 10px;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/form/radio/radio.component.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;AACF;AAAE;EACE,aAAA;EACA,mBAAA;EACA,WAAA;AAEJ;AAAE;EACE,kBAAA;AAEJ","sourcesContent":[":host {\n  width: 100%;\n  .form-check-label {\n    display: flex;\n    align-items: center;\n    width: 100%;\n  }\n  .form-check-input {\n    margin-right: 10px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
