import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';

import { environment } from '@environments/environment';
import { DataApiResponse, handleApiError } from '@app/helpers/api';
import { Observable } from 'rxjs/internal/Observable';
import { Vehicle } from '@models/samsara/vehicle.model';
import { VehicleLocation } from '@models/samsara/vehicle-location.model';
import { Subject } from 'rxjs/internal/Subject';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SamsaraService {
    constructor(
        private http: HttpClient,
    ) {
    }

    private locations$: BehaviorSubject<VehicleLocation[]> = new BehaviorSubject<VehicleLocation[]>([]);

    get locations(): Observable<VehicleLocation[]> {
        return this.locations$.asObservable();
    }

    private setLocations(locations: VehicleLocation[]) {
        this.locations$.next(locations);
    }

    public getFleet(): Observable<Vehicle[]> {
        return this.http.get<DataApiResponse<Vehicle[]>>(`${environment.apiBaseUrl}Samsara/Fleet`)
            .pipe(map(data => {
                return data.data;
            }),
                catchError(handleApiError));
    }

    public getFleetLocations(): Observable<VehicleLocation[]> {
        return this.http.get<DataApiResponse<VehicleLocation[]>>(`${environment.apiBaseUrl}Samsara/FleetLocations`)
            .pipe(map(data => {
                this.setLocations(data.data);
                return data.data;
            }),
                catchError(handleApiError));
    }
}

