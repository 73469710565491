import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@app/shared/shared.module';
import { InvoicePaymentsComponent } from './invoice-payments.component';
import { DatatableModule } from '../datatable';

@NgModule({
	declarations: [
		InvoicePaymentsComponent
	],
	imports: [
		CommonModule,
		SharedModule,
		DatatableModule
	],
	exports: [
		InvoicePaymentsComponent
	]
})
export class InvoicePaymentsModule { }
