import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { isJsObject } from 'src/utils/object';
import { ToastrService } from 'ngx-toastr';
import { environment } from '@environments/environment';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class HelperService {

    private dataStore: {
        isLoading?: boolean,
        isNavCollapsed?: boolean
    } = {}


    private _isLoading: BehaviorSubject<boolean>;
    private _isNavCollapsed: BehaviorSubject<boolean>;

    get isLoading(): boolean {
        return this.dataStore.isLoading;
    }
    set isLoading(value: boolean) {
        this._isLoading.next(value);
    }
    get isLoading$() {
        return this._isLoading.asObservable();
    };

    get isNavCollapsed(): boolean {
        return this.dataStore.isNavCollapsed;
    }
    set isNavCollapsed(value: boolean) {
        this._isNavCollapsed.next(value);
    }
    get isNavCollapsed$() {
        return this._isNavCollapsed.asObservable();
    };


    constructor(
        public router: Router,
        public toastyService: ToastrService,
    ) {
        this._isLoading = new BehaviorSubject(false)
        this._isNavCollapsed = new BehaviorSubject(false)
    }

    

    successMessage(data: any, defaultMessage: any = 'Success') {
        let messageText = '';
        if (typeof data === 'string') {
            messageText = data;
        } else if (data?.message) {
            messageText = data.message;
        } else if (data?.messages && data?.messages?.length > 0) {
            messageText = data.messages.join('<br>');
        } else {
            messageText = defaultMessage;
        }
        this.addToast(messageText, 'Success!', 'success');
    }

    errorMessage(error: any, defaultMessage: string = 'Error, Please refresh the page and try again.'): void {

        if (error === '' || error === null) {
            this.addToast(defaultMessage, 'Oops!', 'error');
            return;
        }

        let message: any = '';

        if (error?.error) {
            error = error.error
        }

        if (typeof error === 'string') {
            this.addToast(error, 'Oops!', 'error');
        } else if (error.messages !== undefined) {
            if (isJsObject(error.messages)) {
                message = error.messages.join(', ');
            } else {
                message = error.messages;
            }
            this.addToast(message, 'Oops!', 'error');

        } else if (error.errors !== undefined) {
            // if (isJsObject(error.errors)) {
            //     message = error?.errors?.join('<br>');
            // } 
            message = Object.keys(error.errors).map(function (key, index) {
                return error.errors[key]
            }).join(' ');
            this.addToast(message, 'Oops!', 'error');
        } else if (error.message !== undefined) {

            if (error.message !== undefined || error.message != null) {
                message = error.message;
            }
            this.addToast(message, 'Oops!', 'error');

        } else {
            if (error.error != undefined || error.error != null) {
                this.addToast(error.error, 'Oops!', 'error');
            } else {
                this.addToast(defaultMessage, 'Oops!', 'error');
            }
        }

        if (error.code === 404) {
            this.router.navigate(['/404']);
        }
    }

    addToast(msg: any, title: any, type: any) {

        const options: any = {
            showClose: true,
            timeout: environment?.production ? 7000 : 15000,
        };

        switch (type) {
            case 'info': this.toastyService.info(msg, title, options); break;
            case 'success': this.toastyService.success(msg, title, options); break;
            case 'error': this.toastyService.error(msg, title, options); break;
            case 'warning': this.toastyService.warning(msg, title, options); break;
            case 'show': this.toastyService.show(msg, title, options); break;
        }
    }
}
