import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ChargeTypeDetailsComponent } from './charge-type-details/charge-type-details.component';
import { ChargeTypeListComponent } from './charge-type-list/charge-type-list.component';

const routes: Routes = [
  {
    path: '',
    component: ChargeTypeListComponent,
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ChargeTypesRoutingModule { }
