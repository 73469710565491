import { Component, forwardRef, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-switch',
  templateUrl: './switch.component.html',
  styleUrls: ['./switch.component.scss'],
  host: {
    "[class.form-check]": 'true',
    "[class.form-switch]": 'true',
  },
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SwitchComponent),
      multi: true
    }
  ],
  encapsulation: ViewEncapsulation.Emulated
})
export class SwitchComponent implements OnInit {
  disabled: boolean;
  onChange: any = () => { };
  onTouched: any = () => { };

  @Input() name: string;
  @Input() indeterminate: boolean;

  private _value: boolean;
  get value(): boolean {
    return this._value;
  }
  set value(updateValues: boolean) {
    this._value = updateValues;
    this.onChange(updateValues);
    this.onTouched(updateValues);
  }

  constructor() { }

  ngOnInit(): void {
  }

  writeValue(value: boolean) {
    this._value = value
  }
  registerOnChange(fn: (rating: number) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
