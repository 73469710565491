import { Component, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { NgModel } from "@angular/forms";
import { DialogService } from "@app/@shared/dialogs/dialog.service";
import { titleCase } from "@app/@shared/utils";
import { ApiPaginationData } from "@app/models/core/base";
import { Driver } from "@app/models/driver.model";
import { Route } from "@app/models/route.model";
import { ZoneSearchRequest } from "@app/models/zone.model";
import { DriverService } from "@app/shared/services/driver.service";
import { HelperService } from "@app/shared/services/helper.service";
import { RoutesService } from "@app/shared/services/router.service";
import { NgbDropdown } from "@ng-bootstrap/ng-bootstrap";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { from, of } from "rxjs";
import { catchError, debounceTime, switchMap, tap } from "rxjs/operators";

@UntilDestroy()
@Component({
    selector: 'app-edit-route-driver',
    templateUrl: './edit-route-driver.component.html',
    styleUrls: ['./edit-route-driver.component.scss']
})
export class EditRouteDriverComponent implements OnInit, OnDestroy {

    @ViewChild('dropdown') dropdown: NgbDropdown;
    @ViewChild('searchInput') searchInput: NgModel;


    private _route: Route;

    @Input()
    set route(value: Route) {
        this._route = value;
        this.selectedDriver = value?.driver
    }

    get route(): Route {
        return this._route;
    }

    search: string;
    drivers: Driver[];
    searchingDriver: boolean;
    isDropDownOpen: boolean;
    disabled: boolean;
    hasMorePage: boolean = false;
    page: number = 1;
    selectedDriver: Driver;
    constructor(
        private driverService: DriverService,
        private dialogService: DialogService,
        private helperService: HelperService,
        private routesService: RoutesService,
    ) { }

    ngOnInit() {

    }

    setDropDown(event) {
        event.preventDefault();
        event.stopPropagation();
        this.isDropDownOpen = !this.isDropDownOpen;
        if (this.isDropDownOpen) {
            this.getDrivers({ SearchTerm: '' })
        }
    }

    ngAfterViewInit() {
        this.searchInput.valueChanges
            .pipe(
                untilDestroyed(this),
                tap(() => {
                    this.searchingDriver = true;
                    this.drivers = [];
                }),
                debounceTime(500),
                switchMap(term => {
                    this.page = 1;
                    const request = this.isDropDownOpen ? this.getDrivers({
                        SearchTerm: term ? term : '',
                    }) : [];
                    return from(request).pipe(
                        catchError(() => of([]))
                    )
                }),
            ).subscribe((val) => {
                // nothing
            })
    }

    loadMoreDriver() {
        this.page++;
        this.getDrivers()
    }

    getDrivers(filter?: Partial<ZoneSearchRequest>) {
        this.searchingDriver = true
        let request = {
            Page: this.page,
            ItemsPerPage: 20,
            ...filter,
        }
        this.driverService.cancelPendingRequestGetAll();
        let resp: Promise<ApiPaginationData<Driver>>;
        resp = this.driverService.getAll(request)
        resp.then((resp) => {
            const { list, totalCount, itemsPerPage } = resp;
            this.hasMorePage = totalCount > (this.page * itemsPerPage);
            if (this.page > 1) {
                this.drivers = this.drivers.concat(list);
            } else {
                this.drivers = list;
            }
        }).finally(() => {
            this.searchingDriver = false
        })
        return resp;
    }

    async handleSelectedDriver(driver) {
        try {
            await this.checkDriverUpdateOnRouteConfirmation(driver);
            if (this.route?.routeId && this.route) {
                this.helperService.isLoading = true;
                this.route.driver = driver;
                this.routesService.update(this.route.routeId, this.route).then((res) => {
                    this.selectedDriver = driver;
                    this.helperService.successMessage(`Route Driver successfully updated.`);
                }).catch((error) => {
                    this.route.driver = this.selectedDriver || null;
                    this.helperService.errorMessage(error);
                }).finally(() => {
                    this.helperService.isLoading = false;
                });
            }
        } catch (error) {
            this.route.driver = this.selectedDriver || null;
        }
        this.dropdown.close();
    }

    async checkDriverUpdateOnRouteConfirmation(driver) {
        return this.dialogService.confirm({
            message: `Would you like to move route #${this.route?.routeId} from the
            ‘${titleCase(this.selectedDriver?.firstName)} ${titleCase(this.selectedDriver?.lastName)} Driver’ to the
            ‘${titleCase(driver?.firstName)} ${titleCase(driver?.lastName)} Driver’?`,
        }, { size: 'sm' })
    }

    ngOnDestroy(): void {
        // nothing
    }
}