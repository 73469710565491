import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';

import { environment } from '@environments/environment';
import { DataApiResponse, handleApiError } from '@app/helpers/api';
import { Attachment } from '@models/attachment.model';
import { Observable } from 'rxjs/internal/Observable';
import { EntityType } from '@models/entity-type.model';
import { OrderStop } from '@models/order-stop.model';
import { OrderItem } from '@models/order-item.model';
import { UtilService } from '@app/services/util.service';
import { Order } from '@app/models/order.model';
import { CrudService } from '@app/shared/services/core/crud.service';


export type Attachable = OrderItem | OrderStop;

@Injectable({ providedIn: 'root' })
export class AttachmentService extends CrudService<Attachment> {
    public basePath = 'Attachments';

    constructor(
        protected http: HttpClient,
        private util: UtilService,
    ) {
        super(http);
    }

    public uploadAttachment(type: EntityType, id: number, file: File): Observable<Attachment> {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('type', `${type}`);
        formData.append('id', `${id}`);

        return this.http.post<DataApiResponse<Attachment>>(`${environment.apiBaseUrl}Attachments/upload/${type}/${id}`, formData)
            .pipe(map(data => {
                console.log(data);
                return data.data;
            }),
                catchError(handleApiError));
    }

    downloadAttachment(attachment: Attachment): Observable<Blob> {
        return this.http.get(`${environment.apiBaseUrl}Attachments/${attachment.attachmentId}`, {
            responseType: 'blob',
        }).pipe(catchError(handleApiError));
    }

    uploadAttachmentFile(event: Event, entityType: EntityType, entity: Attachable) {
        const target = event.target as HTMLInputElement;
        if (target?.files && target?.files?.length > 0) {
            let id: number;
            if ((entity as OrderStop).orderStopId != null) {
                id = (entity as OrderStop).orderStopId;
            } else if ((entity as OrderItem).orderItemId != null) {
                id = (entity as OrderItem).orderItemId;
            } else if ((entity as Order).orderId != null) {
                id = (entity as Order).orderId;
            }
            this.uploadAttachment(entityType, id ?? 0, target.files[0]).subscribe(attachment => {
                entity.attachments.push(attachment);
            }, error => {
                this.util.showError(error.messages);
            });
        }
    }

    getListByOrder(orderId: number | string) {
        return this.http.get<DataApiResponse<Attachment[]>>(`${environment.apiBaseUrl}Attachments/Order/${orderId}`)
            .pipe(
                map(res => res.data),
                catchError(handleApiError));
    }
}

