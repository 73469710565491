// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  color: #B3B3B3;
}

:host {
  height: 44px;
  display: block;
}

.form-control {
  overflow: hidden;
  position: relative;
}
.form-control.opened {
  height: auto;
  z-index: 99;
}
.form-control:focus {
  border-color: #f1f1f1;
  box-shadow: none;
}

app-calendar-navigation {
  margin-top: -2px;
}

.value {
  height: 100%;
  margin: 0 15px;
  padding: 0 24px;
}
.value .fa-calendar-day, .value .fa-chevron-down {
  position: absolute;
  top: 55%;
  transform: translateY(-50%);
}
.value .fa-calendar-day {
  left: 0;
}
.value .fa-chevron-down {
  right: 0;
  transition: 0.2s all;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/basic-datepicker/basic-datepicker.component.scss"],"names":[],"mappings":"AAAA;EACI,cAAA;AACJ;;AAEA;EACI,YAAA;EACA,cAAA;AACJ;;AAEA;EACI,gBAAA;EACA,kBAAA;AACJ;AACI;EACI,YAAA;EACA,WAAA;AACR;AAEI;EACI,qBAAA;EACA,gBAAA;AAAR;;AAIA;EACI,gBAAA;AADJ;;AAIA;EACI,YAAA;EACA,cAAA;EACA,eAAA;AADJ;AAGI;EACI,kBAAA;EACA,QAAA;EACA,2BAAA;AADR;AAII;EACI,OAAA;AAFR;AAKI;EACI,QAAA;EACA,oBAAA;AAHR","sourcesContent":["* {\n    color: #B3B3B3;\n}\n\n:host {\n    height: 44px;\n    display: block;\n}\n\n.form-control {\n    overflow: hidden;\n    position: relative;\n\n    &.opened {\n        height: auto;\n        z-index: 99;\n    }\n\n    &:focus {\n        border-color: #f1f1f1;\n        box-shadow: none;\n    }\n}\n\napp-calendar-navigation {\n    margin-top: -2px;\n}\n\n.value {\n    height: 100%;\n    margin: 0 15px;\n    padding: 0 24px;\n\n    .fa-calendar-day, .fa-chevron-down {\n        position: absolute;\n        top: 55%;\n        transform: translateY(-50%);\n    }\n\n    .fa-calendar-day {\n        left: 0;\n    }\n\n    .fa-chevron-down {\n        right: 0;\n        transition: .2s all;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
