import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiPaginationData, ApiPaginationResponse, ApiResponse, ListRequest } from '@app/models/core/base';
import { toParams } from 'src/utils/to-params';
import { Truck } from '@app/models/truck.model';
import { Vehicle } from '@app/models/samsara/vehicle.model';
import { VehicleLocation } from '@app/models/samsara/vehicle-location.model';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';


@Injectable({
    providedIn: 'root'
})
export class TruckService {

    private basePath = 'Trucks'
    private cancelRequestGetTrucksWithVehicles$ = new Subject<void>();

    constructor(
        private http: HttpClient
    ) {

    }

    get(id: string | number) {
        return this.http.get<ApiResponse<Truck>>(`${this.basePath}/${id}`).toPromise()
            .then((({ data }) => data));
    }

    getAll(request?: ListRequest) {
        return this.http.get<ApiPaginationResponse<Truck>>(`${this.basePath}`, { params: toParams(request) }).toPromise()
            .then((({ data }) => data));
    }

    getTrucksWithExpenses(request?: ListRequest) {
        return this.http.get<ApiPaginationResponse<any>>(`${this.basePath}/TrucksWithExpenses`, { params: toParams(request) })
            .toPromise()
            .then(({ data }) => data);
    }

    getAllTrucksExpenses(request?: any) {
        return this.http.get<any>(`${this.basePath}/AllTrucksExpenses`, { params: toParams(request) })
            .toPromise()
            .then(({ data }) => data);
    }
    
    getTrucksWithVehicles(request?: ListRequest) {
        return new Promise<ApiPaginationData<any>>((resolve, reject) => {
            this.http.get<ApiPaginationResponse<any>>(`${this.basePath}/TrucksWithVehicles`, { params: toParams(request) })
                .pipe(
                    takeUntil(this.cancelRequestGetTrucksWithVehicles$)
                )
                .subscribe(
                    ({ data }: any) => {
                        resolve(data);
                    },
                    error => {
                        reject(error);
                    }
                );
        });
    }

    cancelPendingRequestGetTrucksWithVehicles() {
        this.cancelRequestGetTrucksWithVehicles$.next();
    }

    create(request?: ListRequest) {
        return this.http.post<ApiResponse<Truck>>(`${this.basePath}`, request).toPromise()
            .then((({ data }) => data));
    }

    update(id: string | number, request?: ListRequest) {
        return this.http.put<ApiResponse<Truck>>(`${this.basePath}/${id}`, request).toPromise()
            .then((({ data }) => data));
    }

    delete(id: string | number) {
        return this.http.delete(`${this.basePath}/${id}`).toPromise();
    }

    public getSamsaraFleets() {
        return this.http.get<ApiResponse<Vehicle[]>>(`Samsara/Fleet`).toPromise().then(({ data }) => data)
    }

    public getSamsaraFleetLocations() {
        return this.http.get<ApiResponse<VehicleLocation[]>>(`Samsara/FleetLocations`).toPromise().then(({ data }) => data)
    }

    public updateSamsaraVehicle(request?: ListRequest) {
        return this.http.put<ApiResponse<Truck>>(`Samsara/Vehicle`, request).toPromise()
            .then((({ data }) => data));
    }

}
