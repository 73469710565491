import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PhoneExtensionInputComponent } from './phone-extension-input/phone-extension-input.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TypeaheadSelectModule } from '../typeahead-select/typeahead-select.module';


@NgModule({
  declarations: [
    PhoneExtensionInputComponent,
  ],
  exports: [
    PhoneExtensionInputComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    TypeaheadSelectModule,
    ReactiveFormsModule
  ]
})
export class PhoneExtensionInputModule { }
