import { Component, OnInit } from '@angular/core';
import { stateOptions } from '@app/data/state';
import { Company } from '@app/models/company.model';
import { ImageUploadTypeEnum } from '@app/models/image.model';
import { SubscriptionPlans } from '@app/models/plan.model';
import { User } from '@app/models/user.model';
import { AuthService } from '@app/shared/services/auth/auth.service';
import { CompanyService } from '@app/shared/services/company.service';
import { HelperService } from '@app/shared/services/helper.service';
import { ImagesService } from '@app/shared/services/images.service';
import { SettingService } from '@app/shared/services/setting.service';
import { SubscriptionPlansService } from '@app/shared/services/subscription-plans.service';
import { TenantService } from '@app/shared/services/tenant.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { omit } from 'lodash';
import { UtilService } from 'src/app/services/util.service';
import { number, object, string } from 'yup';
import { AddEditPaymentMethodDialogComponent } from './add-edit-payment-method-dialog/add-edit-payment-method-dialog/add-edit-payment-method-dialog.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { RecurringBillingService } from '@app/shared/services/recurring-billing.service';
import { RecurringBilling } from '@app/models/payment-method.model';
import { DocumentViewMode } from '@app/models/document.model';
import { saveAs } from "file-saver";
import { patterns } from '@app/@shared/regex/regex-patterns.const';
import { removeBinding } from '@app/@shared/utils';

@UntilDestroy()
@Component({
    selector: 'app-settings-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
    planDetailsShow = false;
   companyNameEdit = false;
    profileLoading: boolean;
    user: User;
    company: Company = {
        companyName: '',
        website: '',
        email: null,
        emailFromOrder: null,
        emailFromAccounting: null,
        fromEmail: null,
        phoneNumber: null,
        fullAddress: '',
        city: '',
        state: '',
        zip: null,
        orderNumbersIndex: null,
        transactionNumberIndex: null,
        defaultGasState: '',
        truckAxleNumberForTollPricing: null,

    };
    title = "Profile";
    selectPayment = 1;
    states = stateOptions;
    planDetails: SubscriptionPlans;
    billingHistory: RecurringBilling[];
    defaultTruckMonthlyExpenses = {
        name: '',
        price: null,
    }
    validationSchema = object().shape({
        companyName: string().nullable(),
        website: string().nullable(),
        email: string().label('Company Email').nullable().email(),
        emailCC: string().label('CC Email').nullable().email(),
        emailFromOrder: string().label('Email From (Quote & BOL)').nullable().email(),
        emailFromAccounting: string().label('Email from accounting').nullable().email(),
        fromEmail: string().label('Email from').nullable().email(),
        phoneNumber: string().label('Company Phone Number').nullable().matches(patterns.phone, { excludeEmptyString: true, message: 'Company phone must be a number' }),
        faxNumber: string().label('Fax Number').nullable().matches(/^[0-9]/, { message: 'Fax number must be a number' }).max(10),
        fullAddress: string().label('address').nullable(),
        city: string().nullable(),
        state: string().nullable(),
        zip: string().label('Zip Code').nullable().max(6),
        orderNumbersIndex: number().nullable(),
        transactionNumberIndex: number().nullable(),
        defaultGasState: string().nullable(),
        truckAxleNumberForTollPricing: number().nullable(),
    });
    billingHistories = [
        {
            id: 1,
            amount: 536,
            date: 'Jul 1, 2022',
            name: 'Base Plan',
            status: ' Paid'
        },
        {
            id: 2,
            amount: 706,
            date: 'Jun 1, 2022',
            name: 'Base Plan',
            status: ' Paid'
        },
    ]
    submitLoading = false

    paymentMethods: any

    constructor(
        private authService: AuthService,
        private helperService: HelperService,
        private imagesService: ImagesService,
        private companyService: CompanyService,
        public util: UtilService,
        private subscriptionPlansService: SubscriptionPlansService,
        private recurringBillingService: RecurringBillingService,
        private tenantService: TenantService,
        private ngbModal: NgbModal,
    ) {
    }

    ngOnInit() {
        this.authService.$user
            .pipe(untilDestroyed(this))
            .subscribe((data) => {
                this.company = removeBinding(data?.company);
            })

        this.recurringBillingService.getAll({
            ItemsPerPage: 10,
            Page: 1,
            SortBy: "date",
            SortDirection: "Descending"
        }).then((res) => {
            this.billingHistory = res
        })

        this.subscriptionPlansService.getAll({
            itemsPerPage: 10,
            Page: 1
        }).then((res) => {
            this.planDetails = res.list[0]
        })
        //this.getTenantPaymentMethods();
    }

    getTenantPaymentMethods() {
        this.tenantService.getTenantPaymentMethods({
            itemsPerPage: 10,
            Page: 1
        }).then((res) => {
            this.paymentMethods = res
        }).catch((error) => {
            this.helperService.errorMessage(error)
        })
    }

    addEditPaymentMethod(item?) {
        const modalRef = this.ngbModal.open(AddEditPaymentMethodDialogComponent, { size: 'sm' });
        if (item) {
            modalRef.componentInstance.paymentMethod = item;
        } else {
            modalRef.componentInstance.tenantId = this.user?.tenantId || this.company?.tenantId;
        }
        modalRef.closed
            .pipe(untilDestroyed(this))
            .subscribe((data) => {
                if (data) {
                    this.getTenantPaymentMethods();
                }
            });
    }

    selectPaymentMethod(id) {
        this.selectPayment = id
    }

    async onSelectFile(event) {
        const files = event.target.files;
        for (let index = 0; index < files.length; index++) {
            const file = files[index];
            const request = {
                file: file,
                type: ImageUploadTypeEnum.COMPANY_LOGO,
                id: this.company?.companyId,
            }
            this.imagesService.upload(request).then((resp: any) => {
                this.company.logoUrl = resp?.data
            });
        }
    }

    onSubmit(f) {
        if (f.isValid) {
            this.submitLoading = true;
            const profile = this.company;
            this.companyService.update(omit(profile, 'companyId')).then((resp: any) => {
                this.company = resp
                this.helperService.successMessage("Profile successfully updated")
                this.authService.user = {
                    ...this.authService.user,
                    company: this.company,
                }
                this.submitLoading = false;
                this.companyNameEdit = false;
                this.companyService.selectedCompany = { ...this.companyService.selectedCompany, maxRouteAddresses: this.company?.maxRouteAddresses }
            }).catch((error) => {
                this.helperService.errorMessage(error)
                this.submitLoading = false;
            });
        }
    }


    planDetailsToggle() {
        this.planDetailsShow = !this.planDetailsShow
    }

    downloadAllBillingHistory() {
        this.recurringBillingService.downloadAllPayments(DocumentViewMode.Pdf).then((file) => {
            saveAs(
                file,
            );
        })
    }

    downloadBillingHistory(id) {
        this.recurringBillingService.downloadPayment(id, DocumentViewMode.Pdf).then((file) => {
            saveAs(
                file,
            );
        })
    }
}
