import { Component, Input, OnInit } from '@angular/core';
import { Order } from '@models/order.model';
import { CostLine } from '@models/cost-line.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { costLinesByType } from '@app/data/order';
import { chain, cloneDeep, keyBy, map, sum } from 'lodash';
import { Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { debounceTime } from 'rxjs/operators';
import { OrderService } from '@app/shared/services/order.service';
import { HelperService } from '@app/shared/services/helper.service';

@UntilDestroy()
@Component({
    selector: 'app-cost-lines',
    templateUrl: './cost-lines.component.html',
    styleUrls: ['./cost-lines.component.scss']
})
export class CostLinesComponent implements OnInit {

    colorCodes = [
        '#2ccc71',
        '#cc0000',
        '#00caff',
        '#5a51de',
        '#f39c13',
        'rgb(255, 109, 1)',
        'rgb(70, 189, 198)',
        'rgb(234, 67, 53)',
        'rgb(66, 133, 244)',
    ];

    private _quote: Order;

    @Input('quote')
    get quote(): Order {
        return this._quote;
    }
    set quote(value: Order) {
        this._quote = value;

        this.aggregatedCostLines = chain(this._quote.costLines)
            .cloneDeep()
            .uniqBy('costType')
            .map((costLine: any, index) => {
                const lines = this._quote?.costLines.filter(line => !line.isOverwritten && line.costType === costLine.costType) || [];

                const isActive = lines.filter(({ isActive }) => isActive)?.length > 0;

                const rate = lines.map((x) => x.rate)
                const totalCost = lines.map((x) => x.totalCost)

                costLine.name = costLinesByType.get(costLine.costType)?.name;
                costLine.color = this.colorCodes[index];
                costLine.rate = sum(rate);
                costLine.totalCost = sum(totalCost);
                costLine.isActive = isActive;
                return costLine
            })
            .value();

        this.updateData$.next(true);
    }

    aggregatedLines: CostLine[] = [];
    totalCost: number = 0;
    markupAmount: number = 0;
    markupPercent: number;
    discountAmount: number;
    discountPercent: number;
    totalPrice: number;
    percentCost: number;
    pointPercent: number;
    aggregatedCostLines: CostLine[];
    doughnutChart: any;
    discountCode: any;

    updateData$ = new Subject();
    subTotal: number;

    constructor(
        private activeModal: NgbActiveModal,
        private orderService: OrderService,
        private helperService: HelperService,
    ) {

        this.doughnutChart = {
            data: {
                datasets: [{
                    backgroundColor: this.colorCodes
                }]
            },
            options: {
                view: [100, 100],
                responsive: true,
                cutout: '84%',
                // hover: { mode: null },
                plugins: { tooltip: { enabled: false} },
                elements: {
                    arc: { borderWidth: 0 },
                },
            },
        };
    }

    ngOnInit(): void {

        this.updateData$.pipe(
            untilDestroyed(this),
            debounceTime(500),
        ).subscribe(() => {
            this.updateCalculation();
        })

        this.updateData$.next(true);
    }

    toggleLine(line) {
        line.isActive = !line.isActive
        this.updateCalculation();
    }

    updateCalculation() {

        const lineByType = keyBy(this.aggregatedCostLines, 'costType');

        // Update costLine in quote when toggle
        this._quote.costLines.map((item) => {
            if (lineByType[item?.costType]) {
                item.isActive = lineByType[item?.costType]?.isActive
            }
            return item;
        })

        const activeLines = this.aggregatedCostLines.filter((line) => line.isActive)

        const totalCost = chain(activeLines)
            .map(item => item.totalCost)
            .sum()
            .value()

        this.totalCost = totalCost ? totalCost : 0;

        this.markupAmount = this.quote.markupType === 'Value' ? Number(this.quote.markupValue) : (this.totalCost * (this.quote.markupValue / 100));
        this.markupPercent = this.quote.markupType === 'Percent' ? Number(this.quote.markupValue) : Number((this.markupAmount / this.totalCost).toFixed(2));

        this.discountAmount = this.quote.discountType === 'Value' ? Number(this.quote.discountValue) : Number((this.totalCost + this.markupAmount) * (this.quote.discountValue / 100));
        this.discountPercent = this.quote.discountType === 'Percent' ? Number(this.quote.discountValue) : Number((this.discountAmount / (this.totalCost + this.markupAmount)).toFixed(2));

        this.totalPrice = Number(this.totalCost) + Number(this.markupAmount) - Number(this.discountAmount);

        this.percentCost = (this.totalCost / (this.totalCost + this.markupAmount)) * 100;
        this.pointPercent = Number(((this.totalPrice / (this.totalCost + this.markupAmount)) * 100).toFixed(0));

        this.subTotal = this.totalCost + this.markupAmount;



        this.doughnutChart.data.datasets[0].labels = map(activeLines, (costLine) => {
            return costLinesByType?.get(costLine?.costType)?.name;
        })
        this.doughnutChart.data.datasets[0].data = map(activeLines, 'totalCost')
        this.doughnutChart.data.datasets[0].backgroundColor = map(activeLines, 'color')
        this.doughnutChart = cloneDeep(this.doughnutChart)
    }

    async handleOrderSave() {
        try {
            await this.orderService.saveOrder(this.quote, 'Pricing');
            this.helperService.successMessage("Price Details Successfully Updated.");
            this.close();
        } catch (error) {
            this.helperService.errorMessage(error)
        }
    }

    close() {
        this.activeModal.close();
    }
}
