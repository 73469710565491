import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@app/shared/shared.module';
import { ShipmentTypesRoutingModule } from './shipment-types-routing.module';
import { ShipmentTypeListComponent } from './shipment-type-list/shipment-type-list.component';
import { ShipmentTypeDetailsComponent } from './shipment-type-details/shipment-type-details.component';
import { DatatableModule } from '@app/@shared/datatable';
import { DialogsModule } from '@app/@shared/dialogs/dialogs.module';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { RepeaterModule } from '@app/@shared/repeater/repeater.module';
import { OrderItemModule } from '../../../../shared/components/auxiliary/order-item/order-item.module';
import { CostLinesModule } from '../../../../shared/components/auxiliary/cost-lines/cost-lines.module';
import { CustomerSelectModule } from '@app/@shared/customer-select/customer-select.module';
import { FormValidateModule } from '@app/@shared/form-validate/form-validate.module';

@NgModule({
  declarations: [
    ShipmentTypeListComponent,
    ShipmentTypeDetailsComponent
  ],
  imports: [
    CommonModule,
    ShipmentTypesRoutingModule,
    SharedModule,
    DatatableModule,
    DialogsModule,
    NgxSliderModule,
    TypeaheadModule,
    RepeaterModule,
    OrderItemModule,
    CostLinesModule,
    CustomerSelectModule,
    FormValidateModule
  ]
})
export class ShipmentTypesModule { }
