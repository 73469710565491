import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ApiPaginationData, ApiPaginationResponse, ApiResponse, ListRequest } from '@app/models/core/base';
import { toFormData } from 'src/utils/to-form-data';
import { toParams } from 'src/utils/to-params';
import { saveAs } from 'file-saver';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import * as XLSX from 'xlsx';
export abstract class CrudService<T> {

    protected abstract basePath: string;
    protected hasFileUpload = false;
    private cancelRequestGetAll$ = new Subject<void>();

    constructor(
        protected http: HttpClient
    ) { }

    get(id: string | number) {
        return this.http.get<ApiResponse<T>>(`${this.basePath}/${id}`).toPromise()
            .then((({ data }) => {
                return this.mapResponse(data);
            }));
    }

    getAll(request?: ListRequest) {
        return this.http.get<ApiPaginationResponse<T>>(`${this.basePath}`, { params: toParams(request) }).toPromise()
            .then((({ data }) => {
                data.list == data.list?.map(this.mapResponse)
                return data
            }));
    }

    create(request?: Partial<T>) {
        request = this.mapRequest(request)
        return this.http.post<ApiResponse<T>>(`${this.basePath}`, this.hasFileUpload ? toFormData(request) : request).toPromise()
            .then((async ({ data }) => await this.mapResponse(data)));
    }

    update(id: string | number, request?: Partial<T>) {
        request = this.mapRequest(request)
        return this.http.put<ApiResponse<T>>(`${this.basePath}/${id}`, this.hasFileUpload ? toFormData(request) : request).toPromise()
            .then((async ({ data }) => await this.mapResponse(data)));
    }

    delete(id: string | number) {
        return this.http.delete(`${this.basePath}/${id}`).toPromise();
    }


    mapRequest(request): Promise<any> | any {
        return request;
    }

    mapResponse(data: T): Promise<any> | any {
        return data;
    }

    downloadFile(url: string, options?: {
        name?: string,
        params: any
    }) {
        return this.http.get(url, {
            headers: new HttpHeaders({
                Accept: 'application/vnd.ms-excel',
            }),
            responseType: 'arraybuffer',
            observe: 'response',
            params: toParams(options?.params)
        })
            .toPromise()
            .then((response: any) => {
                const data = response.body;
                const headers = response.headers;
        
                const type = headers.get('Content-Type');
                const blob = new Blob([data], { type });
        
                if (options?.name) {
                    const fileReader = new FileReader();
                    fileReader.onload = () => {
                        const arrayBuffer = fileReader.result as ArrayBuffer;
                        const workbook = XLSX.read(arrayBuffer, { type: 'array' });
                        const csvData = XLSX.utils.sheet_to_csv(workbook.Sheets[workbook.SheetNames[0]]);
                        const csvBlob = new Blob([csvData], { type: 'text/csv' });
                        saveAs(csvBlob, options.name.replace('.xls', '.csv')); // Replace .xls with .csv in the filename
                    };
                    fileReader.readAsArrayBuffer(blob);
                }
        
                return blob;
            });
    }

    getAllWithCancelRequest(request?: any) {
        return new Promise<ApiPaginationData<T>>((resolve, reject) => {
            this.http.get<ApiPaginationResponse<T>>(`${this.basePath}`, { params: toParams(request) })
                .pipe(
                    takeUntil(this.cancelRequestGetAll$)
                )
                .subscribe(
                    ({ data }: any) => {
                        resolve(data);
                    },
                    error => {
                        reject(error);
                    }
                );
        });
    }

    cancelPendingRequestGetAll() {
        // Call this method to cancel pending requests
        this.cancelRequestGetAll$.next();
    }

}
