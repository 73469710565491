import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RepeaterComponent } from './repeater/repeater.component';
import { RepeaterTemplate } from './repeater/repeater-template';



@NgModule({
  declarations: [
    RepeaterComponent,
    RepeaterTemplate
  ],
  imports: [
    CommonModule
  ],
  exports: [
    RepeaterComponent,
    RepeaterTemplate
  ]
})
export class RepeaterModule { }
