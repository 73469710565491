import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '@app/shared/services/auth/auth.service';
import { HelperService } from '@app/shared/services/helper.service';

@Component({
    selector: 'app-forgot-password',
    templateUrl: './forgot-password.component.html',
    styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

    errorMessage: any = null;
    forgotPasswordForm: FormGroup;
    isLoading:boolean = false;

    constructor(
        private router: Router,
        private formBuilder: FormBuilder,
        private authService: AuthService,
        private helperService: HelperService,
    ) {
    }

    ngOnInit() {
        this.forgotPasswordForm = this.formBuilder.group({
            email: ['', [Validators.required, Validators.email]],
        });
    }

    onSubmit() {
        this.isLoading = true;
        if (this.forgotPasswordForm?.valid) {
            const request = {
                UserName: this.forgotPasswordForm?.value?.email
            }
            this.authService.forgotPassword(request).then((res)=>{
                this.isLoading = false;
                this.helperService.successMessage('Password reset link has been sent')
                this.router.navigate(['/auth/login']);     
            }).catch((error)=>{
                this.isLoading = false;
                this.errorMessage = error?.messages[0];
            })
        }

    }

}
