import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '@app/services/api.service';

@Component({
  selector: 'app-quickbooks-authorization',
  templateUrl: './quickbooks-authorization.component.html',
  styleUrls: ['./quickbooks-authorization.component.sass']
})
export class QuickbooksAuthorizationComponent implements OnInit {

  code: string;
  realmId: string;

  constructor(private route: ActivatedRoute, private api: ApiService, private router : Router) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.code = params['code'];
      this.realmId = params['realmId'];
    });
    this.sendCode();
  }

  sendCode() : void {
    this.api.sendQuickbooksAuthorizationCode(this.code, this.realmId, success => { 
      this.navigateToIntegrations();
    }, error => { 
      this.navigateToIntegrations();
    });
  }

  navigateToIntegrations() : void {
    this.router.navigate(['/app/settings/integrations']);
  }

}
