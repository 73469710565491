import { Company } from '@models/company.model';
import { BaseModel } from '@models/base.model';

export interface Document extends BaseModel{
}

export enum DocumentViewMode {
    Pdf = 'Pdf',
    Html = 'Html',
}
