import { Component, OnInit, Input, ContentChildren, AfterViewInit, Output, EventEmitter, TemplateRef, OnDestroy } from '@angular/core';
import { ColumnComponent } from '../column/column.component';
import { merge, of, Subject } from 'rxjs';
import { switchMap, debounceTime } from 'rxjs/operators';
import { getValue, mapDatatableRequestToApi } from '../utils';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { RequestData } from '../datatable.interface';
import { chain, every, get, includes, map, omit, some } from 'lodash';
import { RowToggleHandle } from '../row-toggle-handle';
import { CatchDataStore, DatatableCacheService } from '../datatable-cache.service';
import { Router } from '@angular/router';
import { SelectionModel } from './selection-model';


export interface PeriodicElement {
    value: any;
}

@UntilDestroy()
@Component({
    selector: 'datatable',
    templateUrl: './datatable.component.html',
    styleUrls: ['./datatable.component.scss']
})
export class DatatableComponent implements OnInit, AfterViewInit, OnDestroy {
    detailsRowLoading = new Map<any, any>()
    detailsRowData = new Map<any, any>()
    openRows: SelectionModel<PeriodicElement>;
    selection = new SelectionModel<PeriodicElement>(true, []);

    _this: DatatableComponent;
    getValue = getValue;
    isInit: any;
    resultsLength = 0;
    error: any;
    isLoadingResults = true;
    displayNoRecords = false;
    dataStore: any[];
    dataApi: Function | null;
    requestData: RequestData;
    hasCollapsableCell = false  

    datatableRefresh: Subject<any> = new Subject();
    filter: Subject<any> = new Subject();
    paginator: Subject<any> = new Subject();
    sort: Subject<any> = new Subject();

    @Output() onSelectionChange: EventEmitter<any[]> = new EventEmitter<any[]>();
    @Output() onRowClick: EventEmitter<any> = new EventEmitter<any>();

    @Input() hasDetailRowHandle: boolean;
    @Input() key: string;
    @Input() isClickable: boolean;
    @Input() uniqueBy: string | ((row) => string) = 'id';
    @Input() filterInput: HTMLInputElement;
    @Input() firstPageIndex = 1;
    @Input() defaultOrder: string;
    @Input() defaultOrderDir: 'asc' | 'desc' = 'asc';
    @Input() allowFilter = true;
    @Input() selectable: any = false;
    @Input() icon: any = false;
    @Input() pagination: any = true;
    @Input() initialDataLoading: any = true;
    @Input() limit = 10;
    @Input() rowClass = (row) => '';
    @Input() detailRowTemplate: TemplateRef<any>;
    @Input() collapsableRow: (row) => boolean;
    @Input() onDetailRowToggle: (row: any, isOpen: boolean, oldData?: any) => any;

    pageSizeOptions = [10, 20, 50, 100]

    private _data: any;
    allChecked = false;
    indeterminate = false;
    @Input() get data(): any {
        return this._data;
    }
    set data(val: any) {
        if (val instanceof Function) {
            this._data = [];
            this.dataApi = val;
        } else {
            this.dataApi = null;
            this._data = val;
            this.datatableRefresh.next(true);
        }
    }
    @Input() requestMap: (request: any) => any = (request) => request;


    @ContentChildren(ColumnComponent) cols: ColumnComponent[];
    @ContentChildren(RowToggleHandle) rowToggleHandles: RowToggleHandle[];


    get columnCounts() {
        let count = this.cols.length;
        if (this.selectable) {
            count = count + 1;
        }
        if (this.detailRowTemplate) {
            count = count + 1;
        }
        return count
    }


    constructor(
        private router: Router,
        private datatableCacheService: DatatableCacheService
    ) {
        this._this = this;
        this.openRows = new SelectionModel<PeriodicElement>(true, [], null, this.compareWith);
        this.requestData = {
            order: {}
        }
        if (!this.key) {
            this.key = this.router.url
        }
    }

    getRowUniqId(row) {
        if (this.uniqueBy) {
            if (typeof this.uniqueBy === 'string') {
                return get(row, this.uniqueBy)
            } else {
                return get(row, this.uniqueBy(row))
            }
        } else {
            return row
        }
    }

    clearSelection() {
        this.selection.clear()
    }

    masterToggle($event) {
        if ($event.target.checked) {
            this.dataStore.forEach(row => this.selection.select(row._datatableRowId));
        }
        else {
            this.dataStore.forEach(row => this.selection.deselect(row._datatableRowId));

        }
    }

    toggleSelection(row) {
        this.selection.toggle(row._datatableRowId)
    }

    async toggleDetailsRow(row) {
        this.openRows.toggle(row);
        if (this.onDetailRowToggle) {
            this.detailsRowLoading.set(row?._datatableRowId, true)
            const data = await this.onDetailRowToggle(row, this.openRows.isSelected(row), this.detailsRowData.get(row?._datatableRowId))
            this.detailsRowData.set(row?._datatableRowId, data)
            this.detailsRowLoading.set(row?._datatableRowId, false)
        }

        this.updateDatatableCache({
            openRows: this.openRows.selected
        })
    }

    async handleRowClick(event, row) {
        event.preventDefault();
        event.stopPropagation();

        if (this.isClickable) {
            this.onRowClick.emit(row);
        }
    }

    async handleToggleDetailsClick(event, row) {
        const isDisabled = this.openRows.isDisabled(row)
        if (isDisabled) {
            return
        }
        event.preventDefault();
        event.stopPropagation();

        await this.toggleDetailsRow(row)

    }

    ngOnInit() {

        merge(
            this.datatableRefresh,
            this.filter.pipe(debounceTime(this.dataApi ? 500 : 0))
        )
            .pipe(untilDestroyed(this))
            .subscribe((data: any) => {
                this.clearSelection()
                
            });

        merge(
            this.sort,
            this.datatableRefresh,
            this.paginator,
            this.filter.pipe(debounceTime(this.dataApi ? 500 : 0))
        )
            .pipe(
                debounceTime(this.dataApi ? 500 : 0),
                switchMap((data) => {
                    if (this.dataApi) {
                        // this.dataStore = [];
                        this.isLoadingResults = true;
                        if (!this.isInit) {
                            return of();
                        }

                        const request = this.requestMap(mapDatatableRequestToApi(this.requestData))

                        return this.dataApi(request)
                            .then((resp) => {
                                this.updateDatatableCache({ filter: request });
                                return resp;
                            })
                            .catch((error) => {
                                this.dataStore = [];
                                this.resultsLength = 0;
                                this.isLoadingResults = false;
                                this.error = error.message || error.messages?.join('\n');
                                return { list: [], totalCount: 0 };
                            });
                    } else {
                        const offset = this.requestData.limit * (this.requestData.page - 1);
                        let data: any = chain(this.data)
                            .filter((obj) => this.searchInOject(obj))
                            .orderBy(this.requestData?.order?.name, this.requestData?.order?.direction)

                        const totalCount = data.value().length;

                        data = data.drop(offset)
                            .take(this.requestData.limit)
                            .value();

                        return of({ list: data, totalCount: totalCount });
                    }
                }),
                untilDestroyed(this)
            )
            .subscribe((data: any) => {
                this.resultsLength = data.totalCount || 0
                this.isLoadingResults = false;
                this.hasCollapsableCell = false
                this.dataStore = data.list?.map((item) => {
                    item = {
                        ...item,
                        _datatableRowId: this.getRowUniqId(item)
                    }
                    const isDisabled = (this.collapsableRow && !this.collapsableRow(item))
                    if (isDisabled) {
                        this.openRows.disable(item)
                    }
                    else{
                        this.hasCollapsableCell = true
                    }
                    return item
                });
                this.checkSelection()
            });
    }

    private loadRequestFromCatch() {
        // const cacheRequest = this.getDatatableCache();

        // if (cacheRequest?.filter) {
        //     this.requestData = mapApiRequestToDatatable(cacheRequest?.filter);
        // } else {
            this.requestData = {
                limit: this.limit,
                page: 1,
                ...(this.defaultOrder && {
                    order: {
                        direction: this.defaultOrderDir,
                        name: this.defaultOrder,
                        type: 'string'
                    }
                }),
                filterIn: this.cols?.filter(col => col.searchable).map((col) => ({ name: col.name, type: col.dataType }))
            }
        // }

        // if (cacheRequest.openRows) {
        //     this.openRows = new SelectionModel<PeriodicElement>(true, cacheRequest.openRows, null, this.compareWith)
        // }

    }

    compareWith = (oldValue, newValue) => {
        return this.getRowUniqId(oldValue) == this.getRowUniqId(newValue);
    }

    getDatatableCache(): CatchDataStore {
        return this.datatableCacheService.getCache(this.key);
    }

    setDatatableCache(value: CatchDataStore) {
        this.datatableCacheService.setCache(this.key, value);
    }

    updateDatatableCache(value: Partial<CatchDataStore>) {
        this.datatableCacheService.updateCache(this.key, value);
    }

    clearDatatableCache() {
        this.datatableCacheService.clearCache(this.key);
        this.openRows = new SelectionModel<PeriodicElement>(true, [], null, this.compareWith);
    }

    searchInOject(item: any) {
        if (!this.requestData?.filterValue || this.requestData?.filterValue == '') {
            return true
        }
        const findInAny = this.requestData.filterIn.map(({ name }) => {
            return includes((item[name] + '').toLocaleLowerCase(), this.requestData?.filterValue?.toLocaleLowerCase())
        })
        return includes(findInAny, true);
    }

    checkSelection() {
        const numSelected = Array.from(this.selection.selected);
        const currentIds = map(this.dataStore, '_datatableRowId');
        this.allChecked = every(currentIds, rowId => includes(numSelected, rowId))
        this.indeterminate = false
        if (numSelected?.length > 0 && !this.allChecked) {
            this.indeterminate = some(currentIds, rowId => includes(numSelected, rowId))
        }
    }

    ngAfterViewInit() {

       this.loadRequestFromCatch();
       
        this.isInit = true;
        if (this.initialDataLoading) {
            this.datatableRefresh.next(true)
        }

        if (this.filterInput) {
            this.filterInput.addEventListener('input', this.handleSearchChange)
        }

        this.selection.changed
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                this.checkSelection()
                this.onSelectionChange.emit(this.selection.selected)
            })
    }

    handlePagination(page) {
        this.requestData.page = page;
        this.paginator.next(page)
    }

    handleParPageItems(count: number) {
        this.requestData.limit = count;
        this.refresh(true);
    }

    handleSortChange(col: ColumnComponent) {
        if (!col.sortable) {
            return
        }
        this.requestData.page = this.firstPageIndex;
        if (col.name === this.requestData.order?.name) {
            this.requestData.order.direction = this.requestData.order.direction === 'asc' ? 'desc' : 'asc';
        } else {
            this.requestData.order = {
                direction: 'asc',
                name: col.name,
                type: col.dataType,
            }
        }

        this.sort.next({
            ...this.requestData.order,
        })
    }

    handleSearchChange = (event) => {
        this.setSearchValue(event.target.value)
    }

    setSearchValue(filterValue) {
        this.requestData.page = this.firstPageIndex;
        this.requestData.filterValue = filterValue;
        this.filter.next(filterValue);

        if (filterValue && filterValue.length === 0) {
            this.displayNoRecords = true;
        } else {
            this.displayNoRecords = false;
        }
    }


    setData(data: any[]) {
        this.dataStore = data;
    }

    refresh(pageRest = false) {
        if (pageRest) {
            this.requestData.page = this.firstPageIndex
            this.clearDatatableCache();
        }
        this.datatableRefresh.next({});

    }

    refreshWithUpdatedDetailsRowData() {
        const cacheRequest = this.getDatatableCache();
        const rows = cacheRequest?.openRows;
        if (rows && rows?.length > 0) {
            rows.forEach(async row => {
                if (this.onDetailRowToggle) {
                    this.detailsRowLoading.set(row?._datatableRowId, true);
                    const data = await this.onDetailRowToggle(row, this.openRows.isSelected(row), null);
                    this.detailsRowData.set(row?._datatableRowId, data);
                    this.detailsRowLoading.set(row?._datatableRowId, false);
                }
            });
        }
        this.datatableRefresh.next({});
    }

    setOrderBy(column: string, order: 'asc' | 'desc' = 'asc', dataType: 'string' | 'date' | 'number' = 'string') {
        if (column) {
            this.requestData.order = {
                direction: order,
                name: column,
                type: dataType,
            }
        }
        else {
            this.requestData = omit(this.requestData, 'order')
        }
        this.sort.next({
            ...this.requestData
        });
    }

    ngOnDestroy(): void {
        if (this.filterInput) {
            this.filterInput.removeEventListener('input', this.handleSearchChange)
        }
    }

}
