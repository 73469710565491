import { Component, Input, OnInit } from '@angular/core';
import {
    LoadTypes, ShipmentType
} from '@models/shipment-type.model';
import { OrderItemStop } from '@models/order-item-stop.model';
import { Order } from '@models/order.model';
import { from, Observable, of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, map, switchMap, tap } from 'rxjs/operators';
import { Options } from '@angular-slider/ngx-slider';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ShipmentTypeService } from '@app/shared/services/shipment-type.service';
import { HelperService } from '@app/shared/services/helper.service';
import { flatten, groupBy } from 'lodash';
import { UtilService } from '@app/services/util.service';
import { OrderService } from '@app/shared/services/order.service';
import { Customer } from '@app/models/customer.model';
import { CustomerService } from '@app/shared/services/customer.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { object, string } from 'yup';


@UntilDestroy()
@Component({
    selector: 'app-shipment-type-details',
    templateUrl: './shipment-type-details.component.html',
    styleUrls: ['./shipment-type-details.component.scss'],
})
export class ShipmentTypeDetailsComponent implements OnInit {
    title = "Shipment Type"
    loading = true;
    saveLoading = false;
    public openSection = 1;
    public isNew = false;
    LoadTypes = LoadTypes;
    isTest = false;
    testHours = 3;
    testMiles = 50;
    tollTagCost = 10;
    tollCount = 1;
    testQuote: Order;
    selectedCustomer: Customer;

    pricesByLoadTypes: any = {}

    sliderOptions: Options = {
        floor: 0,
        ceil: 1000
    };

    rangeSliderOptions: Options = {
        floor: 0,
        ceil: 10000
    };

    searchingCustomer

    @Input() item: ShipmentType

    searchCustomer = (text$: Observable<string>) =>
        text$.pipe(
            debounceTime(500),
            distinctUntilChanged(),
            tap(() => this.searchingCustomer = true),
            switchMap(term => {
                const request = this.customerService.search(term)
                return from(request).pipe(
                    map((data) => data.list),
                    catchError(() => of([]))
                )
            }),
            tap(() => this.searchingCustomer = false),
            untilDestroyed(this)
        );

    validationSchema = object().shape({
        name: string().nullable().required(),
    });

    constructor(
        public ngbActiveModal: NgbActiveModal,
        private shipmentTypeService: ShipmentTypeService,
        public helperService: HelperService,
        public orderService: OrderService,
        public utilService: UtilService,
        public customerService: CustomerService,
    ) {
        LoadTypes.forEach((type) => {
            this.pricesByLoadTypes[type] = [{}];
        })
    }

    ngOnInit() {
        if (this.item?.shipmentTypeId) {
            this.loadEntity();
        } else {
            const entity = {
                name: '',
                pricesPerLoadType: [],
                perCftPrices: [],
                perLbPrices: [],
                perHourPrices: [],
                perMilePrices: []
            } as ShipmentType;
            this.item = entity;
            this.mapPricesByLoadTypes();
            this.loading = false;
        }
    }

    loadEntity() {
        this.shipmentTypeService.get(this.item?.shipmentTypeId).then((data) => {
            this.item = data;
            this.mapPricesByLoadTypes();
            this.loading = false;
        }).catch(error => {
            console.log(error);
        });
    }

    private mapPricesByLoadTypes(defaultItem = []) {
        const pricesByLoadTypes = groupBy(this.item.pricesPerLoadType, 'loadType')
        LoadTypes.forEach((type, index) => {
            this.pricesByLoadTypes[type] = pricesByLoadTypes[type] ? pricesByLoadTypes[type] : [{ loadType: type }]
        })
    }

    async save(f) {
        if (f.isValid) {
            this.saveLoading = true;
            try {
                const pricesPerLoadType = [];
                for (const key in this.pricesByLoadTypes) {
                    if (Object.prototype.hasOwnProperty.call(this.pricesByLoadTypes, key)) {
                        const value = this.pricesByLoadTypes[key] || [];
                        pricesPerLoadType.push(value.map((item) => {
                            if (item.from && item.price) {
                                return {
                                    loadType: key,
                                    to: null,
                                    ...item,
                                }
                            }
                        }).filter(Boolean));
                    }
                    this.item.pricesPerLoadType = flatten(pricesPerLoadType)
                }

                let resp: any;
                if (this.item?.shipmentTypeId) {
                    resp = await this.shipmentTypeService.update(this.item.shipmentTypeId, this.item)
                } else {
                    resp = await this.shipmentTypeService.create(this.item)
                }
                this.helperService.successMessage(`${this.title} successfully ${this.item?.shipmentTypeId ? 'updated' : 'created'}`);
                this.ngbActiveModal.close(resp)
                this.saveLoading = false;

            } catch (error) {
                console.log(error)
                this.helperService.errorMessage(error);
                this.saveLoading = false;
            }
        }
    }

    test(stop: OrderItemStop) {
        this.orderService.testPricing(this.item, stop, this.testHours, this.testMiles, this.tollTagCost, this.tollCount).then((res) => {
            this.testQuote = res;
        }).catch((error) => {
            this.helperService.errorMessage(error);
        })
    }

    customerResultFormatter(customer) {
        return customer.customerName;
    }

    selectCustomer(e): void {
        if (e) {
            this.item.customer = e.item;
            this.item.customerId = e.item.customerId;
        } else {
            this.item.customer = null;
            this.item.customerId = null;
        }
    }

    close(isReload?: boolean) {
        this.ngbActiveModal.close(isReload);
    }

}
