import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-order-delete-unpaid-invoice-dialog',
  templateUrl: './order-delete-unpaid-invoice-dialog.component.html',
  styleUrls: ['./order-delete-unpaid-invoice-dialog.component.scss']
})
export class OrderDeleteUnpaidInvoiceDialogComponent implements OnInit {

  constructor(
    public activeModal: NgbActiveModal,
  ) { }

  @Input() type: any;
  @Input() title: string;
  @Input() message: string;
  @Input() yesText: string = 'Yes';
  @Input() noText: string = 'Cancel';

  ngOnInit(): void { }

  cancel() {
    this.activeModal.close();
  }

  confirm() {
    this.activeModal.close(true);
  }


}
