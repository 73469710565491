import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbDropdownModule, NgbModalModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { AttachmentsComponent } from './attachments.component';
import { AttachmentDragDropComponent } from './attachment-drag-drop/attachment-drag-drop.component';
import { AttachmentDragDropDirective } from '@app/directives/attachment-drag-drop-directive.directive';
// import { Pipes } from '@app/shared/pipes';

@NgModule({
  declarations: [
    AttachmentsComponent,
    AttachmentDragDropComponent,
    AttachmentDragDropDirective,
  ],
  exports: [
    AttachmentsComponent,
    AttachmentDragDropComponent,
    AttachmentDragDropDirective
  ],
  imports: [
    CommonModule,
    NgbModalModule,
    NgbDropdownModule,
    NgbTooltipModule
  ]
})
export class AttachmentsModule { }
