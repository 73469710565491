import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

export interface ConfirmData {
    title?: string;
    message?: string;
    yesText?: string;
    noText?: string;
    hideYesButton?: boolean;
    hideNoButton?: boolean;
    callback?: (isConfirm: boolean) => void
}

@Component({
    selector: 'app-confirm',
    templateUrl: './confirm.component.html',
    styleUrls: ['./confirm.component.scss']
})
export class ConfirmComponent implements OnInit {

    @Input() data: ConfirmData;

    constructor(
        public readonly activeModal: NgbActiveModal
    ) {}

    ngOnInit() {
    }

    callback(confirm: any) {
        if (this.data.callback) {
            this.data.callback(confirm);
        }
        this.activeModal.close(confirm);
    }
}
