import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@app/shared/shared.module';
import { FormsModule } from '@angular/forms';
import { AdditionalChargesDropDownSelectComponent } from './additional-charges-drop-down-select.component';

@NgModule({
  declarations: [
    AdditionalChargesDropDownSelectComponent
  ],
  exports: [
    AdditionalChargesDropDownSelectComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule
  ],
})
export class AdditionalChargesDropDownSelectModule { }
