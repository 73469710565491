import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiResponse, DataApiResponse, handleApiError, ListResponse } from '@app/helpers/api';
import { environment } from '@environments/environment';
import { catchError, map } from 'rxjs/operators';
import { UtilService } from '@app/services/util.service';
import { CustomerEmail } from '@models/customer-email.model';
import { CustomerContact } from '@models/customer-contact.model';
import { Customer } from '@models/customer.model';
import { Observable } from 'rxjs/internal/Observable';
import { PaymentMethod } from '@models/payment-method.model';

export interface CustomerContactDetails {
    customerEmails: CustomerEmail[];
    customerContacts: CustomerContact[];
}

@Injectable({ providedIn: 'root' })
export class CustomerService {
    constructor(
        private http: HttpClient,
        private util: UtilService,
    ) {
    }

    getCustomerContactDetails(customerId: number) {
        return this.http.get<DataApiResponse<CustomerContactDetails>>(
            `${environment.apiBaseUrl}Customers/${customerId}/contacts`
        ).pipe(map(data => {
                return data.data;
            }),
            catchError(handleApiError));
    }

    getCustomerFormUrl(customerId: number) {
        return this.http.get<DataApiResponse<string>>(
            `${environment.apiBaseUrl}Customers/${customerId}/formLink`
        ).pipe(map(data => {
                return data.data;
            }),
            catchError(handleApiError));
    }

    updateCustomerByToken(token: string, customer: Customer) {
        return this.http.put<DataApiResponse<CustomerContactDetails>>(
            `${environment.apiBaseUrl}Customers/token/${token}`, customer
        ).pipe(map(data => {
                return data.data;
            }),
            catchError(handleApiError));
    }

    getCustomerByToken(token: string) {
        return this.http.get<DataApiResponse<Customer>>(
            `${environment.apiBaseUrl}Customers/token/${token}`
        ).pipe(map(data => {
                return data.data;
            }),
            catchError(handleApiError));
    }

    deleteCustomerPaymentMethodByToken(token: string, paymentMethodId: number) {
        return this.http.delete<ApiResponse>(
            `${environment.apiBaseUrl}Customers/token/${token}/PaymentMethods/${paymentMethodId}`
        ).pipe(map(data => {
                return data;
            }),
            catchError(handleApiError));
    }

    addCustomerPaymentMethodByToken(token: string, paymentMethod: PaymentMethod) {
        return this.http.post<ApiResponse>(
            `${environment.apiBaseUrl}Customers/token/${token}/PaymentMethods`, paymentMethod
        ).pipe(map(data => {
                return data;
            }),
            catchError(handleApiError));
    }

    updateCustomerPaymentMethodByToken(token: string, id: number, paymentMethod: PaymentMethod) {
        return this.http.put<ApiResponse>(
            `${environment.apiBaseUrl}Customers/token/${token}/PaymentMethods/${id}`, paymentMethod
        ).pipe(map(data => {
                return data;
            }),
            catchError(handleApiError));
    }

    public uploadCustomerImageByToken(token: string, file: File): Observable<string> {
        const formData = new FormData();
        formData.append('file', file);

        return this.http.post<DataApiResponse<string>>(`${environment.apiBaseUrl}Customers/token/${token}/image`, formData)
            .pipe(map(data => {
                    console.log(data);
                    return data.data;
                }),
                catchError(handleApiError));
    }
}
