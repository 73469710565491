import { map } from "lodash";

export const sates = [
    { sortName: 'AL', name: 'Alabama', },
    { sortName: 'AK', name: 'Alaska', },
    { sortName: 'AS', name: 'American Samoa', },
    { sortName: 'AZ', name: 'Arizona', },
    { sortName: 'AR', name: 'Arkansas', },
    { sortName: 'CA', name: 'California', },
    { sortName: 'CO', name: 'Colorado', },
    { sortName: 'CT', name: 'Connecticut', },
    { sortName: 'DE', name: 'Delaware', },
    { sortName: 'DC', name: 'District of Columbia', },
    { sortName: 'FSM', name: 'Federated States of Micronesia', },
    { sortName: 'FL', name: 'Florida', },
    { sortName: 'GA', name: 'Georgia', },
    { sortName: 'GU', name: 'Guam', },
    { sortName: 'HI', name: 'Hawaii', },
    { sortName: 'ID', name: 'Idaho', },
    { sortName: 'IL', name: 'Illinois', },
    { sortName: 'IN', name: 'Indiana', },
    { sortName: 'IA', name: 'Iowa', },
    { sortName: 'KS', name: 'Kansas', },
    { sortName: 'KY', name: 'Kentucky', },
    { sortName: 'LA', name: 'Louisiana', },
    { sortName: 'ME', name: 'Maine', },
    { sortName: 'MH', name: 'Marshall Islands', },
    { sortName: 'MD', name: 'Maryland', },
    { sortName: 'MA', name: 'Massachusetts', },
    { sortName: 'MI', name: 'Michigan', },
    { sortName: 'MN', name: 'Minnesota', },
    { sortName: 'MS', name: 'Mississippi', },
    { sortName: 'MO', name: 'Missouri', },
    { sortName: 'MT', name: 'Montana', },
    { sortName: 'NE', name: 'Nebraska', },
    { sortName: 'NV', name: 'Nevada', },
    { sortName: 'NH', name: 'New Hampshire', },
    { sortName: 'NJ', name: 'New Jersey', },
    { sortName: 'NM', name: 'New Mexico', },
    { sortName: 'NY', name: 'New York', },
    { sortName: 'NC', name: 'North Carolina', },
    { sortName: 'ND', name: 'North Dakota', },
    { sortName: 'MP', name: 'Northern Mariana Islands', },
    { sortName: 'OH', name: 'Ohio', },
    { sortName: 'OK', name: 'Oklahoma', },
    { sortName: 'OR', name: 'Oregon', },
    { sortName: 'PW', name: 'Palau', },
    { sortName: 'PA', name: 'Pennsylvania', },
    { sortName: 'PR', name: 'Puerto Rico', },
    { sortName: 'RI', name: 'Rhode Island', },
    { sortName: 'SC', name: 'South Carolina', },
    { sortName: 'SD', name: 'South Dakota', },
    { sortName: 'TN', name: 'Tennessee', },
    { sortName: 'TX', name: 'Texas', },
    { sortName: 'UT', name: 'Utah', },
    { sortName: 'VT', name: 'Vermont', },
    { sortName: 'USVI', name: 'Virgin Island', },
    { sortName: 'VA', name: 'Virginia', },
    { sortName: 'WA', name: 'Washington', },
    { sortName: 'WV', name: 'West Virginia', },
    { sortName: 'WI', name: 'Wisconsin', },
    { sortName: 'WY', name: 'Wyoming', },
];


export const stateOptions = map(sates).map(({ sortName, name }) => ({ id: sortName, text: name }))

export const statesFilterOptions = sates.map(state => {
    return {
        name: state.name,
        value: state.sortName, // or any other value you want to assign
        selected: false // or any other boolean value based on your logic
    };
});