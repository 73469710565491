import { Address } from './address.model';
import { ShipmentType } from '@models/shipment-type.model';
import { OrderItemStop } from '@models/order-item-stop.model';
import { Customer } from '@models/customer.model';
import { Attachment } from '@models/attachment.model';
import { BaseModel, CompanyBase, TenantBase } from '@models/base.model';
import { RouteItem } from '@models/route-item.model';
import { OrderPriorityEnum } from './order.model';

export interface OrderStop extends BaseModel, TenantBase, CompanyBase {
    orderId?: number;
    shipmentTypeId?: number;
    priority?: OrderPriorityEnum;
    departureDateTime?: Date;
    departureTime?: Date;
    arrivalDateTime?: Date;
    arrivalTime?: Date;
    arrivalDate?: Date;
    waitingTime?: Date | number | string;
    orderStopId?: number;
    order?: string;
    orderStopType?: OrderStopTypeEnum;
    addressId?: number;
    address?: Address;
    addressDetails?: string;
    locationCompanyName?: string;
    locationContactNumber?: string;
    locationContactName?: string;
    locationContactNumberExtension?: string;
    referenceNumber?: string;
    dateType?: OrderStopDateTypeEnum;
    dateTimeToDisplay?: string; //Frontend only property
    date?: string;
    timeType?: OrderStopTimeTypeEnum;
    time?: string;
    orderItemStops?: OrderItemStop[];
    isActive?: boolean;
    routeItems?: RouteItem[];
    createdTime?: string;
    createdUser?: string;
    updatedTime?: string;
    updatedUser?: string;
    shipmentType?: ShipmentType;
    customer?: Customer;
    attachments?: Attachment[];
    tempOrderStopId?: string;
    isBlindStop?: boolean;
    blindCustomer?: Customer;
    blindAddress?: Address;
    blindCustomerName?: string;
    blindAddressFullAddress?: string;
    sortOrder?: number;
    needForPickup?: any;
    needForDelivery?: any;
    driverSettings?: any;
    notes?: string; // TO-DO: Need to add field in API
    isAppointmentRequired?: boolean; // TO-DO: Need to add field in api
    appointmentEmail?: string; // TO-DO: Need to add field in api
    locationContactEmail?: string; // TO-DO: Need to add field in API
    orderStopDelivery?: any; // TO-DO: Need to add field in API
    warehouseId?: number;
    newLocationName?: string;
    overrideAddress?: boolean;
    isNewLocationCompanyName?: boolean;
    callOutcomeResult?: OrderStopCallOutcomeResultEnum;
    activities?:any; //Frontend only property
}


export enum OrderStopCallOutcomeResultEnum {
    CallNotMade = 1,
    Confirmed = 2,
    DoNotDeliver = 3
}

export enum OrderStopDateTypeEnum {
    TODAY = "Today",
    TOMORROW = "Tomorrow",
    NONE = "Custom",
}

export enum OrderStopTimeTypeEnum {
    MORNING = "Morning",
    NOON = "Noon",
    NONE = "None",
    EVENING = "Evening",
    CUSTOM = "Custom",
}

export enum OrderStopTypeEnum {
    PICKUP = "Pickup",
    DELIVERY = "Delivery",
    TRANSFER = "Transfer",
}
