import { Address } from '@models/address.model';
import { RouteItem, RouteItemStatus } from '@models/route-item.model';
import { OrderStop } from '@models/order-stop.model';
import { ShipmentType } from '@models/shipment-type.model';
import { Customer } from '@models/customer.model';
import { BaseModel } from '@models/base.model';
import { ChargeType } from '@models/charge-type.model';
import { OrderPriorityEnum } from './order.model';
import { CustomerContact } from './customer-contact.model';

export enum RouteItemType {
    Pickup = 'Pickup',
    DeliveryToTransfer = 'DeliveryToTransfer',
    PickupFromTransfer = 'PickupFromTransfer',
    Delivery = 'Delivery',
}

export enum RouteItemDisplayStatus {
    OnTime = 'OnTime',
    PickedUp = 'PickedUp',
    PickedUpFromTransfer = 'PickedUpFromTransfer',
    Late = 'Late',
    Delivered = 'Delivered',
    DeliveredToTransfer = 'DeliveredToTransfer',
}

export interface RouteItemStop extends BaseModel {
    orderId?: number;
    orderNumber?: string;
    customer?: Customer;
    customerContact?: CustomerContact;
    priority?: OrderPriorityEnum;
    sortOrder?: number;
    referenceNumber?: string;
    type?: RouteItemType;
    status?: 0 | 1; /*RouteItemStatus;*/
    address?: Address,
    dateType?: string;
    date?: string;
    timeType?: string;
    time?: string;
    transferStop?:any;
    shipmentType?: ShipmentType;
    routeItems?: RouteItem[];
    arrivalDateTime?: string | Date;
    departureDateTime?: string | Date;
    confirmedWithoutAssignItems?: boolean;
    toPickUp?: boolean;
    assignPickup?: boolean;
    assignDelivery?: boolean;
    assignTransfer?: boolean;
    getTransferRouteItem?: RouteItem,
    displayStatus?: RouteItemDisplayStatus;
    orderStop?: OrderStop;
    departureTime?: number | string;
    arrivalTime?: number | string;
    arrivalDate?: number | Date;
    waitingTime?: number | string;
    charges?: ChargeType[];
    index?: number;
    dateToDisplay?: string; //Frontend only property
    isPopupOpen?: boolean; //Frontend only property
}
