import { AfterViewInit, Component, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { NgModel, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ApiPaginationData } from '@app/models/core/base';
import { Truck } from '@app/models/truck.model';
import { ZoneSearchRequest } from '@app/models/zone.model';
import { TruckService } from '@app/shared/services/truck.service';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { from, of } from 'rxjs';
import { catchError, debounceTime, switchMap, tap } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'app-truck-select',
  templateUrl: './truck-select.component.html',
  styleUrls: ['./truck-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TruckSelectComponent),
      multi: true
    }
  ],
  encapsulation: ViewEncapsulation.Emulated
})
export class TruckSelectComponent implements OnInit, AfterViewInit, OnDestroy {

  search: string;
  trucks: Truck[];
  searchingTruck: boolean;
  disabled: boolean;
  hasMorePage: boolean = false;
  page: number = 1;

  @ViewChild('dropdown') dropdown: NgbDropdown;
  @ViewChild('searchInput') searchInput: NgModel;

  onChange = (value: Truck) => { };
  onTouched = () => { };

  @Output() truckChange = new EventEmitter<any>();
  @Input() initialLoad: boolean;
  @Input() type: 'normal' | 'custom' = 'normal';


  private _value: Truck;
  get value(): Truck {
    return this._value;
  }
  set value(updateValue: Truck) {
    this._value = updateValue;
    this.onChange(updateValue);
    this.onTouched();
  }


  constructor(
    private truckService: TruckService,
  ) { }


  ngOnInit(): void {

  }

  ngAfterViewInit() {
    this.searchInput.valueChanges
      .pipe(
        untilDestroyed(this),
        tap(() => {
          this.searchingTruck = true;
          this.trucks = [];
        }),
        debounceTime(500),
        switchMap(term => {
          this.page = 1;
          const request = this.getTrucks({
            SearchTerm: term ? term : '',
          })
          return from(request).pipe(
            catchError(() => of([]))
          )
        }),
      ).subscribe((val) => {
        // nothing
      })


    if (this.initialLoad) {
      this.getTrucks({ SearchTerm: '' })
    }
  }

  loadMoreTruck() {
    this.page++;
    this.getTrucks()
  }

  getTrucks(filter?: Partial<ZoneSearchRequest>) {
    this.searchingTruck = true
    let request = {
      Page: this.page,
      ItemsPerPage: 20,
      ...filter,
    }
    let resp: Promise<ApiPaginationData<Truck>>;
    resp = this.truckService.getAll(request)
    resp.then((resp) => {
      const { list, totalCount, itemsPerPage } = resp;
      this.hasMorePage = totalCount > (this.page * itemsPerPage);
      if (this.page > 1) {
        this.trucks = this.trucks.concat(list);
      } else {
        this.trucks = list;
      }
    }).finally(() => {
      this.searchingTruck = false
    })
    return resp;
  }

  selectedTrucks(Truck) {
    this.value = Truck;
    this.dropdown.close();
    this.truckChange.emit(this.value);
  }

  writeValue(value: Truck) {
    this._value = value
  }

  registerOnChange(fn: (value: Truck) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  clearValue(): void {
    this.value = null;
    this.truckChange.emit(true);
    this.getTrucks({ SearchTerm: 'a' })
  }
  ngOnDestroy(): void {
    // nothing
  }

}
