import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RepeaterModule } from '@app/@shared/repeater/repeater.module';
import { AttachmentsModule } from '@app/@shared/attachments/attachments.module';
import { QtySelectModule } from '@app/@shared/qty-select/qty-select.module';
import { SharedModule } from '@app/shared/shared.module';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { OrderStopItemModule } from '../order-stop-item/order-stop-item.module';
import { EditDeliveryDialogModule } from '../edit-delivery-dialog/edit-delivery-dialog.module';
import { EditDeliveryItemDialogComponent } from './edit-delivery-item-dialog/edit-delivery-item-dialog.component';

@NgModule({
  declarations: [
    EditDeliveryItemDialogComponent,
  ],
  exports: [
    EditDeliveryItemDialogComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    RepeaterModule,
    SharedModule,
    AttachmentsModule,
    QtySelectModule,
    NgbDropdownModule,
    OrderStopItemModule,
    EditDeliveryDialogModule,
  ]
})
export class EditDeliveryItemDialogModule { }
