export const mapStyles = [
    {
        featureType: 'all',
        elementType: 'all',
        stylers: [
            {
                visibility: 'on',
            },
        ],
    },
    {
        featureType: 'administrative',
        elementType: 'labels.text.fill',
        stylers: [
            {
                color: '#444444',
            },
        ],
    },
    {
        featureType: 'administrative.country',
        elementType: 'all',
        stylers: [
            {
                visibility: 'on',
            },
        ],
    },
    {
        featureType: 'administrative.province',
        elementType: 'all',
        stylers: [
            {
                visibility: 'off',
            },
        ],
    },
    {
        featureType: 'administrative.locality',
        elementType: 'all',
        stylers: [
            {
                visibility: 'simplified',
            },
            {
                color: '#00bad7',
            },
        ],
    },
    {
        featureType: 'administrative.neighborhood',
        elementType: 'all',
        stylers: [
            {
                visibility: 'off',
            },
        ],
    },
    {
        featureType: 'administrative.land_parcel',
        elementType: 'all',
        stylers: [
            {
                visibility: 'off',
            },
        ],
    },
    {
        featureType: 'administrative.land_parcel',
        elementType: 'labels.text',
        stylers: [
            {
                visibility: 'off',
            },
        ],
    },
    {
        featureType: 'landscape',
        elementType: 'all',
        stylers: [
            {
                color: '#f2f2f2',
            },
        ],
    },
    {
        featureType: 'landscape.man_made',
        elementType: 'all',
        stylers: [
            {
                visibility: 'simplified',
            },
        ],
    },
    {
        featureType: 'poi',
        elementType: 'all',
        stylers: [
            {
                visibility: 'off',
            },
            {
                color: '#cee9de',
            },
            {
                saturation: '2',
            },
            {
                weight: '0.80',
            },
        ],
    },
    {
        featureType: 'poi.attraction',
        elementType: 'geometry.fill',
        stylers: [
            {
                visibility: 'off',
            },
        ],
    },
    {
        featureType: 'poi.park',
        elementType: 'all',
        stylers: [
            {
                visibility: 'on',
            },
        ],
    },
    {
        featureType: 'poi.park',
        elementType: 'geometry',
        stylers: [
            {
                visibility: 'simplified',
            },
        ],
    },
    {
        featureType: 'poi.park',
        elementType: 'labels',
        stylers: [
            {
                visibility: 'off',
            },
        ],
    },
    {
        featureType: 'road',
        elementType: 'all',
        stylers: [
            {
                saturation: -100,
            },
            {
                lightness: 45,
            },
        ],
    },
    {
        featureType: 'road.highway',
        elementType: 'all',
        stylers: [
            {
                visibility: 'simplified',
            },
        ],
    },
    {
        featureType: 'road.highway',
        elementType: 'geometry.fill',
        stylers: [
            {
                visibility: 'on',
            },
            {
                color: '#f5d6d6',
            },
        ],
    },
    {
        featureType: 'road.highway',
        elementType: 'labels.text',
        stylers: [
            {
                visibility: 'off',
            },
        ],
    },
    {
        featureType: 'road.highway',
        elementType: 'labels.icon',
        stylers: [
            {
                hue: '#ff0000',
            },
            {
                visibility: 'on',
            },
        ],
    },
    {
        featureType: 'road.highway.controlled_access',
        elementType: 'labels.text',
        stylers: [
            {
                visibility: 'simplified',
            },
        ],
    },
    {
        featureType: 'road.highway.controlled_access',
        elementType: 'labels.icon',
        stylers: [
            {
                visibility: 'on',
            },
            {
                hue: '#0064ff',
            },
            {
                gamma: '1.44',
            },
            {
                lightness: '-3',
            },
            {
                weight: '1.69',
            },
        ],
    },
    {
        featureType: 'road.arterial',
        elementType: 'all',
        stylers: [
            {
                visibility: 'on',
            },
        ],
    },
    {
        featureType: 'road.arterial',
        elementType: 'labels.text',
        stylers: [
            {
                visibility: 'off',
            },
        ],
    },
    {
        featureType: 'road.arterial',
        elementType: 'labels.icon',
        stylers: [
            {
                visibility: 'off',
            },
        ],
    },
    {
        featureType: 'road.local',
        elementType: 'all',
        stylers: [
            {
                visibility: 'on',
            },
        ],
    },
    {
        featureType: 'road.local',
        elementType: 'labels.text',
        stylers: [
            {
                visibility: 'simplified',
            },
            {
                weight: '0.31',
            },
            {
                gamma: '1.43',
            },
            {
                lightness: '-5',
            },
            {
                saturation: '-22',
            },
        ],
    },
    {
        featureType: 'transit',
        elementType: 'all',
        stylers: [
            {
                visibility: 'off',
            },
        ],
    },
    {
        featureType: 'transit.line',
        elementType: 'all',
        stylers: [
            {
                visibility: 'on',
            },
            {
                hue: '#ff0000',
            },
        ],
    },
    {
        featureType: 'transit.station.airport',
        elementType: 'all',
        stylers: [
            {
                visibility: 'on',
            },
            {
                hue: '#ff0045',
            },
        ],
    },
    {
        featureType: 'transit.station.bus',
        elementType: 'all',
        stylers: [
            {
                visibility: 'off',
            },
            {
                hue: '#00d1ff',
            },
        ],
    },
    {
        featureType: 'transit.station.bus',
        elementType: 'labels.text',
        stylers: [
            {
                visibility: 'simplified',
            },
        ],
    },
    {
        featureType: 'transit.station.rail',
        elementType: 'all',
        stylers: [
            {
                visibility: 'off',
            },
            {
                hue: '#00cbff',
            },
        ],
    },
    {
        featureType: 'transit.station.rail',
        elementType: 'labels.text',
        stylers: [
            {
                visibility: 'simplified',
            },
        ],
    },
    {
        featureType: 'water',
        elementType: 'all',
        stylers: [
            {
                color: '#46bcec',
            },
            {
                visibility: 'off',
            },
        ],
    },
    {
        featureType: 'water',
        elementType: 'geometry.fill',
        stylers: [
            {
                weight: '1.61',
            },
            {
                color: '#cde2e5',
            },
            {
                visibility: 'on',
            },
        ],
    },
];