import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilService } from 'src/app/services/util.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { HelperService } from '@app/shared/services/helper.service';
import { EntityType } from '@app/models/entity-type.model';
import { Address } from '@app/models/address.model';
import { AddressService } from '@app/shared/services/address.service';
import { OutsourceCompany } from '@app/models/outsource-company.model';
import { OutsourceCompanyService } from '@app/shared/services/outsource-company.service';

@Component({
    selector: 'app-outsource-company-details',
    templateUrl: './outsource-company-details.component.html',
    styleUrls: ['./outsource-company-details.component.scss'],
    host: {
        '[class.page-container]': "true"
    }
})
export class OutsourceCompanyDetailsComponent implements OnInit {
    activeId = 'outsourceInformation'
    outsourceCompany: OutsourceCompany = {};
    states = [];
    isOpen = 'company';
    modalRef: BsModalRef;
    addContactShow: boolean = false;
    editContactShow: any;
    doughnutChart = {
        data: {
            datasets: [{
                data: [3506, 6494],
                backgroundColor: ['rgba(29, 161, 242, 1)', 'rgba(201, 232, 252, 1)']
            }]
        },
        options: {
            responsive: true,
            maintainAspectRatio: false,
            cutout: '84%',
            hover: { mode: null },
            plugins: { tooltip: { enabled: false} },
            elements: {
                arc: { borderWidth: 0 },
            },
        },
    };
    barChartData = {
        data: {
            labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug'],
            datasets: [{
                data: [1000, 155, 360, 500, 100, 588, 52, 400],
                backgroundColor: '#00BAD7'
            }]
        },
        options: {
            scales: {
                xAxes: [{
                    gridLines: {
                        display: false
                    }
                }],
                yAxes: [{
                    gridLines: {
                        display: false
                    },
                    ticks: {
                        steps: 100,
                        stepValue: 100,
                        min: 0
                    }
                }]
            }
        },
    };

    private _data: OutsourceCompany;
    @Input()
    public get data(): OutsourceCompany {
        return this._data;
    }
    public set data(value: OutsourceCompany) {
        this._data = value;
        this.outsourceCompany = value;
    }
    @Output() dataChange = new EventEmitter<OutsourceCompany>();

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private helperService: HelperService,
        public util: UtilService,
        public outsourceCompanyService: OutsourceCompanyService,
        private readonly addressService: AddressService,
    ) { }

    ngOnInit() {
    }

    getOutsource() {
        this.outsourceCompanyService.get(this.outsourceCompany.id).then((res) => {
            this.outsourceCompany = res;
            if (this.outsourceCompany.companyEmails.length === 0) {
                this.outsourceCompany.companyEmails.push({
                    isActive: true,
                });
            }
        }).catch((error) => {
            this.router.navigateByUrl('app/outsource');
            this.helperService.errorMessage("Outsource not found.")
        })
    }

    isOpenChange(section, open) {
        if (open) {
            this.isOpen = section;
        }
    }

    handleBrokerAccountChange(value) {

    }

    /**
     * Update customer information
     * 
     * @param customer 
     */
    async updateOutsourceCompany(outsourceCompany: OutsourceCompany) {
        try {
            delete outsourceCompany.mainAddress;

            const { id } = this.outsourceCompany;
            await this.outsourceCompanyService.update(id, outsourceCompany);

            // Update customer primary address
            await this.updateOutsourcePrimaryAddress(outsourceCompany);

            this.helperService.successMessage('Outsource Details Successfully Update.');
        } catch (error) {
            console.log('Error while updating outsource company information');
            this.helperService.errorMessage(error);
        } finally {
            this.getOutsource();
        }
    }

    /**
     * Update customer primary address
     * 
     * @param address 
     */
    async updateOutsourcePrimaryAddress(outsourceCompany: OutsourceCompany): Promise<void> {
        // Update customer primary address
        try {
            if (outsourceCompany.addresses.length) {
                const primaryAddress: Address = outsourceCompany.addresses.find(
                    (address: Address) => address.entityType == EntityType.OutsourceCompany
                );
                if (primaryAddress) {
                    const { addressId } = primaryAddress;
                    await this.addressService.update(addressId, primaryAddress);
                }
            }
        } catch (error) {
            console.log('Error while updating outsource primary address');
            this.helperService.errorMessage(error);
        }
    }
}
