import { Component, forwardRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-quill-editor',
  templateUrl: './quill-editor.component.html',
  styleUrls: ['./quill-editor.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => QuillEditorComponent),
      multi: true
    }
  ],
  encapsulation: ViewEncapsulation.Emulated
})
export class QuillEditorComponent implements OnInit {

  @ViewChild('quillEditor', { static: true }) quillEditor: QuillEditorComponent;
    onChange: any = () => { };
  onTouched: any = () => { };
  quillConfig = {
    //toolbar: '.toolbar',
    toolbar: {
      container: [
        ['bold', 'italic', 'underline', 'strike'], // toggled buttons
        // ['code-block'],
        // [{ 'header': 1 }, { 'header': 2 }],               // custom button values
        [{ list: 'ordered' }, { list: 'bullet' }],
        // [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
        // [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
        // [{ 'direction': 'rtl' }],                         // text direction
        // [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        // [{ 'font': [] }],
        [{ align: [] }],
        // ['clean'],                                         // remove formatting button
        ['link', 'image', 'video'],
      ],
    },
  };

  private _value: boolean;
  get value(): boolean {
    return this._value;
  }
  set value(updateValues: boolean) {
    this._value = updateValues;
    this.onChange(updateValues);
    this.onTouched(updateValues);
  }

  ngOnInit(): void {
  }
  writeValue(value: boolean) {
    this._value = value
  }
  registerOnChange(fn: (rating: number) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

}
