import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { InvoicePayment } from '@app/models/invoice.model';
import { AccountingService } from '@app/shared/services/accounting.service';

@Component({
	selector: 'app-invoice-payments',
	templateUrl: './invoice-payments.component.html',
	styleUrls: ['./invoice-payments.component.scss'],
})
export class InvoicePaymentsComponent implements OnInit {

	constructor(
		private accountingService: AccountingService,
	) { }
	invoicePayments: InvoicePayment[] = null;
	@Input() invoiceId: any
	@Input() invoiceNumber: any
	@Input() totalAmount: any
	@Output() previewInvoice = new EventEmitter<any>();
	@Output() editInvoice = new EventEmitter<any>();

	ngOnInit(): void {
		if (this.invoiceId) {
			this.getInvoicePayments(this.invoiceId)
		}
	}

	getInvoicePayments(id) {
		this.accountingService.getInvoicePayments(id).then((res) => {
			this.invoicePayments = res.data.sort(
				(objA, objB) => Number(Date.parse(objB.createdTime)) - Number(Date.parse((objA.createdTime)))
			)
		}).catch(()=>{
			this.invoicePayments = []
		})
	}

	getBalance(paymentId) {
		var index = this.invoicePayments.findIndex(x => x.paymentId == paymentId);
		var sum = this.invoicePayments.slice(index).reduce((a, b) => a + (b?.paymentAmount ?? 0), 0);
		return this.totalAmount - sum;
	}

	onClickPreviewInvoice() {
		this.previewInvoice.emit()
	}

	onClickEditInvoice(row) {		
		this.editInvoice.emit(row);
	}

}
