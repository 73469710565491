import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { SingleSelectComponent } from './single-select/single-select.component';


@NgModule({
    declarations: [
        SingleSelectComponent
    ],
    exports: [
        SingleSelectComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        NgSelectModule
    ]
})
export class SingleSelectModule { }
