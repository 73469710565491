import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiResponse } from '@app/models/core/base';
import { ShipmentType } from '@app/models/shipment-type.model';
import { CrudService } from './core/crud.service';

@Injectable({ providedIn: 'root' })
export class ShipmentTypeService extends CrudService<ShipmentType>{

    basePath = 'ShipmentTypes'

    constructor(
        protected http: HttpClient
    ) {
        super(http)
    } 

    getShipmentTypeList(customerId: number) {
        return this.http.get<ApiResponse<ShipmentType[]>>(`${this.basePath}/List/${customerId}`,)
            .toPromise()
            .then(({ data }) => data);
    }


    get(id: number) {
        return this.http.get<ApiResponse<ShipmentType>>(`${this.basePath}/${id}`,)
            .toPromise()
            .then(({ data }) => data);
    }


    update(id: number, chargeType: ShipmentType) {
        return this.http.put<ApiResponse<ShipmentType>>(`${this.basePath}/${id}`, chargeType)
            .toPromise()
            .then(({ data }) => data);
    }

    create(chargeType: ShipmentType) {
        return this.http.post<ApiResponse<ShipmentType>>(`${this.basePath}`, chargeType)
            .toPromise()
            .then(({ data }) => data);
    }

    delete(id: number) {
        return this.http.delete<ApiResponse<any>>(`${this.basePath}/${id}`)
            .toPromise()
            .then(({ data }) => data);
    }

}
