import { Pipe, PipeTransform, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';
import { Address } from '@models/address.model';
import { round } from '@app/helpers/util';

export const MetersInMile = 1609.34;

@Pipe({
    name: 'IsActive',
    pure: false,
})
export class IsActivePipe implements PipeTransform {
    transform<T>(all: T[]): T[] {
        return all && all.filter(x => (x as any).isActive);
    }
}

@Pipe({
    name: 'Filter',
    pure: false,
})
export class FilterPipe implements PipeTransform {
    transform<T>(all: T[], prop, value): T[] {
        return all && all.filter(x => x[prop] === value);
    }
}

@Pipe({ name: 'safe' })
export class SafePipe implements PipeTransform {
    constructor(private sanitizer: DomSanitizer) {
    }

    transform(url) {
        return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }
}

@Pipe({ name: 'address' })
export class AddressPipe implements PipeTransform {
    transform(address: Address): string {
        if (address == null) {
            return '';
        }
        return `${address.addressLine1} ${address.city} ${address.state} ${address.zip}`;
    }
}

@Pipe({
    name: 'filterCallback',
    pure: false
})
export class FilterCallbackPipe implements PipeTransform {
    transform<T>(
        items: T[],
        callback: (item: T, aux1?: any, aux2?: any, aux3?: any, aux4?: any, aux5?: any, aux6?: any) => boolean,
        aux1?: any, aux2?: any, aux3?: any, aux4?: any, aux5?: any, aux6?: any): T[] {
        if (!items || !callback) {
            return items;
        }
        return items.filter(item => callback(item, aux1, aux2, aux3, aux4, aux5, aux6));
    }
}

@Pipe({
    name: 'duration'
})
export class DurationPipe implements PipeTransform {
    transform(value: number): string {
        const leftPad = x => String(x).length >= 2 ? x : leftPad(`0${x}`);
        const rounded = round(value);
        return [(rounded - rounded % 60) / 60, rounded % 60].map(leftPad).join(':');
    }
}

@Pipe({
    name: 'miles'
})
export class MilesPipe implements PipeTransform {
    transform(value: number): number {
        if (value == null) {
            return value;
        }
        return value / MetersInMile;
    }
}

@Pipe({ name: 'ellipsis' })
export class EllipsisPipe implements PipeTransform {
    transform(value: string, maxLength: number): string {
        if (value == null) {
            return value;
        }
        if (value.length <= maxLength) {
            return value;
        }

        const words = value.split(' ');
        let ret = '';
        for (const word of words) {
            if (ret.length > 0) {
                const newRet = `${ret} ${word}`;
                if (newRet.length >= maxLength) {
                    return `${newRet.substr(0, maxLength)}...`;
                } else {
                    ret = newRet;
                }
            } else {
                ret = word;
            }
        }

        return ret;
    }
}

@Pipe({
    name: 'sort'
})
export class ArraySortPipe  implements PipeTransform {
    transform(array: any, field: string): any[] {
        if (!Array.isArray(array)) {
            return;
        }
        array.sort((a: any, b: any) => {
            if (a[field] < b[field]) {
                return -1;
            } else if (a[field] > b[field]) {
                return 1;
            } else {
                return 0;
            }
        });
        return array;
    }
}


@NgModule({
    declarations: [
        IsActivePipe,
        FilterPipe,
        SafePipe,
        AddressPipe,
        FilterCallbackPipe,
        DurationPipe,
        EllipsisPipe,
        MilesPipe,
        ArraySortPipe,
    ],
    imports: [
        CommonModule,
    ],
    exports: [
        IsActivePipe,
        FilterPipe,
        SafePipe,
        AddressPipe,
        FilterCallbackPipe,
        DurationPipe,
        EllipsisPipe,
        MilesPipe,
        ArraySortPipe,
    ],
})
export class PipesModule {
}
