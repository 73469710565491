import { Component, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Data, Router } from "@angular/router";
import { saveAs } from "file-saver";
import { SharedService } from "@app/services/shared.service";
import { Order, OrderListFilters, OrderPaymentStatus, OrderPortStatusEnum, OrderStatusEnum, OrderTripStatusEnum } from "@models/order.model";
import { UtilService } from "@app/services/util.service";
import { OrderService } from "@app/shared/services/order.service";
import { DatatableComponent } from "@app/@shared/datatable";
import * as moment from "moment";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { DocumentViewerComponent } from "@app/@shared/document-viewer/document-viewer.component";
import { DocumentService } from "@app/shared/services/document.service";
import { HelperService } from "@app/shared/services/helper.service";
import { QuoteService } from "@app/shared/services/quote.service";
import { DocumentViewMode } from "@app/models/document.model";
import { orderPortStatusData, orderStatusData } from "@app/data/order";
import { cloneDeep, findIndex, has, includes, isEqual, map, omitBy, reject, set, sumBy } from "lodash";
import { OrderTrip } from "@app/models/order-trip.model";
import { DialogService } from "@app/@shared/dialogs/dialog.service";
import { Customer, SourceEnum } from "@app/models/customer.model";
import { SavedSearchService } from "@app/shared/services/saved-search.service";
import { SavedSearch, SavedSearchEntityTypeEnum } from "@app/models/saved-search.model";
import { Subject } from "rxjs";
import { debounceTime, tap } from "rxjs/operators";
import { mapStyles } from "@app/data/map-styles";
import { SelectionModel } from "@angular/cdk/collections";
import { AppService } from "@app/shared/services/app.service";
import { CopyDetailsToDriverComponent } from "@app/@shared/copy-details-to-driver/copy-details-to-driver.component";
import { CustomerService } from '@app/shared/services/customer.service';
import { EmailType } from "@app/models/sent-email.model";
import { ListTabsComponent } from "@app/@shared/list-tabs/list-tabs.component";
import { filterAndSortSentEmailsByEmailType, titleCase } from "@app/@shared/utils";
import { InvoiceActionsDialogComponent } from "./invoice-actions-dialog/invoice-actions-dialog.component";
import { AccountingService } from "@app/shared/services/accounting.service";
import { OrderDeletePaidInvoiceDialogComponent } from "../components/order-delete-dialog/order-delete-paid-invoice-dialog/order-delete-paid-invoice-dialog.component";
import { OrderDeleteUnpaidInvoiceDialogComponent } from "../components/order-delete-dialog/order-delete-unpaid-invoice-dialog/order-delete-unpaid-invoice-dialog.component";
import { AuthService } from "@app/shared/services/auth/auth.service";
import { ListTabsService } from "@app/@shared/list-tabs/list-tabs.service";

@UntilDestroy()
@Component({
    selector: "app-order-list",
    templateUrl: "./order-list.component.html",
    styleUrls: ["./order-list.component.scss"],
    host: {
        '[class.page-container]': "true"
    }
})
export class OrderListComponent implements OnInit, OnDestroy {

    @ViewChild("datatable") datatable: DatatableComponent;

    date: string | Date;
    orderPortStatus = new Map<OrderPortStatusEnum, string>();
    selectedCustomer: Customer;
    orderPortStatuses = orderPortStatusData
    orderStatusChartCounts = orderStatusData;
    orderStatusChartCountsBox = reject(orderStatusData, { status: OrderStatusEnum.CLOSED });
    tripStatusOptions = [
        { name: 'Open', value: OrderTripStatusEnum.OPEN },
        { name: 'Assigned', value: OrderTripStatusEnum.ASSIGNED },
        { name: 'InTransit', value: OrderTripStatusEnum.INTRANSIT },
        { name: 'Delivered', value: OrderTripStatusEnum.DELIVERED },
    ];
    doughnutChart: any;
    lineChart: any;
    isFilters: boolean;
    isDashboardFilters: boolean;
    @Input() isQuote: boolean;
    @Input() isOrder: boolean;
    defaultFilter: OrderListFilters = {
        orderType: null,
        OrderStatus: [],
        GroupBy: null,
        SearchTerm: '',
        Customer: null,
        Driver: null,
        Date: {
            start: moment().subtract(7, 'day').toDate(),
            end: new Date(),
        },
        orderPortStatus: [],
        selectedOrderStatus: [],
        paymentStatus: null,
        attention: false,
        // New fields for filters
        TripStatus: [],
        Customers: [],
        Drivers: [],
        FromAddress: [],
        ToAddress: [],
        Sources: [SourceEnum.WEB, SourceEnum.PORTAL]
    }
    filters: OrderListFilters;
    savedFilters: SavedSearch[];
    selectedFilter: SavedSearch;
    $filters = new Subject();
    selectOrderStatus = new SelectionModel<string>(true, []);
    mapStyles = mapStyles
    unpaidOrderCounts: number;
    attentionNeededCounts: number;
    totalOrderCounts: number;
    selectedLabelData = {
        count: 0,
        percentage: 0
    };
    @ViewChild('orderStopPopover') orderStopPopover: any;
    currentOrderStopPopover: any;
    orderStopHoverOnPopover = false;

    sourceOptions = [
        { name: 'Web', value: SourceEnum.WEB, selected: true },
        { name: 'Portal', value: SourceEnum.PORTAL, selected: true }
    ];

    orderPaymentStatusOptions = [
        { name: 'Not Paid', listName:'Not Paid (Completed)', value: OrderPaymentStatus.NotPaidAndCompleted , selected: false, bgColor: 'bg-danger', textColor:'text-danger'},
        { name: 'Not Paid', listName:'Not Paid (Assigned)', value: OrderPaymentStatus.NotPaidAndAssigned , selected: false },
        { name: 'Not Paid', listName:'Not Paid (Unassigned)', value: OrderPaymentStatus.NotPaidAndUnassigned , selected: false , bgColor: 'bg-dark', textColor:'text-black'},
        { name: 'Paid', value: OrderPaymentStatus.Paid , selected: false, bgColor: 'bg-success', textColor:'text-success'},
        { name: 'Partly Paid', value: OrderPaymentStatus.PartlyPaid , selected: false, bgColor: 'bg-warning', textColor:'text-warning'}
    ];

    selectedLabelIndex = 0;
    userRoles: any[];

    get isFilterApplied() {
        return !isEqual(this.defaultFilter, this.filters)
    }

    public saveFilters$: Subject<boolean> = new Subject();
    public snapshot$: Data = this.activatedRoute.snapshot.data;

    private isDateRangeChange: boolean;

    @Input() orderListTabs: ListTabsComponent;
    @Input() quoteListTabs: ListTabsComponent;

    constructor(
        public util: UtilService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private shared: SharedService,
        private orderService: OrderService,
        private quoteService: QuoteService,
        private dialogService: DialogService,
        private ngbModal: NgbModal,
        private documentService: DocumentService,
        private helperService: HelperService,
        private savedSearchService: SavedSearchService,
        private appService: AppService,
        private accountingService: AccountingService,
        private listTabsService: ListTabsService,
        private readonly customerService: CustomerService,
        private authService: AuthService
    ) {
        const isDashboardFilters = localStorage.getItem('order-list.isDashboardFilters')
        this.isDashboardFilters = isDashboardFilters === 'yes';

        // Init Filters;
        this.filters = Object.assign({}, this.defaultFilter);

        this.doughnutChart = {
            data: {
                datasets: [{
                    data: map(this.orderStatusChartCounts, 'count'),
                    labels: map(this.orderStatusChartCounts, 'title'),
                    backgroundColor: map(this.orderStatusChartCounts, 'color'),
                    borderWidth: 4
                }]
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                cutout: '84%',
                hover: { mode: null },
                plugins: { tooltip: { enabled: false } },
            },
            elements: {
                arc: { borderWidth: 0 },
            },
        };

        this.lineChart = {
            data: {
                labels: [
                    '7 AM',
                    '10 AM',
                    '1 PM',
                    '4 PM',
                    '7 PM'
                ],
                datasets: [
                    {
                        data: [22, 50, 38, 75, 90],
                        label: 'Counts',
                        fill: false,
                        tension: 0,
                        borderColor: '#00bad7',
                    }
                ]
            },
            options: {
                responsive: false,
                maintainAspectRatio: false,
            },
        }
    }

    get isAdmin(): boolean {
		return this.userRoles.includes("COMPANY_ADMIN");
	}

    ngOnInit() {

        this.authService.$user
			.pipe(untilDestroyed(this))
			.subscribe(user => {
				this.userRoles = user?.roles;
			});

        this.activatedRoute.queryParams
            .pipe(untilDestroyed(this))
            .subscribe((queryParams) => {
                const path: string = this.isQuote ? '/app/quotes' : '/app/orders';
                const list: ListTabsComponent = this.isQuote ? this.quoteListTabs : this.orderListTabs;
                if (queryParams?.id && queryParams?.closeTabId && !this.isQuote) {
                    list.navigateOpenTab(this.activatedRoute.snapshot.queryParams.id, path);
                    this.router.navigate([path], {
                        queryParams: {
                            closeTabId: null,
                        },
                        queryParamsHandling: 'merge'
                    });
                    list.closeTabWithKey('quote', queryParams?.closeTabId);
                } else if (queryParams?.id) {
                    list.navigateOpenTab(this.activatedRoute.snapshot.queryParams.id, path);
                } else if (queryParams?.closeTabId) {
                    this.router.navigate([path], {
                        queryParams: {
                            closeTabId: null,
                        },
                        queryParamsHandling: 'merge'
                    });
                    list.onCloseRedirectListPage(queryParams?.closeTabId)
                }
            })

        this.$filters
            .pipe(
                untilDestroyed(this),
                debounceTime(500)
            )
            .subscribe(() => {
                this.datatable.refresh(true);
            });

        this.appService.$searchTerm
            .pipe(
                untilDestroyed(this),
                debounceTime(500)
            )
            .subscribe((text) => {
                this.applyFilter('SearchTerm', text)
            })

        this.saveFilters$
            .pipe(
                tap(() => this.getSavedFilters()),
                untilDestroyed(this)
            )
            .subscribe();

        // Call subscriber to get saved filters
        this.saveFilters$.next(true);

        if (this.isOrder) {
            const isFilters = localStorage.getItem('order-list.isFilters')
            this.isFilters = isFilters === 'yes';
        } else {
            const isFilters = localStorage.getItem('quote-list.isFilters')
            this.isFilters = isFilters === 'yes';
        }

        this.orderService.orderDatatableRefresh$
            .pipe(
                untilDestroyed(this),
            )
            .subscribe((isOrderDatatableRefresh) => {
                if (isOrderDatatableRefresh) {
                    this.datatable.refreshWithUpdatedDetailsRowData()
                }
            })
    }

    ngOnDestroy() {
        this.appService.searchTerm = ''
        this.isFilters = false
    }

    handleCheckCollapsable = (row) => {
        return row?.orderStops?.length > 0
    }

    handleClickLabel(index) {
        this.selectedLabelIndex = this.selectedLabelIndex === index ? null : index
        this.selectedLabelData = {
            percentage: this.selectedLabelIndex != null ? (this.orderStatusChartCounts[index]?.count || 0) / this.totalOrderCounts * 100 || 0 : null,
            count: this.selectedLabelIndex != null ? (Number(this.orderStatusChartCounts[index]?.count) || 0) : this.totalOrderCounts
        }
    }
    toggleDashboardFilters() {
        this.isDashboardFilters = !this.isDashboardFilters
        localStorage.setItem('order-list.isDashboardFilters', this.isDashboardFilters ? 'yes' : 'no')
    }

    toggleFilters() {
        if (this.isOrder) {
            this.isFilters = !this.isFilters
            localStorage.setItem('order-list.isFilters', this.isFilters ? 'yes' : 'no')
        } else {
            this.isFilters = !this.isFilters
            localStorage.setItem('quote-list.isFilters', this.isFilters ? 'yes' : 'no')
        }
    }

    orderStopMouseEnter(popover) {
        if (!this.currentOrderStopPopover || (this.currentOrderStopPopover && this.currentOrderStopPopover !== popover)) {
            if (this.currentOrderStopPopover) {
                this.currentOrderStopPopover.close();
            }
            popover.open();
            this.currentOrderStopPopover = popover;
        }
    }

    orderStopMouseLeave(popover) {
        setTimeout(() => {
            if (!this.orderStopHoverOnPopover && this.currentOrderStopPopover === popover) {
                popover.close();
                this.currentOrderStopPopover = null;
            }
        }, 200);
    }

    orderStopPopoverMouseEnter() {
        this.orderStopHoverOnPopover = true;
    }

    orderStopPopoverMouseLeave(popover) {
        this.orderStopHoverOnPopover = false;
        setTimeout(() => {
            if (this.currentOrderStopPopover === popover) {
                popover.close();
                this.currentOrderStopPopover = null;
            }
        }, 200);
    }

    get filterCounts(): string {
        let count = 0;
        for (const key in this.defaultFilter) {
            if (has(this.defaultFilter, key) && has(this.filters, key)) {
                if (!isEqual(this.defaultFilter[key], this.filters[key])) {
                    count++;
                }
            } else {
                count++;
            }
        }
        for (const key in this.filters) {
            if (has(this.filters, key) && !has(this.defaultFilter, key)) {
                count++;
            }
        }

        return count == 0 ? 'Apply Filters' : (count == 1 ? (count + ' Filter Applied') : (count + ' Filters Applied'));
    }

    tableClass = (type) => {
        let classes
        if (type == 'header') {
            classes = 'header-fw-normal'
        }
        return classes
    }

    balanceClass = (type) => {
        let classes
        if (type == 'header') {
            classes = 'header-fw-normal  border-left'
        } else if (type == 'body') {
            classes = 'border-left'
        }
        return classes
    }

    textTitleCase(text) {
        return titleCase(text)
    }

    dateTimeFormat(dateTime) {
        return dateTime ? moment(dateTime).format("MMMM D, h:mm A z") + "EST" : "-";
    }

    getOrderStopLineColor(orderStop: any): string {
        if (orderStop?.orderStopTripStatus === 'Completed') {
            const plannedArrivalTime = moment(orderStop?.plannedArrivalTime);
            const actualArrivalTime = moment(orderStop?.actualArrivalTime);
            if (actualArrivalTime.isAfter(plannedArrivalTime)) {
                return 'border-danger'; // Late arrival/delivery
            } else {
                return 'border-success'; // On-time arrival/delivery
            }
        } else if (orderStop?.orderStopTripStatus === 'OnRoute') {
            return 'border-pink'; // On Route
        } else {
            return 'border-grey-400'; // Not confirmed
        }
    }

    /**
     * Get order saved filters
     * 
     */
    async getSavedFilters() {
        try {
            const savedFilters = await this.savedSearchService.getAll({
                type: this.isOrder ? SavedSearchEntityTypeEnum.ORDERS : SavedSearchEntityTypeEnum.QUOTES
            });
            this.savedFilters = savedFilters?.list;
        } catch (error) {
            console.log('Error while getting saved order filters', error);
        }
    }

    filterSourceSelection(values) {
        this.applyFilter('Sources', values?.map(r => r.value));
    }

    filterOrderPaymentStatusSelection(values) {
        this.applyFilter('OrderPaymentStatus', values?.map(r => r.value));
    }

    filterByAttention() {
        this.filters.attention = !this.filters.attention
        this.$filters.next(true);
    }

    filterByPaymentStatus() {
        if (!this.filters.paymentStatus) {
            this.applyFilter('paymentStatus', 'Draft');
        } else {
            this.filters.paymentStatus = null;
            this.$filters.next(true);
        }
    }

    /**
     * Filter order based selected trip status
     * 
     * @param status 
     */
    filterOrderStatus(status: OrderTripStatusEnum) {
        this.selectOrderStatus.toggle(status);
        this.applyFilter('TripStatus', this.selectOrderStatus.selected);

        if (this.selectOrderStatus?.selected?.length == 0) {
            this.filters.TripStatus = [];
        }
    }

    /**
     * Create order saved filters
     * 
     * @param data 
     */
    async createSaveFilter(name: string) {
        try {
            const search = {
                entityType: this.isOrder ? SavedSearchEntityTypeEnum.ORDERS : SavedSearchEntityTypeEnum.QUOTES,
                name: name,
                data: this.filters,
            }
            const savedFilter = await this.savedSearchService.create(search);
            this.selectedFilter = savedFilter;

            this.helperService.successMessage("Filter successfully saved");
        } catch (error) {
            this.helperService.errorMessage(error)
        } finally {
            this.saveFilters$.next(true);
        }
    }

    /**
     * Update order saved filters
     */
    async updateSaveFilter() {
        try {
            if (this.selectedFilter && this.selectedFilter.savedSearchId) {
                this.selectedFilter.data = this.filters;
                this.selectedFilter = await this.savedSearchService.update(
                    this.selectedFilter.savedSearchId,
                    this.selectedFilter
                );
                this.helperService.successMessage('Filter successfully updated');
            }
        } catch (error) {
            this.helperService.errorMessage(error)
        } finally {
            this.saveFilters$.next(true);
        }
    }

    /**
     * Delete order saved filters
     * 
     * @param item 
     */
    async deleteSavedFilter(item: SavedSearch) {
        try {
            await this.savedSearchService.delete(item?.savedSearchId);
            this.selectedFilter = null;

            this.helperService.successMessage('Filter successfully deleted');
        } catch (error) {
            this.helperService.errorMessage(error);
        } finally {
            this.saveFilters$.next(true);
        }
    }

    clearFilter(event) {
        event.preventDefault();
        event.stopPropagation();
        this.appService.searchTerm = ''
        this.selectedFilter = null
        this.selectedCustomer = null
        this.selectOrderStatus.clear()
        this.sourceOptions = this.sourceOptions?.map((res) => {
            res.selected = true
            return res
        })
        this.filters = Object.assign({}, this.defaultFilter);
        this.$filters.next(true);
    }

    applySavedFilter(item: SavedSearch) {
        this.selectedFilter = item;
        this.filters = Object.assign({}, this.defaultFilter, this.selectedFilter.data) as OrderListFilters;

        if (Array.isArray(this.filters?.Sources)) {
            this.sourceOptions = this.sourceOptions?.map((res) => {
                res.selected = this.filters?.Sources?.includes(res.value);
                return res;
            });
        }

        // Trip status selected by default when apply saved filters
        if (Array.isArray(this.filters.TripStatus)) {
            this.filters.TripStatus.forEach((status) => {
                this.selectOrderStatus.toggle(status);
            });
        } else {
            if (this.filters.TripStatus) {
                const status = this.filters.TripStatus;
                this.selectOrderStatus.toggle(status);
            }
        }
        this.$filters.next(true);
    }

    applyFilter(key?: string, value?: any) {
        if (key) {
            set(this.filters, key, value);
        }
        this.$filters.next(true);
    }

    onUnbilledChange(event: any) {
        const isChecked = event.target.checked;
        this.filters.hasInvoice = isChecked ? false : null;
        this.applyFilter('hasInvoice', this.filters.hasInvoice);
        if(isChecked){
            delete this.filters.OrderPaymentStatus
            this.orderPaymentStatusOptions.map((status)=> status.selected = false);
        }
    }

    getPriceAmountOrColorOrTooltip(row: any) {
        if (row?.hasInvoice && row?.paymentStatus === 'Paid') {
            return { color: 'text-success', amount: row?.priceBalance?.toFixed(2), tooltip: row?.priceBalance > 0 ? 'Overpaid' : 'Paid' };
        } else if (row?.hasInvoice && (row?.paymentStatus === 'Draft' || row?.paymentStatus === 'Open' || row?.paymentStatus === "Overdue")) {
            return { color: 'text-danger', amount: row?.priceBalance?.toFixed(2), tooltip: 'Not paid' };
        } else if (row?.hasInvoice && row?.paymentStatus === 'PartialPaid') {
            return { color: 'text-warning', amount: row?.priceBalance?.toFixed(2), tooltip: 'Partly paid' };
        } else {
            return { color: 'text-gray-400', amount: row?.price > 0 ? `-${row?.price?.toFixed(2)}` : '', tooltip: 'Trip is in progress, Not paid yet ' };
        }
    }

    getTripStatusColor(color) {
        switch (color) {
            case 'Open':
                return 'danger';
            case 'Assigned':
                return 'primary';
            case 'InTransit':
                return 'success';
            case 'Delivered':
                return 'gray-400';
            default:
                return 'warning';
        }
    }

    getTripStatusName(name) {
        switch (name) {
            case 'Open':
                return 'Unassigned';
            case 'Assigned':
                return 'Dispatched';
            case 'InTransit':
                return 'On Route';
            case 'Delivered':
                return 'Complete';
            default:
                return name;
        }
    }

    getStopTripStatusName(name) {
        switch (name) {
            case 'OnRoute':
                return 'Assigned';
            case 'Completed':
                return 'Confirmed';
            default:
                return name;
        }
    }

    togglePortFilter(value) {
        if (this.orderPortStatus.has(value)) {
            this.orderPortStatus.delete(value)
        } else {
            this.orderPortStatus.set(value, value)
        }
        this.filters.orderPortStatus = Array.from(this.orderPortStatus.keys())
    }

    getOrderDateRange() {
        const filters = omitBy(this.filters, (value) => value === null || value === 'null');

        if (filters.Date && filters.Date.start && filters.Date.end) {
            return {
                start: moment(filters.Date.start).startOf('day').format("ddd MMM DD YYYY HH:mm:ss"),
                end: moment(filters.Date.end).endOf('day').format("ddd MMM DD YYYY HH:mm:ss")
            };
        }

        return null;
    }

    dateRangeChange() {
        this.$filters.next(true);
        this.isDateRangeChange = true;
    }

    /**
     * Get order / quote list
     * 
     * @param request 
     * @returns 
     */
    getData = async (request) => {
        const filters = omitBy(this.filters, (value) => value === null || value === 'null');
        if (filters.attention == false) { delete filters.attention; }

        request = Object.assign({ IsOrder: this.isOrder, includeInvoiceInfo: true }, request, filters);

        if (!request["SortBy"]) {
            request["moveCompletedToTheEnd"] = true;
            request["SortBy"] = "date";
            request["SortDirection"] = "Descending";
        }

        if (filters.Date) {
            if (filters.Date?.start && filters.Date?.end) {
                request.DateRange = [
                    moment(filters.Date.start).startOf('day').format("ddd MMM DD YYYY HH:mm:ss"),
                    moment(filters.Date.end).endOf('day').format("ddd MMM DD YYYY HH:mm:ss")
                ];
            } else if (filters.Date?.start && !filters.Date?.end) {
                request.Date = moment(filters.Date.start).format("ddd MMM DD YYYY");
            } else if (!filters.Date?.start && !filters.Date?.end) {
                this.filters.Date = null;
                delete request.Date;
            } else {
                request.Date = filters.Date;
            }
        }

        if (filters.Customer) {
            if (filters.Customer?.customerId) {
                request.Customer = filters.Customer?.customerId;
            } else {
                request.Customer = filters.Customer;
            }
        }

        if (filters.Driver) {
            if (filters.Driver?.driverId) {
                request.Driver = filters.Driver?.driverId;
            } else {
                request.Driver = filters.Driver;
            }
        }

        if (filters.GroupBy) {
            if (filters.GroupBy === "Customer") {
                request.SortBy = 'customerName'
            } else if (filters.GroupBy === "Status") {
                request.SortBy = 'tripStatus'
            }
            request.SortDirection = 'Ascending'
            delete filters.GroupBy
        }

        if (typeof filters.OrderStatus !== 'undefined') {
            request.OrderStatus = Array.isArray(filters.OrderStatus) ? filters.OrderStatus : [filters.OrderStatus];
        }

        if (typeof filters.FromAddress !== 'undefined') {
            request.FromAddress = Array.isArray(filters.FromAddress) ? filters.FromAddress : [filters.FromAddress];
        }

        if (typeof filters.ToAddress !== 'undefined') {
            request.ToAddress = Array.isArray(filters.ToAddress) ? filters.ToAddress : [filters.ToAddress];
        }

        if (typeof filters.Customers !== 'undefined') {
            request.Customers = Array.isArray(filters.Customers) ? filters.Customers : [filters.Customers];
        }

        if (typeof filters.Drivers !== 'undefined') {
            request.Drivers = Array.isArray(filters.Drivers) ? filters.Drivers : [filters.Drivers];
        }

        if (typeof filters.TripStatus !== 'undefined') {
            request.TripStatus = Array.isArray(filters.TripStatus) ? filters.TripStatus : [filters.TripStatus];
        }

        if (typeof filters.hasInvoice !== 'undefined') {
            request.hasInvoice = filters.hasInvoice;
        }

        if (this.isOrder) {
            try {

                const orderStats = await this.orderService.getStats({
                    ...(request.DateRange && { DateRange: request.DateRange }),
                    ...(request.Date && { Date: request.Date }),

                    // Additional Filters
                    ...(request.IsOrder && { IsOrder: request.IsOrder }),

                    ...(request.OrderStatus && { OrderStatus: request.OrderStatus }),
                    ...(request.DriverStatus && { DriverStatus: request.DriverStatus }),

                    ...(request.Customers && { Customers: request.Customers }),
                    ...(request.Drivers && { Drivers: request.Drivers }),

                    ...(request.TripStatus && { TripStatus: request.TripStatus }),

                    ...(request.FromAddress && { FromAddress: request.FromAddress }),
                    ...(request.ToAddress && { ToAddress: request.ToAddress }),
                });
                const orderCounts = orderStats;

                this.orderStatusChartCounts = this.orderStatusChartCounts?.map((stats) => {
                    stats.count = orderCounts[stats.type];
                    return stats;
                });

                this.orderStatusChartCountsBox = this.orderStatusChartCountsBox?.map((stats) => {
                    stats.count = orderCounts[stats.type];
                    return stats;
                });

                this.doughnutChart.data.datasets[0].data = map(this.orderStatusChartCounts, 'count');
                this.doughnutChart = cloneDeep(this.doughnutChart)
                this.unpaidOrderCounts = orderCounts['unpaidOrders'];
                this.attentionNeededCounts = orderCounts['pendingOrdersCount'];
                this.totalOrderCounts = sumBy(this.orderStatusChartCounts, "count")
                this.handleClickLabel(0)
                //  this.completeCountsPercentage = orderCounts['completeCount'] / this.totalOrderCounts * 100 || 0;
            } catch (error) {
                console.log('Error while getting order statistic counts', error);
            }
        }
        this.orderService.cancelPendingRequestOrderBasicDetails();

        if (this.isDateRangeChange) {
            this.customerService.reloadCustomers = true;
            this.isDateRangeChange = false;
        }

        return await this.orderService.getOrderBasicDetails(request);
    };

    getDateRange() {
        const filters = omitBy(this.filters, (value) => value === null || value === 'null');
        if (filters?.Date?.start && filters?.Date?.end) {
            return [
                moment(filters.Date.start).startOf('day').format("ddd MMM DD YYYY HH:mm:ss"),
                moment(filters.Date.end).endOf('day').format("ddd MMM DD YYYY HH:mm:ss")
            ];
        }
        else {
            return null;
        }
    }

    getCountPercentage(item) {
        const percentage = (item / this.totalOrderCounts * 100 || 0) + '%';
        return percentage;
    }

    loadDetailsRowData = async (row: Order, isOpen: Boolean, oldData: OrderTrip[]) => {
        // Load data form cache if already loaded ago.
        if (oldData) {
            return oldData;
        }

        const data = await this.orderService.getOrderTrips(row.orderId)
        return data || [];
    }


    getPortStatusActiveIndex(status) {
        return findIndex(this.orderPortStatuses, { status })
    }

    searchUpdated() {
        this.datatable.refresh(true);
    }

    dateRemove() {
        this.date = null;
        this.searchUpdated();
    }


    async emailQuote(order: Order) {
        order.customer = {
            customerId: order.customerId
        }
        const activeModal = this.ngbModal.open(DocumentViewerComponent, {
            scrollable: true,
            size: "lg",
        });
        const content = await this.documentService.downloadQuote(order.orderId, DocumentViewMode.Html)
        if (!content?.changingThisBreaksApplicationSecurity) {
            activeModal.componentInstance.noResultsFound = true;
        }
        activeModal.componentInstance.content = content;
        activeModal.componentInstance.entity = order;

        let sentEmailsQuote = await this.orderService.getSentEmails(order?.orderId, { suggestCustomerPastSentEmails: true });
        sentEmailsQuote = await filterAndSortSentEmailsByEmailType(sentEmailsQuote, EmailType?.Quote);

        if (sentEmailsQuote?.length > 0) {
            activeModal.componentInstance.emails = sentEmailsQuote?.map(se => ({ email: se?.to, type: se?.emailType, label: se?.to }));
            activeModal.componentInstance.selectedEmail = sentEmailsQuote?.filter(x => x?.isSelected).map(rse => rse?.to);
            activeModal.componentInstance.showCustomEmails = true;
        }
        else {
            activeModal.componentInstance.showEmail = true;
        }

        activeModal.result.then(
            (result) => {
                if (result) {
                    if (result.type == "sendEmail") {
                        this.documentService
                            .emailQuote(
                                result?.entity?.orderId,
                                result?.email
                            )
                            .then(() => {
                                this.helperService.successMessage(
                                    "The quote has been sent"
                                );
                            })
                            .catch((error) => {
                                this.helperService.errorMessage(error);
                            });
                    } else {
                        this.documentService
                            .downloadQuote(
                                result?.entity?.orderId,
                                DocumentViewMode.Pdf
                            )
                            .then((file) => {
                                saveAs(
                                    file,
                                    `quote-${result?.entity?.orderNumber}.pdf`
                                );
                            });
                    }
                }
            },
            () => { }
        );
    }

    repeatOrder(order) {
        this.helperService.isLoading = true
        this.quoteService.repeatOrder(order.orderId).then(({ data }) => {
            this.datatable.refresh();
            this.helperService.successMessage('Order Successfully Repeated')
            this.helperService.isLoading = false
        }).catch((error) => {
            this.helperService.errorMessage(error)
            this.helperService.isLoading = false
        })
    }

    async approveQuote(order: Order) {
        try {
            this.helperService.isLoading = true
            const fullOrder = await this.quoteService.get(order.orderId)
            fullOrder.orderStatus = OrderStatusEnum.ORDER;
            fullOrder.isApproveQuote = true;
            await this.orderService.saveOrder(fullOrder, 'Pricing');
            this.quoteListTabs.closeTab(order.orderId);
            this.datatable.refresh();
        } catch (error) {
            this.helperService.errorMessage(error)
        }
        this.helperService.isLoading = false
    }

    async onDeleteOrder(order) {

        const hasInvoice = await order?.hasInvoice;
        const invoicePaymentStatus = await order?.paymentStatus;
        const orderNumber = await order?.orderNumber;
        const orderId = await order?.orderId;
        const invoiceNumber = await order?.invoiceNumber ?? order?.orderInvoiceId;
        const invoiceId = await order?.invoiceId;

        if (hasInvoice) {

            const isPaidInvoice = invoicePaymentStatus === "Paid" || invoicePaymentStatus === "Overdue" || invoicePaymentStatus === "PartialPaid";
            const dialogComponent = isPaidInvoice ? OrderDeletePaidInvoiceDialogComponent : OrderDeleteUnpaidInvoiceDialogComponent;

            const activeModal = this.ngbModal.open(dialogComponent);
            activeModal.componentInstance.message = `Are you sure you want to delete this ${this.snapshot$?.type} #${orderNumber}?`;

            if (isPaidInvoice) {
                activeModal.componentInstance.invoiceNumber = invoiceNumber || "0000";
                activeModal.componentInstance.invoicePaymentStatus = invoicePaymentStatus;
            } else {
                activeModal.componentInstance.type = this.snapshot$?.type;
            }

            activeModal.result.then(
                async (result) => {
                    if (result) {
                        this.deleteOrder(orderId, true, invoiceNumber, invoiceId)
                    }
                },
                () => { }
            );
        } else {
            await this.dialogService.confirm({
                message: `Are you sure you want to delete this ${this.snapshot$?.type} #${orderNumber}?`,
                noText: 'Cancel'
            }).then(() => {
                this.deleteOrder(order)
            }).catch((error) => {
                //
            })
        }
    }

    deleteOrder(orderId,isDeleteInvoice = false,invoiceNumber?: string | number, invoiceId?: string | number) {
        this.quoteService
            .delete(orderId)
            .then(() => {
                if (isDeleteInvoice) {
                    this.deleteInvoice(orderId, invoiceNumber, invoiceId);
                }
                if (this.isQuote) {
                    this.quoteListTabs.closeTab(`${orderId}`);
                } else {
                    this.orderListTabs.closeTab(`${orderId}`);
                }
                this.datatable.refresh();
                this.helperService.successMessage(`${this.isQuote ? 'Quote' : 'Order'} successfully deleted`);
            })
            .catch((error) => {
                this.helperService.errorMessage(error);
            });
    }

    deleteInvoice(orderId, invoiceNumber, invoiceId?: string | number) {
        this.orderService.deleteInvoice(orderId).then(async () => {
            if (invoiceId) {
                await this.listTabsService.closeTab('accounting', `${invoiceId}`);
            }
            this.helperService.successMessage(`Invoice #${invoiceNumber} has been successfully deleted.`);
        }).catch((error) => {
            this.helperService.errorMessage(error);
        });
    }

    async invoiceAction(event, orderId) {
        event.preventDefault();
        event.stopPropagation();

        this.helperService.isLoading = true;
        const orderDetails = await this.quoteService.get(orderId).then((res) => res);
        this.helperService.isLoading = false;
        const invoiceId = orderDetails?.invoice?.invoiceId

        const activeModal = this.ngbModal.open(InvoiceActionsDialogComponent);
        activeModal.componentInstance.hasInvoice = await orderDetails?.hasInvoice;
        activeModal.componentInstance.isCloseAndCreateInvoice = await !this.isQuote ? (orderDetails?.orderStatus !== OrderStatusEnum.CLOSED && (orderDetails?.tripStatus == OrderTripStatusEnum.ASSIGNED || orderDetails?.tripStatus == OrderTripStatusEnum.INTRANSIT)) : false;

        activeModal.result.then(
            (result) => {
                if (result) {
                    this.helperService.isLoading = true
                    if (result === "createInvoice") {
                        this.createInvoice(orderId);
                    } else if (result == "updateInvoice") {
                        this.createInvoice(orderId, false, { isReplace: true }, invoiceId);
                    } else if (result == "closeAndCreateInvoice") {
                        this.createInvoice(orderId, true, { isReplace: false });
                    } else if (result == "openInvoice") {
                        this.openInvoice(invoiceId)
                    }
                }
            },
            () => { }
        );

    }

    createInvoice(orderId, closeOrder: boolean = false, request = {}, closePrevuesInvoiceId?: string | number) {
        this.orderService.createInvoice(orderId, closeOrder, request).then(async (res) => {
           if(closePrevuesInvoiceId){
             await this.listTabsService.closeTab('accounting', `${closePrevuesInvoiceId}`);
           }
            this.router.navigate(['/app/accounting'], { queryParams: { id: res.invoiceId } });
        }).catch((error) => {
            this.helperService.errorMessage(error);
        }).finally(() => {
            this.helperService.isLoading = false
        })
    }

    openInvoice(invoiceId) {
        try {
            this.helperService.isLoading = false
            this.router.navigate(['/app/accounting'], { queryParams: { id: invoiceId } });
        } catch (error) {
            this.helperService.isLoading = false
            this.helperService.errorMessage("Invoice not found");
        }
    }

    handleRowClick(row: Order) {
        if (this.isQuote) {
            this.quoteListTabs.openTab(row.orderId);
        } else {
            this.orderListTabs.openTab(row.orderId);
        }
    }

    addEditOrder(order?: Order) {
        const basePath = this.isQuote ? 'quotes' : 'orders'
        this.orderService.order = null
        if (order) {
            const url = this.router.createUrlTree(['/app', basePath, 'edit', order.orderId]);
            window.open(url.toString(), '_blank');
        } else {
            this.router.navigate(['/app', basePath, 'add']);
        }
    }

    pickupStop(stops) {
        return stops.find(
            (x) => x.stopType !== "Transfer" && x.type === "Pickup"
        );
    }

    deliveryStop(stops) {
        return stops.find(
            (x) => x.stopType !== "Transfer" && x.type === "Delivery"
        );
    }

    statusCount(trips, status) {
        return trips.filter((x) => x.status === status).length;
    }

    async onRevertToQuote(order) {
        const hasInvoice = await order?.hasInvoice;
        const invoicePaymentStatus = await order?.paymentStatus;
        const orderId = await order?.orderId;
        const invoiceNumber = await order?.invoiceNumber ?? order?.orderInvoiceId;
        const invoiceId = await order?.invoiceId;

        if (hasInvoice) {

            const isPaidInvoice = invoicePaymentStatus === "Paid" || invoicePaymentStatus === "Overdue" || invoicePaymentStatus === "PartialPaid";
            const dialogComponent = isPaidInvoice ? OrderDeletePaidInvoiceDialogComponent : OrderDeleteUnpaidInvoiceDialogComponent;

            const activeModal = this.ngbModal.open(dialogComponent);
            activeModal.componentInstance.title = 'Revert';
            activeModal.componentInstance.yesText = 'Revert';
            activeModal.componentInstance.message = `Are you sure you want to revert this order to quote?`;

            if (isPaidInvoice) {
                activeModal.componentInstance.invoiceNumber = invoiceNumber || "0000";
                activeModal.componentInstance.invoicePaymentStatus = invoicePaymentStatus;
            } else {
                activeModal.componentInstance.type = this.snapshot$?.type;
            }

            activeModal.result.then(
                async (result) => {
                    if (result) {
                        this.revertToQuote(orderId, true, invoiceNumber, invoiceId)
                    }
                },
                () => { }
            );
        } else {
            await this.dialogService.confirm({
                message: `Are you sure you want to revert this order to quote?`,
                noText: 'Cancel',
                yesText:'Revert',
                title:'Revert'
            }).then(() => {
                this.revertToQuote(orderId)
            }).catch((error) => {
                //
            })
        }
    }

    revertToQuote(orderId, isDeleteInvoice = false, invoiceNumber?: string | number, invoiceId?: string | number) {
        this.orderService
            .revertOrderToQuote(orderId)
            .then(() => {
                if (isDeleteInvoice) {
                    this.deleteInvoice(orderId, invoiceNumber, invoiceId);
                }
                this.orderListTabs.closeTab(orderId);
                this.datatable.refresh(true);
                this.helperService.successMessage("Order revert to quote successfully.")
            })
            .catch((error) => {
                this.helperService.errorMessage(error);
            });
    }

    canRevertToQuote(order: Order): boolean {
        const orderStops = order?.orderStops;

        if (!orderStops || orderStops?.length === 0) {
            return order?.tripStatus === 'Open' ? true : false;
        }

        return orderStops?.every((orderStop: any) => orderStop?.orderStopTripStatus === "Unassigned");
    }

    copyOrderDetails(order) {
        const activeModal = this.ngbModal.open(CopyDetailsToDriverComponent, {
            scrollable: true,
            size: "dialog-centered",
        });

        this.quoteService
            .copyOrderToDriver(order.orderId)
            .then((order) => {
                activeModal.componentInstance.title = 'Order details copied to clipboard';
                activeModal.componentInstance.order = order;
                activeModal.componentInstance.route = order?.routes[0] || null;
            });

    }

    GetValueWithComme(val, addComme = true) {
        if (val) {
            return val + (addComme ? ',' : '');
        }
        return '';
    }


    async showDispatchSheet(order: Order) {

        const activeModal = this.ngbModal.open(DocumentViewerComponent, {
            scrollable: true,
            size: "lg",
        });

        const content = await this.documentService.downloadDispatchSheet(order?.orderId, DocumentViewMode.Html)

        if (!content?.changingThisBreaksApplicationSecurity) {
            activeModal.componentInstance.noResultsFound = true;
        }
        activeModal.componentInstance.showEmail = false;
        activeModal.componentInstance.content = content;
        activeModal.componentInstance.entity = order;
        activeModal.result.then(
            (result) => {
                if (result) {
                    this.documentService
                        .downloadDispatchSheet(
                            result?.entity?.orderId,
                            DocumentViewMode.Pdf
                        )
                        .then((file) => {
                            saveAs(
                                file,
                                `order-sheet-${result?.entity?.orderNumber}.pdf`
                            );
                        });
                }
            },
            () => { }
        );
    }

    async showBillOfLanding(order: Order) {
        order.customer = {
            customerId: order.customerId
        }
        const activeModal = this.ngbModal.open(DocumentViewerComponent, {
            scrollable: true,
            size: "lg",
        });
        let content = await this.documentService.downloadBillOfLanding(order?.orderId, DocumentViewMode.Html)

        if (!content?.changingThisBreaksApplicationSecurity) {
            activeModal.componentInstance.noResultsFound = true
        }

        let sentEmailsBillOfLanding = await this.orderService.getSentEmails(order?.orderId, { suggestCustomerPastSentEmails: true });
        sentEmailsBillOfLanding = await filterAndSortSentEmailsByEmailType(sentEmailsBillOfLanding, EmailType?.BillOfLanding);

        activeModal.componentInstance.content = content;
        activeModal.componentInstance.entity = order;
        activeModal.componentInstance.showSplitOrderStopItems = true;
        activeModal.componentInstance.showHighlightItems = true;
        activeModal.componentInstance.showHandleLabelItems = true;

        if (sentEmailsBillOfLanding?.length > 0) {
            activeModal.componentInstance.emails = sentEmailsBillOfLanding?.map(se => ({ email: se?.to, type: se?.emailType, label: se?.to }));
            activeModal.componentInstance.selectedEmail = sentEmailsBillOfLanding?.filter(x => x?.isSelected).map(rse => rse?.to);
            activeModal.componentInstance.showCustomEmails = true;
        }
        else {
            activeModal.componentInstance.showEmail = true;
        }

        activeModal.componentInstance.showPrint = true;
        activeModal.result.then(
            (result) => {
                if (result) {
                    if (result.type == "sendEmail") {
                        this.documentService
                            .emailBillOfLanding(
                                result?.entity?.orderId,
                                {
                                    email: result?.email,
                                    splitOrderStopItems: !!result?.splitOrderStopItems,
                                    highlightItems: !!result?.highlightItems
                                }
                            )
                            .then(() => {
                                this.helperService.successMessage(
                                    "The document has been sent"
                                );
                            })
                            .catch((error) => {
                                this.helperService.errorMessage(error);
                            });
                    } else {
                        this.documentService
                            .downloadBillOfLanding(
                                result?.entity?.orderId,
                                DocumentViewMode.Pdf,
                                {
                                    splitOrderStopItems: !!result?.splitOrderStopItems,
                                    highlightItems: !!result?.highlightItems
                                }
                            )
                            .then(async (file) => {
                                await saveAs(
                                    file,
                                    `bol-${result?.entity?.orderNumber}.pdf`
                                );
                            });
                        if (result?.isLabel) {
                            this.documentService
                                .downloadLabels(
                                    result?.entity?.orderId,
                                    DocumentViewMode.Pdf
                                )
                                .then(async (file) => {
                                    await saveAs(
                                        file,
                                        `labels-${result?.entity?.orderNumber}.pdf`
                                    );
                                });
                        }
                    }
                }
            },
            () => { }
        );
    }

    loadTrips(isOpen: boolean, order: Order) {
        if (isOpen && order.orderTrips?.length === 0) {
            this.orderService
                .getOrderTrips(order.orderId)
                .then((res) => {
                    order.orderTrips = res;
                })
                .catch((error) => {
                    console.log(error);
                    this.util.showError(
                        error.messages != null
                            ? error.messages
                            : "An error has occurred",
                        "Error"
                    );
                });
        }
    }

}
