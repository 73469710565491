import {
    Component,
    Input,
} from '@angular/core';
import { UtilService } from 'src/app/services/util.service';
import { RouteService } from '@app/services/route.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AddUnassignedStopsComponent } from '../routes-status-block/add-unassigned-stops/add-unassigned-stops.component';
import { OrderStopType } from '@app/models/route-box.model';

@Component({
    selector: 'app-route-unassign-stop-icon',
    templateUrl: './route-unassign-stop-icon.component.html',
    styleUrls: ['./route-unassign-stop-icon.component.scss']
})
export class RouteUnassignStopIconComponent {
    @Input() isDndPlaceholder: boolean = false;
    @Input() route: any;
    @Input() routes: any;
    @Input() size: 'sm' | 'md' = 'md';
    @Input() OrderStopType: OrderStopType

    constructor(
        public util: UtilService,
        public routeService: RouteService,
        private modalService: NgbModal,
    ) {
    }


    showAddUnassignedStopsDialog(stopType: OrderStopType, event, orderNumber = '') {
        event.stopPropagation()
        event.preventDefault()
        const activeModal = this.modalService.open(AddUnassignedStopsComponent, {
            // size: 'lg',
            scrollable: true,
        })
        activeModal.componentInstance.route = this.route;
        activeModal.componentInstance.routes = this.routes;
        activeModal.componentInstance.search = {
            ...(stopType && { StopType: stopType }),
            ...(orderNumber && { OrderNumber: orderNumber }),
            page: 1,
            itemsPerPage: 20
        }

        activeModal.result.then((result) => {
            if (result) {
            }
        }, () => {

        });
    }

}
