import { Component, OnDestroy, OnInit } from '@angular/core';
import { Warehouse } from '@models/warehouse.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AddEditLocationsComponent } from '../add-edit-locations/add-edit-locations.component';
import { HelperService } from '@app/shared/services/helper.service';
import { Subject } from 'rxjs';
import { ApiPaginationData } from '@app/models/core/base';
import { debounceTime } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { WarehouseService } from '@app/shared/services/warehouse.service';
import { AppService } from '@app/shared/services/app.service';

@UntilDestroy()
@Component({
    selector: 'app-settings-locations-list',
    templateUrl: './locations-list.component.html',
    styleUrls: ['./locations-list.component.scss'],
})
export class LocationsListComponent implements OnInit, OnDestroy {
    title = "Locations";
    searchTerm: string;
    warehouses: ApiPaginationData<Warehouse>;
    warehousesRequest: any = {
        page: 1,
        itemsPerPage: 20
    };
    warehouses$: Subject<any> = new Subject();

    constructor(
        private warehouseService: WarehouseService,
        private modalService: NgbModal,
        private helperService: HelperService,
        private appService: AppService,
    ) {
    }

    ngOnInit() {
        this.warehouses$
            .pipe(
                debounceTime(500),
                untilDestroyed(this)
            ).subscribe(async (request: any) => {
                request = Object.assign({}, this.warehousesRequest, request)
                this.warehouses = await this.warehouseService.getAll(request)
            });
        this.warehouses$.next(true)

        this.appService.$searchTerm
            .pipe(
                untilDestroyed(this),
                debounceTime(500)
            )
            .subscribe((text) => {
                this.searchUpdated(text)
            })
    }

    ngOnDestroy() {
        this.appService.searchTerm = ''
    }

    addEditLocation($event, location?: Warehouse) {
        $event.stopPropagation()
        $event.preventDefault()
        const activeModal = this.modalService.open(AddEditLocationsComponent, {
            size: 'dialog-centered',
        })
        activeModal.componentInstance.location = location ?? {
            name: '', address: {
                fullAddress: null,
                name: null,
                zip: null
            }
        };
        activeModal.result.then((result) => {
            if (result?.warehouseId) {
                this.warehouses$.next(true)
            }
        });
    }

    searchUpdated(search) {
        this.warehousesRequest.SearchTerm = search || [];
        this.warehousesRequest.page = 1
        this.warehouses$.next(true);
    }

    pageChanged(event) {
        this.warehousesRequest.page = event;
        this.warehouses$.next(true);
    }

    preventDefault($event) {
        $event.stopPropagation()
        $event.preventDefault()
    }

    onDelete(location: Warehouse) {
        this.warehouseService.delete(location.warehouseId).then((res) => {
            this.helperService.successMessage('Deleted Successfully');
            this.warehouses$.next(true)
        }).catch((error) => {
            this.helperService.errorMessage(error);
        })
    }
}
