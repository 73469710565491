// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host .btn {
  padding: 8px 60px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/main/drivers/driver-details/driver-personal-information/driver-personal-information.component.scss"],"names":[],"mappings":"AAEI;EACI,iBAAA;AADR","sourcesContent":["@import \"var\";\n:host {\n    .btn {\n        padding: 8px 60px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
