import {
    CUSTOM_ELEMENTS_SCHEMA,
    Component, EventEmitter,
    Input,
    OnChanges,
    Output,
    QueryList,
    SimpleChanges,
    ViewChild,
    ViewChildren
} from '@angular/core';
import * as decodePolyline from 'decode-google-map-polyline';
import DirectionsWaypoint = google.maps.DirectionsWaypoint;
import TravelMode = google.maps.TravelMode;
import LatLngLiteral = google.maps.LatLngLiteral;
import DirectionsResult = google.maps.DirectionsResult;
import { AgmMap, AgmMarker } from '@agm/core';
import { haversineDistance } from '@app/helpers/util';
import MarkerLabel = google.maps.MarkerLabel;
import Icon = google.maps.Icon;
import DirectionsRendererOptions = google.maps.DirectionsRendererOptions;
import { RouteBox } from '@models/route-box.model';
import { RouteBoxGroup } from '@models/route-box-group.model';
import { WaypointInfo } from '@app/models/map.model';
import { mapStyles } from '@app/data/map-styles';
import { RouteItemType } from '@app/models/route-item-stop.model';
import { VehicleLocation } from '@app/models/samsara/vehicle-location.model';

export interface MarkerInfo {
    isDragHover?: boolean;
    dragStartLng?: number;
    dragStartLat?: number;
    latitude: number;
    longitude: number;
    label?: string;
    title?: string;
    titleSave?: string;
    isTitleOpen?: boolean;
    index?: number;
    icon?: Icon;
    markerLabel?: MarkerLabel;
    useInDirections?: boolean;
    location?: string;
    routeBox?: RouteBox;
    type?: 'pickup' | 'dropoff';
    boxGroup?: RouteBoxGroup;
    isDone?: boolean;
}

@Component({
    selector: 'app-route-map',
    templateUrl: './route-map.component.html',
    styleUrls: ['./route-map.component.scss'],
})
export class RouteMapComponent implements OnChanges {
    @ViewChild('map') agmMap: AgmMap;
    @ViewChildren('marker') agmMarkers: QueryList<AgmMarker>;
    @Input() markersDraggable = false;
    @Input() centerLatitude: number;
    @Input() centerLongitude: number;
    @Input() initialZoom: number;
    @Input() markers: MarkerInfo[] = [];
    @Input() height: string;
    @Input() waypointsInfo: WaypointInfo[];
    @Input() origin: string;
    @Input() destination: string;
    @Input() waypoints: DirectionsWaypoint[];
    @Input() directions: DirectionsResult;
    @Input() vehicleLocations: VehicleLocation[];
    @Output() markersSwap: EventEmitter<{ index1: number; index2: number; }> = new EventEmitter<{ index1: number; index2: number }>()

    mode: TravelMode = TravelMode.DRIVING;
    directionPoly: LatLngLiteral[];
    directionPolys: LatLngLiteral[][];
    directionMarkers: MarkerInfo[];
    private map: google.maps.Map;
    directionsInfoShown = false;
    directionsInfo = '';
    directionsInfoLng: number;
    directionsInfoLat: number;

    mapStyles = mapStyles

    readonly markerOrigin = new google.maps.Point(13, 15);
    readonly markerOriginNotDone = new google.maps.Point(10, 12);
    isFullScreen = false;
    directionsRenderOptions: DirectionsRendererOptions =
        {
            polylineOptions: {
                strokeColor: '#f00',
            },
            preserveViewport: true,
            suppressMarkers: true,
            suppressInfoWindows: false,

        };

    get fullScreenDirectionPoints(): MarkerInfo[] {
        return this.markers.filter(m => m.useInDirections);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if ((changes.directions || changes.waypointsInfo) && this.directions != null) {
            this.directionMarkers = this.getDirectionWaypoints();
            this.directionPoly = this.getDirectionPolyline();
            this.directionsInfo = this.getDirectionInfo();
            if (this.map != null) {
                this.centerMap();
            }
        }
        if (changes.vehicleLocations && this.vehicleLocations?.length > 0){
            this.centerMap();
        }
       
    }

    /**
     * Get direction way points
     * 
     * @returns 
     */
    getDirectionWaypoints(): MarkerInfo[] {
        const legs: google.maps.DirectionsLeg[] = this.directions?.routes[0].legs || [];

        const maps = legs?.flatMap((leg: google.maps.DirectionsLeg & any, index) => {
            if (index === 0) {
                return [
                    this.createMarkerInfo(leg.start_location as any as LatLngLiteral, leg.start_address, leg.startLocationType, leg.startLocationIsDone),
                    this.createMarkerInfo(leg.end_location as any as LatLngLiteral, leg.end_address, leg.endLocationType, leg.endLocationIsDone),
                ];
            } else {
                return [
                    this.createMarkerInfo(leg.end_location as any as LatLngLiteral, leg.end_address, leg.endLocationType, leg.endLocationIsDone),
                ];
            }
        }) ?? [];

        const incrementing = 1;

        let pickups = maps.filter((map: MarkerInfo) => map?.type == 'pickup').reverse();
        let deliveries = maps.filter((map: MarkerInfo) => map?.type == 'dropoff');

        // differentiate pickups 
        pickups = pickups.map((map: MarkerInfo, index: number) => {
            map['index'] = index + 1;
            map['markerLabel']['text'] = (index + incrementing).toString();
            return map;
        }) || [];

        // differentiate deliveries
        deliveries = deliveries.map((map: MarkerInfo, index: number) => {
            map['index'] = index + 1;
            map['markerLabel']['text'] = (index + incrementing).toString();
            return map;
        }) || [];

        return [...pickups, ...deliveries];
    }

    getDirectionPolyline(): LatLngLiteral[] {
        return decodePolyline((this.directions.routes[0].overview_polyline as any).points) as LatLngLiteral[];
    }

    onMapReady(map: google.maps.Map) {
        this.map = map;
        this.centerMap();
    }

    private centerMap() {
        if (this.directions != null) {
            const routeBounds = this.directions.routes[0].bounds as any as {
                northeast: LatLngLiteral,
                southwest: LatLngLiteral,
            };
            const bounds = new google.maps.LatLngBounds(routeBounds.southwest, routeBounds.northeast);
            if (this.vehicleLocations?.length > 0) {
                this.vehicleLocations.forEach(vehicle => {
                    bounds.extend({ lat: vehicle.latitude, lng: vehicle.longitude }); 
                });
            }
            this.map.fitBounds(bounds);
        }
    }

    markerDragStart(waypoint: MarkerInfo, marker: HTMLElement, $event: google.maps.MouseEvent) {
        waypoint.dragStartLat = waypoint.latitude;
        waypoint.dragStartLng = waypoint.longitude;
    }

    markerDragEnd(waypoint: MarkerInfo, marker: HTMLElement, $event: google.maps.MouseEvent) {
        const index1 = this.directionMarkers.indexOf(waypoint);
        const near = this.directionMarkers.filter(dm => dm.isDragHover);
        if (near.length === 1) {
            const index2 = this.directionMarkers.indexOf(near[0]);
            this.markersSwap.emit({ index1, index2 });
        }
        for (const wp of this.directionMarkers) {
            wp.isDragHover = false;
        }
        const newWp = { ...waypoint };
        newWp.latitude = waypoint.dragStartLat;
        newWp.longitude = waypoint.dragStartLng;
        this.directionMarkers[this.directionMarkers.indexOf(waypoint)] = newWp;

    }

    markerDrag(waypoint: MarkerInfo, marker: HTMLElement, $event: google.maps.MouseEvent) {
        const ne = this.map.getBounds().getNorthEast();
        const sw = this.map.getBounds().getSouthWest();
        const minDistance = haversineDistance({ lat: ne.lat(), lng: ne.lng() }, { lat: sw.lat(), lng: sw.lng() }) / 110;
        for (const wp of this.directionMarkers) {
            wp.isDragHover = haversineDistance({ lat: wp.latitude, lng: wp.longitude }, {
                lat: $event.latLng.lat(),
                lng: $event.latLng.lng()
            }) < minDistance;
        }
    }

    getAnimation(waypoint: MarkerInfo) {
        if (waypoint.isDragHover) {
            return 'BOUNCE';
        }
        return null;
    }

    directionsMouseOver($event: google.maps.PolyMouseEvent) {
        this.directionsInfoLat = $event.latLng.lat();
        this.directionsInfoLng = $event.latLng.lng();
        this.directionsInfoShown = true;
    }

    directionsMouseOut($event: google.maps.PolyMouseEvent) {
        this.directionsInfoShown = false;
    }

    private formatDistance(distance: number): string {
        distance = distance / 1609;

        return `${distance.toFixed(2)} mi`;
    }

    private getDirectionInfo(): string {
        const distance = this.directions?.routes[0].legs.reduce<number>(
            (curr, leg) => curr + leg.distance.value, 0);

        return this.formatDistance(distance);
    }

    createMarkerInfo(latLgn: LatLngLiteral, title: string, type: string, isDone: boolean): MarkerInfo {
        return {
            title: title,
            longitude: latLgn.lng,
            latitude: latLgn.lat,
            isDone,
            markerLabel: {
                text: ``,
                color: '#fff',
                fontSize: isDone ? '13pt' : '10pt',
                fontWeight: 'bold',
            },
            type: type === RouteItemType.Pickup ? 'pickup' : 'dropoff',
            icon: {
                url: isDone ? 'assets/images/map/route-stop-done.png'
                    : (
                        type === RouteItemType.Pickup
                            ? 'assets/images/map/pickup.png'
                            : 'assets/images/map/delivery.png'
                    )
            }
        };
    }

    onBoundsChange($event: google.maps.LatLngBounds) {
        this.isFullScreen = this.map.getDiv().firstElementChild.clientHeight === window.innerHeight &&
            this.map.getDiv().firstElementChild.clientWidth === window.innerWidth;
    }

    onDirectionsResponse(directions: google.maps.DirectionsResult) {
        this.fullScreenDirectionPoints[0].title = `${this.fullScreenDirectionPoints[0].titleSave} - ${this.formatDistance(directions.routes[0].legs[0].distance.value)} - ${directions.routes[0].legs[0].duration.text}`;
    }
}

