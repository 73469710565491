import { OrderItem } from '@models/order-item.model';
import { RouteItemDisplayStatus, RouteItemStop } from '@models/route-item-stop.model';
import { OrderItemStop } from '@models/order-item-stop.model';
import { OrderStop } from '@models/order-stop.model';
import { TransferStop } from '@models/transfer-stop.model';
import { Driver } from '@models/driver.model';
import { Truck } from '@models/truck.model';
import { BaseModel } from '@models/base.model';

export enum RouteItemStatus {
    ToDo = 'ToDo',
    Done = 'Done',
    _0 = 0,
    _1 = 1,
}

export interface RouteItem extends BaseModel {
    orderItem?: OrderItem;
    orderItemId?: number;
    notes?: string;
    quantity?: number;
    pickQuantity?: number;
    id?: number;
    routeId?: number;
    orderItemStops?: RouteItemStop[];
    routeItemId?: number;
    orderItemStop?: OrderItemStop;
    orderStop?: OrderStop;
    transferStop?: TransferStop;
    sortOrder?: number;
    type?: string;
    status?: RouteItemStatus;
    date?: string;
    time?: string;
    isActive?: boolean;
    driver?: Driver;
    truck?: Truck;
    arrivalDateTime?: string;
    departureDateTime?: string;
    displayStatus?: RouteItemDisplayStatus;
    qtyToPickup?: number;
    qtyPickedUp?: number;
    qtyToDeliver?: number;
    qtyDelivered?: number;
    totalQtyByRoute?: any;
    assignPickup?: boolean;
    assignDelivery?: boolean;
    assignTransfer?: boolean;
    getTransferRouteItem?: any;
    createdTime?: string;
    createdUser?: string;
    updatedTime?: string;
    updatedUser?: string;
    isNewItem?: boolean;
}
