import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { CommonModule } from '@angular/common';
import { AlertComponent } from './alert/alert.component';
import { ConfirmComponent } from './confirm/confirm.component';
import { PromptComponent } from './prompt/prompt.component';
import { CustomComponent } from './custom/custom.component';
import { AlertDirective } from './directive/alert.directive';
import { ConfirmDirective } from './directive/confirm.directive';
import { PromptDirective } from './directive/prompt.directive';
import { CustomDirective } from './directive/custom.directive';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { CheckboxGroupModule } from '../checkbox-group/checkbox-group.module';

const directives = [
  AlertDirective,
  ConfirmDirective,
  PromptDirective,
  CustomDirective
];

const components = [
  AlertComponent,
  ConfirmComponent,
  PromptComponent,
  CustomComponent,
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModalModule,
    CheckboxGroupModule,
  ],
  declarations: [
    ...components,
    ...directives
  ],
  exports: [
    ...directives
  ],
  providers: []
})
export class DialogsModule {

}
