import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { MainComponent } from './layout/main/main.component';
import { AuthGuard } from '@app/shared/services/auth/auth.guard';
import { PageNotFoundComponent } from '@app/shared/components/auxiliary/page-not-found/page-not-found.component';

const routes: Routes = [
  {
    path: '',
    component: MainComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
      { path: 'dashboard', component: DashboardComponent },
      { path: 'dispatch', loadChildren: () => import('./routes/routes.module').then(m => m.RoutesModule) },
      {
        path: 'orders',
        loadChildren: () => import('./orders/orders.module').then(m => m.OrdersModule),
        data: { type: 'order' }
      },
      {
        path: 'quotes',
        loadChildren: () => import('./orders/orders.module').then(m => m.OrdersModule),
        data: { type: 'quote' }
      },
      { path: 'customers', loadChildren: () => import('./customers/customers.module').then(m => m.CustomersModule) },
      { path: 'outsource', loadChildren: () => import('./outsource-company/outsource-company.module').then(m => m.OutsourceCompanyModule) },
      { path: 'drivers', loadChildren: () => import('./drivers/drivers.module').then(m => m.DriversModule) },
      { path: 'accounting', loadChildren: () => import('./accounting/accounting.module').then(m => m.AccountingModule) },
      { path: 'settings', loadChildren: () => import('./settings/settings.module').then(m => m.SettingsModule) },
      {
        path: '**',
        component: PageNotFoundComponent
      }
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MainRoutingModule { }
