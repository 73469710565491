import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CompanySelectComponent } from './company-select/company-select.component';
import { FormsModule } from '@angular/forms';



@NgModule({
  declarations: [
    CompanySelectComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
  ],
  exports: [
    CompanySelectComponent
  ]
})
export class CompanySelectModule { }
