import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { ActivatedRoute, ActivatedRouteSnapshot, Router } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class AppService {

  private dataStore: {
    searchTerm?: string,
  }

  private _searchTerm: BehaviorSubject<string>;

  constructor(
    protected http: HttpClient,
    protected router: Router,
    protected activatedRoute: ActivatedRoute,

  ) {
    this.dataStore = {
      searchTerm: '',
    }
    this._searchTerm = new BehaviorSubject('');

  }

  public get $searchTerm(): Observable<string> {
    return this._searchTerm.asObservable();
  }

  public get searchTerm() {
   // const url = this.router.url.split('?')[0]
    return this.dataStore.searchTerm;
  }

  public set searchTerm(value: string) {
    // const url = this.router.url.split('?')[0]
    this.dataStore.searchTerm = value;
    this._searchTerm.next(value);
    // console.log('url', url, this.dataStore.searchTerm)
  }

  // Function to reset the state to initial values
  resetState() {
    this.searchTerm = ''
  }
}
