import { Component, OnInit, ViewEncapsulation, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { RouteItemCharges } from '@app/models/charge-type.model';
import { ChargeTypeService } from '@app/shared/services/charge-type.service';

@Component({
  selector: 'app-additional-charges-drop-down-select',
  templateUrl: './additional-charges-drop-down-select.component.html',
  styleUrls: ['./additional-charges-drop-down-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AdditionalChargesDropDownSelectComponent),
      multi: true
    }
  ],
  encapsulation: ViewEncapsulation.Emulated,
})

export class AdditionalChargesDropDownSelectComponent implements OnInit {

  additionalCharges: RouteItemCharges[] = [];
  private _value: RouteItemCharges[] = [];

  onChange = (value: any) => { };
  onTouched = () => { };

  get selectedOptions() {
    return this.additionalCharges?.filter(r => r?.selected);
  }

  constructor(private chargeTypeService: ChargeTypeService) { }

  ngOnInit(): void {
    this.chargeTypeService.getWithoutPagination().then((res) => {
      this.additionalCharges = res.map((charge) => ({
        chargeTypeId: charge.chargeTypeId,
        chargeType: charge,
        note: '',
        priceOverride: 0,
        qty: 1,
        selected: false,
      }))

      this.updateSelectedValue();
    })
  }

  writeValue(value: any) {
    this._value = value
    this.updateSelectedValue();
  }

  updateSelectedValue() {
    if (this._value?.length > 0 && this.additionalCharges.length > 0) {
      this.additionalCharges = this.additionalCharges.map(additionalCharge => {
        const selectedValue = this._value?.filter(({ chargeTypeId }) => chargeTypeId == additionalCharge.chargeTypeId)
        if (selectedValue?.length > 0) {
          additionalCharge = {
            ...selectedValue[0],
            selected: true,
            chargeType: additionalCharge.chargeType,
          }
        } else {
          additionalCharge.selected = false
        }
        return additionalCharge
      });
    }
  }

  registerOnChange(fn: (value: any) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  optionSelectionUpdated() {
    const selectedOptions = this.additionalCharges?.filter(r => r?.selected);
    this._value = selectedOptions
    this.onChange(selectedOptions);
    this.onTouched();
  }

}
