import { Injectable } from "@angular/core";
import { OutsourceCompanyFinanceTypeOfWorkEnum } from "@app/models/outsource-company.model";

export type EnumObj = typeof OutsourceCompanyFinanceTypeOfWorkEnum | unknown;

interface KeyValue {
  key: number,
  value: string,
}

@Injectable({
  providedIn: "root",
})
export class EnumFlagService {

  getEnumAsMap(enumObj: EnumObj) {
    const map: Map<number, string> = new Map();
    Object.entries(enumObj)
      .filter(([key, value]) => !isNaN(Number(value)))
      .forEach(([key, value]) => {
        map.set(Number(value), key);
      });

    return map;
  }

  getEnumAsArray(enumObj: EnumObj) {
    const arr: KeyValue[] = [];
    Object.entries(enumObj)
      .filter(([k, v]) => !isNaN(Number(v)))
      .forEach(([key, value]) => {
        arr.push({ key: value, value: key })
      });

    return arr;
  }

  parceValueToArray(enumObj: EnumObj, value: number) {
    const map = this.getEnumAsMap(enumObj);
    const result: KeyValue[] = [];

    if (value === 0) {
      result.push({ key: 0, value: map[0] })

      return result;
    }

    let level = Math.pow(2, map.size);
    let currentValue = value;

    while (currentValue) {
      if (currentValue >= level) {
        currentValue -= level;
        result.push({ key: level, value: map[level] })
      }

      level /= 2
    }

    return result
  }

  getFlagsEnumNumber(flagsEnumValueArray: number[]): number {
    let flagNumber: number = flagsEnumValueArray[0];

    if (flagsEnumValueArray.length > 1) {
        for (let i = 1; i < flagsEnumValueArray.length; i++) {
            flagNumber = flagNumber | flagsEnumValueArray[i];
        }
    }

    return flagNumber;
  }

  getFlagArrayForNumberValueByMaxFlagValue(flagNumber: number, maxFlagValue: number): number[] {
    if (flagNumber === 0) {
        return [flagNumber];
    }

    return this.getFlagArrayForFlagNumberByMaxFlagValue(flagNumber, maxFlagValue);
  }

  getFlagArrayForFlagNumberFomEnum(flagNumber: number,
    enumObject: { [key: string | number]: unknown }): number[] {

    if (flagNumber === 0) {
        return [flagNumber];
    }

    let sumEnumFlagValues: number = <number>this.sumEnumFlagValues(enumObject);

    return this.getFlagArrayForFlagNumberByMaxFlagValue(flagNumber, sumEnumFlagValues);
  }

  getFlagString(possibleValues: Map<unknown, unknown>, flagNumber: number): string {
    const flags = <{ key: number; value: string }[]>(
        Array.from(possibleValues.entries()).map(([key, value]) => ({ key, value }))
    );

    if (flagNumber === 0 && flags.length > 0) {
        return flags[0].value;
    }

    const selectedFlags = flags.filter((flag) => flag.key !== 0 && (flagNumber & flag.key) === flag.key);
    return selectedFlags.map((flag) => flag.value).join(", ");
  }

  private getFlagArrayForFlagNumberByMaxFlagValue(flagNumber: number, maxFlagValue: number): number[] {
    let flags: number[] = [];
    const numberOfBits = Math.ceil(Math.log2(maxFlagValue + 1));
    
    for (let i = 0; i < numberOfBits; i++) {
        const flagValue = 1 << i;
        if ((flagNumber & flagValue) === flagValue) {
            flags.push(flagValue);
        }
    }

    return flags;
  }

  private sumEnumFlagValues(enumObject: { [key: string | number]: unknown }) {
    return Object.keys(enumObject)
        .filter((x) => isNaN(<number>(<unknown>x)))
        .map((key) => enumObject[key])
        .reduce((a, b) => <number>a + <number>b, 0);
  }
}