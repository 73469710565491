// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host app-loading {
  --indicator-size: 40px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/main/settings/integrations/telegram/telegram.component.scss"],"names":[],"mappings":"AACE;EACE,sBAAA;AAAJ","sourcesContent":[":host {\n  app-loading {\n    --indicator-size: 40px;\n  }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
