import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AddEditAccountingInvoiceComponent } from './add-edit-accounting-invoice/add-edit-accounting-invoice.component';
import { AccountingLayoutComponent } from './accounting-layout/accounting-layout.component';
import { TabRedirectGuard } from '@app/@shared/list-tabs/tab-redirect-guard';

const routes: Routes = [
  {
    path: '',
    component: AccountingLayoutComponent,
  },
  {
    path: 'invoice/add',
    component: AddEditAccountingInvoiceComponent
  },
  {
    path: 'invoice/edit/:id',
    data: {
      redirectTo: `/app/accounting`,
      paramsKey: 'id',
      queryParamsKey: 'id'
    },
    canActivate: [TabRedirectGuard],
    component: AccountingLayoutComponent,
  },
  {
    path: 'invoice/:id',
    data: {
      redirectTo: `/app/accounting`,
      paramsKey: 'id',
      queryParamsKey: 'id'
    },
    canActivate: [TabRedirectGuard],
    component: AccountingLayoutComponent
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AccountingRoutingModule { }
