import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CrudService } from './core/crud.service';
import { ApiResponse } from '@app/models/core/base';
import { OutsourceCompanyFinance } from '@app/models/outsource-company.model';


@Injectable({
    providedIn: 'root'
})
export class OutsourceCompanyFinanceService extends CrudService<any>{

    override basePath = 'OutsourceCompanyFinances';

    constructor(protected http: HttpClient) {
        super(http);
    }

    getFinanceList(id: string | number) {
        return this.http.get<ApiResponse<OutsourceCompanyFinance[]>>(`${this.basePath}/${id}/FinanceList`).toPromise()
            .then((({ data }) => data));
    }

}
