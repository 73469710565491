// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host .user-profile {
  border-right: 1px solid #f0f0f0;
  width: 28%;
}
:host .user-profile .avatar {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: #ccc;
}
:host .user-profile .role-color {
  width: 16px;
  height: 16px;
  background-color: currentColor;
  border-radius: 50%;
  margin-right: 10px;
}
:host .user-form-fields {
  width: 68%;
}
:host .user-form-fields label {
  font-size: 12px;
  color: var(--secondary);
  margin-top: 20px;
  margin-bottom: 0;
  padding: 0 16px;
}
:host .user-form {
  flex: 1;
}
:host .option-list ::ng-deep .dropdown-item span {
  white-space: normal;
}`, "",{"version":3,"sources":["webpack://./src/app/components/main/settings/users/user-details/user-details.component.scss"],"names":[],"mappings":"AACI;EACI,+BAAA;EACA,UAAA;AAAR;AACQ;EACI,YAAA;EACA,aAAA;EACA,kBAAA;EACA,sBAAA;AACZ;AACQ;EACI,WAAA;EACA,YAAA;EACA,8BAAA;EACA,kBAAA;EACA,kBAAA;AACZ;AAEI;EACI,UAAA;AAAR;AACQ;EACI,eAAA;EACA,uBAAA;EACA,gBAAA;EACA,gBAAA;EACA,eAAA;AACZ;AAEI;EACI,OAAA;AAAR;AAKgB;EACI,mBAAA;AAHpB","sourcesContent":[":host {\n    .user-profile {\n        border-right: 1px solid #f0f0f0;\n        width: 28%;\n        .avatar {\n            width: 100px;\n            height: 100px;\n            border-radius: 50%;\n            background-color: #ccc;\n        }\n        .role-color {\n            width: 16px;\n            height: 16px;\n            background-color: currentColor;\n            border-radius: 50%;\n            margin-right: 10px;\n        }\n    }\n    .user-form-fields {\n        width: 68%;\n        label {\n            font-size: 12px;\n            color: var(--secondary);\n            margin-top: 20px;\n            margin-bottom: 0;\n            padding: 0 16px;\n        }\n    }\n    .user-form {\n        flex: 1;\n    }\n    .option-list {\n        ::ng-deep {\n            .dropdown-item {\n                span {\n                    white-space: normal;\n                }\n            }\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
