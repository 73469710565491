// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host .qb-icon {
  font-size: 20px;
}
:host .qb-envelope {
  font-size: 16px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/main/accounting/quick-books-email-icon/quick-books-email-icon.component.scss"],"names":[],"mappings":"AACE;EACE,eAAA;AAAJ;AAEE;EACE,eAAA;AAAJ","sourcesContent":[":host {\n  .qb-icon {\n    font-size: 20px;\n  }\n  .qb-envelope {\n    font-size: 16px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
