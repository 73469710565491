import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    Renderer2,
    ViewChild
} from '@angular/core';
import { saveAs } from "file-saver";
import { UtilService } from 'src/app/services/util.service';
import { EntityTypes } from '@models/entity-type.model';
import { Route, Routes } from '@models/route.model';
import { RouteItemStatus } from '@models/route-item.model';
import { RouteService } from '@app/services/route.service';
import { Driver } from '@models/driver.model';
import { RouteItemDisplayStatus, RouteItemStop } from '@models/route-item-stop.model';
import { RouteStopGroup } from '@models/route-stop-group.model';
import { ChargeType } from '@models/charge-type.model';
import DirectionsResult = google.maps.DirectionsResult;
import { OrderStopType } from '@models/route-box.model';
import { RouteBoxUIGroup } from '@app/helpers/route-box-ui-group';
import { DocumentViewerComponent } from '@app/@shared/document-viewer/document-viewer.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DocumentViewMode } from '@app/models/document.model';
import { DocumentService } from '@app/shared/services/document.service';
import { HelperService } from '@app/shared/services/helper.service';
import { MarkerInfo } from '@app/@shared/route-map/route-map.component';
import { RoutesService } from '@app/shared/services/router.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { WaypointInfo } from '@app/models/map.model';
import { ActivatedRoute, Router } from '@angular/router';
import { AddUnassignedStopsComponent } from '../routes-status-block/add-unassigned-stops/add-unassigned-stops.component';
import { ConfirmStopDialogComponent } from '../routes-status-block/confirm-stop-dialog/confirm-stop-dialog.component';
import { SettingService } from '@app/shared/services/setting.service';
import { AssignTransferDialogComponent } from './assign-transfer-dialog/assign-transfer-dialog.component';
import { DateTimeService } from '@app/shared/services/datetime-service';
import moment = require('moment');
import { AppService } from '@app/shared/services/app.service';
import { PickupOrderDetailDialogComponent } from '../routes-status-block/pickup-order-detail-dialog/pickup-order-detail-dialog.component';
import { QuoteService } from '@app/shared/services/quote.service';
import { CopyDetailsToDriverComponent } from '@app/@shared/copy-details-to-driver/copy-details-to-driver.component';
import { chain, cloneDeep, find, flatten, get, groupBy, isArray, isEqual, some } from 'lodash';
import { RouteStopTransferDialogComponent } from './route-stop-transfer-dialog/route-stop-transfer-dialog.component';
import { moveItemInArray, transferArrayItem } from 'src/utils/array-menage';
import { EditOrderStopAddressDialogComponent } from '../../orders/components/edit-order-stop-address-dialog/edit-order-stop-address-dialog.component';
import { OrderStopTypeEnum } from '@app/models/order-stop.model';
import { ChargeTypeService } from '@app/shared/services/charge-type.service';
import { OrderService } from '@app/shared/services/order.service';
import { debounceTime } from 'rxjs/operators';
import { Subject } from 'rxjs/internal/Subject';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { filterAndSortSentEmailsByEmailType } from '@app/@shared/utils';
import { EmailType } from '@app/models/sent-email.model';
import { WarningConfirmationDialogComponent } from './warning-confirmation-dialog/warning-confirmation-dialog.component';

@UntilDestroy()
@Component({
    selector: 'app-route-details',
    templateUrl: './route-details.component.html',
    styleUrls: ['./route-details.component.scss']
})
export class RouteDetailsComponent implements OnInit, AfterViewInit {
    @Input() routes: Routes[] = [];
    @Input() name: string;
    @Input() driver: Driver;
    @Input() readonly = false;
    @Input() route: Route;
    @Input() listenToRoute = true;
    @Input() isStopOpen = false;
    @Input() totalStopsCount = 20;
    @Input() dateRange: any[] = [];
    @Input() limit = 10000;
    // @Input() viewType: 'horizontal' | 'vertical' = 'vertical';
    @Output() widthChanged = new EventEmitter<void>();
    search: any = {
        Page: 1,
        ItemsPerPage: 12,
        status: 0,
    };
    loading: boolean = true;
    detailsShown = false;
    isEmptyData = false;
    OrderStopType = OrderStopType;
    EntityTypes = EntityTypes;
    stopToAssign: any;
    routeTransfer: any;
    routeItemStatus = RouteItemStatus;
    directions: DirectionsResult;
    chargeTypes: ChargeType[];
    routeStopGroups: RouteStopGroup[];
    stopStatusTypes: Record<RouteItemDisplayStatus, { class: string; text: string }> = {
        [RouteItemDisplayStatus.OnTime]: { class: 'on-time', text: 'On Time' },
        [RouteItemDisplayStatus.PickedUp]: { class: 'picked-up', text: 'Picked Up' },
        [RouteItemDisplayStatus.PickedUpFromTransfer]: { class: 'picked-up', text: 'Picked Up (T)' },
        [RouteItemDisplayStatus.Late]: { class: 'late', text: 'Late' },
        [RouteItemDisplayStatus.Delivered]: { class: 'delivered', text: 'Delivered' },
        [RouteItemDisplayStatus.DeliveredToTransfer]: { class: 'delivered', text: 'Delivered (T)' },
    }
    private _viewType: 'horizontal' | 'vertical' = 'vertical'
    isMerged: boolean;
    hasMorePage: boolean = true;
    @Input() set viewType(value: 'horizontal' | 'vertical') {
        this._viewType = value;
        this.handleScrollLintersClasses()
    }
    get viewType() {
        return this._viewType
    }

    // @Input() showCompleted = false;
    private _showCompleted = false
    @Input() set showCompleted(value: boolean) {
        this._showCompleted = value;
        this.search.status = value ? 1 : 0;
        this.search.Page = 1;
        this.loading = true
        this.loadMoreLoading = false
        this.loadRouteGroup$.next(this.search)
    }
    get showCompleted() {
        return this._showCompleted
    }

    scrollEvent$ = new Subject();
    loadRouteGroup$ = new Subject();
    loadMoreLoading: boolean;

    constructor(
        public util: UtilService,
        public routeService: RouteService,
        private settingService: SettingService,
        public chargeTypeService: ChargeTypeService,
        private modalService: NgbModal,
        private documentService: DocumentService,
        private helperService: HelperService,
        private routesService: RoutesService,
        private activatedRoute: ActivatedRoute,
        private dateTimeService: DateTimeService,
        private appService: AppService,
        private quoteService: QuoteService,
        private cdr: ChangeDetectorRef,
        private router: Router,
        private orderService: OrderService,
        private renderer: Renderer2,
    ) {
    }

    routeMarkers: MarkerInfo[] = [];
    routeStopsStatus: WaypointInfo[];

    editRoute(route: Route) {
        this.routeService.editRoute(route);
    }

    ngAfterViewInit(): void {
        this.handleScrollLintersClasses()
    }

    ngOnInit() {
        this.loadRouteGroup$
            .pipe(
                debounceTime(400),
                untilDestroyed(this),
            )
            .subscribe((request: any = {}) => {
                this.getRouteItemGroups({ ...this.search, ...request })
            });

        if (this.route.routeId) {
            this.search.routeId = this.route.routeId;

            const orderId = this.activatedRoute?.snapshot?.queryParams?.orderId
            if (orderId === this.appService.searchTerm) {
                this.search.searchTerm = orderId;
            } else {
                delete this.search.searchTerm;
                this.router.navigate(['app/dispatch'], {
                    queryParams: {
                        orderId: null,
                    },
                    queryParamsHandling: 'merge'
                });
            }
            this.loadRouteGroup$.next(true)
        }
        this.routesService.$mergedRouteBlocks.pipe(untilDestroyed(this)).subscribe(
            async (res: any) => {
                const isMerged = get(res, this.route.routeId, false)
                if ((this.isMerged !== isMerged) && !this.showCompleted) {
                    this.isMerged = isMerged
                    this.handleMergeRouteGroup()
                }
            }
        );

        this.scrollEvent$
            .pipe(
                debounceTime(200),
                untilDestroyed(this),
            )
            .subscribe((resp) => {
                this.loadMoreRoutes();
            });

        if (this.listenToRoute) {
            this.routesService.$currentRoute.pipe(untilDestroyed(this)).subscribe(
                (res: any) => {
                    this.route = res;
                }
            );
        }
        this.chargeTypeService.perStopChargeTypes$
            .subscribe(chargeTypes => this.chargeTypes = chargeTypes);

        this.orderService.orderStopPriority$
            .pipe(
                untilDestroyed(this),
            )
            .subscribe((orderPriority) => {
                if (orderPriority?.orderStopId && orderPriority?.priority && this.route?.routeStopGroups?.length > 0) {
                    this.route?.routeStopGroups.map((routeStopGroup) => {
                        routeStopGroup?.routeItemStops?.map((stop) => {
                            if (stop?.orderStop?.orderStopId === orderPriority?.orderStopId) {
                                stop.orderStop.priority = orderPriority.priority
                            }
                            return stop;
                        })
                        return routeStopGroup;
                    });
                }
            })
    }


    handleScrollLintersClasses() {
        if (this.viewType === 'horizontal') {
            const horizontalLists = document.querySelectorAll('.cards-horizontal-wrapper');
            horizontalLists.forEach((element: HTMLElement) => {
                if (this.isStopOpen) {
                    this.renderer.listen(element, 'scroll', (event) => this.onRoutesScrollVertical(event, element?.dataset?.routeId));
                }
                else {
                    this.renderer.listen(element, 'scroll', (event) => this.onRoutesScrollHorizontal(event, element?.dataset?.routeId));
                }

            });
        }
        else {
            const verticalLists = document.querySelectorAll('.cards-vertical-wrapper');
            verticalLists.forEach((element: HTMLElement) => {
                this.renderer.listen(element, 'scroll', (event) => this.onRoutesScrollVertical(event, element?.dataset?.routeId));
            });
        }
    }


    onRoutesScrollHorizontal(event: any, routeId) {
        if (routeId != this.route.routeId) {
            return
        }
        const dropdownElement = event.target;
        const offset = 80; // You might need to adjust this offset based on your needs

        if (dropdownElement.scrollLeft + dropdownElement.clientWidth >= dropdownElement.scrollWidth - offset) {
            this.scrollEvent$.next(true);
        }
    }
    onRoutesScrollVertical(event: any, routeId) {
        if (routeId != this.route.routeId) {
            return
        }
        const dropdownElement = event.target;
        const offset = 100;
        if (dropdownElement.scrollTop + dropdownElement.clientHeight >= dropdownElement.scrollHeight - offset) {
            this.scrollEvent$.next(true);
        }
    }


    togglePopup(stop) {
        stop.isPopupOpen = !stop.isPopupOpen;
        this.cdr.detectChanges();
    }

    async editPickupDelivery(stop) {
        let order;
        if (stop?.orderId) {
            this.helperService.isLoading = true;
            await this.quoteService.get(stop?.orderId).then(async (res) => {
                order = await res
            }).finally(() => {
                this.helperService.isLoading = false;
            }).catch((error) => {
                this.helperService.errorMessage(`Order not found.`);
                return false;
            })
        }

        const activeModal = this.modalService.open(EditOrderStopAddressDialogComponent, {
            scrollable: true,
            size: "sm",
            windowClass: 'custom-style'
        });

        activeModal.componentInstance.orderStopType = stop?.type === 'Delivery' ? OrderStopTypeEnum.DELIVERY : OrderStopTypeEnum.PICKUP;
        activeModal.componentInstance.order = order;
        activeModal.componentInstance.orderStop = stop?.orderStop;
        activeModal.componentInstance.isDispatch = true;
        activeModal.result.then((res) => {
            if (res) {
                this.loadRouteGroup$.next({ Page: 1 })
            }
        })
    }

    handleMergeRouteGroup() {
        if (this.isMerged && !this.showCompleted && this.viewType === 'vertical') {
            this.routeStopGroups = cloneDeep(this.route.routeStopGroups)
            this.route.routeStopGroups = this.route.routeStopGroups?.map((routeStopGroup) => {
                let stopGroup = groupBy(routeStopGroup.routeItemStops, (stop) => {
                    return `${stop.type} ${stop.date} ${stop.address?.fullAddress} ${stop?.orderStop?.locationCompanyName || stop?.address?.name} ${stop.displayStatus}`
                });
                routeStopGroup.routeItemStops = Object.values(stopGroup)?.map((stop) => {
                    if (stop?.length > 1) {
                        return stop
                    }
                    else {
                        return stop[0]
                    }
                }) as any
                return routeStopGroup
            }) as any
        }
        else {
            this.route.routeStopGroups = cloneDeep(this.routeStopGroups)
        }
    }

    getRouteItemGroups(request) {
        // request.ItemsPerPage = this.totalStopsCount;
        request.DateRange = this.dateRange
        request.searchTerm = this.appService.searchTerm;
        if (!request?.routeId) {
            return
        }
        this.routesService.getRouteItemGroups(request).then((resp) => {
            if (resp?.page > 1) {
                this.route.routeStopGroups = this.route.routeStopGroups.concat(resp.list)
            } else {
                this.route.routeStopGroups = resp.list || [];
            }
            this.setCorrectTimeForRouteItemStops();
            this.isEmptyData = !some(this.route.routeStopGroups, (routeStopGroup) => routeStopGroup?.routeItemStops?.length > 0);
            this.routeStopGroups = cloneDeep(this.route.routeStopGroups)
            const dataCount = this.routeStopGroups?.map(x => x.routeItemStops?.length ?? 0).reduce((a, b) => a + b, 0);
            this.hasMorePage = dataCount < resp?.totalCount
            this.handleMergeRouteGroup()
        }).catch((error) => {
            this.isEmptyData = true
            this.route.routeStopGroups = []
            this.routeStopGroups = []
            this.helperService.errorMessage(error)
        }).finally(() => {
            this.loadMoreLoading = false
            this.loading = false;
        })
    }

    loadMoreRoutes() {
        if (this.hasMorePage && this.route?.routeStopGroups?.length !== 0 && !this.loadMoreLoading && !this.loading) {
            this.loadMoreLoading = true
            this.search.Page++;
            this.loadRouteGroup$.next(this.search)
        }
    }


    showAddUnassignedStopsDialog({ stopType, orderNumber = '' }: { stopType: OrderStopType, orderNumber?: string }) {
        const activeModal = this.modalService.open(AddUnassignedStopsComponent, {
            // size: 'lg',
            scrollable: true,
        })
        activeModal.componentInstance.route = this.route;
        activeModal.componentInstance.routes = this.routes;
        activeModal.componentInstance.search = {
            ...(stopType && { StopType: stopType }),
            ...(orderNumber && { OrderNumber: orderNumber }),
            page: 1,
            itemsPerPage: 20
        }


        activeModal.result.then((result) => {
            if (result) {
            }
        }, (reason) => {
        });

    }

    openAssignTransferModal(stop) {
        const currRouteItem = stop?.getTransferRouteItem;
        const otherRouteItem = currRouteItem?.getTransferRouteItem;
        const orderItem = currRouteItem?.orderItem;
        const currPickupOff = currRouteItem?.type === 'Pickup';
        const request = {
            OrderNumber: stop.orderNumber,
            Customer: stop.customer,
            DropOffRouteItem: currPickupOff ? currRouteItem : otherRouteItem,
            PickupRouteItem: currPickupOff ? otherRouteItem : currRouteItem,
        };
        this.helperService.isLoading = true
        this.routesService.getTransferOptions(request).then((res: any) => {
            this.helperService.isLoading = false
            this.routeTransfer = res?.data;
            this.routeTransfer.DeliveryTimeType = res?.DeliveryTimeType;
            this.routeTransfer.PickupTimeType = res?.PickupTimeType;
            const activeModal = this.modalService.open(AssignTransferDialogComponent, {
                ariaLabelledBy: 'modal-basic-title',
                size: 'lg',
                scrollable: true,
            })
            activeModal.componentInstance.routeTransfer = cloneDeep(this.routeTransfer);
            activeModal.componentInstance.stopStatusTypes = cloneDeep(this.stopStatusTypes);
            activeModal.result.then((result) => {
                if (result) {
                    this.routeTransfer = null;
                }
            }, (reason) => {

            });
        }).catch((error) => {
            this.helperService.isLoading = false
            this.helperService.errorMessage(error)
        })
    }

    changeQtyDown(selectedStopItem) {
        if (!selectedStopItem.pickQuantity) {
            selectedStopItem.pickQuantity = 0;
        } else {
            selectedStopItem.pickQuantity = Math.max(0, selectedStopItem.pickQuantity - 1);
        }
    }

    changeQtyUp(selectedStopItem, allowUp = false) {
        if (!selectedStopItem.pickQuantity) {
            selectedStopItem.pickQuantity = 1;
        } else {
            if ((selectedStopItem.pickQuantity < selectedStopItem.quantityToPick) || allowUp) {
                selectedStopItem.pickQuantity++;
            }
        }
    }

    UploadRouteStop(stop: RouteBoxUIGroup) {
        // process Pickup
        for (const box of stop.pickupRouteBoxes.filter(b => b != null)) {
            this.routesService.processNewPickupStop(box, box.items.filter(i => i.selected), this.route);
        }
        // process DropOff
        for (const box of stop.dropOffRouteBoxes.filter(b => b != null)) {
            this.routesService.processNewPickupStop(box, box.items.filter(i => i.selected), this.route);
        }

        this.routesService.updateRouteStops(this.route.routeId, this.route.routeStopGroups, true).then((res) => {
            this.helperService.successMessage("Trip assigned driver successfully.");
            this.routesService.reloadRoute = { routeId: this.route.routeId }
            // this.routesService.reloadRoutes = true
            this.routesService.reloadUnassigned = true
        }).catch((error) => {
            this.route.routeStopGroups = this.routeStopGroups
            this.helperService.errorMessage(error)
        }).finally(() => {
            this.helperService.isLoading = false;
        });
    }

    async warningConfirmationDialog(title) {
        let reasonData: any = null;
        const activeModals = this.modalService.open(WarningConfirmationDialogComponent);
        activeModals.componentInstance.data = {
            title: 'Warning',
            message: `You have unassigned ‘${title}’, would you like to continue?`,
            yesText: 'Yes',
            noText: `No, Assign ${title}`
        };
        await activeModals.result.then(
            (reason) => {
                reasonData = reason;
            },
            (error) => {
                reasonData = null
            }
        );
        return reasonData
    }

    async getUnassignedRoutes(request) {
        request = {
            page: 1,
            itemsPerPage: 20,
            ...request
        }
        const unassignedRoutes: any = await this.routesService.getUnassignedRoutes(request, false)
        return unassignedRoutes?.list?.length > 0
    }

    async warningNoUnassignedStopsDialog() {
        const activeModals = this.modalService.open(WarningConfirmationDialogComponent);
        activeModals.componentInstance.data = {
            message: `There are no unassigned stops, please create necessary stops and assign`,
            yesText: 'Ok',
        };
        await activeModals.result.then();
        return
    }

    async processUpdateStopStatus(stops: RouteItemStop[], newStatus, index: number = 0) {
        // Check if all stops have been processed
        if (index >= stops.length) {
            return; // Or perform some final action
        }
        const stop = stops[index];
        if (newStatus === RouteItemStatus.Done) {
            const confirmStop = stop;

            if (confirmStop?.assignTransfer && !this.readonly) {
                const assignTransfer = await this.warningConfirmationDialog("Transfer");
                if (assignTransfer) {
                    this.openAssignTransferModal(confirmStop);
                    return
                } else if (assignTransfer === null) return
            } else if (confirmStop?.assignPickup && !this.readonly) {
                const assignPickup = await this.warningConfirmationDialog("Pickup");
                if (assignPickup) {
                    const request = { stopType: OrderStopType?.Pickup, orderNumber: stop?.orderNumber };
                    const isUnassignedRoutes = await this.getUnassignedRoutes(request);
                    if (isUnassignedRoutes) {
                        this.showAddUnassignedStopsDialog(request);
                        return
                    } else {
                        await this.warningNoUnassignedStopsDialog();
                        return
                    }
                } else if (assignPickup === null) return
            } else if (confirmStop?.assignDelivery && !this.readonly) {
                const assignDelivery = await this.warningConfirmationDialog("Delivery");
                if (assignDelivery) {
                    const request = { stopType: OrderStopType?.Delivery, orderNumber: stop?.orderNumber };
                    const isUnassignedRoutes = await this.getUnassignedRoutes(request);
                    if (isUnassignedRoutes) {
                        this.showAddUnassignedStopsDialog(request);
                        return
                    } else {
                        await this.warningNoUnassignedStopsDialog();
                        return
                    }
                } else if (assignDelivery === null) return
            }

            if ((confirmStop?.assignTransfer || confirmStop?.assignPickup || confirmStop?.assignDelivery) && !this.readonly) {
                confirmStop.confirmedWithoutAssignItems = true;
            }

            if (!confirmStop.waitingTime) {
                confirmStop.waitingTime = 0;
            }
            if (!confirmStop.arrivalDate) {
                confirmStop.arrivalDate = moment().startOf('day').toDate();
            }
            confirmStop.routeItems.forEach(ri => {
                ri.pickQuantity = ri.quantity;
            });
            confirmStop.charges = this.chargeTypes.map(x => Object.assign({}, x));

            var currRoute = this.routes?.find(x => x.route?.routeId == this.route?.routeId);

            // Open the dialog for the current stop
            const activeModal = this.modalService.open(ConfirmStopDialogComponent, {
                scrollable: true,
                size: "lg",
            });
            activeModal.componentInstance.routeId = cloneDeep(this.route.routeId);
            activeModal.componentInstance.confirmStop = cloneDeep(confirmStop);
            activeModal.componentInstance.confirmStopNewStatus = cloneDeep(newStatus);
            activeModal.componentInstance.totalStopsCount = currRoute?.totalStopsCount;
            activeModal.componentInstance.completedStopsCount = currRoute?.completedStopsCount;
            // Handle the dialog result
            activeModal.result.then(
                (result) => {
                    if (result?.differentRouteItem) {
                        const activeModal = this.modalService.open(PickupOrderDetailDialogComponent, {
                            scrollable: true,
                            size: 'lg'
                        });
                        activeModal.componentInstance.orderId = confirmStop.orderId;
                        activeModal.result.then(
                            (result) => {
                                if (result) {
                                    this.routesService.reloadRoutes = true
                                }
                                this.processUpdateStopStatus(stops, index + 1);
                            },
                            () => { }
                        );
                    }
                    else {
                        this.processUpdateStopStatus(stops, newStatus, index + 1);
                    }
                },
                (error) => {
                    // Optionally handle the case where the dialog is dismissed without confirmation
                    this.processUpdateStopStatus(stops, newStatus, index + 1);
                }
            );
        }
        else {
            this.helperService.isLoading = true
            this.routesService.updateRouteStopStatus(this.route.routeId, stop, newStatus).then((res) => {
                this.routesService.reloadRoute = { routeId: this.route.routeId }

                this.helperService.successMessage("Route stop status successfully updated.");
            }).catch((error) => {
                this.helperService.errorMessage(error);
            }).finally(() => {
                this.helperService.isLoading = false
            })
        }
    }

    updateRouteStopStatus({ stop, newStatus }: { stop: RouteItemStop | RouteItemStop[], newStatus: RouteItemStatus }) {
        let conformStops = []
        if (isArray(stop) && stop?.length > 0) {
            conformStops = stop
        }
        else if (stop) {
            conformStops = [stop]
        }
        if (conformStops?.length > 0) {
            this.processUpdateStopStatus(conformStops, newStatus)
        }
    }

    close() {
        this.stopToAssign = null;
        this.routeTransfer = null;
    }

    private splitStopsBeforeUpdate(newContainer?: RouteStopGroup, previousContainer?: RouteStopGroup) {
        if (newContainer) {
            newContainer.routeItemStops = flatten(newContainer?.routeItemStops)
        }
        if (previousContainer) {
            previousContainer.routeItemStops = flatten(previousContainer?.routeItemStops)
        }
    }

    async drop($event: { event: CdkDragDrop<any, any>, newContainer: RouteStopGroup, newStopIndex: any }) {
        const { event, newContainer, newStopIndex = null } = $event

        if (this.showCompleted && !event?.isPointerOverContainer) {
            return
        }
        this.helperService.isLoading = true;
        const currentStopIndex = (newStopIndex != null && newStopIndex > 0) ? newStopIndex + event?.currentIndex : event?.currentIndex
        const previousStopIndex = event?.item?.data?.previousStopIndex;

        if (event?.item?.data?.type === 'Unassigned') {
            this.dragInUn(event);
        }
        else {
            const previousContainer = event?.item?.data?.routeGroup;
            const stopData = event?.item?.data?.stop
            const isMergedStops = isArray(stopData)
            const request = {
                Page: 1,
                ItemsPerPage: 999,
                status: 0,
                routeId: previousContainer.routeId,
            }
            let filteredRouteItemStops: any[]
            if (previousContainer?.routeId !== newContainer?.routeId && !isMergedStops) {
                await this.routesService.getRouteItemGroups(request).then(async (res: any) => {
                    filteredRouteItemStops = chain(res.list)
                        .flatMap('routeItemStops')
                        .filter(res =>
                            res?.orderId === stopData?.orderId && res?.orderNumber === stopData?.orderNumber
                        )
                        .value();
                })
            }
            const hasMoreThenOneItem: boolean = filteredRouteItemStops?.length > 1;
            if (isEqual(previousContainer?.routeItemStops, newContainer?.routeItemStops)) {
                const currentIndex = (currentStopIndex > 0 && newStopIndex !== null) ? currentStopIndex - 1 : currentStopIndex
                if (previousStopIndex !== currentIndex) {
                    moveItemInArray(newContainer.routeItemStops, previousStopIndex, currentIndex);
                    // Stops split if merged
                    this.splitStopsBeforeUpdate(newContainer)

                    this.routesService.updateRouteStops(newContainer.routeId, [newContainer]).then((res) => {
                        this.helperService.successMessage("Route stop successfully updated.");
                        // this.routesService.reloadRoutes = true;
                        // Find and replace route data
                        this.routesService.reloadRoute = { routeId: newContainer?.routeId }
                    }).catch((error) => {
                        this.routesService.reloadRoutes = true;
                        this.helperService.errorMessage(error);
                    }).finally(() => {
                        this.helperService.isLoading = false;
                    });
                } else {
                    this.helperService.isLoading = false;
                }
            } else if (previousContainer?.routeId === newContainer?.routeId) {
                const prevGroup = previousContainer?.routeItemStops;
                const curGroup = newContainer?.routeItemStops
                    ?? this.route.routeStopGroups[this.route.routeStopGroups.length - 1].routeItemStops;

                transferArrayItem(prevGroup, curGroup, previousStopIndex, currentStopIndex);
                // Stops split if merged
                this.splitStopsBeforeUpdate(newContainer, previousContainer)

                this.routesService.updateRouteStops(newContainer.routeId, [previousContainer, newContainer]).then((res) => {
                    this.helperService.successMessage("Route stop successfully updated.");
                    // Find and replace route data
                    this.routesService.reloadRoute = { routeId: newContainer?.routeId }
                }).catch((error) => {
                    this.routesService.reloadRoutes = true;
                    this.helperService.errorMessage(error)
                }).finally(() => {
                    this.helperService.isLoading = false;
                })
            } else if (hasMoreThenOneItem) {

                const pickups = await filteredRouteItemStops?.filter(item => item.type === 'Pickup' || item.type === 'PickupFromTransfer');
                const deliveries = await filteredRouteItemStops?.filter(item => item.type === 'Delivery' || item.type === 'DeliveryToTransfer');
                const hasPickups: boolean = hasMoreThenOneItem && pickups.length > 0;
                const hasDeliveries: boolean = hasMoreThenOneItem && deliveries.length > 0;
                const hasWholeOrder: boolean = hasPickups && hasDeliveries;

                const previousRouteDetails = this.routes.find(({ route }) => route?.routeId === previousContainer?.routeId);
                const previousRoute = previousRouteDetails
                    ? {
                        routeId: previousRouteDetails?.route?.routeId,
                        driverName: `${previousRouteDetails?.route?.driver?.firstName || ''} ${previousRouteDetails?.route?.driver?.lastName || ''}`,
                        truckNameNumber: `${previousRouteDetails?.route?.truck?.plateNumber || ''} ${previousRouteDetails?.route?.truck?.name || ''}`
                    }
                    : null;

                const currentRouteDetails = this.routes.find(({ route }) => route?.routeId === newContainer.routeId);
                const currentRoute = currentRouteDetails
                    ? {
                        routeId: currentRouteDetails.route?.routeId,
                        driverName: `${currentRouteDetails?.route?.driver?.firstName || ''} ${currentRouteDetails?.route?.driver?.lastName || ''}`,
                        truckNameNumber: `${currentRouteDetails?.route?.truck?.plateNumber || ''} ${currentRouteDetails?.route?.truck?.name || ''}`
                    }
                    : null;

                const orderDetails = {
                    orderId: stopData?.orderId || '',
                    orderNumber: stopData?.orderNumber || '',
                    type: stopData?.type || ''
                }
                this.helperService.isLoading = false
                const activeModal = this.modalService.open(RouteStopTransferDialogComponent);
                activeModal.componentInstance.previousRoute = previousRoute;
                activeModal.componentInstance.currentRoute = currentRoute;
                activeModal.componentInstance.orderDetails = orderDetails;
                activeModal.componentInstance.hasPickups = hasPickups;
                activeModal.componentInstance.hasDeliveries = hasDeliveries;
                activeModal.componentInstance.hasWholeOrder = hasWholeOrder;
                activeModal.result.then((res) => {
                    this.helperService.isLoading = true
                    if (res?.stopTransferType === 'SelectedItem') {
                        this.routeStopTransferOnlySelectedItem(stopData, previousContainer, newContainer, previousStopIndex, currentStopIndex);
                    } else if (['Order', 'Pickup', 'Delivery'].includes(res?.stopTransferType)) {
                        const request: any = {
                            oldRouteId: previousRoute?.routeId,
                            newRouteId: currentRoute?.routeId,
                            orderId: orderDetails?.orderId,
                        }
                        if (res?.stopTransferType !== 'Order') {
                            request.routeItemType = res?.stopTransferType;
                        }
                        this.changeOrderRoute(request, orderDetails?.orderNumber);
                    } else {
                        this.helperService.isLoading = false;
                    }
                })
            } else {
                this.routeStopTransferOnlySelectedItem(stopData, previousContainer, newContainer, previousStopIndex, currentStopIndex);
            }
        }
    }

    changeOrderRoute(request: any, orderNumber?: number) {
        const type = request?.routeItemType ? (request?.routeItemType === 'Pickup' ? 'pickups' : 'deliveries') : 'items';
        this.routesService.changeOrderRoute(request).then((res: any) => {
            this.routesService.reloadRoutes = true;
            if (res?.otherMessages?.length > 0) {
                this.helperService.addToast(res?.otherMessages[0]?.message, 'Max quantity', 'warning');
            } else {
                this.helperService.successMessage(`Moved the entire order #${orderNumber} (all ${type}) from route #${request?.oldRouteId} to route #${request?.newRouteId}.`);
            }
        }).catch((error) => {
            this.helperService.errorMessage(error)
        }).finally(() => {
            this.helperService.isLoading = false
        })
    }

    routeStopTransferOnlySelectedItem(stopData, previousContainer, newContainer, previousStopIndex, currentStopIndex) {

        const prevGroup = previousContainer?.routeItemStops;
        let curGroup = newContainer?.routeItemStops ?? this.route.routeStopGroups[this.route.routeStopGroups.length - 1].routeItemStops;

        transferArrayItem(prevGroup, curGroup, previousStopIndex, currentStopIndex);

        // Stops split if merged
        this.splitStopsBeforeUpdate(newContainer, previousContainer)
        if (newContainer || this.route.routeStopGroups) {
            this.routesService.updateRouteStops(newContainer?.routeId ?? this.route.routeId,
                [
                    newContainer ?? this.route.routeStopGroups[this.route.routeStopGroups.length - 1]
                ]).then(async (res) => {
                    // Find and replace route data for new container
                    this.routesService.reloadRoute = { routeId: newContainer?.routeId ?? this.route.routeId }
                    if (previousContainer) {
                        await this.routesService.updateRouteStops(previousContainer.routeId, [previousContainer])
                        // Find and replace route data for previous and new container
                        this.routesService.reloadRoute = { routeId: previousContainer.routeId }

                    }

                    this.helperService.successMessage("Routes stop successfully updated.");
                }).catch((error) => {
                    this.routesService.reloadRoutes = true;
                    this.helperService.errorMessage(error);
                })
                .finally(() => {
                    this.helperService.isLoading = false
                })
        }
    }

    dragInUn(event: CdkDragDrop<any, any>) {
        const unassignedStopsGroups: RouteBoxUIGroup[] = this.routesService.unassignedStopsGroups
        const stop = cloneDeep(event?.item.data.stop)
        const stopData = find(unassignedStopsGroups, { _id: stop?._id })
        if (!stopData) {
            return;
        }
        this.assignStop(stopData);
    }

    private assignStop(stop: RouteBoxUIGroup) {
        stop.items.forEach(element => {
            element.selected = true;
        });
        this.UploadRouteStop(stop);
    }

    stopText({ route, stop }) {
        const activeModal = this.modalService.open(CopyDetailsToDriverComponent, {
            scrollable: true,
            size: "dialog-centered",
        });
        activeModal.componentInstance.title = 'Stop details copied to clipboard';

        activeModal.componentInstance.route = route;
        activeModal.componentInstance.routeItemStop = stop;

        const Types = (stop?.type === 'Pickup' || stop?.type === 'PickupFromTransfer') ? [1, 3] : [2, 4];
        const LocationCompanyName = stop?.orderStop?.locationCompanyName;
        const AddressName = stop?.address?.name;
        const LocationContactNumber = stop.orderStop?.locationContactNumber
        const LocationContactNumberExtension = stop?.orderStop?.locationContactNumberExtension
        const FullAddress = stop.address?.fullAddress

        activeModal.componentInstance.routeStopGroupsSearch = {
            routeId: route.routeId, Page: 1,
            status: 0,
            ItemsPerPage: 20,
            ...Types && ({ Types }),
            ...LocationCompanyName && ({ LocationCompanyName }),
            ...!LocationCompanyName && AddressName && ({ AddressName }),
            ...LocationContactNumber && ({ LocationContactNumber }),
            ...LocationContactNumber && LocationContactNumberExtension && ({ LocationContactNumberExtension }),
            ...FullAddress && ({ FullAddress }),
        }
    }

    getRouteStart(route: Route) {
        const stops = route.routeStopGroups.flatMap(rsg => rsg.routeItemStops);
        if (stops.length > 0 && stops[0].status === 0) {
            return stops[0].address.fullAddress;
        }
        return null;
    }

    showDispatchSheet(order: RouteItemStop) {
        this.documentService
            .downloadDispatchSheet(order?.orderId, DocumentViewMode.Html)
            .then((data) => {
                this.dispatchSheetDocumentViewer(data, order);
            });
    }

    dispatchSheetDocumentViewer(content, entity) {
        const activeModal = this.modalService.open(DocumentViewerComponent, {
            scrollable: true,
            size: "lg",
        });

        if (!content?.changingThisBreaksApplicationSecurity) {
            activeModal.componentInstance.noResultsFound = true;
        }
        activeModal.componentInstance.showEmail = false;
        activeModal.componentInstance.content = content;
        activeModal.componentInstance.entity = entity;
        activeModal.result.then(
            (result) => {
                if (result) {
                    this.documentService
                        .downloadDispatchSheet(
                            result?.entity?.orderId,
                            DocumentViewMode.Pdf
                        )
                        .then((file) => {
                            saveAs(
                                file,
                                `dispatch-${result?.entity?.orderNumber}.pdf`
                            );
                        });
                }
            },
            () => { }
        );
    }

    showBillOfLanding(order: RouteItemStop) {
        this.documentService
            .downloadBillOfLanding(order?.orderId, DocumentViewMode.Html)
            .then((data) => {
                this.showBillOfLandingDocumentViewer(data, order);
            });
    }

    async showBillOfLandingDocumentViewer(content, entity) {
        const activeModal = this.modalService.open(DocumentViewerComponent, {
            scrollable: true,
            size: "lg",
        });

        if (!content?.changingThisBreaksApplicationSecurity) {
            activeModal.componentInstance.noResultsFound = true;
        }

        activeModal.componentInstance.content = content;
        activeModal.componentInstance.entity = entity;

        let sentEmailsBillOfLanding = await this.orderService.getSentEmails(entity?.orderId, { suggestCustomerPastSentEmails: true });

        if (entity?.orderId) {
            sentEmailsBillOfLanding = await filterAndSortSentEmailsByEmailType(sentEmailsBillOfLanding, EmailType?.BillOfLanding);

            if (sentEmailsBillOfLanding?.length > 0) {
                activeModal.componentInstance.emails = sentEmailsBillOfLanding?.map(se => ({ email: se?.to, type: se?.emailType, label: se?.to }));
                activeModal.componentInstance.selectedEmail = sentEmailsBillOfLanding?.filter(x => x?.isSelected).map(rse => rse?.to);
                activeModal.componentInstance.showCustomEmails = true;
            }
        }

        if (sentEmailsBillOfLanding?.length < 1) {
            activeModal.componentInstance.showEmail = true;
        }

        activeModal.componentInstance.showPrint = true;
        activeModal.componentInstance.showSplitOrderStopItems = true;
        activeModal.componentInstance.showHighlightItems = true;
        activeModal.componentInstance.showHandleLabelItems = true;
        activeModal.result.then(
            (result) => {
                if (result) {
                    if (result.type == "sendEmail") {
                        this.documentService
                            .emailBillOfLanding(
                                result?.entity?.orderId,
                                {
                                    email: result?.email,
                                    splitOrderStopItems: !!result?.splitOrderStopItems,
                                    highlightItems: !!result?.highlightItems
                                }
                            )
                            .then(() => {
                                this.helperService.successMessage(
                                    "The document has been sent"
                                );
                            })
                            .catch((error) => {
                                this.helperService.errorMessage(error);
                            });
                    } else {
                        this.documentService
                            .downloadBillOfLanding(
                                result?.entity?.orderId,
                                DocumentViewMode.Pdf,
                                {
                                    splitOrderStopItems: !!result?.splitOrderStopItems,
                                    highlightItems: !!result?.highlightItems
                                }
                            )
                            .then((file) => {
                                saveAs(
                                    file,
                                    `bol-${result?.entity?.orderNumber}.pdf`
                                );
                            });
                        if (result?.isLabel) {
                            this.documentService
                                .downloadLabels(
                                    result?.entity?.orderId,
                                    DocumentViewMode.Pdf
                                )
                                .then((file) => {
                                    saveAs(
                                        file,
                                        `labels-${result?.entity?.orderNumber}.pdf`
                                    );
                                });
                        }
                    }
                }
            },
            () => { }
        );
    }

    setCorrectTimeForRouteItemStops() {
        for (let group of this.route.routeStopGroups) {
            for (let stop of group.routeItemStops) {
                stop.dateToDisplay = this.dateTimeService.combineDateAndTime(new Date(stop.date), new Date(stop.time));
            }
        }
    }

}
