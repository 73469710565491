import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DatatableComponent } from './datatable/datatable.component';
import { BodyTemplate } from './column/body-template';
import { ColumnComponent } from './column/column.component';
import { NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { LoadingModule } from '@app/shared/modules/loading/loading.module';
import { FormsModule } from '@angular/forms';
import { RowToggleHandle } from './row-toggle-handle';



@NgModule({
  declarations: [
    DatatableComponent,
    ColumnComponent,
    BodyTemplate,
    RowToggleHandle,
  ],
  imports: [
    CommonModule,
    NgbPaginationModule,
    LoadingModule,
    FormsModule,
  ],
  exports: [
    DatatableComponent,
    ColumnComponent,
    BodyTemplate,
    RowToggleHandle,
  ]
})
export class DatatableModule { }
