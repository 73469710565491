// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host .header {
  align-items: center;
  justify-content: space-between;
}
:host .header .title {
  font-size: 18px;
  line-height: 1.5rem;
}
:host .header .btn {
  padding: 0 16px;
  font-size: 16px;
  font-weight: 600;
  min-height: 40px;
}
:host .from-lable {
  font-weight: 600;
}`, "",{"version":3,"sources":["webpack://./src/app/components/main/settings/zones/zone-details/zone-details.component.scss"],"names":[],"mappings":"AAEI;EACI,mBAAA;EACA,8BAAA;AADR;AAGQ;EACI,eAAA;EACA,mBAAA;AADZ;AAIQ;EACI,eAAA;EACA,eAAA;EACA,gBAAA;EACA,gBAAA;AAFZ;AAKI;EACI,gBAAA;AAHR","sourcesContent":["@import \"var\";\n:host {\n    .header {\n        align-items: center;\n        justify-content: space-between;\n\n        .title {\n            font-size: 18px;\n            line-height: 1.5rem;\n        }\n\n        .btn {\n            padding: 0 16px;\n            font-size: 16px;\n            font-weight: 600;\n            min-height: 40px;\n        }\n    }\n    .from-lable {\n        font-weight: 600;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
