import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { QuillEditorComponent } from './quill-editor.component';
import { QuillModule } from 'ngx-quill';
import { SharedModule } from '@app/shared/shared.module';



@NgModule({
  declarations: [
    QuillEditorComponent
  ],
  exports: [
    QuillEditorComponent
  ],
  imports: [
    CommonModule,
     QuillModule,
     SharedModule
  ]
})
export class QuillEditorModule { }
