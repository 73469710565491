import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiPaginationResponse, ApiResponse, ListRequest } from '@app/models/core/base';
import { Company, TenantCompanyList } from '@app/models/company.model';
import { BehaviorSubject, Observable } from 'rxjs';
import { toParams } from 'src/utils/to-params';
import { AppStorage } from './storage/app-storage';


@Injectable({
    providedIn: 'root'
})
export class CompanyService {

    private basePath = 'Company'

    private dataStore: {
        selectedCompany: TenantCompanyList,
    };

    private _selectedCompany: BehaviorSubject<TenantCompanyList>;

    constructor(
        private http: HttpClient,
        @Inject(AppStorage) private appStorage: Storage,
    ) {
        this.dataStore = {
            selectedCompany: null,
        };
        this._selectedCompany = new BehaviorSubject(this.dataStore.selectedCompany);
    }

    public get $selectedCompany(): Observable<TenantCompanyList> {
        return this._selectedCompany.asObservable();
    }

    public get selectedCompany(): TenantCompanyList {
        return this.dataStore.selectedCompany;
    }

    public set selectedCompany(value: TenantCompanyList) {
        this.dataStore.selectedCompany = value;
        this._selectedCompany.next(value);
    }

    getAll(request) {
        return this.http.get<ApiPaginationResponse<any>>(`${this.basePath}`, { params: toParams(request) })
            .toPromise()
            .then((({ data }) => data));
    }

    get(request?: ListRequest) {
        return this.http.get<ApiResponse<Company>>(`${this.basePath}/Info`, { params: toParams(request) }).toPromise()
            .then((({ data }) => data));
    }

    update(request?: ListRequest) {
        return this.http.put<ApiResponse<Company>>(`${this.basePath}/Info`, request).toPromise()
            .then((({ data }) => data));
    }

    switchCompany(id) {
        return this.http.put<ApiResponse<any>>(`${this.basePath}/SwitchCompany/${id}`, {}).toPromise()
            .then((({ data }) => data));
    }

    // Function to reset the state to initial values
    resetState() {
        this.selectedCompany = null
    }
}
