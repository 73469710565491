import { Component, forwardRef, OnInit, ViewEncapsulation } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgbCalendar, NgbDate, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';

export interface DateRange {
    start: Date | string;
    end: Date | string
}

@Component({
    selector: 'app-date-range-picker',
    templateUrl: './date-range-picker.component.html',
    styleUrls: ['./date-range-picker.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => DateRangePickerComponent),
            multi: true
        }
    ],
    encapsulation: ViewEncapsulation.Emulated
})
export class DateRangePickerComponent implements OnInit {
    onChange = (value: DateRange) => { };
    onTouched = () => { };
    hoveredDate: NgbDate = null;

    fromDate: NgbDate;
    toDate: NgbDate;

    private _value: DateRange;

    get value(): DateRange {
        return this._value;
    }
    set value(updateValues: DateRange) {
        this._value = updateValues;
        if ((updateValues.start && updateValues.end) || (!updateValues.start && !updateValues.end)) {
            this.onChange(updateValues);
        }
        this.onTouched();
    }

    constructor(private calendar: NgbCalendar, public formatter: NgbDateParserFormatter) {
        // this.fromDate = calendar.getToday();
        // this.toDate = calendar.getNext(calendar.getToday(), 'd', 10);
    }

    ngOnInit() {
    }

    writeValue(value: DateRange) {
        if (value?.start && value?.end) {
            const date = moment(value.start).toDate()
            this.fromDate = new NgbDate(date.getFullYear(), date.getMonth() + 1, date.getDate())

            const endDate = moment(value.end).toDate()
            this.toDate = new NgbDate(endDate.getFullYear(), endDate.getMonth() + 1, endDate.getDate())
        } else {
            this.fromDate = null;
            this.toDate = null
        }
    }

    registerOnChange(fn: (value: DateRange) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    onDateSelection(date: NgbDate) {
        if (!this.fromDate && !this.toDate) {
            this.fromDate = date;
        } else if (this.fromDate && !this.toDate && date && (date.after(this.fromDate) || date.equals(this.fromDate))) {
            this.toDate = date;
        } else {
            this.toDate = null;
            this.fromDate = date;
        }

        this.handleChange()

    }

    clearDate() {
        this.fromDate = null
        this.toDate = null
        this.handleChange()
    }

    handleChange() {
        let start = null;
        let end = null

        if (this.fromDate) {
            start = this.fromDate ? moment()
                .set('D', this.fromDate.day)
                .set('month', this.fromDate.month - 1)
                .set('year', this.fromDate.year)
                .startOf('day')
                .toDate() : null
        }

        if (this.toDate) {
            end = this.toDate ? moment()
                .set('D', this.toDate.day)
                .set('month', this.toDate.month - 1)
                .set('year', this.toDate.year)
                .startOf('day')
                .toDate() : null
        }

        this.value = {
            start,
            end,
        }
    }

    isHovered(date: NgbDate) {
        return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) &&
            date.before(this.hoveredDate);
    }

    isInside(date: NgbDate) { return this.toDate && date.after(this.fromDate) && date.before(this.toDate); }

    isRange(date: NgbDate) {
        return date.equals(this.fromDate) || (this.toDate && date.equals(this.toDate)) || this.isInside(date) ||
            this.isHovered(date);
    }

    validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
        const parsed = this.formatter.parse(input);
        return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
    }
}
