import { Component, ContentChildren, forwardRef, Input, OnInit, QueryList, TemplateRef, ViewEncapsulation } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { uniqueId } from 'lodash';
import { RepeaterTemplate } from './repeater-template';

@Component({
  selector: 'app-repeater',
  templateUrl: './repeater.component.html',
  styleUrls: ['./repeater.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RepeaterComponent),
      multi: true
    }
  ],
  encapsulation: ViewEncapsulation.Emulated
})
export class RepeaterComponent implements OnInit {

  @Input() defaultValue = {};
  @Input() defaultItems = 1;
  @ContentChildren(RepeaterTemplate) templates: QueryList<RepeaterTemplate>;


  contentTemplate: TemplateRef<any>;;
  _this: this;
  disabled: boolean;
  onChange: any = () => { };
  onTouched: any = () => { };

  private _value: any[];
  get value(): any[] {
    return this._value;
  }
  set value(updateValues: any[]) {
    this._value = updateValues;
    this.onChange(updateValues);
    this.onTouched(updateValues);
  }

  constructor() {
    this._this = this;
  }

  ngOnInit(): void {
  }

  ngAfterContentInit() {

    this.templates.forEach((item) => {
      this.contentTemplate = item.template
    });

    this.initDefaultItems()

  }

  initDefaultItems() {
    if (!this.value || this.value?.length == 0) {
      for (let index = 0; index < this.defaultItems; index++) {
        this.push()
      }
    }
  }

  push(index?: number) {
    if (!this.value) {
      this.value = []
    }
    if (index >= 0) {
      this.value.splice(index, 0, Object.assign({ _id: uniqueId() }, this.defaultValue))
    } else {
      this.value.push(Object.assign({ _id: uniqueId() }, this.defaultValue))
    }
  }

  remove(index: number) {
    this.value.splice(index, 1)
  }

  writeValue(value: any[]) {
    this._value = value || []
    this.initDefaultItems()
  }

  registerOnChange(fn: (value: any[]) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }


  count(index) {
    return this.value?.length;
  }

  isFirst(index) {
    return index === 0;
  }

  isLast(index) {
    return index === this.value?.length - 1
  }

}
