import { TokenData, UpdateData } from "../angular-cardknox-ifields/typings";


export class IfieldsHandler {
  xToken?: string;
  issuer?: string;
  onToken({ data }: { data: TokenData; }) {
    this.xToken = data.xToken;
  }
  setIssuer(issuer: string) {
    this.issuer = issuer;
  }
  onBlur({ data }: { data: UpdateData; }) {
    this.issuer = data.issuer;
  }
}
