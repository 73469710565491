import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModalModule, NgbModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { DocumentViewerComponent } from './document-viewer.component';
import { FormsModule } from '@angular/forms';
import { LoadingModule } from '@app/shared/modules/loading/loading.module';
import { NgSelectModule } from '@ng-select/ng-select';

@NgModule({
  declarations: [
    DocumentViewerComponent
  ],
  exports: [
    DocumentViewerComponent
  ],
  imports: [
    CommonModule,
    NgbModalModule,
    NgbTooltipModule,
    FormsModule,
    LoadingModule,
    NgSelectModule,
  ]
})
export class DocumentViewerModule { }
