import { Pipe, PipeTransform } from '@angular/core';
import { OrderStopDateTypeEnum } from '@app/models/order-stop.model';
import { CustomDatePipe } from './custom-date.pipe';

@Pipe({
  name: 'orderStopDate'
})
export class OrderStopDatePipe extends CustomDatePipe implements PipeTransform {



  transform(value, timeType?: OrderStopDateTypeEnum, ...args: any[]) {

    switch (timeType) {
      case OrderStopDateTypeEnum.TODAY:
        return "Today";

      case OrderStopDateTypeEnum.TOMORROW:
        return "Tomorrow";

      default:
        return value ? super.transform(value, ...args) : ''

    }
  }

}
