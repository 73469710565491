import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TabRedirectGuard  {
  constructor(private router: Router) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    const data = { ...next.data };

    if (data?.isOrderOrQuote) {
      if (state.url?.startsWith('/app/orders')) {
        data.redirectTo = '/app/orders';
      } else {
        data.redirectTo = '/app/quotes';
      }
    }

    if (data.redirectTo && data.queryParamsKey && data.paramsKey) {
      this.router.navigate([data.redirectTo], {
        queryParams: {
          [data.queryParamsKey]: next.params[data.paramsKey],
          ...data.queryParams,
        }
      });
      return false;
    }
    return true;
  }
}
