import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrderItemComponent } from './order-item.component';
import { SharedModule } from '@app/shared/shared.module';



@NgModule({
  declarations: [OrderItemComponent],
  exports: [OrderItemComponent],
  imports: [
    CommonModule,
    SharedModule,
  ]
})
export class OrderItemModule { }
