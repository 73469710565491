import { Directive, Input, Output, HostListener, EventEmitter } from '@angular/core';
import { DialogService } from '../dialog.service';
import { PromptComponent, PromptData } from '../prompt/prompt.component';

@Directive({
    selector: '[promptDialog]'
})
export class PromptDirective {

    data: PromptData = {
        inputType: 'text',
        okText: 'Ok',
        cancelText: 'Cancel',
    };

    @Input() set message(value) {
        this.data.message = value;
    }
    @Input() set title(value) {
        this.data.title = value;
    }

    @Input() set okText(value) {
        this.data.okText = value;
    }
    @Input() set cancelText(value) {
        this.data.cancelText = value;
    }
    @Input() set placeholder(value) {
        this.data.placeholder = value;
    }
    @Input() set label(value) {
        this.data.label = value;
    }
    @Input() set inputType(value) {
        this.data.inputType = value;
    }

    @Input() set options(value) {
        this.data.options = value;
    }

    @Output() callback = new EventEmitter<any>();
    @Output() confirm = new EventEmitter<any>();

    constructor(private dialogService: DialogService) {

    }

    @HostListener('click', ['$event']) onClick($event) {
        $event.stopPropagation();

        const dialogRef = this.dialogService.open(PromptComponent, this.data, { size: 'sm' });

        dialogRef.closed.subscribe(result => {
            if (result) {
                this.confirm.emit(result);
            }
            this.callback.emit(result);
        });
    }
}
