import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';

import { environment } from '@environments/environment';
import { ApiResponse, handleApiError } from '@app/helpers/api';
import { Observable } from 'rxjs/internal/Observable';

export enum DocumentViewMode
{
    Pdf = 'Pdf',
    Html = 'Html',
}

@Injectable({ providedIn: 'root' })
export class DocumentService {
    constructor(
        private http: HttpClient,
    ) {
    }

    downloadBillOfLanding(orderId: number, mode: DocumentViewMode): Observable<Blob> {
        return this.downloadOrderDocument('BillOfLanding', orderId, mode);
    }

    downloadLabels(orderId: number, mode: DocumentViewMode): Observable<Blob> {
        return this.downloadOrderDocument('Labels', orderId, mode);
    }

    downloadQuote(orderId: number, mode: DocumentViewMode): Observable<Blob> {
        return this.downloadOrderDocument('Quote', orderId, mode);
    }

    downloadDispatchSheet(orderId: number, mode: DocumentViewMode): Observable<Blob> {
        return this.downloadOrderDocument('DispatchSheet', orderId, mode);
    }

    downloadInvoice(invoiceId: number, mode: DocumentViewMode): Observable<Blob> {
        return this.downloadOrderDocument('Invoice', invoiceId, mode);
    }

    emailBillOfLanding(orderId: number, email: string): Observable<ApiResponse> {
        return this.emailOrderDocument('BillOfLanding', orderId, email);
    }

    emailQuote(orderId: number, email: string): Observable<ApiResponse> {
        return this.emailOrderDocument('Quote', orderId, email);
    }

    emailInvoice(invoiceId: number, email: string): Observable<ApiResponse> {
        return this.emailOrderDocument('Invoice', invoiceId, email);
    }

    emailDispatchSheet(orderId: number, email: string): Observable<ApiResponse> {
        return this.emailOrderDocument('DispatchSheet', orderId, email);
    }

    private downloadOrderDocument(url: string, entityId: number, mode: DocumentViewMode): Observable<Blob> {
        return this.http.get(`${environment.apiBaseUrl}Documents/${url}/${entityId}/${mode}`, {
            responseType: 'blob',
        }).pipe(catchError(handleApiError));
    }

    private emailOrderDocument(url: string, orderId: number, email: string) {
        return this.http.put<ApiResponse>(`${environment.apiBaseUrl}Documents/${url}/email/${orderId}`,{ email })
            .pipe(catchError(handleApiError));
    }
}
