import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PickupOrderDetailComponent } from './pickup-order-detail.component';
import { NgbDropdownModule, NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from '@app/shared/shared.module';
import { FormsModule } from '@angular/forms';
import { FormValidateModule } from '@app/@shared/form-validate/form-validate.module';
import { RepeaterModule } from '@app/@shared/repeater/repeater.module';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { EditDeliveryDialogModule } from '../edit-delivery-dialog/edit-delivery-dialog.module';
import { EditDeliveryItemDialogModule } from '../edit-delivery-item-dialog/edit-delivery-item-dialog.module';
import { EditPickupDialogModule } from '../edit-pickup-dialog/edit-pickup-dialog.module';
import { EditPickupItemDialogModule } from '../edit-pickup-item-dialog/edit-pickup-item-dialog.module';
import { OrderStopModule } from '../order-stop/order-stop.module';
import { DialogsModule } from '@app/@shared/dialogs/dialogs.module';



@NgModule({
  declarations: [
    PickupOrderDetailComponent,
  ],
  exports: [
    PickupOrderDetailComponent,
  ],
  imports: [
    CommonModule,
    NgbDropdownModule,
    NgbModalModule,
    FormsModule,
    FormValidateModule,
    RepeaterModule,
    SharedModule,
    DragDropModule,
    EditPickupDialogModule,
    EditPickupItemDialogModule,
    EditDeliveryDialogModule,
    EditDeliveryItemDialogModule,
    OrderStopModule,
    DialogsModule,
  ]
})
export class PickupOrderDetailModule { }
