// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  border-color: #f5f5f5;
  border-style: solid;
  border-width: 1.5px 0;
  display: block;
}

i {
  cursor: pointer;
  padding: 0 15px;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/calendar-navigation/calendar-navigation.component.scss"],"names":[],"mappings":"AAAA;EACI,qBAAA;EACA,mBAAA;EACA,qBAAA;EACA,cAAA;AACJ;;AAEA;EACI,eAAA;EACA,eAAA;AACJ","sourcesContent":[":host {\n    border-color: #f5f5f5;\n    border-style: solid;\n    border-width: 1.5px 0;\n    display: block;\n}\n\ni {\n    cursor: pointer;\n    padding: 0 15px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
