import { OrderItem } from '@models/order-item.model';
import { ShipmentType } from '@models/shipment-type.model';
import { Customer } from '@models/customer.model';
import LatLngLiteral = google.maps.LatLngLiteral;
import { Waypoint } from './map.model';

export enum OrderStopType {
    Pickup = 'Pickup',
    Delivery = 'Delivery',
    Transfer = 'Transfer',
    Both = '',
}

export interface RouteBox {
    items?: OrderItem[];
    index: number;

    orderId: number;
    orderNumber:string;
    customer: Customer;
    fullAddress: string;

    orderItemStopId: number;
    orderStopId: number;
    stopType: OrderStopType;
    quantity: number;
    quantityToPick: number;
    pickQuantity: number;

    qtyAssinedToGroup: number;

    dateType: number | string;
    date: string;
    timeType: number;
    time: string;
    orderItem: OrderItem;
    shipmentType: ShipmentType;
    waypoint: Waypoint;
}
