import { AvatarComponent } from "./avatar/avatar.component";
import { BasicDatepickerComponent } from "./basic-datepicker/basic-datepicker.component";
import { CalendarNavigationComponent } from "./calendar-navigation/calendar-navigation.component";
import { CheckboxComponent } from "./form/checkbox/checkbox.component";
import { ControlMessages } from "./form/control-messages";
import { RadioComponent } from "./form/radio/radio.component";
import { HttpMessageComponent } from "./http-message/http-message.component";
import { IconComponent } from "./icon/icon.component";
import { PickupDatepickerComponent } from "./pickup-datepicker/pickup-datepicker.component";
import { PickupDatetimepickerComponent } from "./pickup-datetimepicker/pickup-datetimepicker.component";
import { PickupTimeComponent } from "./pickup-time/pickup-time.component";
import { SwitchComponent } from "./form/switch/switch.component";
import { DatepickerComponent } from "./form/datepicker/datepicker.component";
import { DateRangePickerComponent } from "./form/date-range-picker/date-range-picker.component";
import { AvatarUploadComponent } from "./form/avatar-upload/avatar-upload.component";
import { StatusBadgeComponent } from "./status-badge/status-badge.component";
import { AuxiliaryComponents } from "./auxiliary";

const Components = [
  AvatarComponent,
  IconComponent,
  ControlMessages,
  HttpMessageComponent,
  PickupDatepickerComponent,
  PickupTimeComponent,
  CalendarNavigationComponent,
  
  // Form
  CheckboxComponent,
  SwitchComponent,
  RadioComponent,
  BasicDatepickerComponent,
  PickupDatetimepickerComponent,
  DatepickerComponent,
  DateRangePickerComponent,
  AvatarUploadComponent,
  StatusBadgeComponent,


  // TO-DO: Create septate mobile
  ...AuxiliaryComponents,
];


export { Components };
