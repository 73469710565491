import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrderBillingComponent } from './order-billing.component';
import { NgbDropdownModule, NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from '@app/shared/shared.module';
import { FormsModule } from '@angular/forms';
import { FormValidateModule } from '@app/@shared/form-validate/form-validate.module';



@NgModule({
  declarations: [
    OrderBillingComponent,
  ],
  exports: [
    OrderBillingComponent,
  ],
  imports: [
    CommonModule,
    NgbDropdownModule,
    NgbModalModule,
    FormsModule,
    FormValidateModule,
    SharedModule
  ]
})
export class OrderBillingModule { }
