import { AfterContentChecked, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { saveAs } from 'file-saver';
import { AccountingService } from '@app/shared/services/accounting.service';
import { Invoice, TnxStatus } from '@models/invoice.model';
import { InvoiceLine, LineType } from '@models/invoice-line.model';
import { Company } from '@app/models/company.model';
import { Customer } from '@app/models/customer.model';
import { concat, filter as lodashFilter, omit } from 'lodash';
import { HelperService } from '@app/shared/services/helper.service';
import { DocumentViewerComponent } from '@app/@shared/document-viewer/document-viewer.component';
import { array, object, string, number, date } from 'yup';
import { AuthService } from '@app/shared/services/auth/auth.service';
import { NgbCalendar, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DocumentService } from '@app/shared/services/document.service';
import { DocumentViewMode } from '@app/models/document.model';
import { pickupTypes } from '@app/data/order';
import { debounceTime, switchMap, tap, } from "rxjs/operators";
import { Subject, of } from "rxjs";
import { User } from '@app/models/user.model';
import { Attachment } from '@app/models/attachment.model';
import { EntityType, EntityTypes } from '@app/models/entity-type.model';
import { ImagesService } from '@app/shared/services/images.service';
import { environment } from '@environments/environment';
import mime from 'mime';
import { DialogService } from '@app/@shared/dialogs/dialog.service';
import * as moment from 'moment';
import { EmailType } from '@app/models/sent-email.model';
import { filterAndSortSentEmailsByEmailType, reverseAmount } from '@app/@shared/utils';

export enum DateKeyEnum {
    TODAY = 'Due today',
    TOMORROW = 'Due tomorrow',
    END_OF_THE_WEEK = 'Due end of the week',
    PLUS_15_DAYS = 'Due 15 days',
    PLUS_30_DAYS = 'Due 30 days',
    PLUS_60_DAYS = 'Due 60 days',
    PLUS_90_DAYS = 'Due 90 days',
}
interface Item {
    searchTerm: string;
    previousSearchTerm?: string;
}

@UntilDestroy()
@Component({
    selector: 'app-add-edit-accounting-invoice',
    templateUrl: './add-edit-accounting-invoice.component.html',
    styleUrls: ['./add-edit-accounting-invoice.component.scss']
})
export class AddEditAccountingInvoiceComponent implements AfterContentChecked, OnInit {
    pickupTypes = pickupTypes;
    invoice: Invoice = {
        status: TnxStatus.Draft,
        date: moment().toDate(),
        dueDate: moment().add(15, 'days').toDate()
    };
    imageUrl = environment.imagePreviewUrl
    invoiceItems: InvoiceLine[] = [];
    invoiceFees: InvoiceLine[] = [];
    company: Company;
    selectedCustomer: Customer;
    autogenerateInvoiceNumber: string;
    customsInvoiceNumber: string;
    isEditNumber: boolean;
    orderAttachments: Attachment[];
    invoiceAttachments: Attachment[];
    isDuplicateInvoiceNumber = false
    EntityTypes = EntityTypes;
    @ViewChild('customsInvoiceNumberInput') customsInvoiceNumberInput: ElementRef<HTMLElement>
    maxLengthInvoice: number = 16;
    minDate = this.calendar.getToday();
    maxDate = this.calendar.getNext(this.calendar.getToday(), 'y', 1);
    duaDateOptions: any[] = [
        {
            title: DateKeyEnum.TODAY,
            value: moment()
        },
        {
            title: DateKeyEnum.TOMORROW,
            value: moment().add(1, 'days')
        },
        {
            title: DateKeyEnum.END_OF_THE_WEEK,
            value: moment().endOf('week')
        },
        {
            title: DateKeyEnum.PLUS_15_DAYS,
            value: moment().add(15, 'days')
        },
        {
            title: DateKeyEnum.PLUS_30_DAYS,
            value: moment().add(30, 'days')
        },
        {
            title: DateKeyEnum.PLUS_60_DAYS,
            value: moment().add(60, 'days')
        },
        {
            title: DateKeyEnum.PLUS_90_DAYS,
            value: moment().add(90, 'days')
        },
    ];

    private _data: Invoice;
    @Input()
    public get data(): Invoice {
        return this._data;
    }
    public set data(value: Invoice) {
        this._data = value;
        this.invoice = value;
        if (this.invoice) {
            this.helperService.isLoading = true
            this.updateInvoiceData(this.invoice);
        }
    }

    @Output() dataChange = new EventEmitter<Invoice>();

    @Output() onEdit = new EventEmitter<boolean>();
    invoiceNumber$: Subject<any> = new Subject();

    isSave: boolean;
    originalTotal: number = 0;
    acceptedFiles: any[] = [];

    get totalBalance() {
        let sum = 0;
        this.invoiceItems.forEach(element => {
            if (element.amount && !isNaN(element.amount)) {
                const qty = Number(element.quantity) || 1;
                sum += (Number(element.amount) * qty);
            }
        });
        this.invoiceFees.forEach(element => {
            if (element.amount && !isNaN(element.amount)) {
                sum += Number(element.amount);
            }
        });
        return reverseAmount(sum);
    }

    get customerBalance() {
        if (this.invoice?.customer) {
            return this.invoice?.invoiceId ? (this.totalBalance - this.originalTotal + this.invoice?.customer?.openBalance) :  (this.totalBalance + this.invoice?.customer?.openBalance);
        }
        return 0;
    }

    validationSchema = object().shape({
        customer: object().shape({
            customerName: string().label('Company Name').required()
        }),
        invoiceItems: array()
            .of(
                object().shape({
                    quantity: number().label('Quantity').positive().nullable(),
                    measurement: string().label('Measurement').nullable(),
                    weight: string().label('Weight').nullable(),
                    amount: number().label('Item Cost').positive().nullable(),
                })
            ),
        invoiceFees: array()
            .of(
                object().shape({
                    charge: string().label('Fee Type').nullable(),
                    amount: number().label('Fee Cost').nullable(),
                })
            ),
        date: date().label('Date Issued').nullable().required(),
        dueDate: date().label('Due Date').nullable().required(),
        order:object().shape({
            purchaseOrderNumber: string().label('PO Number').nullable().max(256),
        })
    });

    constructor(
        private activatedRoute: ActivatedRoute,
        private accountingService: AccountingService,
        private helperService: HelperService,
        private router: Router,
        private authService: AuthService,
        private readonly cdr: ChangeDetectorRef,
        private ngbModal: NgbModal,
        private documentService: DocumentService,
        private dialogService: DialogService,
        private imagesService: ImagesService,
        private el: ElementRef,
        private calendar: NgbCalendar,
    ) {
        if (this.router.getCurrentNavigation()?.extras?.state?.customer != null) {
            const customer = this.router?.getCurrentNavigation()?.extras?.state?.customer;
            if (customer && customer?.customerId) {
                this.setupCustomerInfo(customer);
            }
        }
    }

    ngOnInit() {
        this.authService.$user
            .pipe(
                tap((user: User) => this.company = user?.company),
                untilDestroyed(this)
            )
            .subscribe();

        this.invoiceNumber$.pipe(
            debounceTime(300),
            switchMap((invoiceNumber: string) => {
                let searchNumber = invoiceNumber
                if ((this.autogenerateInvoiceNumber && this.autogenerateInvoiceNumber === invoiceNumber) || !invoiceNumber) {
                    return of(false);
                }
                if (this.autogenerateInvoiceNumber) {
                    searchNumber = this.autogenerateInvoiceNumber + '-' + searchNumber
                }
                return this.accountingService.checkInvoiceNo(searchNumber).then(({ data }) => {
                    return !!data
                }).catch(() => {
                    return false
                })
            }),
            untilDestroyed(this)
        ).subscribe(response => {
            this.isDuplicateInvoiceNumber = response
        });
    }

    editInvoiceNumber(): void {
        this.isEditNumber = true;
        setTimeout(() => {
            this.customsInvoiceNumberInput.nativeElement.focus()
        }, 100);
    }

    async backTOPrevuePage() {
        if (this.invoice?.invoiceId) {
            this.scrollToTop('#invoiceDetails');
            this.onEdit.emit(false);
        } else {
            this.router.navigateByUrl('app/accounting');
        }
    }

    selectFeeType(type, item) {
        if (typeof type === 'object') {
            item.charge = type?.name;
            if (type?.pricePerUnit != null) {
                item.amount = type?.pricePerUnit
            }
        } else {
            item.charge = type
        }
    }

    editInvoiceLine(item) {
        item.isEdit = !item.isEdit;
    }

    deleteInvoiceLine(item) {
        item.isActive = false
    }

    ngAfterContentChecked() {
        this.cdr.detectChanges();
    }

    async updateInvoiceData(invoice) {
        this.loadInvoiceAttachments(invoice?.invoiceId);
        if (invoice?.customerId) {
            await this.updateSelectedCustomerData(invoice?.customerId);
        }
        if (invoice?.orderId) {
            this.loadOrderAttachments(invoice?.orderId)
        }
        this.invoice.date = new Date(invoice?.date);
        if (invoice.customer) {
            this.selectedCustomer = invoice.customer;
        }
        if (!invoice?.dueDate) {
            this.invoice.dueDate = moment().add(15, 'days').toDate()
        }
        this.invoice = invoice;
        if (this.invoice?.lines) {
            this.invoiceFees = lodashFilter(this.invoice.lines, { lineType: LineType.Charge, isActive: true });
            this.invoiceItems = lodashFilter(this.invoice.lines, { lineType: LineType.Item, isActive: true });
        }
        if (this.invoiceItems?.length === 0) {
            this.invoiceItems = []
        }
        if (this.invoiceFees?.length === 0) {
            this.invoiceFees = []
        }
        if (this.invoice?.number) {
            const dashIndex = this.invoice?.number?.indexOf('-');
            this.maxLengthInvoice = 16;
            if (dashIndex !== -1) {
                this.autogenerateInvoiceNumber = this.invoice?.number?.slice(0, dashIndex).trim();
                this.customsInvoiceNumber = this.invoice?.number?.slice(dashIndex + 1).trim();
                this.maxLengthInvoice -= this.autogenerateInvoiceNumber?.length;
            } else {
                this.autogenerateInvoiceNumber = this.invoice?.number;
                this.maxLengthInvoice -= this.autogenerateInvoiceNumber?.length;
            }

            if (this.maxLengthInvoice < 1) {
                this.maxLengthInvoice = 0;
            }

        }

        this.originalTotal = this.totalBalance;
        this.helperService.isLoading = false
    }

    private async loadOrderAttachments(id) {
        try {
            this.orderAttachments = await this.imagesService.getListByOrderAttachments(id);
        } catch (error) {
            this.helperService.errorMessage(error)
        }
    }

    private async loadInvoiceAttachments(id) {
        try {
            this.invoiceAttachments = await this.imagesService.getListByInvoiceAttachments(id);
        } catch (error) {
            this.helperService.errorMessage(error)
        }
    }

    selectCustomer(e): void {
        if (!this.selectedCustomer || e == null || !e) {
            this.invoice.customer = null;
            this.invoice.billToDetails = null;
        } else {
            this.setupCustomerInfo(e);
        }
    }

    customerResultFormatter(customer) {
        return customer.customerName;
    }

    async setupCustomerInfo(customer) {           
        this.invoice.customer = omit(customer, 'invoices');
        this.selectedCustomer = customer;
        const defaultAddress = await customer.addresses.filter(x => x.entityType === 1);          
        const add = defaultAddress[0] || null;    
        if (add) {           
            this.invoice.billToDetails = `${add?.addressLine1} ${add?.addressLine2 || ''}\n${add?.city}, ${add?.state}, ${add?.zip}`;
        } else {
            this.invoice.billToDetails = null;
        }
    }
    
    onInvoiceNumberChange() {
        this.invoiceNumber$.next(this.customsInvoiceNumber);
    }

    saveInvoice(f, closeAfter: boolean, sendInvoice?: boolean) {
        if (f.errors?.invoiceItems) {
            this.handleErrors(this.invoiceItems, '#invoiceItems');
        } else if (f.errors?.invoiceFees) {
            this.handleErrors(this.invoiceFees, '#invoiceFees');
        } else if (f.errors) {
            this.scrollToTop('#invoiceDetails');
        } else if (f.isValid) {

            this.isSave = true

            const request = omit(this.invoice);
            request.lines = concat(
                this.invoiceItems.map((item) => {
                    return {
                        ...item,
                        lineType: LineType.Item
                    }
                }),
                this.invoiceFees.map((item) => {
                    return {
                        ...item,
                        lineType: LineType.Charge
                    } as InvoiceLine
                })
            )
            if (this.autogenerateInvoiceNumber && this.customsInvoiceNumber) {
                request.number = `${this.autogenerateInvoiceNumber}-${this.customsInvoiceNumber}`
            } else {
                request.number = this.autogenerateInvoiceNumber || this.customsInvoiceNumber
            }


            if (this.invoice?.invoiceId) {
                this.updateInvoice(this.invoice.invoiceId, request, closeAfter, sendInvoice);
            } else if (request.number) {
                this.accountingService.checkInvoiceNo(request.number).then(({ data }) => {
                    if (data) {
                        this.dialogService.confirm({
                            message: "Invoice for the Order already exist! Would you like to replace Invoice?",
                        })
                            .then(() => {
                                this.updateInvoice(data, request, closeAfter, sendInvoice);
                            })
                            .catch(() => {
                                this.isSave = false;
                            })
                    } else {
                        this.createInvoice(request, closeAfter)
                    }
                });
            } else {
                this.createInvoice(request, closeAfter)
            }
        }
    }

    updateInvoice(invoiceId, request, closeAfter, sendInvoice?: boolean) {
        this.helperService.isLoading = true;
        this.accountingService.updateInvoice(invoiceId, request)
            .then(async ({ data }) => {
                await this.scrollToTop('#invoiceDetails');
                this.helperService.successMessage("Invoice successfully saved")
                if (closeAfter) {
                    this.router.navigate(['/app/accounting'], { queryParams: { closeTabId: invoiceId } });
                    this.helperService.isLoading = false;
                    this.isSave = false;
                    return
                }
                await this.dataChange.emit(data);
                this.router.navigate(['/app/accounting'], { queryParams: { isReloadAccounting: true } });
                if (sendInvoice) {
                    this.showInvoice();
                } else {
                    this.invoiceItems.map((res) => {
                        res.isEdit = false
                    })
                    this.invoiceFees.map((res) => {
                        res.isEdit = false
                    })
                }   
                this.helperService.isLoading = false;
                this.isSave = false;
            })
            .catch(error => {
                this.helperService.isLoading = false;
                this.isSave = false;
                this.helperService.errorMessage(error)
            });
    }

    createInvoice(request, closeAfter) {
        this.helperService.isLoading = true;
        return this.accountingService.createInvoice(request)
            .then(async ({ data }) => {
                this.helperService.successMessage("Invoice successfully saved");
                if (this.acceptedFiles?.length > 0 && this.acceptedFiles) {
                    for await (const file of this.acceptedFiles) {
                        await this.imagesService.uploadAttachment(EntityType.Invoice, data.invoiceId, file.file);
                    }
                }
                if (data?.customerId) {
                    await this.updateSelectedCustomerData(data?.customerId);
                }
                if (closeAfter) {
                    this.router.navigate(['/app/accounting'])
                } else {
                    this.router.navigate(['/app/accounting'], { queryParams: { id: data.invoiceId } });
                }
            })
            .finally(() => {
                this.helperService.isLoading = false;
                this.isSave = false;
            })
            .catch((error) => {
                // this.isSave = false;
                // this.isSaveClose = false;
                this.helperService.errorMessage(error)
            });
    }

    updateSelectedCustomerData(customerId) {
        // Update customer data in memory;
        if (customerId || this.accountingService.selectedCustomer.customerId) {
            return this.accountingService.getAccountingCustomerBasicDetails({
                customers: [customerId || this.accountingService.selectedCustomer.customerId]
            }).then(({ list }) => {
                if (list?.length > 0) {
                    this.accountingService.selectedCustomer = list[0];
                }
            }).catch(() => {
                // noting
            })
        }
    }

    handleErrors(items: any[], elementId: string) {
        this.scrollToTop(elementId);
        items.forEach((res) => {
            res.isEdit = true;
        });
    }

    async scrollToTop(elementId: string) {
        const element = await this.el.nativeElement.querySelector(elementId);
        if (element) {
            await element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
        }
    }

    async showInvoice() {
        const activeModal = this.ngbModal.open(DocumentViewerComponent, {
            scrollable: true,
            size: "xl",
        });
        const content = await this.documentService.downloadInvoice(this.invoice?.invoiceId, DocumentViewMode.Html)
        if (!content?.changingThisBreaksApplicationSecurity) {
            activeModal.componentInstance.noResultsFound = true;
        }

        let invoiceSentEmails = await this.accountingService.getSentEmails(this.invoice?.invoiceId, { suggestCustomerPastSentEmails: true });
        invoiceSentEmails = await filterAndSortSentEmailsByEmailType(invoiceSentEmails, EmailType?.Invoice);

        activeModal.componentInstance.content = content;
        activeModal.componentInstance.entity = this.invoice;

        if (invoiceSentEmails?.length > 0) {
            activeModal.componentInstance.emails = invoiceSentEmails?.map(se => ({ email: se?.to, type: se?.emailType, label: se?.to }));
            activeModal.componentInstance.selectedEmail = invoiceSentEmails?.filter(x => x?.isSelected).map(rse => rse?.to);
            activeModal.componentInstance.showCustomEmails = true;
        }
        else {
            activeModal.componentInstance.showEmail = true;
        }

        activeModal.result.then(
            (result) => {
                if (result) {
                    if (result.type == "sendEmail") {
                        this.documentService
                            .emailInvoice(
                                result?.entity?.invoiceId,
                                result?.email
                            )
                            .then(async () => {
                                this.router.navigate(['/app/accounting'], { queryParams: { closeTabId: this.invoice?.invoiceId } })
                                this.helperService.successMessage(
                                    "The invoice has been sent"
                                );
                            })
                            .catch((error) => {
                                this.helperService.errorMessage(error);
                            }).finally(() => {
                                if (this.invoice?.customerId) {
                                    this.updateSelectedCustomerData(this.invoice?.customerId);
                                }
                            });

                    } else {
                        this.documentService
                            .downloadInvoice(
                                result?.entity?.invoiceId,
                                DocumentViewMode.Pdf
                            )
                            .then((file) => {
                                saveAs(
                                    file,
                                    `Invoice-${result?.entity?.invoiceId}.pdf`
                                );
                            });
                    }
                }
            },
            () => { }
        );
    }

    removeFiles(index) {
        this.acceptedFiles.splice(index, 1);
    }

    uploadFiles(files: FileList): void {
        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            if (file.type.startsWith('image/')) {
                const reader = new FileReader();
                reader.onload = (e: any) => {
                    const newFile = {
                        name: file.name,
                        url: e.target.result,
                        file: file
                    };

                    const existingFileIndex = this.acceptedFiles.findIndex(f => f.name === newFile.name);

                    if (existingFileIndex !== -1) {
                        this.acceptedFiles[existingFileIndex] = newFile;
                    } else {
                        this.acceptedFiles.push(newFile);
                    }
                };
                reader.readAsDataURL(file);
            }
        }
    }


    async uploadInvoiceAttachments(files: FileList | any) {
        try {
            const invoiceId = this.invoice?.invoiceId;
            if (invoiceId) {
                for await (const file of files) {
                    const { data }: any = await this.imagesService.uploadAttachment(EntityType.Invoice, invoiceId, file);
                    if (data) {
                        this.invoiceAttachments.push(data)
                        this.helperService.successMessage("Attachment successfully upload.")
                    }
                }
            } else {
                this.uploadFiles(files);
            }
        } catch (error) {
            this.helperService.errorMessage(error, "Error while uploading attachment for invoice.");
        }
    }

    async uploadOrderAttachments(files: any[]) {
        try {
            const orderId = this.invoice?.orderId;
            for await (const file of files) {
                const { data }: any = await this.imagesService.uploadAttachment(EntityType.Order, orderId, file);
                if (data) {
                    this.orderAttachments.push(data)
                    this.helperService.successMessage("Order Attachment successfully upload.")
                }
            }
        } catch (error) {
            this.helperService.errorMessage(error, "Error while uploading attachment for order.");
        }
    }

    deleteAttachment(attachment, index, type: 'Order' | 'Invoice') {
        this.imagesService.deleteAttachment(attachment?.attachmentId).then((res) => {
            if (type === 'Order') {
                this.orderAttachments.splice(index, 1)
                this.helperService.successMessage("Order attachment successfully deleted.")
            } else {
                this.invoiceAttachments.splice(index, 1)
                this.helperService.successMessage("Invoice attachment successfully deleted.")
            }
        }).catch((error) => {
            this.helperService.errorMessage(error)
        })
    }

    viewAttachment(attachment) {
        this.helperService.isLoading = true
        this.imagesService.downloadAttachment(attachment).then((res) => {
            const blob = new Blob([res], { type: mime.getType(attachment.name)});
            const fileURL = window.URL.createObjectURL(blob);
            this.helperService.isLoading = false
            window.open(fileURL, '_blank');
        }).catch((error) => {
            this.helperService.isLoading = false
            this.helperService.errorMessage(error)
        })
    }


}
