// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host .integration-img {
  width: 100px;
  height: 50px;
}
:host .warning-icon {
  color: red;
  font-size: 30px;
}
:host .tooltip {
  position: relative;
  display: inline-block;
  opacity: 1;
}
:host .tooltip .tooltiptext {
  visibility: hidden;
  width: 150px;
  background-color: rgb(187, 184, 184);
  color: rgb(0, 0, 0);
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  font-size: 15px;
  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: 50%;
}
:host .tooltip:hover .tooltiptext {
  visibility: visible;
}`, "",{"version":3,"sources":["webpack://./src/app/components/main/settings/integrations/integrations.component.scss"],"names":[],"mappings":"AACE;EACE,YAAA;EACA,YAAA;AAAJ;AAGE;EACE,UAAA;EACA,eAAA;AADJ;AAIE;EACE,kBAAA;EACA,qBAAA;EACA,UAAA;AAFJ;AAKE;EACE,kBAAA;EACA,YAAA;EACA,oCAAA;EACA,mBAAA;EACA,kBAAA;EACA,cAAA;EACA,kBAAA;EACA,eAAA;EACA,kBAAA;EACA,UAAA;EACA,YAAA;EACA,SAAA;AAHJ;AAME;EACE,mBAAA;AAJJ","sourcesContent":[":host {\n  .integration-img {\n    width: 100px;\n    height: 50px;\n  }\n\n  .warning-icon {\n    color: red;\n    font-size: 30px;\n  }\n\n  .tooltip {\n    position: relative;\n    display: inline-block;\n    opacity: 1;\n  }\n  \n  .tooltip .tooltiptext {\n    visibility: hidden;\n    width: 150px;\n    background-color: rgb(187, 184, 184);\n    color: rgb(0, 0, 0);\n    text-align: center;\n    padding: 5px 0;\n    border-radius: 6px;\n    font-size: 15px;\n    position: absolute;\n    z-index: 1;\n    bottom: 100%;\n    left: 50%;\n  }\n  \n  .tooltip:hover .tooltiptext {\n    visibility: visible;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
