// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-info {
  font-size: 10px;
  color: #5d666d;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/auxiliary/user-info/user-info.component.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,cAAA;AACJ","sourcesContent":[".user-info {\n    font-size: 10px;\n    color: #5d666d;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
