import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { User } from '@app/models/user.model';
import { ApiPaginationData, ApiPaginationResponse, ApiResponse, ListRequest } from '@app/models/core/base';
import { toParams } from 'src/utils/to-params';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';


@Injectable({
    providedIn: 'root'
})
export class UserService {

    private cancelRequestGetAllUsers$ = new Subject<void>();
    constructor(
        private http: HttpClient
    ) {

    }

    changePassword(request?: any) {
        return this.http.post('Auth/ChangePassword', request).toPromise();
    }

    getUser() {
        return this.http.get<ApiResponse<User>>('App/User').toPromise()
            .then((({ data }) => {
                return data;
            }));
    }

    getUserData() {
        return this.http.get<ApiResponse<User>>('App/UserData').toPromise()
            .then((({ data }) => {
                return data;
            }));
    }

    getAllUsers(request?:ListRequest) {       
        return new Promise<ApiPaginationData<User>>((resolve, reject) => {
            this.http.get<ApiPaginationResponse<User>>('Settings/Users', { params: toParams(request) })
                .pipe(
                    takeUntil(this.cancelRequestGetAllUsers$)
                )
                .subscribe(
                    ({ data }: any) => {
                        resolve(data);
                    },
                    error => {
                        reject(error);
                    }
                );
        });
    }

    cancelPendingRequestGetAllUsers() {
        this.cancelRequestGetAllUsers$.next();
    }

    getUserPrompts(request?){
        return this.http.get<ApiPaginationResponse<any>>('UserPrompts', { params: toParams(request) }).toPromise()
            .then((({ data }) => data));
    }
    // getCompany() {
    //     return this.http.get('Company/Info').toPromise();
    // }

    // updateCompany(request) {
    //     return this.http.put('Company/Info', request).toPromise();
    // }

}
