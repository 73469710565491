import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiPaginationResponse, ApiResponse, ListRequest } from '@app/models/core/base';
import { toParams } from 'src/utils/to-params';
import { Order } from '@app/models/order.model';
import { Route } from '@app/models/route.model';
import { firstValueFrom, Observable } from 'rxjs';
import { OrderStopCallOutcomeResultEnum } from '@app/models/order-stop.model';


@Injectable({
    providedIn: 'root'
})
export class QuoteService {

    private basePath = 'Quotes'

    constructor(
        private http: HttpClient
    ) {

    }

    get(id: string | number) {
        return this.http.get<ApiResponse<any>>(`${this.basePath}/${id}`, { params: { SortBy: "date", SortDirection: "Descending", moveCompletedToTheEnd: true } }).toPromise()
            .then((({ data }) => data));
    }

    getAll(request?: ListRequest) {
        return this.http.get<ApiPaginationResponse<any>>(`${this.basePath}`, { params: toParams(request) }).toPromise()
            .then((({ data }) => data));
    }

    create(request?: ListRequest) {
        return this.http.post<ApiResponse<any>>(`${this.basePath}`, request).toPromise()
            .then((({ data }) => data));
    }

    update(id: string | number, request?: ListRequest, updateType?: any, returnQuote = true) {
        return this.http.put<ApiResponse<any>>(`${this.basePath}/${id}/${updateType}?returnQuote=${returnQuote}`, request).toPromise()
            .then((({ data }) => data));
    }

    delete(id: number) {
        return this.http.delete<ApiResponse<any>>(`${this.basePath}/${id}`)
            .toPromise()
            .then(({ data }) => data);
    }

    checkQuoteNo(orderNumber) {
        return this.http.get<ApiResponse<Order>>(`${this.basePath}/QuoteCheck/${orderNumber}`).toPromise();
    }

    importOrderStops(data: FormData) {
        return this.http.post<ApiResponse<any>>(`${this.basePath}/ImportOrderStops`, data)
            .toPromise();
    }

    getImportExample(): Observable<Blob> {
        return this.http.get(`${this.basePath}/ImportOrderStopsExample`, {
            responseType: 'blob',
        });
    }
    repeatOrder(orderStopId: string | number) {
        return this.http.put<ApiResponse<any>>(`${this.basePath}/${orderStopId}/repeat`, {}).toPromise()
    }
    swapOrder(orderStopId: string | number) {
        return this.http.put<ApiResponse<any>>(`${this.basePath}/${orderStopId}/Swap`, {}).toPromise()
    }
    undoOrderAllStops(orderId: string | number) {
        return this.http.put<ApiResponse<any>>(`${this.basePath}/${orderId}/UndoOrder`, {}).toPromise()
    }
    getOrderRoutes(orderId: string | number) {
        return this.http.get<ApiResponse<Route[]>>(`${this.basePath}/${orderId}/Routes`, { params: { includeInactiveRoutes: true } }).toPromise()
            .then((({ data }) => data));
    }

    copyOrderToDriver(orderId: string | number) {
        return this.http.get<ApiResponse<Order>>(`${this.basePath}/${orderId}/copyOrderToDriver`).toPromise()
            .then((({ data }) => data));
    }

    updateCallOutcome(orderStopId: string | number, callOutcome: OrderStopCallOutcomeResultEnum) {
        return firstValueFrom(this.http.put<ApiResponse<any>>(`${this.basePath}/${orderStopId}/callOutcome/${callOutcome}`, {}))
      }


}
