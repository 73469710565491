import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CreateDriverComponent } from './create-driver/create-driver.component';
import { DriverLayoutComponent } from './driver-layout/driver-layout.component';
import { DriverDetailsComponent } from './driver-details/driver-details.component';
import { TabRedirectGuard } from '@app/@shared/list-tabs/tab-redirect-guard';

const routes: Routes = [
  {
    path: '',
    component: DriverLayoutComponent,
  },
  {
    path: 'add',
    component: CreateDriverComponent,
  },
  {
    path: 'edit/:id',
    data: {
      redirectTo: `/app/drivers`,
      paramsKey: 'id',
      queryParamsKey: 'id',
    },
    canActivate: [TabRedirectGuard],
    component: DriverDetailsComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DriversRoutingModule { }
