import { Injectable } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { CompanyService } from '../company.service';

@Injectable({
    providedIn: 'root'
})
export class AppInitService {

    constructor(
        private authService: AuthService,
    ) { }

    async init() {
        await this.authService.init();
    }
}
