// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host .table {
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.06);
  margin: 0;
}
:host .attachments ::ng-deep div {
  height: 26px;
  width: 26px;
  min-height: 26px;
  border-radius: 99%;
  padding: 0;
}
:host .attachments ::ng-deep div .upload-label {
  font-size: 10px;
}
:host .quantity input {
  width: 60px;
}
:host .quantity .form-control,
:host .quantity .form-select,
:host .quantity .btn {
  min-height: 30px !important;
  height: 30px;
}
:host .icon-down-arrow-up {
  transform: rotate(180deg);
}
:host .is-un-collapsed-charge-types {
  max-height: 100% !important;
}
:host .charge-types {
  max-height: 78px;
  overflow: hidden;
  transition: max-height 0.5s;
  padding-top: 4px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/main/routes/routes-status-block/confirm-stop-dialog/confirm-stop-dialog.component.scss"],"names":[],"mappings":"AACI;EACI,2CAAA;EACA,SAAA;AAAR;AAIY;EACI,YAAA;EACA,WAAA;EACA,gBAAA;EACA,kBAAA;EACA,UAAA;AAFhB;AAGgB;EACI,eAAA;AADpB;AAOQ;EACI,WAAA;AALZ;AAOQ;;;EAGI,2BAAA;EACA,YAAA;AALZ;AASI;EACI,yBAAA;AAPR;AAUI;EACI,2BAAA;AARR;AAWI;EACI,gBAAA;EACA,gBAAA;EACA,2BAAA;EACA,gBAAA;AATR","sourcesContent":[":host {\n    .table {\n        box-shadow: 0px 2px 6px rgb(0 0 0 / 6%);\n        margin: 0;\n    }\n    .attachments {\n        ::ng-deep {\n            div {\n                height: 26px;\n                width: 26px;\n                min-height: 26px;\n                border-radius: 99%;\n                padding: 0;\n                .upload-label {\n                    font-size: 10px;\n                }\n            }\n        }\n    }\n    .quantity {\n        input {\n            width: 60px;\n        }\n        .form-control,\n        .form-select,\n        .btn {\n            min-height: 30px !important;\n            height: 30px;\n        }\n    }\n\n    .icon-down-arrow-up {\n        transform: rotate(180deg);\n      }\n\n    .is-un-collapsed-charge-types {\n        max-height: 100% !important;\n    }\n\n    .charge-types {\n        max-height: 78px;\n        overflow: hidden;\n        transition: max-height 0.5s;\n        padding-top: 4px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
