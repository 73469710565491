import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilService } from 'src/app/services/util.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Customer } from '@models/customer.model';
import { CustomerService } from '@app/shared/services/customer.service';
import { HelperService } from '@app/shared/services/helper.service';
import { EntityType } from '@app/models/entity-type.model';
import { Address } from '@app/models/address.model';
import { AddressService } from '@app/shared/services/address.service';

@Component({
    selector: 'app-customer-details',
    templateUrl: './customer-details.component.html',
    styleUrls: ['./customer-details.component.scss'],
    host: {
        '[class.page-container]': "true"
    }
})
export class CustomerDetailsComponent implements OnInit {
    activeId = 'companyInformation'

    private _data: Customer;
    @Input()
    public get data(): Customer {
        return this._data;
    }
    public set data(value: Customer) {
        this._data = value;
        this.customer = value;
    }
    @Output() dataChange = new EventEmitter<Customer>();

    customer: Customer;
    states = [];
    isOpen = 'company';
    modalRef: BsModalRef;
    addContactShow: boolean = false;
    editContactShow: any;
    doughnutChart = {
        data: {
            datasets: [{
                data: [3506, 6494],
                backgroundColor: ['rgba(29, 161, 242, 1)', 'rgba(201, 232, 252, 1)']
            }]
        },
        options: {
            responsive: true,
            maintainAspectRatio: false,
            cutout: '84%',
            hover: { mode: null },
            plugins: { tooltip: { enabled: false} },
            elements: {
                arc: { borderWidth: 0 },
            },
        },
    };
    barChartData = {
        data: {
            labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug'],
            datasets: [{
                data: [1000, 155, 360, 500, 100, 588, 52, 400],
                backgroundColor: '#00BAD7'
            }]
        },
        options: {
            scales: {
                xAxes: [{
                    gridLines: {
                        display: false
                    }
                }],
                yAxes: [{
                    gridLines: {
                        display: false
                    },
                    ticks: {
                        steps: 100,
                        stepValue: 100,
                        min: 0
                    }
                }]
            }
        },
    };

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private helperService: HelperService,
        public util: UtilService,
        public customerService: CustomerService,
        private readonly addressService: AddressService,
    ) { }

    ngOnInit() {
        this.states = this.util.getUsStates();
        const fragment = this.route.snapshot.fragment
        if (fragment === 'payment') {
            this.activeId = 'payment'
            window.location.hash = "";
        }
    }

    getCustomer() {
        this.customerService.get(this.customer.customerId).then((res) => {
            this.customer = res;
            if (this.customer.customerEmails.length === 0) {
                this.customer.customerEmails.push({
                    isActive: true,
                });
            }
            this.dataChange.emit(this.customer);
        }).catch((error) => {
            this.router.navigateByUrl('app/customers');
            this.helperService.errorMessage("Customers not found.")
        })
    }

    updatePayment(event) {
        this.getCustomer();
    }

    isOpenChange(section, open) {
        if (open) {
            this.isOpen = section;
        }
    }

    handleBrokerAccountChange(value) {
        this.updateCustomer({
            ...this.customer,
            isBrokerAccount: value
        })
    }

    /**
     * Update customer information
     * 
     * @param customer 
     */
    async updateCustomer(customer: Customer) {
        try {
            delete customer.mainAddress;

            const { customerId } = this.customer;
            await this.customerService.update(customerId, customer);

            // Update customer primary address
            await this.updateCustomerPrimaryAddress(customer);

            this.helperService.successMessage('Customer Details Successfully Updated.');
        } catch (error) {
            console.log('Error while updating customer company information');
            this.helperService.errorMessage(error);
        } finally {
            this.getCustomer();
        }
    }

    /**
     * Update customer primary address
     * 
     * @param address 
     */
    async updateCustomerPrimaryAddress(customer: Customer): Promise<void> {
        // Update customer primary address
        try {
            if (customer.addresses.length) {
                const primaryAddress: Address = customer.addresses.find(
                    (address: Address) => address.entityType == EntityType.Customer
                );
                if (primaryAddress) {
                    const { addressId } = primaryAddress;
                    await this.addressService.update(addressId, primaryAddress);
                }
            }
        } catch (error) {
            console.log('Error while updating customer primary address');
            this.helperService.errorMessage(error);
        }
    }
}
