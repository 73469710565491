import { RouteBoxGroup } from '@models/route-box-group.model';
import { RouteBox } from '@models/route-box.model';
import { Customer } from '@models/customer.model';
import { OrderItem } from '@models/order-item.model';
import { uniqueId } from 'lodash';
import { OrderPriorityEnum } from '@app/models/order.model';

export class RouteBoxUIGroup {
    public _id: string
    constructor(
        public orderId: number,
        public orderNumber: string,
        public purchaseOrderNumber: string,
        public priority: OrderPriorityEnum,
        public customer: Customer,
        public pickupDateType: number | string,
        public pickupDate: string,
        public pickupTimeType: number,
        public pickupTime: string,
        public dropoffDateType: number | string,
        public dropoffDate: string,
        public dropoffTimeType: number,
        public dropoffTime: string,
    ) {
        this._id = uniqueId();
    }

    isSelected: boolean;
    selectedRoute: number;

    get items(): OrderItem[] {
        return this.groups.flatMap(g => g.items);
    }

    get dropOffRouteBoxes(): RouteBox[] {
        return this.groups.flatMap(g => g.dropOffRouteBox);
    }

    get pickupRouteBoxes(): RouteBox[] {
        return this.groups.flatMap(g => g.pickupRouteBox);
    }

    private groups: RouteBoxGroup[] = [];
    public uniqDropOffRouteBoxes: RouteBox[] = [];
    public uniqPickupRouteBoxes: RouteBox[] = [];

    public static createGroups(data: RouteBoxGroup[]) {
        const ret: RouteBoxUIGroup[] = [];

        for (const group of data) {
            const pickup = ret.find(
                g => g.uniqPickupRouteBoxes.length === 1 &&
                    g.uniqPickupRouteBoxes[0].orderStopId === group.pickupRouteBox?.orderStopId &&
                    g.uniqPickupRouteBoxes[0].dateType === group.pickupRouteBox?.dateType &&
                    g.uniqPickupRouteBoxes[0].date === group.pickupRouteBox?.date &&
                    g.uniqPickupRouteBoxes[0].timeType === group.pickupRouteBox?.timeType &&
                    g.uniqPickupRouteBoxes[0].time === group.pickupRouteBox?.time &&
                    g.uniqDropOffRouteBoxes?.every(
                        gg => gg.dateType === group.dropOffRouteBox?.dateType &&
                            gg.date === group.dropOffRouteBox?.date &&
                            gg.timeType === group.dropOffRouteBox?.timeType &&
                            gg.time === group.dropOffRouteBox?.time
                    )
            );
            const dropoff = ret.find(
                g => g.uniqDropOffRouteBoxes.length === 1 &&
                    g.uniqDropOffRouteBoxes[0].orderStopId === group.dropOffRouteBox?.orderStopId &&
                    g.uniqDropOffRouteBoxes[0].dateType === group.dropOffRouteBox?.dateType &&
                    g.uniqDropOffRouteBoxes[0].date === group.dropOffRouteBox?.date &&
                    g.uniqDropOffRouteBoxes[0].timeType === group.dropOffRouteBox?.timeType &&
                    g.uniqDropOffRouteBoxes[0].time === group.dropOffRouteBox?.time &&
                    g.uniqPickupRouteBoxes?.every(
                        gg => gg.dateType === group.pickupRouteBox?.dateType &&
                            gg.date === group.pickupRouteBox?.date &&
                            gg.timeType === group.pickupRouteBox?.timeType &&
                            gg.time === group.pickupRouteBox?.time
                    )
            );
            if (pickup != null && group.dropOffRouteBox != null) {
                pickup.add(group);
            } else if (dropoff != null && group.pickupRouteBox != null) {
                dropoff.add(group);
            } else {
                const newGroup = new RouteBoxUIGroup(group.orderId, group.orderNumber, group.purchaseOrderNumber, group.priority, group.customer,
                    group.pickupRouteBox?.dateType,
                    group.pickupRouteBox?.date,
                    group.pickupRouteBox?.timeType,
                    group.pickupRouteBox?.time,
                    group.dropOffRouteBox?.dateType,
                    group.dropOffRouteBox?.date,
                    group.dropOffRouteBox?.timeType,
                    group.dropOffRouteBox?.time,
                );
                newGroup.add(group);
                ret.push(newGroup);
            }
        }

        return ret;
    }

    public add(group: RouteBoxGroup) {
        if (group.pickupRouteBox) {
            group.pickupRouteBox.items = group.items;
        }
        if (group.dropOffRouteBox) {
            group.dropOffRouteBox.items = group.items;
        }
        this.groups.push(group);

        const allDropOffs = this.dropOffRouteBoxes;
        this.uniqDropOffRouteBoxes = allDropOffs.filter(
            (rb, i) => allDropOffs.findIndex(box =>
                box?.orderStopId === rb?.orderStopId &&
                box?.dateType === rb?.dateType &&
                box?.date === rb?.date &&
                box?.timeType === rb?.timeType &&
                box?.time === rb?.time
            ) === i)
            .filter(box => box != null);

        const allPickups = this.pickupRouteBoxes;
        this.uniqPickupRouteBoxes = allPickups.filter(
            (rb, i) => allPickups.findIndex(box =>
                box?.orderStopId === rb?.orderStopId &&
                box?.dateType === rb?.dateType &&
                box?.date === rb?.date &&
                box?.timeType === rb?.timeType &&
                box?.time === rb?.time
            ) === i)
            .filter(box => box != null);
    }

}
