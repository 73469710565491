import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'reverseAmount'
})
export class ReverseAmountPipe implements PipeTransform {

  transform(value: number): number {
    if (value >= 0) {
      return -Math.abs(value); // Converts positive to negative
    } else {
      return Math.abs(value); // Converts negative to positive
    }
  }

}
