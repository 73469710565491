import { Directive, HostListener, ElementRef } from '@angular/core';

@Directive({
  selector: '[appDragScrollable]'
})
export class DragScrollableDirective {
  private isDragable: boolean = false;
  private pageXVar: number;

  constructor(
    private element: ElementRef
  ) { }

  @HostListener('mouseup', ['$event']) onMouseUp(event: MouseEvent) {
    this.isDragable = false;
    this.element.nativeElement.classList.remove('cursor-grabbing');
  }

  @HostListener('mousemove', ['$event']) onMouseMove(event: MouseEvent) {
    if (this.isDragable) {
      this.element.nativeElement.scrollLeft = this.element.nativeElement.scrollLeft + (this.pageXVar - event.pageX);

      this.pageXVar = event.pageX;
    }
  }

  @HostListener('mousedown', ['$event']) onMouseDown(event: MouseEvent) {
    this.isDragable = true;
    event.preventDefault();

    this.element.nativeElement.classList.add('cursor-grabbing');
  }
}
