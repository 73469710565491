import { HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';

export interface ListResponse<T> {
    list: T;
    totalCount: number;
    itemsPerPage: number;
    page: number;
}

export interface ApiResponse {
    success?: boolean;
    error?: string;
    message?: string;
    messages?: string[];
}

export interface DataListApiResponse<T> extends ApiResponse{
    data?: ListResponse<T>;
}

export interface DataApiResponse<T> extends ApiResponse{
    data?: T;
}

const InternalServerErrorMessage = 'Something bad happened; please try again later.';
const UnauthorizedErrorMessage = 'Login incorrect. Please try again';

export function handleApiError(error: Error) {
    if (error instanceof HttpErrorResponse) {
        if (error.status === 401) {
            return throwError(error);
        }
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error(
                `Backend returned code ${error.status}, ` +
                `body was: ${error.error}`);
        }
        return throwError(error.error.messages ? { messages: error.error.messages} : InternalServerErrorMessage);
    } else if (error instanceof Error) {
        return throwError(error.message || InternalServerErrorMessage);
    } else if (error === 'Unauthorized') {
        return throwError(UnauthorizedErrorMessage);
    }
    return throwError(InternalServerErrorMessage);
}


export function handleApplicationError(res: ApiResponse) {
    if (res.success === false || res.error) {
        throw new Error(res.error);
    }
}
