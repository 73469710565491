export function moveElementInArray(array, oldIndex, newIndex) {
    if (newIndex >= array.length) {
        let k = newIndex - array.length + 1;
        while (k--) {
            array.push(undefined);
        }
    }
    array.splice(newIndex, 0, array.splice(oldIndex, 1)[0]);
    return array;
}

function clamp(value: number, max: number): number {
    return Math.max(0, Math.min(max, value));
}

export function moveItemInArray<T = any>(array: T[], fromIndex: number, toIndex: number): void {
    const from = clamp(fromIndex, array.length - 1);
    const to = clamp(toIndex, array.length - 1);

    if (from === to) {
        return;
    }

    const target = array[from];
    const delta = to < from ? -1 : 1;

    for (let i = from; i !== to; i += delta) {
        array[i] = array[i + delta];
    }

    array[to] = target;
}

export function transferArrayItem<T = any>(
    currentArray: T[],
    targetArray: T[],
    currentIndex: number,
    targetIndex: number,
): void {
    const from = clamp(currentIndex, currentArray.length - 1);
    const to = clamp(targetIndex, targetArray.length);

    if (currentArray.length) {
        targetArray.splice(to, 0, currentArray.splice(from, 1)[0]);
    }
}