import { Component, Input, OnInit } from '@angular/core';
import { HelperService } from '@app/shared/services/helper.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { object, ref, string } from 'yup';

@Component({
  selector: 'app-order-delete-paid-invoice-dialog',
  templateUrl: './order-delete-paid-invoice-dialog.component.html',
  styleUrls: ['./order-delete-paid-invoice-dialog.component.scss']
})
export class OrderDeletePaidInvoiceDialogComponent implements OnInit {

  @Input() invoiceNumber: any;
  @Input() invoicePaymentStatus: any;
  @Input() title: string;
  @Input() message: string;
  @Input() yesText: string = 'Yes';
  @Input() noText: string = 'Cancel';

  number: string;

  validationSchema = object().shape({
    number: string().label('Invoice Number').nullable().required().test('match', 'Invoice number must match the input value', function (value) {
      return value === this.parent.invoiceNumber; // Compare invoiceNumber with number
    }),
    invoiceNumber: string().label('Invoice Number'),
  })

  constructor (
    public helperService: HelperService,
    public activeModal: NgbActiveModal,
  ) { }

  ngOnInit(): void {}

  cancel() {
    this.activeModal.close();
  }

  confirm(form) {
    if (form.isValid) {
        this.activeModal.close(true);     
    }
  }

}
