import { Component, OnInit, Inject, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';


export class Button {
    name = 'button';
    type: 'button' | 'submit' | 'reset' = 'button';
    label = 'Submit';
    color?: 'primary' | 'danger' | 'success' | 'warning' | 'default' | 'info' | 'secondary' = 'primary';
    close = true;
    callback: (button: Button) => void;
}


export interface CustomData {
    title?: string;
    template?: any;
    callback?: (button: Button) => void;
    buttons?: Button[]

}

@Component({
    selector: 'app-custom',
    templateUrl: './custom.component.html',
    styleUrls: ['./custom.component.scss']
})
export class CustomComponent implements OnInit {


    @Input() data: CustomData;

    constructor(public activeModal: NgbActiveModal) { }

    ngOnInit() {
    }

    callback(button) {
        if (this.data.callback) {
            this.data.callback(button)
        }
        if (button.callback !== undefined) {
            button.callback(this.activeModal, this);
        }
        if (button.close !== false) {
            this.activeModal.close();
        }
    }

}
