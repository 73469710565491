import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FileUploadComponent } from './file-upload.component';
import { FormsModule } from '@angular/forms';
import { SharedModule } from '@app/shared/shared.module';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { DialogsModule } from '../dialogs/dialogs.module';
import { AttachmentPreviewModule } from '../attachment-preview/attachment-preview.module';

@NgModule({
	declarations: [
		FileUploadComponent
	],
	exports: [
		FileUploadComponent
	],
	imports: [
		CommonModule,
		FormsModule,
		SharedModule,
		NgbDropdownModule,
		DialogsModule,
		AttachmentPreviewModule
	]
})
export class FileUploadModule { }
