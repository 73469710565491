export interface Expense {
  type?: 'Trucks' | 'Drivers' | 'Office' | 'Misc'
  expenseId?: number;
  name?: string;
  total?: string;
  insurance?: string;
  gas?: string;
  dueAmount?: number;
}

export const ExportTypes = ['Trucks', 'Drivers', 'Office', 'Misc']

export interface NewExpense {
  expenseId?: number;
  type?: ExpenseType;
  subtype?: ExpenseSubtype;
  amount?: number;
  truckId?: number;
  date?: Date;
}

export enum ExpenseType {
  Trucks = 0,
}

export enum ExpenseSubtype {
  TruckGas = 0,
  TruckInsurance = 1,
  TruckMaintenance = 2,
  TruckPayment = 3,
  TruckTrailer = 4,
  TruckLicense = 5,
  TruckTool = 6,
  TruckOther = 7,
  TruckMiles = 8,
  OdometerStart = 9,
  OdometerEnd = 10,
}