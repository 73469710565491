import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilService } from 'src/app/services/util.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '@app/shared/services/auth/auth.service';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
    loginForm: FormGroup;
    showPassword = false;
    errorMessage: any = null;
    return = '';

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private util: UtilService,
        private authService: AuthService,
        private formBuilder: FormBuilder,
    ) {
        this.route.queryParams.subscribe(params => this.return = params.return ?? '/');
    }

    ngOnInit() {
        this.loginForm = this.formBuilder.group({
            userName: ['', [Validators.required]],
            keepMeSignedIn: [false, null],
            password: ['', Validators.compose(
                [
                    Validators.minLength(8),
                    Validators.required,
                    //CustomValidator.strongPassword(),
                ]
            )],
        });
    }

    toggleShowPassword() {
        this.showPassword = !this.showPassword;
    }


    onSubmit() {
        this.errorMessage = null;
        if (this.loginForm.valid) {
            this.authService.login(this.loginForm.value).then(success => {
                this.router.navigate([this.return]);
                this.util.mainLoaderOff();
            }).catch(error => {
                this.errorMessage = error?.error?.messages[0]
            });
        }
    }
}
