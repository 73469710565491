// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
  width: 100%;
  min-height: 200px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
:host .coming-soon-placeholder {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.3);
  z-index: 9;
  opacity: 0.7;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/coming-soon/coming-soon.component.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,WAAA;EACA,iBAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,kBAAA;AACF;AACE;EACE,kBAAA;EACA,MAAA;EACA,OAAA;EACA,QAAA;EACA,SAAA;EACA,oCAAA;EACA,UAAA;EACA,YAAA;AACJ","sourcesContent":[":host {\n  display: block;\n  width: 100%;\n  min-height: 200px;\n  height: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  position: relative;\n\n  .coming-soon-placeholder {\n    position: absolute;\n    top: 0;\n    left: 0;\n    right: 0;\n    bottom: 0;\n    background: rgba(#fff, 0.3);\n    z-index: 9;\n    opacity: 0.7;\n  }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
