import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@app/shared/shared.module';
import { RoutesModule } from '@app/components/main/routes/routes.module';
import { OrderRoutesComponent } from './order-routes.component';

@NgModule({
  declarations: [
    OrderRoutesComponent,
  ],
  exports: [
    OrderRoutesComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    RoutesModule,
  ]
})
export class OrderRoutesModule { }
