// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
    .error-message {
        color: #f44336;
        font-size: 0.9em;

    }
    .material-icons{
      vertical-align:
      text-bottom;
      font-size: 1.2em;
    }`, "",{"version":3,"sources":["webpack://./src/app/shared/components/form/control-messages.ts"],"names":[],"mappings":";IACI;QACI,cAAc;QACd,gBAAgB;;IAEpB;IACA;MACE;iBACW;MACX,gBAAgB;IAClB","sourcesContent":["\n    .error-message {\n        color: #f44336;\n        font-size: 0.9em;\n\n    }\n    .material-icons{\n      vertical-align:\n      text-bottom;\n      font-size: 1.2em;\n    }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
