import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@app/shared/shared.module';
import { DatatableModule } from '@app/@shared/datatable';
import { RatingModule } from '@app/@shared/rating/rating.module';
import { FormPhoneInputModule } from '@app/@shared/forms';
import { FormValidateModule } from '@app/@shared/form-validate/form-validate.module';
import { NgxSelectModule } from '@app/@shared/ngx-select';
import { NgChartsModule } from 'ng2-charts';
import { DialogsModule } from '@app/@shared/dialogs/dialogs.module';
import { TimepickerModule } from '@app/@shared/timepicker/timepicker.module';
import { RepeaterModule } from '@app/@shared/repeater/repeater.module';
import { CardInputsModule } from '@app/@shared/card-inputs/card-inputs.module';
import { DirectivesModule } from '@app/@shared/directives/directives.module';
import { DropDownSelectModule } from '@app/@shared/drop-down-select/drop-down-select.module';
import { CreateOutsourceCompanyComponent } from './create-outsource-company/create-outsource-company.component';
import { OutsourceCompanyRoutingModule } from './outsource-company-routing.module';
import { OutsourceCompanyDriversComponent } from './outsource-company-details/outsource-company-drivers/outsource-company-drivers.component';
import { OutsourceCompanyFinanceComponent } from './outsource-company-details/outsource-company-finance/outsource-company-finance.component';
import { OutsourceCompanyInformationComponent } from './outsource-company-details/outsource-company-information/outsource-company-information.component';
import { OutsourceCompanyDetailsComponent } from './outsource-company-details/outsource-company-details.component';
import { OutsourceCompanyListComponent } from './outsource-company-list/outsource-company-list.component';
import { OutsourceCompanyContactsComponent } from './outsource-company-details/outsource-company-contacts/outsource-company-contacts.component';
import { AddEditTypeOfTruckDialogComponent } from './type-of-trucks/add-edit-type-of-truck-dialog/add-edit-type-of-truck-dialog.component';
import { TypeOfTruckListComponent } from './type-of-trucks/type-of-truck-list/type-of-truck-list.component';
import { OutsourceCompanyLayoutComponent } from './outsource-company-layout/outsource-company-layout.component';
import { ListTabsModule } from '@app/@shared/list-tabs/list-tabs.module';
import { EnumFlagsDropDownSelectModule } from '@app/@shared/enum-flags-drop-down-select/enum-flags-drop-down-select.module';

@NgModule({
  declarations: [
    CreateOutsourceCompanyComponent,
    OutsourceCompanyDriversComponent,
    OutsourceCompanyFinanceComponent,
    OutsourceCompanyInformationComponent,
    OutsourceCompanyDetailsComponent,
    OutsourceCompanyListComponent,
    OutsourceCompanyContactsComponent,
    AddEditTypeOfTruckDialogComponent,
    TypeOfTruckListComponent,
    OutsourceCompanyLayoutComponent
  ],
  imports: [
    CommonModule,
    OutsourceCompanyRoutingModule,
    SharedModule,
    DatatableModule,
    DialogsModule,
    RatingModule,
    NgChartsModule,
    NgxSelectModule,
    TimepickerModule,
    RepeaterModule,
    CardInputsModule,
    FormValidateModule,
    FormPhoneInputModule,
    DirectivesModule,
    DropDownSelectModule,
    ListTabsModule,
    EnumFlagsDropDownSelectModule,
  ]
})
export class OutsourceCompanyModule { }
