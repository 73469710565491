import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ActivityLogRoutingModule } from './activity-log-routing.module';
import { ActivityLogComponent } from './activity-log.component';
import { DatatableModule } from '@app/@shared/datatable';
import { SharedModule } from '@app/shared/shared.module';


@NgModule({
  declarations: [
    ActivityLogComponent
  ],
  imports: [
    CommonModule,
    ActivityLogRoutingModule,
    DatatableModule,
    SharedModule
  ]
})
export class ActivityLogModule { }
