import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiResponse } from '@app/models/core/base';
import { CustomerMessage } from '@app/models/customer-message.model';


@Injectable({
    providedIn: 'root'
})
export class CustomerMessagesService {

    basePath = 'CustomerMessages';

    constructor(
        private http: HttpClient
    ) {

    }

    getByCustomerId(customerId: number) {
        return this.http.get<ApiResponse<CustomerMessage[]>>(`${this.basePath}`, { params: { customerId } })
            .toPromise()
            .then(({ data }) => data);
    }

    sendMessage(data: FormData) {
        return this.http.post<ApiResponse<CustomerMessage>>(`${this.basePath}`, data).toPromise()
            .then((({ data }) => data));
    }
}
