import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CostLinesComponent } from './cost-lines.component';
import { NgChartsModule } from 'ng2-charts';
import { FormsModule } from '@angular/forms';
import { NgbProgressbarModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from '@app/shared/shared.module';

@NgModule({
	declarations: [
		CostLinesComponent,
	],
	exports: [
		CostLinesComponent,
	],
	imports: [
		CommonModule,
		NgChartsModule,
		FormsModule,
		NgbProgressbarModule,
		SharedModule
	]
})
export class CostLinesModule { }
