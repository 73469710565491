export const plans = [
    {
        subscriptionPlanId: 1,
        tag: '',
        name: 'Intro',
        price: 1200,
        shortDescription: 'Designed for smaller companies with less needs. Below is a list of the features included.',
        description: "<ul><li>5 User Access</li><li>Trucking Dispatch Management</li><li>Dispatch Schedule</li><li>Assign river & Equipment</li><li> Routing(Google Maps) </li><li> Trip Sheet </li><li> Driver Acknowledgement </li><li> Route Planner </li><li> Fuel Optimization </li><li> Trucking Maintenance </li><li>Chat Options </li><li> Unlimited Users </li><li> Unlimited Orders </li> <li> No Fleet Limit </li><li> Tracking Logs </li><li> Advanced Dispatch Options </li><li> Company Dashboard </li><li> Driver & Vehicle Profiles </li></ul>"
    },
    {
        subscriptionPlanId: 2,
        tag: 'Popular',
        name: 'Base',
        price: 1500,
        shortDescription: 'Designed for mid-sized companies with average needs. Below is a list of the features included.',
        description: "<ul><li> 5 User Access</li><li>Trucking Dispatch Management </li><li> Dispatch Schedule </li><li> Assign river & Equipment </li><li> Routing(Google Maps) </li><li> Trip Sheet </li><li> Driver Acknowledgement </li><li> Route Planner </li><li> Fuel Optimization </li><li> Trucking Maintenance </li><li>Chat Options </li><li> Unlimited Users </li><li> Unlimited Orders </li> <li> No Fleet Limit </li><li> Tracking Logs </li><li> Advanced Dispatch Options </li><li> Company Dashboard </li><li> Driver & Vehicle Profiles </li></ul>"
    },
    {
        subscriptionPlanId: 3,
        tag: '',
        name: 'Popular',
        price: 2100,
        shortDescription: 'Designed for large companies with substantial needs. Below is a list of the features included.',
        description: "<ul><li> 5 User Access</li><li>Trucking Dispatch Management </li><li> Dispatch Schedule </li><li> Assign river & Equipment </li><li> Routing(Google Maps) </li><li> Trip Sheet </li><li> Driver Acknowledgement </li><li> Route Planner </li><li> Fuel Optimization </li><li> Trucking Maintenance </li><li>Chat Options </li><li> Unlimited Users </li><li> Unlimited Orders </li> <li> No Fleet Limit </li><li> Tracking Logs </li><li> Advanced Dispatch Options </li><li> Company Dashboard </li><li> Driver & Vehicle Profiles </li></ul>"
    }
]