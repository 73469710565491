import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardInputComponent } from './card-input/card-input.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularIfieldsModule } from '../angular-cardknox-ifields';

@NgModule({
  declarations: [
    CardInputComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AngularIfieldsModule,
  ],
  exports: [
    CardInputComponent
  ]
})
export class CardInputsModule { }
