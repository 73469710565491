// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.doc {
  padding: 30px;
  width: 100%;
}

.main {
  padding: 10px;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/auxiliary/address-info/address-info.component.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,WAAA;AACJ;;AAEA;EACI,aAAA;AACJ","sourcesContent":[".doc {\n    padding: 30px;\n    width: 100%;\n}\n\n.main {\n    padding: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
