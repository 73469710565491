import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    NgZone,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import { AgmCircle, AgmMap, AgmMarker } from '@agm/core';
import { SamsaraService } from '@app/services/samsara.service';
import { Subject } from 'rxjs/internal/Subject';
import { takeUntil } from 'rxjs/operators';
import { VehicleLocation } from '@models/samsara/vehicle-location.model';
import { Route, RouteStatusEnum } from '@models/route.model';
import { RouteService } from '@app/services/route.service';
import * as decodePolyline from 'decode-google-map-polyline';
import { RouteBoxGroup } from '@models/route-box-group.model';
import { haversineDistance } from '@app/helpers/util';
import { RouteBoxUIGroup } from '@app/helpers/route-box-ui-group';
import { RouteItemType } from '@models/route-item-stop.model';
import DirectionsResult = google.maps.DirectionsResult;
import LatLngLiteral = google.maps.LatLngLiteral;
import { MetersInMile } from '@app/shared/components/auxiliary/pipes';
import { Truck } from '@models/truck.model';
import { MarkerInfo } from '@app/@shared/route-map/route-map.component';
import { RoutesService } from '@app/shared/services/router.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { WaypointInfo } from '@app/models/map.model';
import { mapStyles } from '@app/data/map-styles';
import { HelperService } from '@app/shared/services/helper.service';
import { cloneDeep, filter, flatMap, get, has, size } from 'lodash';
import { Clipboard } from '@angular/cdk/clipboard';

interface RouteViewData {
    route: Route;
    directions: DirectionsResult;
    waypoints: WaypointInfo[];
    directionsInfoShown: boolean;
    directionsInfo: string;
    directionsInfoLng: number;
    directionsInfoLat: number;
    directionPolys?: {
        polyline: LatLngLiteral[],
        isDone?: boolean;
        distance?: string;
        directionsInfoShown?: string;
        directionsInfoLat?: string;
        directionsInfoLng?: string;
    }[];
    completedDirectionPoly?: LatLngLiteral[];
    unCompletedDirectionPoly?: LatLngLiteral[];
    directionMarkers: MarkerInfo[];
}

@UntilDestroy()
@Component({
    selector: 'app-routes-map',
    templateUrl: './routes-map.component.html',
    styleUrls: ['./routes-map.component.scss']
})
export class RoutesMapComponent implements OnInit, OnChanges {
    @Input() height: number;
    @Input() vehicleLocations: VehicleLocation[];
    @ViewChild('map') agmMap: AgmMap;
    @ViewChild('legend') legend: ElementRef<HTMLDivElement>;
    @ViewChild('selectionCircle') selectionCircle: AgmCircle

    filteredVehicleLocations: VehicleLocation[];
    initialRouteViews: RouteViewData[] = [];
    routeViews: RouteViewData[] = [];

    private map: google.maps.Map;
    private destroyed$: Subject<boolean> = new Subject();
    private boundsAdjusted = false;
    routes: Route[];
    waypoints: WaypointInfo[]
    readonly markerOrigin = new google.maps.Point(13, 15);
    readonly markerOriginNotDone = new google.maps.Point(10, 12);
    unassigned: RouteBoxGroup[];
    unassignedMarkers: MarkerInfo[] = [];
    initialUnassignedMarkers: MarkerInfo[] = [];

    radius: number = null;
    activeVehicle: VehicleLocation;

    selectedRoute: Route;
    openedRoutes: any
    mapStyles = mapStyles

    infoWindowOpen = false;
    routeLocationInfoWindowIndex = null;
    activeMarker: any;

    lastCopiedText = ''

    @Input() showUnassigned: boolean;
    @Input() showRoutes: boolean;
    @Input() showTrucks: boolean;
    @Input() showPickups: boolean;
    @Input() showDropoffs: boolean;


    @Output() unassignedMarkersCount = new EventEmitter<number>();
    @Output() unassignedPickupsCount = new EventEmitter<number>();
    @Output() unassignedDropOffsCount = new EventEmitter<number>();


    get unassignedDropoffs() {
        return this.unassignedMarkers?.filter(x => x.type === 'dropoff') ?? [];
    }

    constructor(
        private samsaraService: SamsaraService,
        private routeService: RouteService,
        private zone: NgZone,
        private routesService: RoutesService,
        private clipboard: Clipboard,
        private helperService: HelperService,
        private readonly cdr: ChangeDetectorRef,
    ) {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.vehicleLocations != null) {
            this.filterVehicleLocationsData()
            this.adjustBounds();
        }
        if (has(changes, 'showUnassigned') || has(changes, 'showDropoffs') || has(changes, 'showPickups')) {
            this.filterUnassignedRouteBox()
        }
    }

    ngOnInit() {
        this.samsaraService.locations.pipe(takeUntil(this.destroyed$)).subscribe(
            locations => {
                this.vehicleLocations.forEach(loc => {
                    const newLoc = locations.find(l => l.id === loc.id);
                    if (newLoc != null) {
                        loc.longitude = newLoc.longitude;
                        loc.latitude = newLoc.latitude;
                    }
                })
                this.filterVehicleLocationsData()
            }
        );

        // this.routesService.getAll({
        //     page: 1,
        //     itemsPerPage: 20
        // }).then((res) => {
        //     console.log(res, 'new loadRoutes');
        //     // const routes = res.list.map(r => r.route);
        //     // routes.forEach(route => this.routeItemGroups(true, route));
        // })
        this.routesService.$openedRoutes.pipe(untilDestroyed(this)).subscribe(
            async (res) => {
                this.openedRoutes = res || {}
                if (this.selectedRoute) {
                    this.filterRouteBaseOnSelected()
                }
            }
        );

        this.routesService.$allRoutes.pipe(untilDestroyed(this)).subscribe(
            (res: any) => {
                const route = res?.map(r => r.route)
                this.waypoints = res?.map(r => r.waypoints)
                route?.forEach(route => {
                    route.truck.location = this.vehicleLocations.find(loc => loc.id === route?.truck?.samsaraId);
                });
                this.routes = route;
                this.setupRoutes();
                this.filterVehicleLocationsData()
            }
        );

        this.routesService.$currentRoute.pipe(untilDestroyed(this)).subscribe(
            (res: any) => {
                this.selectedRoute = res;
                this.filterRouteBaseOnSelected()
                this.filterVehicleLocationsData()
                if (res != null) {
                    this.adjustBoundsToSelectedRoute();
                }
            }
        );

        this.routesService.$unassignedStops.pipe(untilDestroyed(this)).subscribe(
            (res: any) => {
                this.unassigned = res;
                this.setupUnassigned();
            }
        );

        this.routesService.$currentTruck.pipe(untilDestroyed(this)).subscribe(
            (res: any) => {
                if (res != null) {
                    this.adjustBoundsToTruck(res);
                }
            }
        );

        this.routesService.$currentRouteBox.pipe(untilDestroyed(this)).subscribe(
            (res: any) => {
                if (res != null) {
                    let index = 0
                    const dropUpBox = res?.dropOffRouteBoxes.map((boxGroup) => {
                        if (boxGroup) {
                            const stop = this.mapRouteBoxContent(boxGroup, boxGroup?.waypoint, 'dropoff', index, res)
                            index++
                            return stop
                        }
                        return null
                    })
                    const pickup = res?.pickupRouteBoxes.map((boxGroup) => {
                        if (boxGroup) {
                            const stop = this.mapRouteBoxContent(boxGroup, boxGroup?.waypoint, 'pickup', index, res)
                            index++
                            return stop
                        }
                        return null
                    })
                    this.unassignedMarkers = [...dropUpBox, ...pickup].filter(Boolean)
                    this.adjustBoundsToRouteBox(res);
                } else {
                    this.unassignedMarkers = this.initialUnassignedMarkers
                    this.unassignedMarkers?.forEach(m => {
                        m.isTitleOpen = false;
                    });
                }
            }
        );
    }

    openInfoWindow(marker: any): void {
        this.activeMarker = marker;
        this.infoWindowOpen = true;
    }

    toggleOnRouteInfoWindow(event: AgmMarker, marker: any, markerId): void {
        if (markerId === this.routeLocationInfoWindowIndex) {
            this.routeLocationInfoWindowIndex = null
        }
        else {
            this.routeLocationInfoWindowIndex = markerId
        }
    }

    closeInfoWindow(): void {
        this.infoWindowOpen = false;
    }

    onMapReady(map: google.maps.Map) {
        this.map = map;
        this.map.controls[google?.maps?.ControlPosition?.LEFT_BOTTOM].push(this.legend?.nativeElement);
        this.centerMap();
        this.map.addListener('click', this.onMouseDown.bind(this));
        this.map.addListener('mousemove', this.onMouseMove.bind(this));
    }

    private centerMap() {
        /*
                if (this.directions != null) {
                    const routeBounds = this.directions.routes[0].bounds as any as {
                        northeast: LatLngLiteral,
                        southwest: LatLngLiteral,
                    };
                    const bounds = new google.maps.LatLngBounds(routeBounds.southwest, routeBounds.northeast);
                    this.map.fitBounds(bounds);
        */
    }

    public adjustBounds() {
        if (this.map && !this.boundsAdjusted) {
            const bounds = this.getVehiclesBounds(this.vehicleLocations);
            if (bounds != null) {
                this.map.fitBounds(bounds);
                this.boundsAdjusted = true;
            }
        }
    }

    public adjustBoundsToSelectedRoute() {
        if (this.map) {
            const selectedRouteView = this.initialRouteViews.find(rv => rv.route.routeId === this.selectedRoute.routeId);
            const vehicle = this.vehicleLocations.find(vl => vl.id === this.selectedRoute.truck.samsaraId);
            const bounds = this.getRouteBounds(selectedRouteView, null);
            if (bounds != null) {
                this.map.fitBounds(bounds);
            }
        }
    }

    public adjustBoundsToTruck(truck: Truck) {
        if (this.map) {
            const vehicle = this.vehicleLocations.find(vl => vl.id === truck.samsaraId);
            if (vehicle != null) {
                this.map.setCenter({ lat: vehicle.latitude, lng: vehicle.longitude });
            }
        }
    }

    public adjustBoundsToRouteBox(routeBox: RouteBoxUIGroup) {

        const hasValidDropOffRoute = routeBox?.uniqDropOffRouteBoxes?.some(box => box?.waypoint?.point !== null);
        const hasValidPickupRoute = routeBox?.uniqPickupRouteBoxes?.some(box => box?.waypoint?.point !== null);

        if (this.map && (hasValidDropOffRoute || hasValidPickupRoute)) {
            this.unassignedMarkers.forEach(marker => {
                const isMarkerInRouteBox = routeBox?.uniqDropOffRouteBoxes?.includes(marker.routeBox) || routeBox?.uniqPickupRouteBoxes?.includes(marker.routeBox);
                marker.isTitleOpen = isMarkerInRouteBox;
            });
            const dropOffWaypoints = routeBox?.uniqDropOffRouteBoxes
                ?.filter(box => box?.waypoint?.point !== null)
                .map(box => box?.waypoint?.point) || [];

            const pickupWaypoints = routeBox?.uniqPickupRouteBoxes
                ?.filter(box => box?.waypoint?.point !== null)
                .map(box => box?.waypoint?.point) || [];

            const routeBoxWayPoints: LatLngLiteral[] = [...dropOffWaypoints, ...pickupWaypoints];

            const hasValidLatAndLng = routeBoxWayPoints?.every(box => box?.lat !== null && box?.lng !== null);

            if (hasValidLatAndLng) {

                const latitudes = routeBoxWayPoints.map(point => point?.lat).filter(lat => lat !== null) as number[];
                const longitudes = routeBoxWayPoints.map(point => point?.lng).filter(lng => lng !== null) as number[];

                if (latitudes.length > 0 && longitudes.length > 0) {
                    const minLat = Math.min(...latitudes);
                    const maxLat = Math.max(...latitudes);
                    const minLng = Math.min(...longitudes);
                    const maxLng = Math.max(...longitudes);
                    if (!Number.isNaN(minLat) && !Number.isNaN(minLng) && !Number.isNaN(maxLat) && !Number.isNaN(maxLng)) {
                        this.setFitBounds(minLat, minLng, maxLat, maxLng);
                    }
                }
            }
        }
    }

    setFitBounds(minLat, minLng, maxLat, maxLng) {
        const southWest = new google.maps.LatLng(minLat, minLng);
        const northEast = new google.maps.LatLng(maxLat, maxLng);
        const bounds = new google.maps.LatLngBounds(southWest, northEast);
        if (this.map && this.map.fitBounds && bounds) {
            this.map.fitBounds(bounds);
        }
    }

    private getRouteBounds(selectedRouteView: RouteViewData, vehicle: VehicleLocation): google.maps.LatLngBounds | undefined {
        const bounds = new google.maps.LatLngBounds();
        let isExtended = false;
        if (vehicle != null) {
            bounds.extend({ lat: vehicle.latitude, lng: vehicle.longitude });
            isExtended = true;
        }
        if (selectedRouteView != null) {
            for (const path of selectedRouteView.directionMarkers) {
                bounds.extend({ lat: path.latitude, lng: path.longitude });
                isExtended = true;
            }
        }
        return isExtended ? bounds : null;
    }

    getVehiclesBounds(data: VehicleLocation[]): google.maps.LatLngBounds | undefined {
        const bounds = new google.maps.LatLngBounds();
        let isExtended = false;
        for (const path of data) {
            bounds.extend({ lat: path.latitude, lng: path.longitude });
            isExtended = true;
        }
        return isExtended ? bounds : null;
    }

    private setupRoutes() {
        this.initialRouteViews = this.routes?.map(route => {
            return {
                waypoints: this.waypoints,
                route: route,
                status: route?.status,
                directions: route.directions,
                directionsInfo: this.getDirectionInfo(route),
                directionsInfoLat: 0,
                directionsInfoLng: 0,
                directionsInfoShown: false,
                directionPolys: this.mapDirectionPolyLines(route) as any,
                // completedDirectionPoly: this.getDirectionPolyline(route, 'complied'),
                // unCompletedDirectionPoly: this.getDirectionPolyline(route, 'unComplied'),
                directionMarkers: this.getDirectionWaypoints(route, this.waypoints),
            };
        })
        this.filterRouteBaseOnSelected()
    }

    getDirectionWaypoints(route: Route, waypoints: WaypointInfo[]): MarkerInfo[] {


        return route.directions?.routes[0]?.legs?.flatMap(
            (leg: any, index, legs) =>
            (index === 0
                ? [
                    this.createMarkerInfo(0, leg.start_location as any as LatLngLiteral, leg.start_address, leg?.startLocationType, leg?.startLocationIsDone),
                    this.createMarkerInfo(1, leg.end_location as any as LatLngLiteral, leg.end_address, leg?.endLocationType, leg?.endLocationIsDone),
                ]
                : [
                    this.createMarkerInfo(index + 1, leg.end_location as any as LatLngLiteral, leg.end_address, leg?.endLocationType, leg?.endLocationIsDone),
                ])
        ) ?? [];
    }

    createMarkerInfo(index: number, latlgn: google.maps.LatLngLiteral, title: string, type: string, isDone: boolean): MarkerInfo {
        return {
            index,
            title,
            isDone,
            longitude: latlgn.lng,
            latitude: latlgn.lat,
            label: `${index + 1}`,
            markerLabel: {
                text: `${index + 1}`,
                color: '#fff',
                fontSize: isDone ? '13pt' : '10pt',
                fontWeight: 'bold',
            },
            icon: {
                url: type === RouteItemType.Pickup
                    ? 'assets/images/map/pickup.png'
                    : 'assets/images/map/delivery.png',
                labelOrigin: isDone ? this.markerOrigin : this.markerOriginNotDone
            }
        };
    }


    private formatDistance(distance: number): string {
        distance = distance / 1609;

        return `${distance.toFixed(2)} mi`;
    }

    private getDirectionInfo(route: Route): string {
        const distance = route.directions?.routes[0]?.legs.reduce<number>(
            (curr, leg) => curr + leg.distance.value, 0);

        return this.formatDistance(distance);
    }

    mapDirectionPolyLines(route: Route) {
        if (route?.directions && route?.directions['routes']?.length > 0) {
            const legs = route?.directions?.routes[0].legs
            return legs?.map((leg: any) => {
                if (leg?.overviewPoints) {
                    return {
                        polyLine: decodePolyline(leg?.overviewPoints) as any,
                        isDone: leg?.endLocationIsDone && leg?.startLocationIsDone,
                        distance: leg?.distance?.text
                    }
                }
                else {
                    return {
                        polyLine: [],
                        isDone: leg?.endLocationIsDone && leg?.startLocationIsDone,
                        distance: 0
                    }
                }

            })
        }
        return [];
    }

    getDirectionPolyline(route: Route, type: 'complied' | 'unComplied' = 'unComplied'): google.maps.LatLngLiteral[] {
        if (route.directions != null && route.directions.routes[0] != null) {
            const overview_polyline: any = route.directions.routes[0].overview_polyline
            if (!type && overview_polyline.points) {
                return decodePolyline(overview_polyline.points) as LatLngLiteral[];
            }
            if (type === 'complied' && overview_polyline?.completedPoints) {
                return decodePolyline(overview_polyline?.completedPoints) as LatLngLiteral[]
            }
            if (type === 'unComplied' && overview_polyline?.uncompletedPoints) {
                return decodePolyline(overview_polyline?.uncompletedPoints) as LatLngLiteral[]
            }
        }
        return [];
    }

    directionsMouseOver($event: google.maps.PolyMouseEvent, directionPoly: any) {
        directionPoly.directionsInfoLat = $event.latLng.lat();
        directionPoly.directionsInfoLng = $event.latLng.lng();
        directionPoly.directionsInfoShown = true;
    }

    directionsMouseOut($event: google.maps.PolyMouseEvent, directionPoly: any) {
        directionPoly.directionsInfoShown = false;
    }

    private async setupUnassigned() {
        this.unassignedMarkers = await this.unassigned
            ?.flatMap(boxGroup => [{
                boxGroup,
                waypoint: boxGroup.pickupRouteBox?.waypoint,
                routeBox: boxGroup.pickupRouteBox,
                type: 'pickup'
            }, {
                boxGroup,
                waypoint: boxGroup.dropOffRouteBox?.waypoint,
                type: 'dropoff',
                routeBox: boxGroup.dropOffRouteBox,
            }]
                ?.filter(bx => bx.waypoint != null))
            ?.map((wp, index) => (this.mapRouteBoxContent(wp.boxGroup, wp.waypoint, wp.type, index) as any));
        this.initialUnassignedMarkers = cloneDeep(this.unassignedMarkers)
        this.unassignedMarkersCount.emit(this.unassignedMarkers?.length);
        this.unassignedPickupsCount.emit(size(filter(this.unassignedMarkers, { type: 'pickup' })) || 0);
        this.unassignedDropOffsCount.emit(size(filter(this.unassignedMarkers, { type: 'dropoff' })) || 0);
    }

    mapRouteBoxContent(boxGroup, waypoint, type, index, mainBox?: any,) {
        return {
            boxGroup: mainBox || boxGroup,
            type: type,
            routeBox: boxGroup,
            latitude: waypoint?.point?.lat || null,
            longitude: waypoint?.point?.lng || null,
            label: waypoint?.name || '',
            title: `${type === 'pickup' ? 'Pickup' : 'Dropoff'}: ${waypoint?.name}`,
            markerLabel: {
                text: `${index + 1}`,
                color: '#fff',
                fontSize: '10pt',
                fontWeight: 'bold',
            },
            index: undefined,
            icon: {
                url: type === 'pickup' ? 'assets/images/map/pickup.png' : 'assets/images/map/delivery.png',
            }
        }
    }

    dragStart(vehicle: VehicleLocation) {
        vehicle.isDrag = true;
        vehicle.radius = 0;
        this.activeVehicle = vehicle;
    }

    dragEnd(vehicle: VehicleLocation, $event: google.maps.MouseEvent) {
        const routes: Route = this.routes.find(r => vehicle.id === r?.truck?.samsaraId)
        const unassigned = this.unassignedMarkers.filter(marker => {
            if (marker.type === 'dropoff') {
                return false;
            }
            const distance = haversineDistance(
                { lat: this.activeVehicle.latitude, lng: this.activeVehicle.longitude },
                { lat: marker.latitude, lng: marker.longitude }
            );
            return distance < this.radius;
        });
        this.assignUnassignedBoxes(routes, unassigned);
        vehicle.isDrag = false;
        vehicle.radius = null;
        this.radius = null;
        this.activeVehicle = null;
        this.vehicleLocations[this.vehicleLocations.indexOf(vehicle)] = { ...vehicle };
        this.filterVehicleLocationsData()
    }

    drag(vehicle: VehicleLocation, $event) {

        const radius = haversineDistance(
            { lat: vehicle.latitude, lng: vehicle.longitude },
            { lat: $event.coords.lat, lng: $event.coords.lng }
        );
        this.radius = radius;
        setTimeout(() => {
            vehicle.radius = radius * MetersInMile;
        });
    }

    getAnimation(waypoint: MarkerInfo) {
        if (waypoint.type === 'dropoff') {
            return null;
        }

        if (this.activeVehicle == null) {
            return null;
        }
        const distance = haversineDistance(
            { lat: this.activeVehicle.latitude, lng: this.activeVehicle.longitude },
            { lat: waypoint.latitude, lng: waypoint.longitude }
        );
        if (distance < this.radius) {
            return 'BOUNCE';
        }
        return null;
    }

    private assignUnassignedBoxes(routes: Route, unassigned: MarkerInfo[]) {
        const boxes = unassigned.map(mi => mi.boxGroup);
        const uiBoxes = RouteBoxUIGroup.createGroups(boxes);
        const assignStopsToRoute = {
            routeId: routes.routeId,
            stops: uiBoxes
        }

        this.routesService.assignStopsToRoute = assignStopsToRoute
    }

    filterVehicleLocationsData() {
        this.filteredVehicleLocations = cloneDeep(this.vehicleLocations)?.filter((location) => {
            if (this.selectedRoute != null) {
                return location.id === this.selectedRoute.truck.samsaraId;
            }
            return this.routes != null && this.routes.some(route => route.truck.samsaraId === location.id && route?.status === RouteStatusEnum.ACTIVE);
        })
    }

    filterEmpty(location: VehicleLocation, routes: Route[], selectedRoute: Route): boolean {
        if (selectedRoute != null) {
            return location.id === selectedRoute.truck.samsaraId;
        }
        return routes != null && routes.some(route => route.truck.samsaraId === location.id);
    }

    filterRouteBaseOnSelected() {
        let routeViews = cloneDeep(this.initialRouteViews)
        let isHistory = false
        if (this.selectedRoute) {
            if (this.openedRoutes) {
                const data = get(this.openedRoutes, this.selectedRoute.routeId, null)
                isHistory = data?.isHistory || false
            }
            routeViews = routeViews.filter((routeView) => routeView.route.routeId === this.selectedRoute.routeId);
            routeViews = routeViews?.map((routeView) => {
                routeView.directionMarkers = routeView.directionMarkers.filter((directionMarker) => directionMarker?.isDone === isHistory)
                return routeView
            })
            this.routeViews = routeViews
        }
        else {
            this.routeViews = routeViews?.filter((route: any) => route?.status === RouteStatusEnum.ACTIVE)
        }
    }

    filterUnassignedRouteBox() {
        let unassignedMarkers = cloneDeep(this.initialUnassignedMarkers) || []
        if (!this.showUnassigned || (!this.showPickups && !this.showDropoffs)) {
            unassignedMarkers = []
        }
        if (this.showUnassigned && this.showPickups && !this.showDropoffs) {
            unassignedMarkers = this.unassignedMarkers.filter(({ type }) => type === 'pickup')
        }
        if (this.showUnassigned && this.showDropoffs && !this.showPickups) {
            unassignedMarkers = this.unassignedMarkers.filter(({ type }) => type === 'dropoff')
        }
        this.unassignedMarkers = unassignedMarkers
    }

    getRoute(location: VehicleLocation): Route {
        return this.routes.find(r => r.truck.samsaraId === location.id);
    }

    selectionCircleCenter: LatLngLiteral = { lat: 0, lng: 0 };
    selectionCircleRadius = 0;

    onMouseDown($event: google.maps.MapMouseEvent) {
        this.zone.run(() => {
            this.selectionCircleCenter = { lat: $event.latLng.lat(), lng: $event.latLng.lng() };
            this.selectionCircleRadius = 10 * MetersInMile;
        });
    }

    onMouseMove($event: google.maps.MapMouseEvent) {
    }

    selectionCircleRadiusChanged($event: number) {
        this.selectionCircleRadius = $event;
    }

    getUnassignedInSelectionCircle() {
        return this.unassignedMarkers
            .filter(marker => marker.type !== 'dropoff')
            .filter(marker => {
                const distance = haversineDistance(
                    { lat: this.selectionCircleCenter.lat, lng: this.selectionCircleCenter.lng },
                    { lat: marker.latitude, lng: marker.longitude }
                );
                return distance < this.selectionCircleRadius / MetersInMile;
            }
            );
    }

    selectionCircleCenterChanged($event: google.maps.LatLngLiteral) {
        this.selectionCircleCenter = $event;
    }

    assignBySelection() {
        this.assignUnassignedBoxes(this.selectedRoute, this.getUnassignedInSelectionCircle());
        this.zone.run(() => {
            this.selectionCircleCenter = { lat: 0, lng: 0 };
            this.selectionCircleRadius = 0;
        });
    }

    onMouseOver(infoWindow, $event: MouseEvent) {
        infoWindow.open();
    }

    onMouseOut(infoWindow, $event: MouseEvent) {
        infoWindow.close();
    }

    toggleUnassignedMarker(marker) {
        this.unassignedMarkers.forEach(m => {
            if (m !== marker) {
                m.isTitleOpen = false;
            }
        });
        marker.isTitleOpen = !marker.isTitleOpen;
    }

    copyToClipboard(text: string, tooltip) {
        this.clipboard.copy(text)
        this.lastCopiedText = text
        tooltip.close();
    }
}
