import { Pipe, PipeTransform, } from '@angular/core';
import { camelCase, startCase } from 'lodash';

@Pipe({
    name: 'camelCaseToTitleCase'
  })
  export class CamelCaseToTitleCasePipe implements PipeTransform {
    transform(value: string): string {
      if (!value) {
        return '';
      }
  
      // Replace each camelCase word with title case
      return startCase(camelCase(value));
    }
  }
