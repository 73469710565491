// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-route-trimble-map {
  height: 85vh;
}`, "",{"version":3,"sources":["webpack://./src/app/components/main/routes/route-trimble-map/route-trimble-map.component.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;AACJ","sourcesContent":[".app-route-trimble-map {\n    height: 85vh;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
