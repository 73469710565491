
export interface IImageUploadRequest {
    id?: any;
    file?: File;
    type?: ImageUploadTypeEnum;
}

export enum ImageUploadTypeEnum {
    COMPANY_CONTACT = 'companyContact',
    OUTSOURCE = 'outsource',
    CUSTOMER_CONTACT = 'customerContact',
    CUSTOMER = 'customer',
    COMPANY_LOGO = 'companyLogo',
    DRIVER = 'driver',
}
