import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { UtilService } from './util.service';
import { saveAs } from 'file-saver';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs/internal/Observable';
import { Customer } from '@models/customer.model';
import { DataListApiResponse } from '@app/helpers/api';

@Injectable({
    providedIn: 'root',
})
export class ApiService {

    constructor(
        private http: HttpClient,
        private cookie: CookieService,
        private router: Router,
        private util: UtilService,
    ) {
    }

    AuthForgotPassword(data, success, error) {

        const option = {
            method: 'POST',
            url: 'Auth/ForgotPassword',
            data,
        };

        this.makeApiRequest(option, success, error);
    }

    AuthResetPassword(data, success, error) {

        const option = {
            method: 'POST',
            url: 'Auth/ResetPassword',
            data,
        };

        this.makeApiRequest(option, success, error);
    }

    AuthChangePassword(data, success, error) {

        const option = {
            method: 'POST',
            url: 'Auth/ChangePassword',
            data,
        };

        this.makeApiRequest(option, success, error);
    }


    AppUser(success, error) {

        const option = {
            method: 'GET',
            url: 'App/User',
        };

        this.makeApiRequest(option, success, error);
    }

    // companyInfo(success, error) {

    //     const option = {
    //         method: 'GET',
    //         url: 'Company/Info',
    //         data: {},
    //     };

    //     this.makeApiRequest(option, success, error);
    // }

    // CompanyInfoSave(info, success, error) {

    //     const option = {
    //         method: 'PUT',
    //         url: 'Company/Info',
    //         data: info,
    //     };

    //     this.makeApiRequest(option, success, error);
    // }


    SettingsUsers(search, success, error) {
        const option = {
            method: 'GET',
            url: 'Settings/Users',
            data: search,
        };

        this.makeApiRequest(option, success, error);
    }

    SettingsUserAdd(data, success, error) {
        const method = (data.id ? 'PUT' : 'POST');

        const option = {
            method,
            url: 'Settings/Users/' + (data.id ? data.id : ''),
            data,
        };

        this.makeApiRequest(option, success, error);
    }

    EnableUser(id: string, success, error) {
        const option = {
            method: 'PUT',
            url: `Settings/Users/${id}/enable`,
            data: {},
        };

        this.makeApiRequest(option, success, error);
    }

    DisableUser(id: string, success, error) {
        const option = {
            method: 'PUT',
            url: `Settings/Users/${id}/disable`,
            data: {},
        };

        this.makeApiRequest(option, success, error);
    }

    sendQuickbooksAuthorizationCode(code: string, realmId: string, success, error) {
        const option = {
            method: 'POST',
            url: `Quickbooks/AuthorizationCode?code=${code}&realmId=${realmId}`
        };

        this.makeApiRequest(option, success, error);
    }

    getQuickbooksIntegrationStatus(success, error) {
        const option = {
            method: 'GET',
            url: `Quickbooks/IntegrationStatus`
        };

        this.makeApiRequest(option, success, error);
    }

    // Customers
    GetCustomers(search, success, error) {
        const option = {
            method: 'GET',
            url: 'Customers/',
            data: search,
        };
        this.makeApiRequest(option, success, error);
    }

    GetCustomer(customerId, success, error) {
        const option = {
            method: 'GET',
            url: 'Customers/' + customerId,
        };
        this.makeApiRequest(option, success, error);
    }

    AddCustomer(data, success, error) {
        const option = {
            method: 'POST',
            url: 'Customers',
            data,
        };

        this.makeApiRequest(option, success, error);
    }


    AddPaymentMethod(data, custId, success, error) {
        const option = {
            method: 'POST',
            url: 'Customers/' + custId + '/PaymentMethods',
            data,
        };
        this.makeApiRequest(option, success, error);
    }

    UpdatePaymentMethod(id, pm, success, error) {
        const option = {
            method: 'PUT',
            url: 'Customers/PaymentMethods/' + id,
            data: pm,
        };
        this.makeApiRequest(option, success, error);
    }

    DeletePaymentMethod(paymentMethodId, success, error) {
        const option = {
            method: 'DELETE',
            url: 'Customers/PaymentMethods/' + paymentMethodId,
        };
        this.makeApiRequest(option, success, error);
    }

    ExportCustomer(customerIds) {
        const url = this.util.getUrlWithParams('Customers/export', { ids: customerIds })
        this.downloadFile(url, 'customers.xls')
    }

    ExportCustomerSample() {
        const url = this.util.getUrlWithParams('Customers/export', { sample: 'true' })
        this.downloadFile(url, 'customers.xls')
    }

    UpdateCustomer(data, success, error) {
        const option = {
            method: 'PUT',
            url: 'Customers/' + data.customerId,
            data,
        };

        this.makeApiRequest(option, success, error);
    }

    DeleteCustomers(ids, success, error) {
        const option = {
            method: 'DELETE',
            url: 'Customers',
            data: {
                ids,
            },
        };
        this.makeApiRequest(option, success, error);
    }

    SearchCustomers(searchTerm): Observable<Customer[]> {

        let url = environment.apiBaseUrl + 'Customers';
        const httpOptions = this.getHttpOptions(false);

        const search = {
            Page: 1,
            ItemsPerPage: 20,
            SearchTerm: searchTerm,
        };

        url = this.util.getUrlWithParams(url, search);

        return this.http.get<DataListApiResponse<Customer[]>>(url, httpOptions)
            .pipe(map(response => response['data'].list));

    }

    SearchCustomerAddress(customerId, searchTerm) {

        let url = environment.apiBaseUrl + `Customers/${customerId}/lookup_address`;
        const httpOptions = this.getHttpOptions(false);

        const search = {
            SearchTerm: searchTerm,
        };

        url = this.util.getUrlWithParams(url, search);

        return this.http.get(url, httpOptions)
            .pipe(map(response => response['data'] || []));

    }

    // Quotes
    ExportQuotes(quoteIds) {
        const url = this.util.getUrlWithParams('Quotes/export', { ids: quoteIds })
        this.downloadFile(url, 'quotes.xls')
    }

    GetQuotes(search, success, error) {
        const option = {
            method: 'GET',
            url: 'Quotes/',
            data: search,
        };
        this.makeApiRequest(option, success, error);
    }

    GetQuote(id, success, error) {
        const option = {
            method: 'GET',
            url: 'Quotes/' + id,
        };
        this.makeApiRequest(option, success, error);
    }

    SaveQuote(quote, success, error) {
        const option = {
            method: 'POST',
            url: 'Quotes',
            data: quote,
        };
        this.makeApiRequest(option, success, error);
    }

    UpdateQuote(id, quote, updateType, success, error) {
        const quoteCopy = { ...quote };
        delete quoteCopy.routes;
        const option = {
            method: 'PUT',
            url: 'Quotes/' + id + '/' + updateType,
            data: quoteCopy,
        };
        this.makeApiRequest(option, success, error);
    }

    DeleteQuote(id, success, error) {
        const option = {
            method: 'DELETE',
            url: 'Quotes/' + id,
        };
        this.makeApiRequest(option, success, error);
    }

    // Orders
    GetOrders(search, success, error) {
        const option = {
            method: 'GET',
            url: 'Orders/',
            data: search,
        };
        this.makeApiRequest(option, success, error);
    }

    GetOrderList(search, success, error) {
        const option = {
            method: 'GET',
            url: 'Orders/OrderList',
            data: search,
        };
        this.makeApiRequest(option, success, error);
    }

    SearchOrderAddresses(searchTerm) {

        let url = environment.apiBaseUrl + 'Orders/Address';
        const httpOptions = this.getHttpOptions(false);

        const search = {
            Page: 1,
            ItemsPerPage: 20,
            SearchTerm: searchTerm,
        };

        url = this.util.getUrlWithParams(url, search);

        return this.http.get(url, httpOptions)
            .pipe(map(response => response['data']));

    }

    SearchOrderAddressesGet(searchTerm, success, error) {
        const search = {
            Page: 1,
            ItemsPerPage: 20,
            SearchTerm: searchTerm,
        };

        const option = {
            method: 'GET',
            url: 'Orders/Address/',
            data: search,
        };
        this.makeApiRequest(option, success, error);
    }

    // Drivers
    SearchDrivers(searchTerm) {

        let url = environment.apiBaseUrl + 'Drivers';
        const httpOptions = this.getHttpOptions(false);

        const search = {
            Page: 1,
            ItemsPerPage: 20,
            SearchTerm: searchTerm,
        };

        url = this.util.getUrlWithParams(url, search);

        return this.http.get(url, httpOptions)
            .pipe(map(response => response['data'].list));

    }

    GetDrivers(search, success, error) {
        const option = {
            method: 'GET',
            url: 'Drivers/',
            data: search,
        };
        this.makeApiRequest(option, success, error);
    }

    GetDriver(id, success, error) {
        const option = {
            method: 'GET',
            url: 'Drivers/' + id,
        };
        this.makeApiRequest(option, success, error);
    }

    SaveDriver(driver, success, error) {
        const option = {
            method: 'POST',
            url: 'Drivers/',
            data: driver,
        };
        this.makeApiRequest(option, success, error);
    }

    UpdateDriver(id, driver, success, error) {
        const option = {
            method: 'PUT',
            url: 'Drivers/' + id,
            data: driver,
        };
        this.makeApiRequest(option, success, error);
    }

    DeleteDriver(id, success, error) {
        const option = {
            method: 'DELETE',
            url: 'Drivers/' + id,
        };
        this.makeApiRequest(option, success, error);
    }

    // Routes
    GetRoutes(search, success, error) {
        const option = {
            method: 'GET',
            url: 'Routes/',
            data: search,
        };
        this.makeApiRequest(option, success, error);
    }

    GetRoute(id, success, error) {
        const option = {
            method: 'GET',
            url: 'Routes/' + id,
        };
        this.makeApiRequest(option, success, error);
    }

    SaveRoute(route, success, error) {
        const option = {
            method: 'POST',
            url: 'Routes',
            data: route,
        };
        this.makeApiRequest(option, success, error);
    }

    UpdateRoute(id, route, success, error) {
        const option = {
            method: 'PUT',
            url: 'Routes/' + id,
            data: route,
        };
        this.makeApiRequest(option, success, error);
    }

    UpdateRouteStops(id, groups, success, error) {
        const option = {
            method: 'PUT',
            url: 'Routes/' + id + '/stops',
            data: groups,
        };
        this.makeApiRequest(option, success, error);
    }

    UpdateRouteStopStatus(routeId, routeItems, newStatus, success, error) {
        const option = {
            method: 'PUT',
            url: 'Routes/' + routeId + '/routeItems/updateStatus/' + newStatus,
            data: routeItems,
        };
        this.makeApiRequest(option, success, error);
    }

    DeleteRoutes(id, success, error) {
        const option = {
            method: 'DELETE',
            url: 'Routes/' + id,
        };
        this.makeApiRequest(option, success, error);
    }

    GetTransferOptions(transfer, success, error) {
        const option = {
            method: 'POST',
            url: 'Routes/TransferOptions',
            data: transfer,
        };
        this.makeApiRequest(option, success, error);
    }

    CreateTransfer(transfer, success, error) {
        const option = {
            method: 'POST',
            url: 'Routes/NewTransfer',
            data: transfer,
        };
        this.makeApiRequest(option, success, error);
    }

    // Trucks
    TrucksSearch(search, success, error) {
        const option = {
            method: 'GET',
            url: 'Trucks/',
            data: search,
        };
        this.makeApiRequest(option, success, error);
    }

    TruckGet(id, success, error) {
        const option = {
            method: 'GET',
            url: 'Trucks/' + id,
        };
        this.makeApiRequest(option, success, error);
    }

    TruckUpdate(id, truck, success, error) {
        const option = {
            method: 'PUT',
            url: 'Trucks/' + id,
            data: truck,
        };
        this.makeApiRequest(option, success, error);
    }

    TruckAdd(truck, success, error) {
        const option = {
            method: 'POST',
            url: 'Trucks',
            data: truck,
        };
        this.makeApiRequest(option, success, error);
    }

    TruckDelete(id, success, error) {
        const option = {
            method: 'DELETE',
            url: 'Trucks/' + id,
        };
        this.makeApiRequest(option, success, error);
    }

    // ShipmentType
    ShipmentTypeSearch(search, success, error) {
        const option = {
            method: 'GET',
            url: 'ShipmentTypes/',
            data: search,
        };
        this.makeApiRequest(option, success, error);
    }

    ShipmentTypeList(customerId, success, error) {
        const option = {
            method: 'GET',
            url: `ShipmentTypes/List/${customerId}`,
            data: {},
        };
        this.makeApiRequest(option, success, error);
    }

    ShipmentTypeGet(id, success, error) {
        const option = {
            method: 'GET',
            url: 'ShipmentTypes/' + id,
        };
        this.makeApiRequest(option, success, error);
    }

    ShipmentTypeUpdate(id, truck, success, error) {
        const option = {
            method: 'PUT',
            url: 'ShipmentTypes/' + id,
            data: truck,
        };
        this.makeApiRequest(option, success, error);
    }

    ShipmentTypeAdd(truck, success, error) {
        const option = {
            method: 'POST',
            url: 'ShipmentTypes',
            data: truck,
        };
        this.makeApiRequest(option, success, error);
    }

    ShipmentTypeDelete(id, success, error) {
        const option = {
            method: 'DELETE',
            url: 'ShipmentTypes/' + id,
        };
        this.makeApiRequest(option, success, error);
    }

    // Zones
    ZoneSearch(search, success, error) {
        const option = {
            method: 'GET',
            url: 'Zones/',
            data: search,
        };
        this.makeApiRequest(option, success, error);
    }

    ZoneGet(id, success, error) {
        const option = {
            method: 'GET',
            url: 'Zones/' + id,
        };
        this.makeApiRequest(option, success, error);
    }

    ZoneUpdate(id, zone, success, error) {
        const option = {
            method: 'PUT',
            url: 'Zones/' + id,
            data: zone,
        };
        this.makeApiRequest(option, success, error);
    }

    ZoneAdd(zone, success, error) {
        const option = {
            method: 'POST',
            url: 'Zones',
            data: zone,
        };
        this.makeApiRequest(option, success, error);
    }

    ZoneDelete(id, success, error) {
        const option = {
            method: 'DELETE',
            url: 'Zones/' + id,
        };
        this.makeApiRequest(option, success, error);
    }

    // OrderTrips
    GetOrderTrips(search, success, error) {
        const option = {
            method: 'GET',
            url: 'Orders/Trips',
            data: search,
        };
        this.makeApiRequest(option, success, error);
    }

    // SavedSearches
    GetSearches(type, success, error) {
        const option = {
            method: 'GET',
            url: 'SavedSearch/',
            data: type,
        };
        this.makeApiRequest(option, success, error);
    }

    SaveSearch(search, success, error) {
        const option = {
            method: 'POST',
            url: 'SavedSearch/',
            data: search,
        };
        this.makeApiRequest(option, success, error);
    }

    UpdateSearch(id, search, success, error) {
        const option = {
            method: 'PUT',
            url: 'SavedSearch/' + id,
            data: search,
        };
        this.makeApiRequest(option, success, error);
    }

    // Accounting
    SearchTnxList(search, success, error) {
        const option = {
            method: 'POST',
            url: 'Accounting',
            data: search,
        };

        this.makeApiRequest(option, success, error);
    }

    GetAccountingCustomers(search, success, error) {
        const option = {
            method: 'GET',
            url: 'Accounting/customers',
            data: search,
        };
        this.makeApiRequest(option, success, error);
    }

    SearchAccountingCustomers(searchTerm) {

        let url = environment.apiBaseUrl + 'Accounting/customers';
        const httpOptions = this.getHttpOptions(false);

        const search = {
            Page: 1,
            ItemsPerPage: 20,
            SearchTerm: searchTerm,
        };

        url = this.util.getUrlWithParams(url, search);

        return this.http.get(url, httpOptions)
            .pipe(map(response => response['data'].list));

    }

    AddInvoice(data, success, error) {
        const option = {
            method: 'POST',
            url: 'Accounting/invoice',
            data,
        };

        this.makeApiRequest(option, success, error);
    }

    GetInvoice(invoiceId, success, error) {
        const option = {
            method: 'GET',
            url: 'Accounting/invoice/' + invoiceId,
        };

        this.makeApiRequest(option, success, error);
    }

    DeleteInvoice(invoiceId, success, error) {
        const option = {
            method: 'DELETE',
            url: 'Accounting/invoice/' + invoiceId,
        };

        this.makeApiRequest(option, success, error);
    }

    UpdateInvoice(invoiceId, invoice, success, error) {
        const option = {
            method: 'PUT',
            url: 'Accounting/invoice/' + invoiceId,
            data: invoice,
        };

        this.makeApiRequest(option, success, error);
    }

    ExportInvoices(ids, success, error) {
        const option = {
            method: 'POST',
            url: 'Accounting/export',
            data: ids,
        };

        this.makeApiRequest(option, success, error);
    }

    AddPayment(data, success, error) {
        const option = {
            method: 'POST',
            url: 'Accounting/Payment',
            data,
        };
        this.makeApiRequest(option, success, error)
    }

    // Settings
    StgsTlgSendCode(data, success, error) {
        const option = {
            method: 'POST',
            url: 'Settings/Telegram/sendCode',
            data,
        };

        this.makeApiRequest(option, success, error);
    }

    StgsTlgSignIn(data, success, error) {
        const option = {
            method: 'POST',
            url: 'Settings/Telegram/SignIn',
            data,
        };

        this.makeApiRequest(option, success, error);
    }

    StgsTlgSendMessage(data, success, error) {
        const option = {
            method: 'POST',
            url: 'Settings/Telegram/SendMessageBulk',
            data,
        };

        this.makeApiRequest(option, success, error);
    }


    private downloadFile(url, fileName) {
        const httpOptions: any = {
            headers: new HttpHeaders({
                Authorization: 'Bearer ' + this.cookie.get(environment.cookie), Accept: 'application/vnd.ms-excel',
            }),
            responseType: 'arraybuffer',
            observe: 'response',
        };
        url = environment.apiBaseUrl + url
        this.http.get(url, httpOptions).subscribe((response: any) => {

            // var defaultFileName;
            const data = response.body;
            const headers = response.headers;

            const type = headers.get('Content-Type');
            // var disposition = headers.get('Content-Disposition');
            // if (disposition) {
            //    var match = disposition.match(/.*filename=\"?([^;\"]+)\"?.*/);
            //    if (match[1])
            //        defaultFileName = match[1];
            // }

            // if (!defaultFileName) {
            //    defaultFileName = 'download';
            // }

            // defaultFileName = defaultFileName.replace(/[<>:"\/\\|?*]+/g, '_');
            const blob = new Blob([data], { type });

            saveAs(blob, fileName);

        }, error => {
            console.log(error);
        });
    }

    UploadFile(channelId, file, successCallback, errorCallback) {

        const url = environment.apiBaseUrl + 'Customers/import';
        const formData = new FormData();
        // for (let file of files)
        formData.append(file.name, file);
        this.http.post(url, formData, this.getHttpOptions(true)).subscribe(
            data => successCallback(data['Data']),
            error => this.errorCallback(error.error, errorCallback));
    }

    public UploadImage(type, id, file, success, error) {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('type', type);
        formData.append('id', id);

        const option = {
            method: 'POST',
            url: 'Images/upload',
            data: formData,
            isFile: true,
        };

        this.makeApiRequest(option, success, error);
    }


    /* Internal functions */
    private errorCallback(error, cb) {

        cb(error);

        if (!this.router.url.startsWith('/auth') && error.Message && error.Message === 'Authorization has been denied for this request.') {
            this.router.navigate(['/auth/sign-in']);
        }
    }

    private makeApiRequest(option, success, errorCallback): Subscription {
        const $this = this;

        return this.apiRequest(option, success, (error) => {
            console.log(error);
            if (error.status === 401) {
                $this.router.navigate(['/auth/login']);
            } else {
                errorCallback(error.error);
            }
        });
    }

    private apiRequest(option, success, errorCallback): Subscription {

        let url = option.url;
        const httpOptions = this.getHttpOptions(option.isFile);

        if (option.method === 'GET') {
            if (option.data) {
                url = this.util.getUrlWithParams(url, option.data);
            }

            return this.http
                .get(url, httpOptions)
                .subscribe(
                    data => success(data['data']),
                    error => errorCallback(error),
                );
        } else if (option.method === 'POST') {
            return this.http
                .post(url, option.data, httpOptions)
                .subscribe(
                    data => success(data['data']),
                    error => errorCallback(error),
                );
        } else if (option.method === 'PUT') {
            return this.http
                .put(url, option.data, httpOptions)
                .subscribe(
                    data => success(data['data']),
                    error => errorCallback(error),
                );
        } else if (option.method === 'DELETE') {
            if (option.data) {
                url = this.util.getUrlWithParams(url, option.data);
            }

            return this.http
                .delete(url, httpOptions)
                .subscribe(
                    data => success(data['data']),
                    error => errorCallback(error),
                );
        }
    }

    private getHttpOptions(isFile: boolean = false) {

        const headers = {};

        if (!isFile) {
            headers['Content-Type'] = 'application/json';
        }

        const httpOptions: any = {
            headers: new HttpHeaders(headers),
        };

        return httpOptions;
    }
}
