// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#dropzone {
  width: 100%;
  margin-top: 5px;
  margin-bottom: 5px;
  min-height: unset !important;
}

ngx-dropzone-preview {
  height: unset !important;
  min-height: unset !important;
  margin: 2px !important;
}

ngx-dropzone-label {
  overflow-wrap: anywhere !important;
  margin-bottom: 7px !important;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/auxiliary/files-viewer/files-viewer.component.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,eAAA;EACA,kBAAA;EACA,4BAAA;AACJ;;AAEA;EACI,wBAAA;EACA,4BAAA;EACA,sBAAA;AACJ;;AAEA;EACI,kCAAA;EACA,6BAAA;AACJ","sourcesContent":["#dropzone {\n    width: 100%;\n    margin-top: 5px;\n    margin-bottom: 5px;\n    min-height: unset !important;\n}\n\nngx-dropzone-preview {\n    height: unset !important;\n    min-height: unset !important;\n    margin: 2px !important;\n}\n\nngx-dropzone-label {\n    overflow-wrap: anywhere !important;\n    margin-bottom: 7px !important;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
