import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ReceivePaymentComponent } from '../add-payment/receive-payment.component';

@Component({
  selector: 'app-accounting-details',
  templateUrl: './accounting-details.component.html',
  styleUrls: ['./accounting-details.component.scss']
})
export class AccountingDetailsComponent implements OnInit {
  @Input() customer: any = {};
  @Output() reloadAccounting = new EventEmitter();
  constructor(
    private router: Router,
    private modalService: NgbModal,
  ) { }

  ngOnInit() {

  }

  getPrimaryContactName() {

    var primary = this.customer?.customerContacts ? this.customer?.customerContacts?.filter(x => x.isPrimary) : [];
    return primary.length > 0 ? primary[0].firstName || '' + ' ' + primary[0].lastName || '' : '';
  }

  getFirstEmail() {

    var emails = this.customer?.customerEmails ? this.customer?.customerEmails : [];
    if (emails.length > 0) {
      return emails[0].email;
    }

    return '';
  }

  getAddress() {
    var address = this.customer?.addresses ? this.customer?.addresses?.filter(x => x.entityType == 1) : [];
    return address.length > 0 ? address[0].fullAddress : '';
  }


  getDefaultPaymentMethod() {
    var payment = this.customer?.customerPaymentMethods ? this.customer?.customerPaymentMethods?.filter(x => x.isDefault) : [];
    return payment.length > 0 ? payment[0]?.formatedMethod : 'N/A';
  }

  newInvoice() {
    this.router.navigateByUrl('app/accounting/invoice/add', { state: { customer: this.customer } });
  }

  newPayment() {
    const activeModal = this.modalService.open(ReceivePaymentComponent,
      {
        modalDialogClass: 'service_type_modal customer-payment-modal'
      });
    if (this.customer) {
      activeModal.componentInstance.customerId = this.customer?.customerId
      activeModal.componentInstance.customerName = this.customer?.customerName
    }
    activeModal.result.then(
      (result) => {
        if (result) {
          this.reloadAccounting.emit()
        }
      },
      () => { }
    );
  }
}
