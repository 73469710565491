import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthenticatedUserGuard } from '@app/guards/authenticated-user.guard';
import { PaymentComponent } from './payment/payment.component';
import { PlanLayoutComponent } from './plan-layout.component';
import { PlanComponent } from './plan/plan.component';

const routes: Routes = [
  {
    path: '',
    component: PlanLayoutComponent,
    canActivate: [AuthenticatedUserGuard],
    children: [
      {
        path: '',
        redirectTo: 'plan',
        pathMatch: 'full'
      },
      {
        path: 'plan',
        component: PlanComponent
      },
      {
        path: 'plan/payment',
        component: PaymentComponent
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PlanLayoutRoutingModule { }
