import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { orderPortStatusData } from '@app/data/order';
import { findIndex } from 'lodash';
import { FileUploadComponent } from '../file-upload/file-upload.component';

@Component({
  selector: 'app-port-status',
  templateUrl: './port-status.component.html',
  styleUrls: ['./port-status.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FileUploadComponent),
      multi: true
    }
  ],
})
export class PortStatusComponent implements OnInit {

  orderPortStatuses = orderPortStatusData;
  portStatusActiveIndex?: number = 0;
  onChange: any = () => { };
  onTouched: any = () => { };


  private _value: string;

  @Input() display: 'inline' | 'column' = 'inline'

  @Input()
  public get value(): string {
    return this._value;
  }
  public set value(value: string) {
    this._value = value;
    this.portStatusActiveIndex = findIndex(this.orderPortStatuses, { status: value })
  }

  constructor() { }

  writeValue(value: string) {
    this._value = value
    this.portStatusActiveIndex = findIndex(this.orderPortStatuses, { status: value })
  }

  registerOnChange(fn: (attachments: string[] | string) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  ngOnInit(): void {
  }

}
