import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { HelperService } from '@app/shared/services/helper.service';
import { AddressService } from '@app/shared/services/address.service';
import { stateOptions } from '@app/data/state';
import { object, string } from 'yup';
import { Address } from '@app/models/address.model';

@Component({
    selector: 'app-customer-address-dialog',
    templateUrl: './customer-address-dialog.component.html',
    styleUrls: ['./customer-address-dialog.component.scss'],
})

export class CustomerAddressDialogComponent {
    title = "Address"
    saveLoading = false;
    states = stateOptions;

    private _address: Address;
    @Input()
    get address(): Address {
        return this._address;
    }
    set address(value: Address) {
        this._address = value;
    }

    validationSchema = object().shape({
        addressLine1: string().label('address').nullable().required(),
        addressDetails: string().nullable(),
        city: string().nullable().required(),
        state: string().nullable().required(),
        zip: string().label('zip code').nullable().matches(/^[0-9]/, { message: 'Zip code must be a number' }).max(6).required(),
    });

    constructor(
        private ngbActiveModal: NgbActiveModal,
        private addressService: AddressService,
        private helperService: HelperService
    ) {
    }
    async save(f) {
        if (f.isValid) {
            this.saveLoading = true;
            this.addressService.update(this.address?.addressId, this.address).then((resp) => {
                this.saveLoading = false;
                this.helperService.successMessage(`${this.title} successfully updated`);
                this.ngbActiveModal.close(resp)
            }).catch((error) => {
                this.helperService.errorMessage(error);
                this.saveLoading = false;
            })
        }
    }
    close(isReload?: boolean) {
        this.ngbActiveModal.close(isReload);
    }
}
