import { Component, OnInit } from '@angular/core';
import { Permission, Role } from '@app/models/user.model';
import { HelperService } from '@app/shared/services/helper.service';
import { PermissionService } from '@app/shared/services/permission.service';
import { RolesService } from '@app/shared/services/roles.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { find, map, some } from 'lodash';

@UntilDestroy()
@Component({
    selector: 'app-role-list',
    templateUrl: './role-list.component.html',
    styleUrls: ['./role-list.component.scss']
})
export class RoleListComponent implements OnInit {

    title = "Roles"
    roles: Role[];
    permissions: Permission[];
    isLoading: boolean = true

    defaultRolesItem = {
        isEdit: false,
        name: '',
        description: '',
        permissions: []
    }


    constructor(
        private rolesService: RolesService,
        private permissionService: PermissionService,
        private helperService: HelperService,
    ) {
    }

    ngOnInit() {
        this.getRoles();
        this.permissionService.getAll({ itemsPerPage: 30, page: 1 }).then((res) => {
            this.permissions = res.list;
        })
    }

    getRoles(){
        this.rolesService.getAll({ itemsPerPage: 10, page: 1 }).then((res) => {
            const roles = res?.list?.map((res) => {
                res.permissions = map(res?.permissions, 'permissionId')
                return res
            });
            this.roles = roles
            this.isLoading = false
        }).catch((error) => {
            this.isLoading = false
            this.helperService.errorMessage(error)
        })
    }

    addRole() {
        this.roles.splice(0, 0, {
            isEdit: true,
            name: '',
            description: '',
            permissions: []
        })
    }

    editRole(index) {
        this.roles[index].isEdit = true
    }

    cancelEditRole(index) {
        const role = this.roles[index];
        if (role && role.appRoleId) {
            role.isEdit = false;
            const oldAddress = find(this.roles, { appRoleId: role.appRoleId })
            this.roles.splice(index, 1, oldAddress);
        } else {
            this.roles.splice(index, 1);
        }
    }

    deleteRole(index) {
        const role = this.roles[index]
        if (role) {
            this.rolesService.delete(role.appRoleId)
                .then(() => {
                    this.roles.splice(index, 1);
                }).catch((error) => {
                    this.helperService.errorMessage(error)
                })
        } else {
            this.roles.splice(index, 1);
        }
    }

    onChange(index, permissionId, isChecked) {
        if (isChecked) {
            this.roles[index]?.permissions?.push(permissionId);
        } else {
            this.roles[index].permissions = this.roles[index]?.permissions?.filter(item => permissionId !== item);
        }
    }

    inputChecked(index, permissionId) {
        let checked = false;
        for (let l = 0; l < this.roles[index]?.permissions?.length; l++) {
            let temp = this.roles[index]?.permissions[l];
            if (temp?.permissionId == permissionId || temp == permissionId) {
                checked = true;
            }
        }
        return checked;
    }

    selectAllPermissions(index, isChecked) {
        console.log(index, isChecked);
        if (isChecked) {
            this.roles[index].permissions = map(this.permissions, 'permissionId');
        } else {
            this.roles[index].permissions = []
        }
    }

    inputCheckedAllPermissions(index) {
        let checked = false;
        checked = this.roles[index].permissions?.length === map(this.permissions, 'permissionId')?.length
        return checked;
    }

    saveRole(index) {
        const role = this.roles[index];
        role.permissionIds = role?.permissions
        delete role?.permissions
        if (role && role?.appRoleId) {
            this.rolesService.update(role.appRoleId, role)
                .then((data) => {
                    role.isEdit = false;
                   // this.roles.splice(index, 1, data);
                    this.getRoles();
                }).catch((error) => {
                    this.helperService.errorMessage(error)
                })
        } else {
            this.rolesService.create(role)
                .then((data) => {
                    role.isEdit = false;
                  //  this.roles.splice(index, 1, data);
                    this.getRoles();
                }).catch((error) => {
                    this.helperService.errorMessage(error)
                })
        }
    }

}
