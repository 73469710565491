import { Component, Inject, Input, OnInit } from '@angular/core';
import { TenantCompanyList } from '@app/models/company.model';
import { AuthService } from '@app/shared/services/auth/auth.service';
import { CompanyService } from '@app/shared/services/company.service';
import { AppStorage } from '@app/shared/services/storage/app-storage';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-company-select',
  templateUrl: './company-select.component.html',
  styleUrls: ['./company-select.component.scss'],
})
export class CompanySelectComponent implements OnInit {

  selectedCompany: TenantCompanyList;
  @Input() disabled: boolean;
  companies: TenantCompanyList[];

  constructor(
    private companyService: CompanyService,
    private authService: AuthService,
    @Inject(AppStorage) private appStorage: Storage,
  ) { }

  ngOnInit(): void {

    this.companyService.getAll({
      page: 1,
      itemsPerPage: 10
    }).then((res) => {
      this.companies = res?.list
    })

    this.companyService.$selectedCompany
      .pipe(untilDestroyed(this))
      .subscribe((selectedCompany) => {
        this.selectedCompany = selectedCompany;
      })

  }

  compareFn(a, b) {
    return a && b && a.companyId == b.companyId;
  }

  switchCompany() {
    if (this.selectedCompany) {
      this.companyService.switchCompany(this.selectedCompany?.companyId).then(async (res) => {
        this.authService.token = await res;
        this.authService.setUserCompany();
      })
    }
  }
}
