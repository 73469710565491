import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiPaginationResponse, ApiResponse } from '@app/models/core/base';
import { toParams } from 'src/utils/to-params';
import { Expense } from '@app/models/expense.model';

@Injectable({ providedIn: 'root' })
export class NewExpenseService {

    private basePath = 'Expenses'

    constructor(
        private http: HttpClient,
    ) {
    }

    addMultiple(request) {
        return this.http.post(`${this.basePath}/multiple`, request)
            .toPromise();
    }

}
