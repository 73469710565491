// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
  width: 100%;
}
:host .customer-dropdown-input {
  display: flex;
  align-items: center;
  flex-direction: row;
  height: 100%;
  white-space: normal;
}
:host .actions {
  flex-shrink: 0;
  display: flex;
  align-items: center;
}
:host .dropdown-icon {
  padding-left: 8px;
  padding-right: 8px;
  font-size: 18px;
}
:host .placeholder-text,
:host .selected-customer {
  flex-shrink: 1;
  width: 100%;
}
:host .customer-dropdown-item {
  cursor: pointer;
  padding: 16px 8px;
}
:host .item-primary-line {
  color: inherit;
}

::ng-deep .customer-select-dropdown {
  width: 322px;
}
::ng-deep .customer-select-dropdown .item-primary-line {
  color: inherit;
}
::ng-deep .customer-select-dropdown .list-group {
  max-height: 300px;
  overflow: auto;
}`, "",{"version":3,"sources":["webpack://./src/app/@shared/customer-select/customer-dropdown/customer-dropdown.component.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,WAAA;AACF;AACE;EACE,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,YAAA;EACA,mBAAA;AACJ;AAEE;EACE,cAAA;EACA,aAAA;EACA,mBAAA;AAAJ;AAGE;EACE,iBAAA;EACA,kBAAA;EACA,eAAA;AADJ;AAIE;;EAEE,cAAA;EACA,WAAA;AAFJ;AAKE;EACE,eAAA;EACA,iBAAA;AAHJ;AAOE;EACE,cAAA;AALJ;;AAUE;EACE,YAAA;AAPJ;AAUI;EACE,cAAA;AARN;AAWI;EACE,iBAAA;EACA,cAAA;AATN","sourcesContent":[":host {\n  display: block;\n  width: 100%;\n\n  .customer-dropdown-input {\n    display: flex;\n    align-items: center;\n    flex-direction: row;\n    height: 100%;\n    white-space: normal;\n  }\n\n  .actions {\n    flex-shrink: 0;\n    display: flex;\n    align-items: center;\n  }\n\n  .dropdown-icon {\n    padding-left: 8px;\n    padding-right: 8px;\n    font-size: 18px;\n  }\n\n  .placeholder-text,\n  .selected-customer {\n    flex-shrink: 1;\n    width: 100%;\n  }\n\n  .customer-dropdown-item {\n    cursor: pointer;\n    padding: 16px 8px;\n  }\n\n\n  .item-primary-line {\n    color: inherit;\n  }\n}\n\n::ng-deep {\n  .customer-select-dropdown {\n    width: 322px;\n    \n\n    .item-primary-line {\n      color: inherit;\n    }\n\n    .list-group {\n      max-height: 300px;\n      overflow: auto;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
