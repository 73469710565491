import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiResponse } from '@app/models/core/base';
import { Zone } from '@app/models/zone.model';
import { CrudService } from './core/crud.service';

@Injectable({ providedIn: 'root' })
export class ZoneService extends CrudService<Zone> {

    basePath = 'Zones'

      constructor(
        protected http: HttpClient
    ) {
        super(http)
    } 

    get(id: number) {
        return this.http.get<ApiResponse<Zone>>(`${this.basePath}/${id}`,)
            .toPromise()
            .then(({ data }) => data);
    }

    update(id: number, request: Zone) {
        return this.http.put<ApiResponse<Zone>>(`${this.basePath}/${id}`, request)
            .toPromise()
            .then(({ data }) => data);
    }

    create(request: Zone) {
        return this.http.post<ApiResponse<Zone>>(`${this.basePath}`, request)
            .toPromise()
            .then(({ data }) => data);
    }

    delete(id: number) {
        return this.http.delete<ApiResponse<any>>(`${this.basePath}/${id}`)
            .toPromise()
            .then(({ data }) => data);
    }

}
