import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CopyDetailsToDriverComponent } from './copy-details-to-driver.component';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { LoadingModule } from '@app/shared/modules/loading/loading.module';
import { ClipboardModule } from '@angular/cdk/clipboard';

@NgModule({
  declarations: [
    CopyDetailsToDriverComponent
  ],
  exports: [
    CopyDetailsToDriverComponent
  ],
  imports: [
    CommonModule,
    NgbModalModule,
    FormsModule,
    LoadingModule,
    ClipboardModule,
  ]
})
export class CopyDetailsToDriverModule { }
