import { Injectable } from '@angular/core';
import { cloneDeep, find, findIndex, get, map, uniqBy } from 'lodash';
import { BehaviorSubject } from 'rxjs';

export type TabId = string | number;

export interface TabData {
  title?: string;
  data?: any;
  extra?: any;
  tabId: TabId;
}

@Injectable({
  providedIn: 'root'
})
export class ListTabsService {

  private dataStore: {
    [key: string]: TabData[]
  } = {}

  private _tabChange: BehaviorSubject<string>;

  get tabChange$() {
    return this._tabChange.asObservable();
  }

  constructor() {
    this._tabChange = new BehaviorSubject(null)
  }


  getTab(key: string, id: TabId): TabData {
    const tabs = this.getAllTabs(key);
    return tabs.filter(({ tabId }) => id == tabId)[0];
  }

  getAllTabs(key: string): TabData[] {
    return this.dataStore[key] || [];
  }


  updateTabId(key: string, oldTabId: TabId, newTabId: TabId) {
    let tabData = this.getTab(key, oldTabId);
    this.updateTab(key, oldTabId, { ...tabData, tabId: newTabId });
    delete this.dataStore[oldTabId];
  }

  setTab(key: string, tabId: TabId, data: Partial<TabData>) {
    let tabData = this.getTab(key, tabId);
    if (tabData) {
      tabData = Object.assign(tabData, data);
    }
    this.updateTab(key, tabId, tabData);
  }

  openTab(key: string, tabId: TabId) {
    const tabs = this.getAllTabs(key);
    const index = findIndex(tabs, (tab) => tab.tabId == tabId);
    if (index === -1) {
      this.dataStore[key].push({
        tabId,
        data: null,
        title: null,
      })
    }
    this._tabChange.next(key);
    this.saveTabsInLocalStorage(key);
  }

  closeTab(key: string, tabId: TabId) {
    if (!this.dataStore[key]) {
      this.initTabs(key)
    }   
    if (this.dataStore[key]) {
      const tabs = this.getAllTabs(key);
      const index = findIndex(tabs, (tab) => tab.tabId == tabId);
      tabs.splice(index, 1);
      this.dataStore[key] = tabs
      this._tabChange.next(key);
      this.saveTabsInLocalStorage(key);
    }
  }

  closeAllTabs(key: string) {
    this.dataStore[key] = [];
    this._tabChange.next(key);
    this.saveTabsInLocalStorage(key);
  }

  initTabs(key: string) {
    const localTabs = this.getTabsFromLocalStorage(key) || [];
    this.dataStore[key] = localTabs || []
    this._tabChange.next(key);
  }

  private updateTab(key: string, tabId: TabId, data: TabData) {
    const tabs = this.getAllTabs(key);
    const index = findIndex(tabs, (tab) => tab.tabId == tabId);
    tabs.splice(index, 1, data);
    this.dataStore[key] = uniqBy(tabs, 'tabId');
    this._tabChange.next(key);
    this.saveTabsInLocalStorage(key);
  }

  updateTabsIndex(key: string, tabs: TabData[]) {
    this.dataStore[key] = tabs;
    this.saveTabsInLocalStorage(key);
  }

  private saveTabsInLocalStorage(key?: string) {
    let localData: any = this.getTabsFromLocalStorage() || {};
    const dataStore = cloneDeep(this.dataStore);
    if (key) {
      localData[key] = map(dataStore[key], (tab) => {
        tab.data = null;
        return tab;
      });
    } else {
      localData = [];
      for (const i in dataStore) {
        if (Object.prototype.hasOwnProperty.call(dataStore, i)) {
          localData[i] = map(dataStore[i], (tab) => {
            tab.data = null;
            return tab;
          });
        }
      }
    }
    localStorage.setItem('list-tabs', JSON.stringify(localData));
  }

  private getTabsFromLocalStorage(key?: string) {
    const tabs = localStorage.getItem('list-tabs');
    try {
      const data = JSON.parse(tabs);
      if (key) {
        return data[key];
      } else {
        return data;
      }
    } catch (error) {
      return null
    }
  }

}
