import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { customerEmailTypes } from '@app/data/customer';
import { stateOptions } from '@app/data/state';
import { Country } from '@app/models/address.model';
import { Customer, PipelineEnum, SourceEnum } from '@app/models/customer.model';
import { ImageUploadTypeEnum } from '@app/models/image.model';
import { AddressService } from '@app/shared/services/address.service';
import { CustomerService } from '@app/shared/services/customer.service';
import { HelperService } from '@app/shared/services/helper.service';
import { SettingService } from '@app/shared/services/setting.service';
import { cloneDeep, orderBy } from 'lodash';
import { ValidationError, array, boolean, object, string } from 'yup';

@Component({
  selector: 'app-company-information',
  templateUrl: './company-information.component.html',
  styleUrls: ['./company-information.component.scss']
})
export class CompanyInformationComponent implements OnInit {

  defaultEmailItem = {
    isActive: true,
    emailType: null,
    email: '',
  }

  isEdit: boolean = false

  private _customer: Customer;
  customerEmailTypes = customerEmailTypes;
  states = stateOptions;
  users: any[];
  ImageUploadTypeEnum = ImageUploadTypeEnum;
  PipelineEnum = PipelineEnum;
  SourceEnum = SourceEnum;
  isEmailItemsCollapse: boolean = true;

  validationSchema = object().shape({
    companyName: string()
      .max(256)
      .when([], {
        is: () => this.isEdit,
        then: (schema) => schema.required(),
        otherwise: (schema) => schema.nullable()
      }),
    customerEmails: array().of(
      object().shape({
        email: string().label('Email').email().max(256).when([], {
          is: () => this.isEdit,
          then: (schema) => schema.required(),
          otherwise: (schema) => schema.nullable()
        }),
        emailType: string().nullable(),
        isActive: boolean().default(true),
      })
    ).test('unique-emails', 'Emails must be unique', function (arr: any[]) {
      const path = this.path;
      let errors = [];
      let valueCount = arr.reduce((acc, obj) => {
        if (obj.isActive) {
          acc[obj.email] = (acc[obj.email] || 0) + 1;
        }
        return acc;
      }, {});

      arr.forEach((obj, index) => {
        if (obj.email && valueCount[obj.email] > 1) {
          const error = this.createError({ message: `Email is duplicated`, path: `${path}[${index}].email` });
          errors.push(error);
        }
      });
      if (errors?.length > 0) {
        return new ValidationError(errors);
      }
      return true
    }),
    addressDetails: string().nullable(),
    companyLocation: string().label('Company Location').nullable().max(256),
    city: string().label('City').nullable().max(256),
    zip: string().label('Zip Code').label("Zip code").nullable().max(32),
  });

  countries: Country[];

  @Output() customerChange = new EventEmitter()
  initialCustomer: Customer;
  
  @Input()
  public get customer(): Customer {
    return this._customer;
  }
  public set customer(value: Customer) {
    this._customer = cloneDeep(value);
    this.initialCustomer = cloneDeep(value);
    if (!this._customer?.customerEmails) {
      this._customer.customerEmails = []
    } else {
      this._customer.customerEmails = value?.customerEmails?.filter((res) => res.isActive === true);
    }
    if (!this._customer?.mainAddress) {
      this._customer.mainAddress = {}
    }
    if (!this._customer?.addresses) {
      this._customer.addresses = [{ entityType: 1, name: 'Main' }]
    }
  }

  constructor(
    private settingService: SettingService,
    private addressService: AddressService,
    public customerService: CustomerService,
    public helperService: HelperService,
  ) {
  }


  ngOnInit(): void {

    this.addressService.getCountries().then(res => {
			this.countries = res;
		}).catch((error) => {
      this.countries = [];
			this.helperService.errorMessage(error)
		})

    this.settingService.getUsers().then(data => {
      this.users = orderBy(data?.list, [user => user?.firstName?.toLowerCase()], ['asc']);
    }).catch(error => {

    });
  }

  mainAddress(): any {
    if (!this.customer.addresses.find(x => x.entityType === 1)) {
      return { addresses: [{ entityType: 1, name: 'Main', addressLine1: null, city: null, state: null, zip: null, addressDetails: null }] };
    } else {
      return this.customer.addresses.find(x => x.entityType === 1);
    }
  }

  deleteCustomerEmail(item) {
    item.isActive = false;
  }

  discardChanges() {
    this.isEdit = false
    this.isEmailItemsCollapse = true;
    this.customer = this.initialCustomer
  }

  editCompanyInformation() {
    this.isEdit = true;
    this.isEmailItemsCollapse = false;
  }

  toggleEmailItemsCollapse() {
		this.isEmailItemsCollapse = !this.isEmailItemsCollapse;
	}

  handleSubmit(f) {
    if (f.isValid && this.isEdit) {
      try {
        this.customerChange.emit(this.customer)
        this.isEdit = false;
        this.isEmailItemsCollapse = true;
      } catch (error) {
        this.isEdit = true;
        this.isEmailItemsCollapse = false;
      }
    }
  }

}
