import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {

  Navigation = [
    {
      header: "GENERAL",
      navLists: [
        {
          path: 'profile',
          title: 'Profile',
        },
        {
          path: 'users',
          title: 'Users',
        },
        {
          path: 'fleet',
          title: 'Fleet',
        },
        {
          path: 'locations',
          title: 'Locations',
        },
        {
          path: 'expenses',
          title: 'Expenses',
        },
      ]
    },
    {
      header: "SETUP",
      navLists: [
        {
          path: 'drivers-settings',
          title: 'Drivers Settings',
        },
     
        {
          path: 'billing',
          title: 'Billing',
        },
        {
          path: 'charge-types',
          title: 'Charge Types',
        },
        {
          path: 'shipment-types',
          title: 'Shipment Types',
        },
        {
          path: 'zones',
          title: 'Zones',
        },
        {
          path: 'integrations',
          title: 'Integrations',
        },
        {
          path: 'driver-app',
          title: 'Driver App',
        },
      ]
    },
    {
      navLists: [
        {
          path: 'activity-log',
          title: 'Activity Log',
        },
        {
          path: 'terms-conditions',
          title: 'Terms & Conditions',
        },
      ]
    }
  ]

  constructor() { }

  ngOnInit() {
  }

}
