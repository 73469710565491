import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { CompanyUser } from '@app/models/company-user.model';
import { HelperService } from '@app/shared/services/helper.service';
import { AuthService } from '@app/shared/services/auth/auth.service';


@Component({
    selector: 'app-change-password',
    templateUrl: 'change-password.component.html',
    styleUrls: ['./change-password.component.scss'],
})
export class ChangePasswordComponent implements OnInit {

    @Input() user: CompanyUser;

    errorMessage: any = null;
    changePasswordForm: FormGroup;
    showPassword = false;
    showConfirmPassword = false;
    showOldPassword = false;


    constructor(
        private formBuilder: FormBuilder,
        private readonly activeModal: NgbActiveModal,
        private readonly authService: AuthService,
        private readonly helperService: HelperService,
    ) {
        this.changePasswordForm = this.formBuilder.group({
            oldPassword: ['', [Validators.required]],
            password: ['', Validators.compose(
                [
                    Validators.minLength(8),
                    Validators.required,
                    //CustomValidator.strongPassword(),
                ]
            )],
            confirmPassword: ['', [Validators.required, this.confirmPasswordValidator]],
        });
    }

    ngOnInit(): void {
        console.log(this.user)
    }

    close() {
        this.activeModal.close();
    }

    onSubmit() {
        this.errorMessage = null
        if (this.changePasswordForm.valid) {
            const request = this.changePasswordForm.value
            this.authService.changePassword(request)
                .then((res) => {
                    this.helperService.successMessage('Password has been changed successfully')
                    this.activeModal.close(true);

                }).catch((error) => {
                    this.errorMessage = this.helperService.errorMessage(error, 'Something went wrong when change your password, Please try again');
                })
        }
    }

    confirmPasswordValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
        if (!control.parent || !control) {
            return null;
        }

        const password = control.parent.get('password');
        const passwordConfirm = control.parent.get('confirmPassword');

        if (!password || !passwordConfirm) {
            return null;
        }

        if (passwordConfirm.value === '') {
            return null;
        }

        if (password.value === passwordConfirm.value) {
            return null;
        }

        return { passwordsNotMatching: true };
    };


}

