import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@app/shared/shared.module';
import { ExpensesRoutingModule } from './expenses-routing.module';
import { DatatableModule } from '@app/@shared/datatable';
import { DialogsModule } from '@app/@shared/dialogs/dialogs.module';
import { ExpenseListComponent } from './expense-list/expense-list.component';
import { ExpenseDetailsComponent } from './expense-details/expense-details.component';
import { NgChartsModule } from 'ng2-charts';
import { TruckExpenseListComponent } from './truck-expenses/truck-expenses-list.component';
import { RepeaterModule } from '@app/@shared/repeater/repeater.module';
import { FormValidateModule } from '@app/@shared/form-validate/form-validate.module';

@NgModule({
  declarations: [
    ExpenseListComponent,
    ExpenseDetailsComponent,
    TruckExpenseListComponent
  ],
  imports: [
    CommonModule,
    ExpensesRoutingModule,
    SharedModule,
    DatatableModule,
    DialogsModule,
    RepeaterModule,
    FormValidateModule,
    NgChartsModule
  ]
})
export class ExpensesModule { }
