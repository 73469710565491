// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-group {
  margin-top: 1rem;
  margin-bottom: 0;
}

:host .dialog-from {
  min-width: 300px;
}
:host .mat-form-field {
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/app/@shared/dialogs/prompt/prompt.component.scss"],"names":[],"mappings":"AAEA;EACI,gBAAA;EACA,gBAAA;AADJ;;AAII;EACI,gBAAA;AADR;AAGI;EACI,WAAA;AADR","sourcesContent":["//@import 'dialog.scss';\n\n.form-group {\n    margin-top: 1rem;\n    margin-bottom: 0;\n}\n:host {\n    .dialog-from {\n        min-width: 300px;\n    }\n    .mat-form-field {\n        width: 100%;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
