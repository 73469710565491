import { Component } from '@angular/core';
import { NgbDropdownConfig, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject } from 'rxjs';
import { LoaderService } from './components/global/spinner/services/loader.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  
  title = 'Rush Hour Company Portal';

  mainLoaderStatus$: BehaviorSubject<boolean>;

  constructor(
    private _loaderService: LoaderService,
    private config: NgbModalConfig,
    private ngbDropdownConfig: NgbDropdownConfig
    ) {
    this.mainLoaderStatus$ = this._loaderService.mainLoaderOn$;

    ngbDropdownConfig.container = 'body';
    config.centered = true;
  }

  ngOnInit(): void {
    
  }
}
