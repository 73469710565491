import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TypeaheadSelectComponent } from './typeahead-select/typeahead-select.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';


@NgModule({
  declarations: [
    TypeaheadSelectComponent,
  ],
  exports: [
    TypeaheadSelectComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    NgbTypeaheadModule,
    ReactiveFormsModule
  ]
})
export class TypeaheadSelectModule { }
