import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DataApiResponse, handleApiError, ListResponse } from '@app/helpers/api';
import { environment } from '@environments/environment';
import { catchError, map } from 'rxjs/operators';
import { UtilService } from '@app/services/util.service';
import { TelegramBotMessage, TelegramChatMessage } from '@models/telegram.model';

@Injectable({ providedIn: 'root' })
export class TelegramService {
    constructor(
        private http: HttpClient,
        private util: UtilService,
    ) {
    }

    searchChatHistory(driverId: number, search: string) {
        return this.http.post<DataApiResponse<TelegramChatMessage[]>>(
            `${environment.apiBaseUrl}Telegram/ChatHistory`, { driverId, search }
        ).pipe(map(data => {
                return data.data;
            }),
            catchError(handleApiError));
    }

    searchBotHistory(driverId: number, search: string) {
        return this.http.post<DataApiResponse<TelegramBotMessage[]>>(
            `${environment.apiBaseUrl}Telegram/BotHistory`, { driverId, search }
        ).pipe(map(data => {
                return data.data;
            }),
            catchError(handleApiError));
    }
}
