import { BaseModel } from "./base.model";

export interface CustomerMessage extends BaseModel {
    text?: string;
    type?: CustomerMessageTypeEnum;
    customerId?: number;
}

export enum CustomerMessageTypeEnum {
    Incoming = 'Incoming',
    Outcoming = 'Outcoming'
}