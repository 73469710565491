import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER } from '@angular/core';
import { AppInitService } from './core/app-init.service';
import { CustomHttpInterceptor } from './core/custom-http-interceptor';
import { AppStorage, CookiesStorage } from './storage/app-storage';
import { LocalStorage } from './storage/local.storage';
import { CookiesStorage as CookiesStorageService } from './storage/cookies.storage';

export const Services = [
  { provide: HTTP_INTERCEPTORS, useClass: CustomHttpInterceptor, multi: true },
  { provide: 'REQUEST', useValue: null },
  { provide: AppStorage, useClass: LocalStorage },
  { provide: CookiesStorage, useClass: CookiesStorageService },
  {
    provide: APP_INITIALIZER,
    useFactory: (appInitService: AppInitService) => {
      return async () => {
        await appInitService.init();
      };
    },
    deps: [AppInitService],
    multi: true,
  },
];
