import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@app/shared/shared.module';
import { OrderDeletePaidInvoiceDialogComponent } from './order-delete-paid-invoice-dialog/order-delete-paid-invoice-dialog.component';
import { OrderDeleteUnpaidInvoiceDialogComponent } from './order-delete-unpaid-invoice-dialog/order-delete-unpaid-invoice-dialog.component';
import { FormValidateModule } from '@app/@shared/form-validate/form-validate.module';

@NgModule({
  declarations: [
    OrderDeletePaidInvoiceDialogComponent,
		OrderDeleteUnpaidInvoiceDialogComponent
  ],
  exports: [
    OrderDeletePaidInvoiceDialogComponent,
		OrderDeleteUnpaidInvoiceDialogComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
		FormValidateModule
  ]
})
export class OrderDeleteDialogModule { }
