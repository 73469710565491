import { Component, Input, OnInit } from '@angular/core';
import { CustomerService } from '@app/shared/services/customer.service';
import { HelperService } from '@app/shared/services/helper.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { filter, map } from 'lodash';

@Component({
    selector: 'app-customer-export',
    templateUrl: './customer-export.component.html',
    styleUrls: ['./customer-export.component.scss']
})
export class CustomerExportComponent implements OnInit {

    @Input() selectedCustomerIds: [];
    items = [
        {
            name: 'Company Name',
            fieldName: 'customerName',
            checked: true
        },
        {
            name: 'Email',
            fieldName: 'firstEmail',
            checked: true
        },
        {
            name: 'Balance',
            fieldName: 'openBalance',
            checked: true
        },
        {
            name: 'Address',
            fieldName: 'mainAddress',
            checked: true
        },
        {
            name: 'Phone Number',
            fieldName: 'phone',
            checked: true
        },
        {
            name: 'Status',
            fieldName: 'status',
            checked: true
        },

        {
            name: 'Pipeline',
            fieldName: 'pipeline',
            checked: true
        },
        {
            name: 'Phone Extension',
            fieldName: 'phoneExtension',
            checked: true
        },
        {
            name: 'Broker Account',
            fieldName: 'isBrokerAccount',
            checked: true
        },
    ];

    fieldOrder = [
        'customerName',
        'mainAddress',
        'pipeline',
        'firstEmail',
        'phone',
        'phoneExtension',
        'openBalance',
        'status',
        'isBrokerAccount'
    ];

    filteredItems = this.items;

    get isExport(): boolean {
        return this.items.filter((item) => item?.checked)?.length > 0;
    }

    get inputCheckedAllFields(): boolean {
        return this.items?.every(item => item?.checked);
    }

    constructor(
        public activeModal: NgbActiveModal,
        private helperService: HelperService,
        private customerService: CustomerService,
    ) { }

    filterItems(searchTerm: string) {
        this.filteredItems = this.items.filter(item =>
            item.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.fieldName.toLowerCase().includes(searchTerm.toLowerCase())
        );
    }

    selectAllFields(isChecked) {
        this.items?.forEach(item => item.checked = isChecked);
    }

    handelExport() {
        if (this.isExport) {
            const filteredFieldNames = this.items
                .filter(item => item.checked)
                .map(item => item.fieldName);

            const orderedFieldNames = this.fieldOrder
                .filter(fieldName => filteredFieldNames.includes(fieldName));

            const fieldNames = orderedFieldNames.join(',');
            const ids = map(this.selectedCustomerIds).join(",");

            const request = {
                fields: fieldNames,
                ...(this.selectedCustomerIds && { ids })
            }
            try {
                this.customerService.customExport(request)
            } catch (error) {
                this.helperService.errorMessage(error)
            }
            this.close();
        }
    }

    close() {
        this.activeModal.close();
    }

    ngOnInit() {
    }

}
