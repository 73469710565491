import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModalModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { ChatHistoryViewerComponent } from './chat-history-viewer.component';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    ChatHistoryViewerComponent
  ],
  exports: [
    ChatHistoryViewerComponent
  ],
  imports: [
    CommonModule,
    NgbModalModule,
    NgbTooltipModule,
    FormsModule
  ]
})
export class ChatHistoryViewerModule { }
