// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
}
:host cardknox-ifields {
  display: block;
}
:host ::ng-deep iframe {
  height: 42px;
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/app/@shared/card-inputs/card-input/card-input.component.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;AACF;AACE;EACE,cAAA;AACJ;AAGI;EACE,YAAA;EACA,WAAA;AADN","sourcesContent":[":host {\n  display: block;\n\n  cardknox-ifields {\n    display: block;\n  }\n\n  ::ng-deep {\n    iframe {\n      height: 42px;\n      width: 100%;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
