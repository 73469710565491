import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';
import { Order } from '@models/order.model';

@Injectable({
    providedIn: 'root',
})
export class SharedService {

    constructor() {
    }

    quote$ = new BehaviorSubject<any>({});
    private sharedQuote = {};

    setSharedQuote(quote) {
        this.sharedQuote = quote;
        this.quote$.next(quote);
    }

    getSharedQuote() {
        return this.sharedQuote;
    }

    convertNgbDateToString(dateToConvert) {
        return dateToConvert.year + '-' + ('0' + (dateToConvert.month)).slice(-2) + '-' + ('0' + dateToConvert.day).slice(-2);
    }
}
