import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ChargeType } from '@app/models/charge-type.model';
import { AccountingService } from '@app/shared/services/accounting.service';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Observable, Subject, from, merge, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, finalize, map, switchMap } from 'rxjs/operators';

@UntilDestroy()
@Component({
    selector: 'app-fees-type-dropdown',
    templateUrl: './fees-type-dropdown.component.html',
    styleUrls: ['./fees-type-dropdown.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => FeesTypeDropdownComponent),
            multi: true
        }
    ],
    encapsulation: ViewEncapsulation.Emulated,
})
export class FeesTypeDropdownComponent implements AfterViewInit, OnInit {

    onChange = (value: string) => { };
    onTouched = () => { };

    focus$ = new Subject<string>();
    click$ = new Subject<string>();
    @ViewChild('instance', { static: true }) instance: NgbTypeahead;

    @Output() valueChange = new EventEmitter<any>();

    private _value: string | any;
    loading: boolean;
    public get value(): string | any {
        return this._value;
    }
    public set value(val: string | any) {
        if (typeof val === 'object') {
            val = val?.name;
        }
        this._value = val;
        this.onChange(val);
        this.onTouched();
    }

    constructor(
        private accountingService: AccountingService
    ) { }

    formatter = (chargeType: ChargeType) => chargeType.name;

    // Custom input formatter to display the selected item in the input box
    inputFormatter = (chargeType: ChargeType) => (typeof chargeType === 'object') ? chargeType.name : chargeType;

    search = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(
            debounceTime(200),
            distinctUntilChanged()
        );

        const clicksWithClosedPopup$ = this.click$.pipe(filter(() => !this.instance.isPopupOpen()));
        const inputFocus$ = this.focus$;
        return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
            switchMap(term => {      
                this.loading = true;         
                if (!term || term === '') {
                    return from(this.accountingService.getAllChargeTypes()).pipe(
                        map(({ data }: any) => data),
                        finalize(() => this.loading = false),
                    ); 
                } else {
                    return from(this.accountingService.getAllChargeTypes()).pipe(
                        map(({ data }: any) => data.filter((v: any) => v.name && v.name.toLowerCase().indexOf(term.toLowerCase()) > -1)),
                        finalize(() => this.loading = false),
                    );
                }
            })
        );
    }

    selectFeeType(type) {
        this.value = type
        this.valueChange.emit(type);
    }

    ngOnInit(): void {
    }

    ngAfterViewInit() {
    }

    writeValue(value: string) {
        this._value = value
    }

    registerOnChange(fn: (value: string) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }


}
