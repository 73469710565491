import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@app/shared/shared.module';
import { DriversSettingsRoutingModule } from './drivers-settings-routing.module';
import { DriversSettingsComponent } from './drivers-settings.component';

@NgModule({
  declarations: [
    DriversSettingsComponent
  ],
  imports: [
    CommonModule,
    DriversSettingsRoutingModule,
    SharedModule
  ]
})
export class DriversSettingsModule { }
