import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { stateOptions } from '@app/data/state';
import { Driver } from '@app/models/driver.model';
import { UtilService } from '@app/services/util.service';
import { DriverService } from '@app/shared/services/driver.service';
import { object } from 'yup';


@Component({
  selector: 'app-driver-license',
  templateUrl: './driver-license.component.html',
  styleUrls: ['./driver-license.component.scss']
})
export class DriverLicenseComponent implements OnInit {
  title = 'Driver’s License';

  private _driver: Driver = {
    licenseNumber: '',
    licenseType: '',
    licenseCountry: '',
    licenseState: '',
    licenseExpires: new Date(),
  }

  validationSchema = object().shape({})

  @Input() isLoading: boolean = false;
  
  @Input()
  public get driver(): Driver {
    return this._driver;
  }
  public set driver(value: Driver) {
    this._driver = value
  }

  @Output() driverChange = new EventEmitter()
  
  states = stateOptions;

  constructor(
    public driverService: DriverService,
    public util: UtilService,
  ) { }

  ngOnInit(): void {
  }

  onSubmit(f) {
    if(f.isValid){
      this.driverChange.emit(this.driver)
    }
  }

  async uploadLicenseFile(event) {
    const files = event.target.files;
    for (let index = 0; index < files.length; index++) {
      const file = files[index];
      const request = {
        file: file,
        type: 'driver',
        id: this.driver?.driverId,
        isFile: true,
      }
      this.driverService.uploadImage(request).then((resp: any) => {
        this.driver.licenseImage = resp?.data
      });
    }
  }

  async uploadInsuranceFile(event) {
    const files = event.target.files;
    for (let index = 0; index < files.length; index++) {
      const file = files[index];
      const request = {
        file: file,
        type: 'driver',
        id: this.driver?.driverId,
        isFile: true,
      }
      this.driverService.uploadImage(request).then((resp: any) => {
        this.driver.insurancenCardImage = resp?.data
      });
    }
  }


}
