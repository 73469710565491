import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ShipmentTypeDetailsComponent } from './shipment-type-details/shipment-type-details.component';
import { ShipmentTypeListComponent } from './shipment-type-list/shipment-type-list.component';

const routes: Routes = [
  {
    path: '',
    component: ShipmentTypeListComponent,

  },
  {
    path: 'edit/:id',
    component: ShipmentTypeDetailsComponent,
  },
  {
    path: 'create',
    component: ShipmentTypeDetailsComponent,
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ShipmentTypesRoutingModule { }
