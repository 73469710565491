import { RouteItemStop, RouteItemType } from '@models/route-item-stop.model';

export class RouteDetailsCommon {
    constructor() {

    }

    isOpened($event, item: HTMLDivElement) {
        $event.stopPropagation()
        if (item.classList.contains('opened')) {
            item.classList.remove('opened')
        } else {
            item.classList.add('opened');
        }
    }

    getStopTypeLabelColor(stop: RouteItemStop): string {
        switch (stop.type) {
            case RouteItemType.Delivery:
                return "text-danger";
            case RouteItemType.Pickup:
                return "text-primary";
            case RouteItemType.DeliveryToTransfer:
                return "text-danger";
            case RouteItemType.PickupFromTransfer:
                return "text-primary";
        }

        return '';
    }

    getStopActionLabel(stop: RouteItemStop) {
        switch (stop.type) {
            case RouteItemType.Delivery:
                return 'Delivered';
            case RouteItemType.Pickup:
                return 'Picked Up';
            case RouteItemType.DeliveryToTransfer:
                return 'Delivered To Transfer';
            case RouteItemType.PickupFromTransfer:
                return 'Picked Up From Transfer';
        }
        return '';
    }

    getStopTypeLabel(stop: RouteItemStop): string {
        switch (stop.type) {
            case RouteItemType.Delivery:
                return 'Delivery';
            case RouteItemType.Pickup:
                return 'Pickup';
            case RouteItemType.DeliveryToTransfer:
                return 'Delivery To Transfer';
            case RouteItemType.PickupFromTransfer:
                return 'Pickup From Transfer';
        }
        return '';
    }
}