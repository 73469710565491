import { CustomerContact } from '@models/customer-contact.model';
import { CustomerEmail } from '@models/customer-email.model';
import { PaymentMethod } from '@models/payment-method.model';
import { BaseModel } from '@models/base.model';
import { Address } from '@models/address.model';
import { ListRequest } from './core/base';

export interface Customer extends BaseModel {
    customerId?: number;
    companyId?: number;
    rating?: number;
    customerName?: string;
    phone?: string;
    phoneNumber?: string; //this is temporary field 
    phoneExtension?: string; //this is temporary field 
    logo?: string;
    salesRep?: string;
    howDidYouHear?: string;
    isBrokerAccount?: boolean;
    isEntrepreneur?: boolean;
    status?: number;
    source?: SourceEnum;
    addresses?: Address[];
    customerEmails?: CustomerEmail[];
    customerContacts?: CustomerContact[];
    customerPaymentMethods?: PaymentMethod[];
    invoices?: string;
    payments?: any;
    firstEmail?: string;
    mainAddress?: Address;
    pipeline?: PipelineEnum;
    defaultCC?: string;
    openBalance?: number;
    createdTime?: string;
    createdUser?: string;
    updatedTime?: string;
    updatedUser?: string;
}

export interface CustomerContactDetails {
    customerEmails: CustomerEmail[];
    customerContacts: CustomerContact[];
}

export interface ValidateCustomer {
    existedName: boolean;
    existedPhone: boolean;
    existedEmailIndex: [];
    existedProspect?: any;
}

export interface CustomerSearchRequest extends ListRequest {

}

export enum PipelineEnum {
    PROSPECT = 0,
    CUSTOMER = 1,
    LEAD = 2,
}

export enum SourceEnum {
    PORTAL = 0,
    WEB = 1,
}
