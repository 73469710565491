import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Route } from '@models/route.model';
import { TrimbleMapsService } from '../../../../services/trimble-maps.service';
import { RouteService } from '@app/services/route.service';
import { RouteBoxGroup } from '@app/models/route-box-group.model';
import { haversineDistance } from '@app/helpers/util';
import { RouteBoxUIGroup } from '@app/helpers/route-box-ui-group';
import { VehicleLocation } from '@models/samsara/vehicle-location.model';
import { SamsaraService } from '@app/services/samsara.service';
import * as TrimbleMaps from '@trimblemaps/trimblemaps-js';
import { RouteBox } from '@app/models/route-box.model';
import DirectionsResult = google.maps.DirectionsResult;
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { RoutesService } from '@app/shared/services/router.service';
import MarkerLabel = google.maps.MarkerLabel;
import Icon = google.maps.Icon;
import { UnassignedTripsComponent } from '@app/@shared/unassigned-trips/unassigned-trips.component';

export interface MarkerInfo {
    isDragHover?: boolean;
    dragStartLng?: number;
    dragStartLat?: number;
    latitude: number;
    longitude: number;
    label?: string;
    title?: string;
    titleSave?: string;
    isTitleOpen?: boolean;
    index: number;
    icon?: Icon;
    markerLabel?: MarkerLabel;
    useInDirections?: boolean;
    location?: string;
    routeBox?: RouteBox;
    type?: 'pickup' | 'dropoff';
    boxGroup?: RouteBoxGroup;
}

@UntilDestroy()
@Component({
    selector: 'app-routes-trimble-map',
    templateUrl: './routes-trimble-map.component.html',
    styleUrls: ['./routes-trimble-map.component.scss']
})

export class RoutesTrimbleMapComponent implements OnInit {
    @Input() vehicleLocations: VehicleLocation[] = [];
    @Input() directions: DirectionsResult
    @Input() mapStyle: string = "TRANSPORTATION";
    @Input() mapCenterLon: number = -74.13450816226678;
    @Input() mapCenterLat: number = 41.10702178173934;
    @Input() mapCenterZoom: number = 10;
    @ViewChild("map", { static: true }) elementRef: ElementRef;

    routes: Route[] = [];
    unassignedMarkers: MarkerInfo[] = [];
    showRoutes = true;
    showUnassigned = false;
    showPickups = false;
    showDropoffs = false;
    selectedRoute: Route;
    showTrucks: Boolean = true;
    milesToKmCoefficient = 1.609;
    radius: number;

    private mapLoaded: Boolean = false;
    private haveTruckMarkers: Boolean = false;
    private trimbleMapsRoutes: TrimbleMaps.Route[] = [];
    private unassigned: RouteBoxGroup[] = [];
    private draggingTrackCoords: { lat: number, lng: number };
    private map: TrimbleMaps.Map;
    private unassignedTrimbleMapsMarkers: TrimbleMaps.Marker[] = [];
    private editingMarkers: TrimbleMaps.Marker[] = [];
    private circleCenter: [number, number] = [0, 0];
    private truckMarkers: TrimbleMaps.Marker[] = [];
    private defaultRadius = 16.0934;
    private showedRoutesIds: string[] = [];
    private hasPreviewCircle: Boolean = false;
    private hasCircle: Boolean = false;
    private circleCenterMarker: TrimbleMaps.Marker;
    private pickupType = 'pickup';
    private dropoffType = 'dropoff';
    private trafficIncident = new TrimbleMaps.TrafficIncident();
    private trafficCamera = new TrimbleMaps.TrafficCamera();

    constructor(
        private routeService: RouteService,
        private trimbleMapsService: TrimbleMapsService,
        private samsaraService: SamsaraService,
        private routesService: RoutesService,
    ) { }

    ngOnInit() {
        this.initMap();
        this.loadTruckLocations();
        this.setRoutes();
        this.setUnassignedStops();
        this.setMapOnLoadEvent();
        this.setMapOnClickEvent();
        this.setSelectedRoute();
        this.setCurrentRouteBox();
    }

    get unassignedPickups() {
        return this.unassignedMarkers?.filter(x => x.type === this.pickupType) ?? [];
    }

    get unassignedDropoffs() {
        return this.unassignedMarkers?.filter(x => x.type === this.dropoffType) ?? [];
    }

    assignBySelection() {
        this.assignUnassignedBoxes(this.selectedRoute, this.getUnassignedInSelectionCircle());
        this.trimbleMapsService.removeRadiusCircle(this.map, this.hasCircle, this.editingMarkers, this.circleCenterMarker);
        this.hasCircle = false;
    }

    showRoutesToggle() {
        this.showRoutes = !this.showRoutes;

        if (!this.showRoutes) {
            this.trimbleMapsRoutes.forEach(route => route.remove());
        }
        else {
            this.trimbleMapsRoutes.forEach(route => route.addTo(this.map));
        }
    }

    showTrucksToggle() {
        this.showTrucks = !this.showTrucks;

        if (!this.showTrucks) {
            this.truckMarkers.forEach(marker => marker.remove());
        }
        else {
            this.truckMarkers.forEach(marker => marker.addTo(this.map));
        }
    }

    showUnassignedToggle() {
        this.showUnassigned = !this.showUnassigned;

        if (this.showUnassigned) {
            this.trimbleMapsService.setMarkerVisibility(true, this.unassignedTrimbleMapsMarkers);
            this.showPickups = true;
            this.showDropoffs = true;
        }
        else {
            this.trimbleMapsService.setMarkerVisibility(false, this.unassignedTrimbleMapsMarkers);
            this.showPickups = false;
            this.showDropoffs = false;
        }
    }

    showUnassignedPickupsToggle() {
        this.showPickups = !this.showPickups;

        if (this.showPickups) {
            this.trimbleMapsService.setMarkerVisibility(true, this.unassignedTrimbleMapsMarkers, this.pickupType);
        }
        else {
            this.trimbleMapsService.setMarkerVisibility(false, this.unassignedTrimbleMapsMarkers, this.pickupType);
        }
    }

    showUnassignedDropoffsToggle() {
        this.showDropoffs = !this.showDropoffs;

        if (this.showDropoffs) {
            this.trimbleMapsService.setMarkerVisibility(true, this.unassignedTrimbleMapsMarkers, this.dropoffType);
        }
        else {
            this.trimbleMapsService.setMarkerVisibility(false, this.unassignedTrimbleMapsMarkers, this.dropoffType);
        }
    }

    getUnassignedInSelectionCircle() {
        return this.unassignedMarkers.filter(marker => marker.type !== this.dropoffType).filter(marker => {
            const distance = haversineDistance({
                lat: this.circleCenter[1],
                lng: this.circleCenter[0]
            }, { lat: marker.latitude, lng: marker.longitude });

            return distance < this.radius / this.milesToKmCoefficient;
        });
    }

    toggleTrafficVisability(){
        this.map.toggleTrafficVisibility();
    }

    togglePOIVisibility(){
        this.map.togglePOIVisibility();
    }

    toggleTrafficIncedentsVisability(){
        this.trafficIncident.toggleVisibility();
    }

    toggleTrafficCameraVisibility(){
        this.trafficCamera.toggleVisibility();
    }

    private addTruckMarkersDragEvents() {
        this.truckMarkers.forEach(marker => {
            marker.on("drag", (event) => {
                if (!this.draggingTrackCoords) {
                    this.draggingTrackCoords = {
                        lat: marker.getLngLat().lat,
                        lng: marker.getLngLat().lng
                    }
                }

                this.radius = haversineDistance(
                    { lat: this.draggingTrackCoords.lat, lng: this.draggingTrackCoords.lng },
                    { lat: event.target._lngLat.lat, lng: event.target._lngLat.lng }
                ) * this.milesToKmCoefficient;

                this.circleCenter = [this.draggingTrackCoords.lng, this.draggingTrackCoords.lat];

                this.circleCenterMarker = this.trimbleMapsService.addRadiusCircle(this.map, this.draggingTrackCoords.lng, this.draggingTrackCoords.lat, this.radius,
                    this.hasCircle, false, this.editingMarkers, this.circleCenter, this.circleCenterMarker);
                this.hasCircle = true;
            });

            marker.on("dragend", () => {
                let route = this.routes.find(r => marker.getElement().getAttribute("data-samsaraId") === r?.truck?.samsaraId.toString());
                let unassigned = this.getUnassignedInSelectionCircle();
                this.assignUnassignedBoxes(route, unassigned);
                marker.setLngLat([this.draggingTrackCoords.lng, this.draggingTrackCoords.lat]);
                this.draggingTrackCoords = null;
                this.trimbleMapsService.removeRadiusCircle(this.map, this.hasCircle, this.editingMarkers, this.circleCenterMarker);
                this.hasCircle = false;
            });
        });
    }

    private getRoute(location: VehicleLocation): Route {
        return this.routes.find(r => r.truck.samsaraId === location.id);
    }

    private setupUnassigned() {
        this.unassignedMarkers = this.unassigned.flatMap(boxGroup => [{
            boxGroup,
            waypoint: boxGroup.pickupRouteBox?.waypoint,
            routeBox: boxGroup.pickupRouteBox,
            type: this.pickupType
        }, {
            boxGroup,
            waypoint: boxGroup.dropOffRouteBox?.waypoint,
            type: this.dropoffType,
            routeBox: boxGroup.pickupRouteBox,
        }].filter(bx => bx.waypoint != null)).map(wp => ({
            boxGroup: wp.boxGroup,
            type: wp.type === this.pickupType ? 'pickup' : 'dropoff',
            routeBox: wp.routeBox,
            latitude: wp.waypoint.point.lat,
            longitude: wp.waypoint.point.lng,
            label: wp.waypoint.name,
            title: `${wp.type === this.pickupType ? 'Pickup' : 'Dropoff'}: ${wp.waypoint.name}`,
            index: undefined
        })
        );
    }

    private assignUnassignedBoxes(route: Route, unassigned: MarkerInfo[]) {
        const boxes = unassigned.map(mi => mi.boxGroup);
        const uiBoxes = RouteBoxUIGroup.createGroups(boxes);
        const assignStopsToRoute = {
            routeId: route.routeId,
            stops: uiBoxes
        }
        this.routesService.assignStopsToRoute = assignStopsToRoute
    }

    private loadTruckLocations() {
        this.samsaraService.locations.subscribe(locations => {
            this.vehicleLocations.forEach(loc => {
                const newLoc = locations.find(location => location.id === loc.id);
                if (newLoc != null) {
                    loc.longitude = newLoc.longitude;
                    loc.latitude = newLoc.latitude;
                    if (this.haveTruckMarkers) {
                        this.trimbleMapsService.updateTruckMarkerLocation(loc, this.truckMarkers);
                    }
                }
            });

            if (!this.haveTruckMarkers && this.vehicleLocations.length > 0) {
                this.vehicleLocations.forEach(location => {
                    this.trimbleMapsService.addTruckMarker(this.map, location, this.getRoute(location), this.truckMarkers);
                });

                this.haveTruckMarkers = true;
                this.addTruckMarkersDragEvents();
            }
        });
    }

    private setRoutes() {
        this.routesService.$allRoutes.pipe(untilDestroyed(this)).subscribe(
            (res: any) => {
                this.routes = res?.map(resp => resp?.route);
                this.trimbleMapsRoutes.forEach(route => route.remove());
                this.trimbleMapsRoutes = [];
                this.showedRoutesIds = [];
                if(this.routes){
                    this.routes.forEach(route => {
                        if (route.directions) {
                            route.truck.location = this.vehicleLocations.find(loc => loc.id === route?.truck?.samsaraId);
                            this.trimbleMapsRoutes.push(this.trimbleMapsService.getRoute(route.directions, route.routeId));
                            this.showRoutes = true;
                        }
                    });
                }

                if (this.mapLoaded) {
                    this.trimbleMapsRoutes.forEach(route => {
                        route.addTo(this.map);
                        this.showedRoutesIds.push(route.getRouteId());
                    });

                }
                else {
                    this.showRoutes = false;
                }
            }
        );
    }

    private setUnassignedStops() {
        this.routesService.$unassignedStops.pipe(untilDestroyed(this)).subscribe(
            (res: any) => {
            this.unassigned = res;
            this.unassignedMarkers = [];
            if(this.unassigned) {
                this.setupUnassigned();
                this.trimbleMapsService.removeUnassignedMarkers(this.unassignedTrimbleMapsMarkers);
                this.trimbleMapsService.setupUnassignedMarkers(this.map, this.unassignedMarkers, this.unassignedTrimbleMapsMarkers);
                this.trimbleMapsService.setMarkerVisibility(this.showUnassigned, this.unassignedTrimbleMapsMarkers);
            }
        });
    }

    private setCurrentRouteBox() {
        this.routesService.$currentRouteBox.pipe(untilDestroyed(this)).subscribe(
            (res: any) => {
                if (res != null) {
                    this.adjustBoundsToRouteBox(this.routesService.getRouteBox(res));
                }
            }
        );

    }

    private adjustBoundsToRouteBox(box: RouteBox) {
        this.map.setCenter({ lat: box.waypoint.point.lat, lng: box.waypoint.point.lng });
    }

    private initMap() {
        const mapOptions: TrimbleMaps.MapOptions = {
            container: this.elementRef.nativeElement,
            style: TrimbleMaps.Common.Style[this.mapStyle],
            center: [this.mapCenterLon, this.mapCenterLat],
            zoom: this.mapCenterZoom,
            hash: false
        };
        this.map = this.trimbleMapsService.initMap(mapOptions);
    }

    private setMapOnLoadEvent() {
        this.map.on('load', () => {
            this.mapLoaded = true;
            this.trafficIncident.addTo(this.map);
            this.trafficIncident.setVisibility(false);
            this.trafficCamera.addTo(this.map);
            this.trafficCamera.setVisibility(false);
        });
    }

    private setMapOnClickEvent() {
        this.map.on("click", (event) => {
            // if trimble maps marker clicked, defaultPrevented is true
            let isMarker = event.defaultPrevented;
            
            this.unassignedMarkers.forEach(marker => {
                let distance = haversineDistance({ lat: event.lngLat.lat, lng: event.lngLat.lng },
                    { lat: marker.latitude, lng: marker.longitude });

                if (distance < 0.6) {
                    isMarker = true;
                };
            });

            this.truckMarkers.forEach(marker => {
                let distance = haversineDistance({ lat: event.lngLat.lat, lng: event.lngLat.lng },
                    { lat: marker.getLngLat().lat, lng: marker.getLngLat().lng });

                if (distance < 0.6) {
                    isMarker = true;
                }
            });

            if (!isMarker) {
                this.circleCenter = [event.lngLat.lng, event.lngLat.lat];
                this.circleCenterMarker = this.trimbleMapsService.addRadiusCircle(this.map, event.lngLat.lng, event.lngLat.lat, this.defaultRadius,
                    this.hasCircle, false, this.editingMarkers, this.circleCenter, this.circleCenterMarker);
                this.radius = this.defaultRadius;
                this.hasCircle = true;
                this.addEditingMarkersDragEvents();
                this.addCircleCenterMarkerDragEvents();
            }
        });
    }

    private setSelectedRoute() {

        this.routesService.$currentRoute.pipe(untilDestroyed(this)).subscribe(
            (route: any) => {
                if (this.showRoutes) {
                    if (this.selectedRoute) {
                        this.trimbleMapsRoutes.filter(tmr => tmr.getRouteId() !== this.selectedRoute.routeId.toString()
                        ).forEach(route => {
                            if (!this.showedRoutesIds.find(r => r === route.getRouteId())) {
                                route.addTo(this.map);
                            }
                        });
                    }

                    this.selectedRoute = route;

                    if (this.selectedRoute) {
                        this.adjustBoundsToSelectedRoute();
                    }
                }
            }
        );


    }

    private adjustBoundsToSelectedRoute() {
        if (this.mapLoaded && this.showRoutes) {
            let selectedRouteId = this.selectedRoute.routeId.toString();
            if (this.selectedRoute.directions) {
                this.trimbleMapsRoutes.find(route => route.getRouteId() === selectedRouteId).frameRoute();
            }
            this.trimbleMapsRoutes.filter(tmr => tmr.getRouteId() !== selectedRouteId).forEach(route => {
                let index = this.showedRoutesIds.indexOf(this.showedRoutesIds.find(r => r === route.getRouteId()));
                this.showedRoutesIds.splice(index, 1);
                route.remove();
            });
        }
    }

    private addEditingMarkersDragEvents() {
        this.editingMarkers.forEach(marker => {
            marker.on("drag", (ev) => {
                const circleRadius = haversineDistance({ lat: this.circleCenter[1], lng: this.circleCenter[0] },
                    { lat: ev.target._lngLat.lat, lng: ev.target._lngLat.lng });

                this.radius = circleRadius * this.milesToKmCoefficient;

                this.trimbleMapsService.drawPreviewCircle(this.map, this.hasPreviewCircle, this.circleCenter, this.radius)
                this.hasPreviewCircle = true;
            });

            marker.on("dragend", (ev) => {
                this.radius = this.trimbleMapsService.scaleCircleAndGetRadius(this.map, [ev.target._lngLat.lng, ev.target._lngLat.lat], this.circleCenter,
                    this.editingMarkers, this.hasCircle, this.circleCenterMarker);
                this.trimbleMapsService.replaceEditingMarkers(this.map, this.circleCenter, this.radius, this.editingMarkers, this.circleCenterMarker);
                this.addEditingMarkersDragEvents();
                if (this.hasPreviewCircle) {
                    this.map.removeLayer("previewCircle");
                    this.map.removeSource("previewCircle");
                    this.hasPreviewCircle = false;
                }
            });
        });
    }

    private addCircleCenterMarkerDragEvents() {
        this.circleCenterMarker.on("drag", (ev) => {
            this.trimbleMapsService.drawPreviewCircle(this.map, this.hasPreviewCircle, [ev.target._lngLat.lng, ev.target._lngLat.lat], this.radius);
            this.hasPreviewCircle = true;
        });

        this.circleCenterMarker.on("dragend", (ev) => {
            this.circleCenter = [ev.target._lngLat.lng, ev.target._lngLat.lat];
            this.circleCenterMarker = this.trimbleMapsService.addRadiusCircle(this.map, ev.target._lngLat.lng, ev.target._lngLat.lat, this.radius, this.hasCircle,
                false, this.editingMarkers, this.circleCenter, this.circleCenterMarker);
            this.hasCircle = true;
            this.trimbleMapsService.replaceEditingMarkers(this.map, this.circleCenter, this.radius, this.editingMarkers, this.circleCenterMarker)
            this.addCircleCenterMarkerDragEvents();
            this.addEditingMarkersDragEvents();
            if (this.hasPreviewCircle) {
                this.map.removeLayer("previewCircle");
                this.map.removeSource("previewCircle");
                this.hasPreviewCircle = false;
            }
        });
    }
}
