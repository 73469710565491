import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@app/shared/shared.module';
import { TermsConditionsRoutingModule } from './terms-conditions-routing.module';
import { TermsConditionsComponent } from './terms-conditions.component';
import { QuillModule } from 'ngx-quill';
import { QuillEditorModule } from '@app/@shared/quill-editor/quill-editor.module';
import { AddTermsConditionsDialogComponent } from './add-terms-conditions-dialog/add-terms-conditions-dialog.component';
import { FormValidateModule } from '@app/@shared/form-validate/form-validate.module';
import { DialogsModule } from '@app/@shared/dialogs/dialogs.module';

@NgModule({
  declarations: [
    TermsConditionsComponent,
    AddTermsConditionsDialogComponent
  ],
  imports: [
    CommonModule,
    TermsConditionsRoutingModule,
    SharedModule,
    QuillEditorModule,
    FormValidateModule,
    DialogsModule
  ],

})
export class TermsConditionsModule { }
