// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
:host .empty-star,
:host .fill-star {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
}
:host .fill-star {
  position: absolute;
  color: #fdd836;
  left: 0;
  right: 0;
  z-index: 9;
  overflow: hidden;
}
:host .star-input {
  cursor: pointer;
}
:host .star-input.active {
  color: #fdd836;
}`, "",{"version":3,"sources":["webpack://./src/app/@shared/rating/rating.component.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,oBAAA;EACA,sBAAA;AACF;AAAE;;EAEE,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,iBAAA;AAEJ;AAAE;EACE,kBAAA;EACA,cAAA;EACA,OAAA;EACA,QAAA;EACA,UAAA;EACA,gBAAA;AAEJ;AACE;EACE,eAAA;AACJ;AAAI;EACE,cAAA;AAEN","sourcesContent":[":host {\n  position: relative;\n  display: inline-flex;\n  vertical-align: middle;\n  .empty-star,\n  .fill-star {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    flex-wrap: nowrap;\n  }\n  .fill-star {\n    position: absolute;\n    color: #fdd836;\n    left: 0;\n    right: 0;\n    z-index: 9;\n    overflow: hidden;\n  }\n\n  .star-input {\n    cursor: pointer;\n    &.active {\n      color: #fdd836;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
