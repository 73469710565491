import { Directive, HostListener } from '@angular/core';

@Directive({
    selector: 'input[type=number]',
})
export class InputTypeNumberDirective {
    @HostListener('wheel', ['$event'])
    onWheel(event: WheelEvent) {
        if (event.target instanceof HTMLInputElement && event.target.type === 'number') {
            event.preventDefault();
        }
    }
}
