import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiPaginationResponse, ApiResponse } from '@app/models/core/base';
import { toParams } from 'src/utils/to-params';
import { Expense } from '@app/models/expense.model';

@Injectable({ providedIn: 'root' })
export class ExpenseService {

    private basePath = 'TruckExpenses'

    constructor(
        private http: HttpClient,
    ) {
    }

    getAll(request?: any) {
        return this.http.get<ApiPaginationResponse<Expense>>(`${this.basePath}`, { params: toParams(request) })
            .toPromise()
            .then(({ data }) => data);
    }

}
