import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainRoutingModule } from './main-routing.module';
import { DragScrollableDirective } from '@app/directives/drag-scrollable.directive';
import { SvgComponent } from '../global/svg/svg.component';
import { CustomerImportComponent } from './customers/customer-list/customer-import/customer-import.component';
import { DragDropDirective } from './customers/customer-list/customer-import/drag-drop-directive.directive';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AgmCoreModule } from '@agm/core';
import { AgmSnazzyInfoWindowModule } from '@agm/snazzy-info-window';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { HttpClientModule } from '@angular/common/http';
import { NgbActiveModal, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { CalendarModule } from 'angular-calendar';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { CookieModule } from 'ngx-cookie';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { QuillModule } from 'ngx-quill';
import { SpinnerModule } from '../global/spinner/spinner.module';
import { NavigationComponent } from './layout/navigation/navigation.component';
import { MainComponent } from './layout/main/main.component';
import { ToolbarComponent } from './layout/toolbar/toolbar.component';
import { SharedModule } from '@app/shared/shared.module';
import { NgChartsModule } from 'ng2-charts';
import { CompanySelectModule } from '@app/@shared/company-select/company-select.module';
import { FormsModule } from '@angular/forms';
import { AgmDirectionModule } from '@agm/direction';

@NgModule({
  declarations: [
    MainComponent,
    DashboardComponent,
    SvgComponent,
    SvgComponent,
    CustomerImportComponent,
    DragDropDirective,
    DragScrollableDirective,
    NavigationComponent,
    ToolbarComponent,
    
  ],
  imports: [
    CommonModule,
    FormsModule,
    MainRoutingModule,
    NgbTooltipModule,
    SharedModule,
    BsDropdownModule,
    PopoverModule,
    ModalModule,
    AccordionModule,
    HttpClientModule,
    CookieModule,
    PaginationModule,
    SweetAlert2Module,
    BsDatepickerModule,
    TypeaheadModule,
    TooltipModule,
    CalendarModule,
    NgSelectModule,
    DragDropModule,
    NgxMaterialTimepickerModule,
    AgmCoreModule,
    NgxDropzoneModule,
    AgmSnazzyInfoWindowModule,
    AgmDirectionModule,
    QuillModule,
    NgxSliderModule,
    SpinnerModule,
    NgChartsModule,
    NgxDropzoneModule,   
    CompanySelectModule,
    
  ],
  providers: [
    NgbActiveModal,
  ]
})
export class MainModule { }
