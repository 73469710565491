import { Component, EventEmitter, Output, TemplateRef, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Order } from '@models/order.model';
import { Invoice } from '@models/invoice.model';
import { TelegramService } from '@app/services/telegram.service';
import { TelegramBotMessage, TelegramChatMessage } from '@models/telegram.model';

interface EmailRecipient {
    email: string;
    name?: string;
    type: string;
}

export interface DocumentViewerResult {
    entity: Order | Invoice;
    email?: string;
}

@Component({
    selector: 'app-chat-history-viewer',
    templateUrl: './chat-history-viewer.component.html',
    styleUrls: ['./chat-history-viewer.component.scss']
})
export class ChatHistoryViewerComponent {
    @ViewChild('template') template: TemplateRef<any>;
    @Output() downloadClick: EventEmitter<DocumentViewerResult> = new EventEmitter<DocumentViewerResult>();
    @Output() emailClick: EventEmitter<DocumentViewerResult> = new EventEmitter<DocumentViewerResult>();
    driverId: number;
    search: string;
    modalRef: BsModalRef;
    showEmail = true;

    data: (TelegramChatMessage | TelegramBotMessage)[] = [];
    mode: 'chat' | 'bot';

    constructor(
        private modalService: BsModalService,
        private telegramService: TelegramService,
    ) {
    }

    public show(driverId: number, mode: 'chat' | 'bot') {
        this.mode = mode;
        this.search = '';
        this.driverId = driverId;
        this.modalRef = this.modalService.show(
            this.template,
            Object.assign({}, { class: 'service_type_modal' })
        );
        if (mode === 'chat') {
            this.telegramService.searchChatHistory(this.driverId, this.search).subscribe(res => {
                this.data = res;
            }, err => {
                console.log(err);
            });
        } else {
            this.telegramService.searchBotHistory(this.driverId, this.search).subscribe(res => {
                this.data = res;
            }, err => {
                console.log(err);
            });
        }
    }

    close() {
        this.modalRef.hide();
    }

    getChatMessage(message: TelegramChatMessage | TelegramBotMessage): TelegramChatMessage {
        return message as TelegramChatMessage;
    }

    getBotMessage(message: TelegramChatMessage | TelegramBotMessage): TelegramBotMessage {
        return message as TelegramBotMessage;
    }
}
