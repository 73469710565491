// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host .page-title {
  padding-bottom: 60px;
}
:host .save-btn {
  justify-content: center;
  min-height: 40px;
  padding: 8px 60px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/main/settings/drivers-settings/drivers-settings.component.scss"],"names":[],"mappings":"AAEI;EACI,oBAAA;AADR;AAGI;EACI,uBAAA;EACA,gBAAA;EACA,iBAAA;AADR","sourcesContent":["@import \"var\";\n:host {\n    .page-title {\n        padding-bottom: 60px;\n    }\n    .save-btn {\n        justify-content: center;\n        min-height: 40px;\n        padding: 8px 60px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
