import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PortStatusComponent } from './port-status.component';
import { FormsModule } from '@angular/forms';



@NgModule({
  declarations: [
    PortStatusComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
  ],
  exports: [
    PortStatusComponent
  ]
})
export class PortStatusModule { }
