// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host .number-input {
  max-width: 300px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/main/orders/components/order-delete-dialog/order-delete-paid-invoice-dialog/order-delete-paid-invoice-dialog.component.scss"],"names":[],"mappings":"AAEI;EACI,gBAAA;AADR","sourcesContent":["@import \"var\";\n:host{\n    .number-input{\n        max-width: 300px;\n    }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
