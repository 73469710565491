import { NgModule } from '@angular/core';
import { OrderPriorityComponent } from './order-priority.component';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { CommonModule } from '@angular/common';

@NgModule({
	declarations: [
		OrderPriorityComponent
	],
	imports: [
		NgbTooltipModule,
		CommonModule,
	],
	exports: [
		OrderPriorityComponent
	]
})
export class OrderPriorityModule { }
