import { Component, Input } from '@angular/core';
import { AbstractControl, FormGroup, NgModel, ValidatorFn } from '@angular/forms';
import { titleCase } from '@app/@shared/utils';


@Component({
  selector: 'control-messages',
  template: `
    <div class="invalid-feedback d-block" [innerHtml]="errorMessage[0]"></div>
  `,
  styles: [`
    .error-message {
        color: #f44336;
        font-size: 0.9em;

    }
    .material-icons{
      vertical-align:
      text-bottom;
      font-size: 1.2em;
    }`]
})
export class ControlMessages {
  errorMessage: any = [];
  //control: any;
  submitted: boolean = false;
  @Input('type') type: string = "";

  private _name: any = "This field";

  @Input('name')
  public get name(): any {
    return this._name || this.control.name;
  }
  public set name(value: any) {
    this._name = value;
  }

  @Input('control') control: NgModel;
  @Input() form: FormGroup;

  constructor() {

  }

  ngDoCheck(): void {

    if (!this.control) {
      this.control = this.form.controls[this.name] as any;
    }
    // this.submitted = this.control.formDirective.submitted
    

    if (this.submitted == undefined) {
      if (this.submitted) {
       
        this.errorMessage = [this.name];
      }
    } else if (this.control != undefined && this.control != null && this.control.touched || this.submitted) {

      this.errorMessage = [];

      if (this.control != undefined) {
        for (let propertyName in this.control.errors) {
          if (this.control.errors.hasOwnProperty(propertyName)) {
            let validatorValue: any = this.control.errors[propertyName];
            if (typeof validatorValue == 'boolean') {
              validatorValue = { name: this.name, type: this.type };
            } else {
              validatorValue.name = this.name;
              validatorValue.type = this.type;
            }
            //validatorValue.name = this.name;
            let message = ValidationService.getValidatorErrorMessage(propertyName, validatorValue);
            
            this.errorMessage.push(message);
          }
        }
      }

    }
  }


}


export class ValidationService {
  
  static getValidatorErrorMessage(validatorName: string, validatorValue?: any, select: boolean = false) {
    if (validatorValue.name == undefined) {
      validatorValue.name = "This field";
    }
    let config = {
      'required': `${titleCase(validatorValue.name)} is required.`,
      'email': `Please enter a valid ${titleCase(validatorValue.name)} address.`,
      'pattern': `Please provide valid ${validatorValue.name}!`,
      'invalidCreditCard': 'Is invalid credit card number.',
      'invalidEmailAddress': 'Please provide valid email.',
      'invalidPassword': 'Invalid password. Password must be at least 6 characters long, and contain a number.',
      'strongPassword': 'Invalid password. Password must be at least 8 characters long, and contain a number.',
      //'areEqual': `${validatorValue.name} must be equal!`,
      'minlength': `Please enter a ${titleCase(validatorValue.name)} that has atleast ${validatorValue.requiredLength} characters.`,
      'passwordsNotMatching': `Password must match.`
    };
    if (validatorValue.type == "select" || validatorValue.type == "radio") {
      config.required = `Please select ${validatorValue.name}.`;
    }
    if (validatorValue.type == "checkbox") {
      config.required = `Please select at last one ${validatorValue.name}.`;
    }
    if (validatorValue.type == "other") {
      config.required = `${validatorValue.name}`;
    }
    return config[validatorName];
  }

  static creditCardValidator(control) {
    // Visa, MasterCard, American Express, Diners Club, Discover, JCB
    if (control.value.match(/^(?:4[0-9]{12}(?:[0-9]{3})?|5[1-5][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11})$/)) {
      return null;
    } else {
      return { 'invalidCreditCard': true };
    }
  }

  static emailValidator(control) {
    // RFC 2822 compliant regex
    if (control.value != null && control.value != "" && control.value.match(/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/)) {
      return null;
    } else {
      return { 'invalidEmailAddress': true };
    }
  }

  static passwordValidator(control) {
    // {6,100}           - Assert password is between 6 and 100 characters
    // (?=.*[0-9])       - Assert a string has at least one number
    if (control.value.match(/^(?=.*[0-9])[a-zA-Z0-9!@#$%^&*]{6,100}$/)) {
      return null;
    } else {
      return { 'invalidPassword': true };
    }
  }
}

export class CustomValidator {

  static email(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const emailRegex = new RegExp(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/);

      return (control.value && !emailRegex.test(control.value) ? { email: true } : null);
    };
  }

  static strongPassword(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const regex = new RegExp(/^(?=.*[0-9])[a-zA-Z0-9!@#$%^&*]{8,100}$/);
      return (control.value && !regex.test(control.value) ? { strongPassword: true } : null);
    };
  }

  static numaric(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const regex = new RegExp(/^[0-9]{2,}$/);
      return (control.value && !regex.test(control.value) ? { numaric: true } : null);
    };
  }
}
