import { BaseModel } from '@models/base.model';

export enum CostType
{
    FuleCharges,
    Toll,
    Milage,
    DriverCost,
    Accessories,
    Other,
    Surcharge,
    Fee,
    MinPrice,
    Weight,
    Cubic,
    Item,
    FragileFee,
    HazardousFee,
    OverweightFee,
    RefrigeratedFee,
    Time,
    Truck,
    AdditionalCharges 
}

export interface CostLineDescription {
    lineName: string;
    itemName?: string;
}

export const CostLineDescriptions: Readonly<Record<CostType, CostLineDescription>> = Object.freeze({
    [CostType.FuleCharges]: { lineName: 'Fuel' },
    [CostType.Accessories]: { lineName: 'Accessories'},
    [CostType.Cubic]: { lineName: 'Cubic'},
    [CostType.DriverCost]: { lineName: 'Driver Cost'},
    [CostType.Fee]: { lineName: 'Fee'},
    [CostType.Toll]: { lineName: 'Tolls'},
    [CostType.Other]: { lineName: 'Other'},
    [CostType.MinPrice]: { lineName: 'Min Price'},
    [CostType.Weight]: { lineName: 'Weight' },
    [CostType.Surcharge]: { lineName: 'Surcharge'},
    [CostType.Milage]: { lineName: 'Millage'},
    [CostType.Item]: { lineName: 'Item'},
    [CostType.FragileFee]: { lineName: 'Fragile Fee'},
    [CostType.HazardousFee]: { lineName: 'Hazardous Fee'},
    [CostType.OverweightFee]: { lineName: 'Overweight Fee'},
    [CostType.RefrigeratedFee]: { lineName: 'Refrigerated Fee'},
    [CostType.Time]: { lineName: 'Time'},
    [CostType.Truck]: { lineName: 'Truck'},
    [CostType.AdditionalCharges ]: { lineName: 'Additional Charges'},
});

export interface CostLine extends BaseModel {
    costLineId?: number;
    costType?: CostType;
    quantity?: number;
    rate?: number;
    totalCost?: number;
    isActive?: boolean;
    isOverwritten?: boolean;
    itemDescription: string;
}
