import { Component, Input } from '@angular/core';
import { Route } from '@app/models/route.model';
import { HelperService } from '@app/shared/services/helper.service';
import { RoutesService } from '@app/shared/services/router.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { cloneDeep } from 'lodash';
import { object, string } from 'yup';

@Component({
  selector: 'app-add-edit-route-dialog',
  templateUrl: './add-edit-route-dialog.component.html',
  styleUrls: ['./add-edit-route-dialog.component.scss']
})
export class AddEditRouteDialogComponent {
  submitLoading: boolean = false;

  private _route: Route = {
    name: '',
    driver: null,
    truck: null,
    zone: null,
  };

  @Input()
  public get route(): Route {
    return this._route;
  }
  public set route(value: Route) {
    this._route = cloneDeep(value);
  }

  validationSchema = object().shape({
    driver: object().nullable().required('Please Select Driver.'),
    truck: object().nullable().required('Please Select Truck.'),
    zone: object().nullable().required('Please Select Zone.'),
  });

  constructor(
    private routesService: RoutesService,
    private activeModal: NgbActiveModal,
    public helperService: HelperService,
  ) { }

  onSubmit(f) {
    if (f.isValid) {
      this.submitLoading = true
      if (!this.route.routeId) {
        this.routesService.create(this.route).then((res) => {
          this.helperService.successMessage("Route successfully created.");
          this.activeModal.close(true);
          this.submitLoading = false
        }).catch((error) => {
          this.helperService.errorMessage(error);
          this.submitLoading = false
        });
      } else {
        this.routesService.update(this.route.routeId, this.route).then((res) => {
          this.helperService.successMessage("Route successfully updated.");
          this.activeModal.close(true);
          this.submitLoading = false
        }).catch((error) => {
          this.helperService.errorMessage(error);
          this.submitLoading = false
        });
      }
    }
  }

  close() {
    this.activeModal.close();
  }

}
