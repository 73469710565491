import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { OrderLayoutComponent } from './order-layout/order-layout.component';


const routes: Routes = [
  {
    path: '',
    component: OrderLayoutComponent
  },
  {
    path: '',
    loadChildren: () => import('./add-edit-order/add-edit-order.module').then(m => m.AddEditOrderModule)
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class OrdersRoutingModule { }
