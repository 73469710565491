
export interface PickupType {
    class?: string;
    label: string;
    value?: string;
    image?: string;
}


export enum PickupTypeEnum {
    BOXES_CRATES = "Boxes & Crates",
    PALLETS = "Pallets",
    FULL_LOAD = "Full Load",
    CFS = "CFS",
    OTHER = "Other",
}