import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@app/shared/shared.module';
import { BillingComponent } from './billing.component';
import { BillingRoutingModule } from './billing-routing.module';
import { RepeaterModule } from '@app/@shared/repeater/repeater.module';
import { FormValidateModule } from '@app/@shared/form-validate/form-validate.module';

@NgModule({
  declarations: [
    BillingComponent
  ],
  imports: [
    CommonModule,
    BillingRoutingModule,
    SharedModule,
    RepeaterModule,
    FormValidateModule
  ]
})
export class BillingModule { }
