import { Component, Input, OnInit } from '@angular/core';
import { removeBinding } from '@app/@shared/utils';
import { Permission } from '@app/models/user.model';
import { HelperService } from '@app/shared/services/helper.service';
import { PermissionService } from '@app/shared/services/permission.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UntilDestroy } from '@ngneat/until-destroy';
import { object, string } from 'yup';

@UntilDestroy()
@Component({
    selector: 'app-add-edit-permission-dialog',
    templateUrl: './add-edit-permission-dialog.component.html',
    styleUrls: ['./add-edit-permission-dialog.component.scss']
})
export class AddEditPermissionDialogComponent implements OnInit {

    /*
	* Getter & Setter for Permission
	*/
	private _permission: Permission;
	get permission(): Permission {
		return this._permission;
	}
	@Input() set permission(value: Permission) {
		this._permission = removeBinding(value);
	}

    loading: boolean;
    validationSchema = object().shape({
        name: string().nullable().required(),
    });

    constructor(
        public activeModal: NgbActiveModal,
        private permissionService: PermissionService,
        private helperService: HelperService,
    ) {
    }

    ngOnInit() {        
    }

    submit(f) {
        if (f.isValid) {
            this.loading = true;
            if (this.permission?.permissionId) {
                this.permissionService.update(this.permission?.permissionId,this.permission).then((res) => {
                    this.loading = false;
                    this.activeModal.close(true);
                }).catch(({ error }) => {
                    this.loading = false;
                    this.helperService.errorMessage(error);
                })
            } else {
                this.permissionService.create(this.permission).then((res) => {
                    this.loading = false;
                    this.activeModal.close(true);
                }).catch(({ error }) => {
                    this.loading = false;
                    this.helperService.errorMessage(error);
                })
            }
        }
    }

    close() {
        this.activeModal.close();
    }


}
