import { Component, Input, OnInit } from '@angular/core';
import { OrderStop } from '@app/models/order-stop.model';
import { Order } from '@app/models/order.model';
import { RouteItemStop } from '@app/models/route-item-stop.model';
import { RouteStopGroup } from '@app/models/route-stop-group.model';
import { Route } from '@app/models/route.model';
import { ApiService } from '@app/services/api.service';
import { HelperService } from '@app/shared/services/helper.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { chain, cloneDeep, compact, flatten, groupBy, isEqual, map, sumBy, uniq } from 'lodash';
import { Clipboard } from '@angular/cdk/clipboard';
import { debounceTime, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { RoutesService } from '@app/shared/services/router.service';
@UntilDestroy()
@Component({
  selector: 'app-copy-details-to-driver',
  templateUrl: './copy-details-to-driver.component.html',
  styleUrls: ['./copy-details-to-driver.component.scss']
})
export class CopyDetailsToDriverComponent implements OnInit {

  @Input() title: string;
  @Input() route: Route;

  routeStopGroups: RouteStopGroup[];

  private _routeStopGroupsSearch: any = {
    Page: 1,
    ItemsPerPage: 20,
    status: 0,
  };

  @Input()
  public get routeStopGroupsSearch(): any {
    return this._routeStopGroupsSearch;
  }
  public set routeStopGroupsSearch(value: any) {
    this._routeStopGroupsSearch = (value);

    if (value?.routeId) {
      this.isLoading = true;
      this.getRouteItemGroups(value);
    }
  }

  private _routeItemStop: RouteItemStop;
  @Input()
  public get routeItemStop(): RouteItemStop {
    return this._routeItemStop;
  }
  public set routeItemStop(value: RouteItemStop) {
    this._routeItemStop = value;

    if (value) {
      this.parseMessages();
      this.isLoading = false;
      this.isSingleStop = true;
    }
  }

  private _order: Order;
  @Input()
  public get order(): Order {
    return this._order;
  }
  public set order(value: Order) {
    this._order = value;
    if (value) {
      this.parseMessages();
      this.isLoading = false;
    }
  }

  private _orderStop: OrderStop;
  @Input()
  public get orderStop(): OrderStop {
    return this._orderStop;
  }
  public set orderStop(value: OrderStop) {
    this._orderStop = value;
    if (value) {
      this.parseMessages();
      this.isLoading = false;
    }
  }

  private _isGroup?: boolean;
  @Input()
  public get isGroup(): boolean {
    return this._isGroup;
  }
  public set isGroup(value: boolean) {
    this._isGroup = value;
    if (this._isGroup) {
      this.messageGroup();
    }
    else {
      this.parseMessages()
    }

  }

  content: any
  messages: any
  messagesClone: any;
  messageWithSameAddress: any
  isLoading?: boolean = true;
  isSingleStop?: boolean = false;
  hasSameAddress?: boolean = false;
  loadMoreLoading?: boolean = false;
  loadRouteGroup$ = new Subject();
  hasMorePage: boolean = false;

 

  constructor(
    private api: ApiService,
    private activeModal: NgbActiveModal,
    private helperService: HelperService,
    private routesService: RoutesService,
    private clipboard: Clipboard
  ) { }

  ngOnInit(): void {

    this.loadRouteGroup$
      .pipe(
        debounceTime(400),
        untilDestroyed(this),
      )
      .subscribe((request: any = {}) => {
        this.getRouteItemGroups({ ...this.routeStopGroupsSearch, ...request })
      });

    this.copyMessage();
  }

  async parseMessages() {
    let messages = [];

    if (this._routeItemStop) {
      messages = await this.parseRouteItem(this._routeItemStop);
    } else if (this.routeStopGroups) {
      messages = this.parseRouteItemGroups(this.routeStopGroups);
    } else if (this._orderStop) {
      messages = this.parseOrderStop(this._orderStop);
    } else if (this._order) {
      messages = this.parseOrder(this._order);
    }
    this.messagesClone = cloneDeep(messages)
    this.messages = messages
    this.copyMessage();
    if (this.isGroup) {
      this.messageGroup()
    }

    const messageString = messages.map((msg) => {
      return this.groupMessageByComparer(msg)
    });

    const uniqueMessageString = uniq(messageString);

    this.hasSameAddress = messageString.length !== uniqueMessageString.length;
    
    if (this.isSingleStop) {
      const sameAddressString = this.messageWithSameAddress.map((address) => {
        return this.groupMessageByComparer(address);
      });

      const stopsSameAddress = [...messageString, ...sameAddressString];

      const uniqueStopSameAddress = uniq([...messageString, ...sameAddressString]);

      this.hasSameAddress = uniqueStopSameAddress.length !== stopsSameAddress.length;
    }
  }

  private messageGroup() {
    if (this.messageWithSameAddress?.length > 0) {
      const selectedMessage = this.messageWithSameAddress.filter((item: any) => !!item?.isSelected)
      this.messages = [...this.messagesClone, ...selectedMessage]
    }
    this.messagesClone = cloneDeep(this.messages)
    const messageGroup = groupBy(this.messages, (messageData) => {
      return this.groupMessageByComparer(messageData);
    });
    this.messages = Object.values(messageGroup)
    if (this.messageWithSameAddress?.length > 0) {
      this.messageWithSameAddress = this.messageWithSameAddress.filter((item: any) => !item?.isSelected)
    }
    this.copyMessage();
  }

  private getPhoneNumber(phone, extension) {
    if (phone && extension) {
      return `${phone} x ${extension}`
    }
    else if (phone) {
      return phone
    }
    return null
  }

  private parseStop(stop) {
    const isBlind = this.isBlindOrderStop(stop.orderStop);
    return {
      type: stop.type === 'Pickup' || stop.type === 'PickupFromTransfer' ? 'Pickup From' : 'Deliver To',
      name: stop?.orderStop?.locationCompanyName || stop?.address?.name,
      locationContactName: stop?.orderStop?.locationContactName,
      locationContactNumber: this.getPhoneNumber(stop.orderStop?.locationContactNumber, stop?.orderStop?.locationContactNumberExtension),
      locationContactEmail: stop.orderStop?.locationContactEmail,
      fullAddress: stop.address?.fullAddress,
      referenceNumber: stop?.referenceNumber,
      orderNumber: stop?.orderNumber,
      customerName: isBlind ? 'Shimon\'s Express' : stop?.customer?.customerName,
      routeItems: stop?.routeItemsText && !isBlind ? stop?.routeItemsText : [],
      stopId: stop?.orderStop?.orderStopId || stop?.transferStop?.transferStopId,
      // routeItems: !isBlind ? stop.routeItems?.map(({ orderItem, quantity }) => {
      //   return `${orderItem.itemName} - ${quantity} ${orderItem.itemTypeQty}`
      // }) : [],
    };
  }

  private async parseRouteItem(routeItemStop) {
    routeItemStop.routeItemsText = this.routeItemTexts(routeItemStop, routeItemStop?.routeItems)
    const messageData = this.parseStop(routeItemStop);
    const currentMessageAddress = this.groupMessageByComparer(messageData);
    this.messageWithSameAddress = await this.parseRouteItemGroups(this.routeStopGroups)
      .filter((item) => {        
        return currentMessageAddress == this.groupMessageByComparer(item) && !isEqual(item, messageData)
      });
    return [messageData];
  }

  private parseRouteItemGroups(routeStopGroups) {
    const stops = chain(routeStopGroups)
      .map('routeItemStops')
      .flatten()
      .filter((stop) => stop.status === 0)
      .compact()
      .value();
    const groupedStops = this.menageGroupSameStopAndSameOrder(stops)
    return groupedStops.map((stop) => {
      return this.parseStop(stop);
    })
  }

  private parseOrderStop(orderStop) {
    const stops = chain(this._order?.routes)
      .map('routeStopGroups')
      .flatten()
      .map('routeItemStops')
      .flatten()
      .filter((stop) => {
        return (stop?.orderStop?.orderStopId == orderStop?.orderStopId &&
          stop?.orderStop?.orderStopType == orderStop?.orderStopType &&
          stop?.orderStop?.isActive)
      })
      .compact()
      .value();
    const groupedStops = this.menageGroupSameStopAndSameOrder(stops)

    return groupedStops.map((stop) => {
      return this.parseStop(stop);
    })
  }

  private parseOrder(order) {
    const stops = chain(order?.routes)
      .map('routeStopGroups')
      .flatten()
      .map('routeItemStops')
      .flatten()
      .filter((routeItemStop) => {
        return (routeItemStop?.orderStop?.isActive && routeItemStop.status === 0)
      })
      .compact()
      .value();
    const groupedStops = this.menageGroupSameStopAndSameOrder(stops)

    return groupedStops.map((stop) => {
      return this.parseStop(stop);
    })
  }
  private routeItemTexts(stop, routeItems) {
    const isBlind = this.isBlindOrderStop(stop.orderStop);
    if (isBlind) {
      return []
    }

    var activeRouteItems = routeItems?.filter(x => x.isActive) || [];

    if (!activeRouteItems.length) {
      return [];
    }

    let routeItemGroup: any = groupBy(cloneDeep(activeRouteItems), (routeItem) => {
      return `${routeItem?.orderItemStopId} ${routeItem?.orderItem?.itemName} ${routeItem?.orderItem?.itemTypeQty}`
    })

    return map(routeItemGroup, (val, keys) => {
      const totalQty = sumBy(val, (item: any) => Number(item?.quantity) || 0)
      const { orderItem } = val[0]
      return `${orderItem?.itemName} - ${totalQty || 0} ${orderItem?.itemTypeQty}`

    })
  }

  private menageGroupSameStopAndSameOrder(stops: any) {
    const items = chain(cloneDeep(stops))
      .groupBy((stop) => {
        return `${stop?.type} ${stop?.orderNumber} ${stop?.orderStop?.orderStopId || stop?.transferStop?.transferStopId}`
      })
      .map((value: any, key) => {
        if (value?.length == 0) {
          value.routeItemsText = this.routeItemTexts(value, value?.routeItems)
          return value
        }
        if (value?.length > 0) {
          value[0].routeItemsText = this.routeItemTexts(value, flatten(map(value, 'routeItems')))
          return value[0]
        }
        return value
      })
      .value()
    return items
  }

  private isBlindOrderStop(orderStop) {
    return orderStop?.isBlindStop;
    // return Boolean(orderStop.isBlindStop || orderStop.blindCustomerName || orderStop.blindCustomer || orderStop.blindCustomer || orderStop.blindAddress || orderStop.blindAddressAddressId);
  }

  private groupMessageByComparer(messageData) {
    return `${messageData.type} ${messageData.name} ${messageData.locationContactNumber} ${messageData.fullAddress}`
  }

  private convertMessageToTelegram(messageData) {
    const stopText = this.convertMessageToHtml(messageData);
    return this.convertMessageHtmlToTelegram(stopText)
  }

  private convertMessageHtmlToTelegram(html) {
    return html
      .replace(/<b class="[^"]+">/g, '**')
      .replace(/<b>/g, '**')
      .replace(/<\/b>/g, '**')
      .replace(/<br \/>/g, '\n')
      .replace(/<br>/g, '\n')
      .replace(/<hr \/>/g, '----------------\n')
      .replace(/<hr>/g, '----------------\n')
  }

  private convertMessageToHtml(messageData) {
    if (messageData instanceof Array) {

      const firstMessages = messageData[0];
      let message = [
        `<b class="${firstMessages.type === 'Pickup From' ? 'text-primary' : 'text-danger'}">${firstMessages.type}:</b> ${[firstMessages.name, firstMessages.fullAddress].join(', ')}`,
        firstMessages?.locationContactName ? `<b>Contact Name:</b> ${firstMessages.locationContactName}` : null,
        firstMessages?.locationContactNumber ? `<b>Phone:</b> ${firstMessages.locationContactNumber}` : null
        // firstMessages?.locationContactEmail ? `<b>Email:</b> ${firstMessages.locationContactEmail}` : null,
      ]

      messageData.forEach((data) => {
        const extraData = [
          data.referenceNumber ? `<b>Reference:</b> ${data.referenceNumber}` : null,
          `[ <b>Order Number:</b> ${data.orderNumber} ]`,
          `<b>For:</b> ${data.customerName}`,
          data.routeItems?.length > 0 ? `<b>Items:</b> ${data.routeItems?.join(', ')}` : null
        ]
          .filter(text => text);

        // Add divider if more the 1 message in group
        if (messageData.length > 1) {
          extraData[0] = `<hr /> ${extraData[0]}`;
        }

        message = message.concat(extraData);
      })

      return message
        .filter(text => text)
        .join('<br /><br />')
    } else {
      const messageHtml = [
        `<b class="${messageData.type === 'Pickup From' ? 'text-primary' : 'text-danger'}">${messageData.type}:</b> ${[messageData.name, messageData.fullAddress].join(', ')}`,
        messageData?.locationContactName ? `<b>Contact Name:</b> ${messageData.locationContactName}` : null,
        messageData?.locationContactNumber ? `<b>Phone:</b> ${messageData.locationContactNumber}` : null,
        // messageData?.locationContactEmail ? `<b>Email:</b> ${messageData.locationContactEmail}` : null,
        messageData.referenceNumber ? `<b>Reference:</b> ${messageData.referenceNumber}` : null,
        `[ <b>Order Number:</b> ${messageData.orderNumber} ]`,
        `<b>For:</b> ${messageData.customerName}`,
        messageData.routeItems?.length > 0 ? `<b>Items:</b> ${messageData.routeItems?.join(', ')}` : null
      ]
      return messageHtml
        .filter(text => text)
        .join('<br /><br />')
    }
  }

  generateMessage(type: 'copy' | 'telegram' = 'copy') {
    const message = map(this.messages, (message) => this.convertMessageToTelegram(message))
    const sameAddressMessage = chain(this.messageWithSameAddress)
      .filter(({ isSelected }) => isSelected == true)
      .map((message) => this.convertMessageToTelegram(message))
      .value();

    if (type === 'telegram') {
      return [...message, ...sameAddressMessage]
    }
    else {
      return message?.join('\n\n\n') + '\n\n\n' + sameAddressMessage?.join('\n\n\n')
    }
  }

  private copyMessage() {
    let text = this.generateMessage('copy') as string

    if (text) {
      // Remove Markdown bold markers (e.g., **text**)
      text = text.replace(/\*\*(.*?)\*\*/g, '$1');
          
      // Trim any leading or trailing whitespace from the text
      text = text.trim();
    }

    this.clipboard.copy(text);
  }

  updateCopy() {
    if (this.isGroup) {
      this.messageGroup()
    }
    this.copyMessage();
  }

  close() {
    this.activeModal.close();
  }

  onSubmit() {
    if (this.route?.driver?.telegramUserId) {
      const messages = this.generateMessage('telegram')

      this.api.StgsTlgSendMessage({ driverIds: [this.route.driver?.driverId], messages: messages }, () => {
        this.helperService.successMessage("The messages has been sent.");
      }, error => {
        this.helperService.errorMessage(error != null ? error : 'An error has ocurred', 'Error sending to driver');
      });
    } else {
      this.helperService.errorMessage('Missing driver\'s telegram user id', 'Error sending to driver');
    }

    this.close();
    this.helperService.successMessage("Stop details has been successfully copied.");
  }


  getRouteItemGroups(request) {

    if (!request?.routeId) {
      return
    }

    this.routesService.getRouteItemGroups(request).then((resp) => {
      if (resp?.page > 1) {
        this.routeStopGroups = this.routeStopGroups.concat(resp.list)
      } else {
        this.routeStopGroups = resp.list || [];
      }      
      const dataCount = this.routeStopGroups?.map(x => x.routeItemStops?.length ?? 0).reduce((a, b) => a + b, 0);
      this.hasMorePage = dataCount < resp?.totalCount     
      this.parseMessages(); 
    }).catch((error) => {
      this.routeStopGroups = []
      this.helperService.errorMessage(error)
    }).finally(() => {
      this.loadMoreLoading = false
      this.isLoading = false;
    })
  }

  loadMoreRouteGroups() {    
        this.loadMoreLoading = true
        this.routeStopGroupsSearch.Page++;
        this.loadRouteGroup$.next(this.routeStopGroupsSearch);    
  }



}
