import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-driver-app',
  templateUrl: './driver-app.component.html',
  styleUrls: ['./driver-app.component.scss']
})
export class DriverAppComponent implements OnInit {

  title = "Driver App"
  startOfShift = [
    'Enter License plate #',
    'Take a photo of front of vehicle',
    'Take a photo of back of vehicle',
    'Test headlights',
    'Test turn signal',
    'Test brake lights',
    'Check for fluid leaks',
    'Test horn',
    'Signature',
  ];

  pickup = [
    'Scan Items',
    'Enter item # manually',
    'Count item contents',
    'Take a photo of goods',
  ];

  dropOff = [
    'Take a photo of goods',
  ];

  endOfShift = [
    'Signature',
    'Take a photo of front of vehicle',
    'Take a photo of back of vehicle',
    'Take a photo of inside of vehicle/ cab',
    'Signature',
  ];


  activeTab = "Flow"

  constructor() { }

  ngOnInit(): void {
  }

  dropStartOfShift(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.startOfShift, event.previousIndex, event.currentIndex);
  }

}
