import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListTabsComponent } from './list-tabs.component';
import { NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { LoadingModule } from '@app/shared/modules/loading/loading.module';
import { NgxSmoothDnDModule } from '../ngx-smooth-dnd';
import { DragDropModule } from '@angular/cdk/drag-drop';

@NgModule({
    imports: [
        CommonModule,
        NgbNavModule,
        LoadingModule,
        NgxSmoothDnDModule,
        DragDropModule
    ],
    declarations: [
        ListTabsComponent,
    ],
    exports: [
        ListTabsComponent,
    ],
})
export class ListTabsModule {
}
