import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { DatatableComponent } from '@app/@shared/datatable';
import { ApiPaginationData } from '@app/models/core/base';
import { Customer } from '@app/models/customer.model';
import { CreditInquiriesService } from '@app/shared/services/credit-inquiries.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { object, string, number } from 'yup';


const doughnutOptions = {
  responsive: true,
  maintainAspectRatio: false,
  cutout: '84%',
  hover: { mode: null },
  plugins: { tooltip: { enabled: false} },
  elements: {
    arc: { borderWidth: 0 },
  },
};

@UntilDestroy()
@Component({
  selector: 'app-credit',
  templateUrl: './credit.component.html',
  styleUrls: ['./credit.component.scss']
})
export class CreditComponent implements OnInit {


  charts = {
    limit: {
      data: {
        datasets: [{
          data: [100000, 40000],
          backgroundColor: ['rgb(26, 206, 179)', 'rgba(0,0,0,0.05)']
        }],
      },
      options: doughnutOptions,
    },
    spent: {
      data: {
        datasets: [{
          data: [80000, 40000],
          backgroundColor: ['rgb(90, 81, 222)', 'rgba(0,0,0,0.05)']
        }],
      },

      options: doughnutOptions,
    },
    available: {
      data: {
        datasets: [{
          data: [60000, 40000],
          backgroundColor: ['rgb(90, 81, 222)', 'rgba(0,0,0,0.05)']
        }],
      },
      options: doughnutOptions,
    },
  };

  doughnutChart = {
    data: [3506, 6494],
    colors: [{
      backgroundColor: ['rgba(29, 161, 242, 1)', 'rgba(201, 232, 252, 1)']
    }],
    options: doughnutOptions,
  };

  barChartData = {
    data: [1000, 155, 360, 500, 100, 588, 52, 400],
    labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug'],
    colors: [{
      backgroundColor: '#00BAD7'
    }],
    options: {
      scales: {
        xAxes: [{
          gridLines: {
            display: false
          }
        }],
        yAxes: [{
          gridLines: {
            display: false
          },
          ticks: {
            steps: 100,
            stepValue: 100,
            min: 0
          }
        }]
      }
    },
  };

  @ViewChild("datatable") datatable: DatatableComponent;
  private _customer: Customer;
  creditInquiriesAll: any;
  @Input()
  public get customer(): Customer {
    return this._customer;
  }
  public set customer(value: Customer) {
    this._customer = value;
  }
  credit: any = {
    amount: null,
    creditDays: 1,
    backupPaymentType: 0,
    notes: '',
  }
  discount: any = {
    amount: 1,
    notes: '',
  }
  validationSchema = object().shape({
    amount: number().nullable().positive().moreThan(0).required(),
    creditDays: number().nullable().positive().moreThan(0).required(),
    backupPaymentType: string().nullable().required(),
    notes: string().nullable(),
  });

  creditInquiries: ApiPaginationData<any>;
  creditInquiriesRequest: any = {
    ItemsPerPage: 10,
    Page: 1
  };
  creditInquiries$: Subject<any> = new Subject();

  constructor(
    private creditInquiriesService: CreditInquiriesService,
  ) { }

  ngOnInit(): void {

    this.creditInquiries$
      .pipe(
        debounceTime(500),
        untilDestroyed(this)
      ).subscribe(async (request: any) => {
        if (this.customer?.customerId) {
          request = Object.assign({}, this.creditInquiriesRequest, request)
          request.customerId = this.customer?.customerId
          this.creditInquiries = await this.creditInquiriesService.getAll(request)
          this.creditInquiriesAll = this.creditInquiries?.list
        } else {
          this.creditInquiries = null
          this.creditInquiriesAll = null
        }
      });

    this.creditInquiries$.next(true)

  }

  incrementCreditDays() {
    if (!this.credit.creditDays) {
      this.credit.creditDays = 1;
    } else {
      this.credit.creditDays++;
    }
  }

  decrementCreditDays() {
    if (!this.credit.creditDays) {
      this.credit.creditDays = 1;
    } else {
      this.credit.creditDays = Math.max(1, this.credit.creditDays - 1);
    }
  }

  incrementDiscount() {
    if (!this.discount.amount) {
      this.discount.amount = 1;
    } else {
      this.discount.amount++;
    }
  }

  decrementDiscount() {
    if (!this.discount.amount) {
      this.discount.amount = 1;
    } else {
      this.discount.amount = Math.max(1, this.discount.amount - 1);
    }
  }

  onSubmit(f) {
    if (f.isValid && this.customer?.customerId) {
      this.credit.customerId = this.customer?.customerId
      this.creditInquiriesService.create(this.credit).then((res) => {
        this.credit = {
          amount: 1,
          creditDays: 1,
          backupPaymentType: 0,
          notes: '',
        }
        this.creditInquiries$.next(true)
      })
    }
  }



}
