// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host .form-check-input {
  cursor: pointer;
}
:host .broker-account-checkbox {
  margin-top: 38px;
}
:host .email-type-select {
  max-width: 140px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/main/outsource-company/outsource-company-details/outsource-company-information/outsource-company-information.component.scss"],"names":[],"mappings":"AAEI;EACI,eAAA;AADR;AAII;EACI,gBAAA;AAFR;AAII;EACI,gBAAA;AAFR","sourcesContent":["@import \"var\";\n:host {\n    .form-check-input {\n        cursor: pointer;\n    }\n\n    .broker-account-checkbox{\n        margin-top: 38px;\n    }\n    .email-type-select{\n        max-width: 140px;\n    }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
