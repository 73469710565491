export class DatatableRequest {
    page = 1;
    limit = 10;
    filter: any = {};
    order: any = '';
    // tslint:disable-next-line: variable-name
    order_by: any = '';
}

export interface DatatableInterface {
    request: DatatableRequest;
}

export interface DatatableInterface {
    field?: string;
    dataType?: string;
    header?: string;
    column?: string;
}

export interface RequestData {
    limit?: number;
    page?: number;
    filterIn?: {
        name?: string;
        type?: string;
    }[],
    filterValue?: string;
    order?: {
        direction?: 'asc' | 'desc';
        name?: string;
        type?: 'number' | 'date' | 'string'
    }
}