import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CreateOutsourceCompanyComponent } from './create-outsource-company/create-outsource-company.component';
import { TypeOfTruckListComponent } from './type-of-trucks/type-of-truck-list/type-of-truck-list.component';
import { TabRedirectGuard } from '@app/@shared/list-tabs/tab-redirect-guard';
import { OutsourceCompanyLayoutComponent } from './outsource-company-layout/outsource-company-layout.component';

const routes: Routes = [
  {
    path: 'type-of-trucks',
    component: TypeOfTruckListComponent,
  },
  {
    path: '',
    component: OutsourceCompanyLayoutComponent,
  },
  {
    path: 'add',
    component: CreateOutsourceCompanyComponent,
  },
  {
    path: 'edit/:id',
    data: {
      redirectTo: `/app/customers`,
      paramsKey: 'id',
      queryParamsKey: 'id'
    },
    canActivate: [TabRedirectGuard],
    component: OutsourceCompanyLayoutComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class OutsourceCompanyRoutingModule { }
