import { Component, OnInit } from '@angular/core';
import { User } from '@app/models/user.model';
import { AuthService } from '@app/shared/services/auth/auth.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-plan-layout',
  templateUrl: './plan-layout.component.html',
  styleUrls: ['./plan-layout.component.scss']
})
export class PlanLayoutComponent implements OnInit {


  user: User;

  constructor(private authService: AuthService) { }

  ngOnInit() {
    this.authService.$user
      .pipe(untilDestroyed(this))
      .subscribe(user => {
        this.user = user;
      });
  }

  logout() {
    this.authService.logout();
  }

}
