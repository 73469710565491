import { AfterViewInit, Directive, Host, Input, Optional } from '@angular/core';
import { NgForm } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { get, set } from 'lodash';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { ValidationError } from 'yup';

@UntilDestroy()
@Directive({
  selector: '[appFormValidate]',
  exportAs: 'appFormValidate'
})
export class FormValidateDirective implements AfterViewInit {

  @Input() validationSchema
  errors: any;
  values: any;
  isValid: boolean;

  errors$ = new Subject<any>();


  constructor(
    @Optional()
    @Host()
    public ngForm: NgForm
  ) { }

  ngAfterViewInit(): void {
    this.ngForm.valueChanges
      .pipe(
        untilDestroyed(this),
        debounceTime(10)
      )
      .subscribe((value) => {
        if (this.validationSchema) {
          const parsedValue = this.parseFormKeys(value)
          this.values = parsedValue;
          this.validate(parsedValue);
        }
      })
  }

  getControlErrors(name): string[] {
    return get(this.errors, name, [])
  }


  private async validate(value) {
    await this.validationSchema.validate(
      value,
      { abortEarly: false },
    ).then(() => {
      this.isValid = true
      this.errors = null
      this.errors$.next(null)
    }).catch((err) => {
      this.isValid = false
      this.errors = this.yupErrorToErrorObject(err)
      this.errors$.next(this.errors)
    });
  }

  private yupErrorToErrorObject(err: ValidationError, isFlatObject = false) {

    const object: any = {};
    err.inner.forEach((x) => {
      if (x.path !== undefined) {
        if (isFlatObject) {
          object[x.path] = x.errors
        } else {
          set(object, x.path, x.errors)
        }
      }
    });
    return object;
  }

  private parseFormKeys(values: any) {
    const object: any = {};
    for (const key in values) {
      if (Object.prototype.hasOwnProperty.call(values, key)) {
        const value = values[key];
        set(object, key, value)
      }
    }
    return object;
  }

}
