// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-routes-trimble-map {
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  height: 300px;
  display: block;
}

.map-filters {
  padding: 4px 6px 4px 10px;
  font-size: 16px;
  color: var(--secondary);
  position: relative;
  height: 58px;
  display: inline-block;
  vertical-align: middle;
}

.map-legend {
  background-color: white;
  border: 1px solid black;
  padding: 3px;
  margin-bottom: 5px;
  position: absolute;
  display: contents;
}

.map-container {
  margin: 15px 0px 15px 2px;
}

.assign-button {
  margin-top: -28px;
  margin-left: 5px;
  margin-right: 5px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/main/routes/routes-trimble-map/routes-trimble-map.component.scss"],"names":[],"mappings":"AAAA;EACI,SAAA;EACA,MAAA;EACA,OAAA;EACA,QAAA;EACA,aAAA;EACA,cAAA;AACJ;;AAEA;EACI,yBAAA;EACA,eAAA;EACA,uBAAA;EACA,kBAAA;EACA,YAAA;EACA,qBAAA;EACA,sBAAA;AACJ;;AAEA;EACI,uBAAA;EACA,uBAAA;EACA,YAAA;EACA,kBAAA;EACA,kBAAA;EACA,iBAAA;AACJ;;AAEA;EACI,yBAAA;AACJ;;AAEA;EACI,iBAAA;EACA,gBAAA;EACA,iBAAA;AACJ","sourcesContent":[".app-routes-trimble-map {\n    bottom: 0;\n    top: 0;\n    left: 0;\n    right: 0;\n    height: 300px;\n    display: block;\n}\n\n.map-filters {\n    padding: 4px 6px 4px 10px;\n    font-size: 16px;\n    color: var(--secondary);\n    position: relative;\n    height: 58px;\n    display: inline-block;\n    vertical-align: middle;\n}\n\n.map-legend {\n    background-color: white;\n    border: 1px solid black;\n    padding: 3px;\n    margin-bottom: 5px;\n    position: absolute;\n    display: contents;\n}\n\n.map-container {\n    margin: 15px 0px 15px 2px;\n}\n\n.assign-button {\n    margin-top: -28px;\n    margin-left: 5px;\n    margin-right: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
