export interface IPaymentType { 
    name?: string;
    type?: PaymentTypeEnum;
    logo?: string;
    isActive?: boolean;
}

export interface ICardType { 
    name?: string;
    type?: CreditCardTypeEnum;
    logo?: string;
}

export enum PaymentTypeEnum {
    CREDIT_CARD = 0,
    ACH = 1,
    PAYPAL = 2,
    CASH = 3,
}

export enum CreditCardTypeEnum {
    VISA = 0,
    AMERICAN_EXPRESS = 1,
    MASTER = 2,
    DISCOVER = 3,
    GIFT_CARD = 4,
}
