import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouteMapComponent } from './route-map.component';
import { AgmCoreModule, GoogleMapsScriptProtocol } from '@agm/core';
import { environment } from '@environments/environment';
import { AgmSnazzyInfoWindowModule } from '@agm/snazzy-info-window';

@NgModule({
  declarations: [
    RouteMapComponent
  ],
  exports: [
    RouteMapComponent
  ],
  imports: [
    CommonModule,
    AgmSnazzyInfoWindowModule,
    AgmCoreModule.forRoot({
      apiKey: environment.googleKey,
      libraries: ['map'],
      language: 'en',
      protocol: GoogleMapsScriptProtocol.HTTPS,
    }),
  ]
})
export class RouteMapModule { }
