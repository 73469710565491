import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RoleGuard } from '@app/guards/role.guard';
import { QuickbooksAuthorizationComponent } from './quickbooks-authorization/quickbooks-authorization.component';
import { SettingsComponent } from './settings.component';

const routes: Routes = [
  {

    path: '',
    component: SettingsComponent,
    canActivate: [RoleGuard],
    data: {
      expectedRole: 'admin'
    },
    children: [
      {
        path: '',
        redirectTo: 'profile',
        pathMatch: 'full'
      },
      { path: 'profile', loadChildren: () => import('./profile/profile.module').then(m => m.ProfileModule) },
      { path: 'users', loadChildren: () => import('./users/users.module').then(m => m.UsersModule) },
      { path: 'fleet', loadChildren: () => import('./trucks/trucks.module').then(m => m.TrucksModule) },
      { path: 'locations', loadChildren: () => import('./locations/locations.module').then(m => m.LocationsModule) },
      { path: 'terms-conditions', loadChildren: () => import('./terms-conditions/terms-conditions.module').then(m => m.TermsConditionsModule) },
      { path: 'shipment-types', loadChildren: () => import('./shipment-types/shipment-types.module').then(m => m.ShipmentTypesModule) },
      { path: 'charge-types', loadChildren: () => import('./charge-types/charge-types.module').then(m => m.ChargeTypesModule) },
      { path: 'zones', loadChildren: () => import('./zones/zones.module').then(m => m.ZonesModule) },
      { path: 'expenses', loadChildren: () => import('./expenses/expenses.module').then(m => m.ExpensesModule) },
      { path: 'drivers-settings', loadChildren: () => import('./drivers-settings/drivers-settings.module').then(m => m.DriversSettingsModule) },
      { path: 'billing', loadChildren: () => import('./billing/billing.module').then(m => m.BillingModule) },
      { path: 'integrations', loadChildren: () => import('./integrations/integrations.module').then(m => m.IntegrationsModule) },
      { path: 'driver-app', loadChildren: () => import('./driver-app/driver-app.module').then(m => m.DriverAppModule) },
      { path: 'activity-log', loadChildren: () => import('./activity-log/activity-log.module').then(m => m.ActivityLogModule) },
      { path: 'quickbooksauthorization', component: QuickbooksAuthorizationComponent }

    ]
  }];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SettingsRoutingModule { }
