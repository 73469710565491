import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormValidateDirective } from './form-validate.directive';
import { FormInputErrorComponent } from './form-input-error/form-input-error.component';



@NgModule({
  declarations: [
    FormValidateDirective,
    FormInputErrorComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    FormValidateDirective,
    FormInputErrorComponent
  ]
})
export class FormValidateModule { }
