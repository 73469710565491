import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Components } from './components';
import { Pipes } from './pipes';
import { RouterModule } from '@angular/router';
import { Directives } from './diractive';
import { Services } from './services';
import { LoadingModule } from './modules/loading/loading.module';
import { HttpClientModule } from '@angular/common/http';
import { NgbModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PipesModule } from '@app/shared/components/auxiliary/pipes';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { AddressLookupModule } from '@app/@shared/address-lookup/address-lookup.module';
import { ComingSoonComponent } from './components/coming-soon/coming-soon.component';
import { ComingSoonDirective } from './components/coming-soon/coming-soon.directive';
import { CalendarModule } from 'angular-calendar';

const Modules = [
  RouterModule,
  HttpClientModule,
  LoadingModule,
  NgbModule,
  FormsModule,
  ReactiveFormsModule,
  PipesModule,
  NgxDropzoneModule,
  AddressLookupModule,
  CalendarModule,
];

@NgModule({
  imports: [
    CommonModule,
    NgbTooltipModule,
    ...Modules
  ],
  declarations: [
    ...Components,
    ...Directives,
    ...Pipes,

    ComingSoonDirective,
    ComingSoonComponent,

  ],
  exports: [
    ...Modules,
    ...Components,
    ...Directives,
    ...Pipes,


    ComingSoonDirective,
    ComingSoonComponent,
  ]
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [
        ...Services,
      ]
    };
  }
}
