import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@app/shared/shared.module';
import { FormsModule } from '@angular/forms';
import { EnumFlagsDropDownSelectComponent } from './enum-flags-drop-down-select.component';


@NgModule({
  declarations: [
    EnumFlagsDropDownSelectComponent
  ],
  exports: [
    EnumFlagsDropDownSelectComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule
  ]
})
export class EnumFlagsDropDownSelectModule { }
