import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { openOrders } from "@app/data/order";
import { cloneDeep, keyBy, map, sumBy } from "lodash";
import { ActivityLogService } from "@app/shared/services/activity-log.service";
import { ActivityLog } from "@app/models/activity-log.model";
import { CustomerService } from "@app/shared/services/customer.service";
import { Customer } from "@app/models/customer.model";
import { BehaviorSubject, Subject } from "rxjs";
import { debounceTime, tap } from "rxjs/operators";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Routes } from "@app/models/route.model";
import * as moment from 'moment';
import { RoutesService } from "@app/shared/services/router.service";
import { OrderService } from "@app/shared/services/order.service";
import { DriverService } from "@app/shared/services/driver.service";
import { AccountingService } from "@app/services/accounting.service";
import { DriverStatusEnum } from "@app/models/driver.model";

@UntilDestroy()
@Component({
    selector: "app-dashboard",
    templateUrl: "./dashboard.component.html",
    styleUrls: ["./dashboard.component.scss"],
})
export class DashboardComponent implements OnInit, AfterViewInit {
    @ViewChild("myCanvas") canvas: ElementRef;
    company: any = {};
    customerTab: string = "activeCustomers";
    driverTab: any = "drivers";
    lineChartColors: any

    searchingActiveUsers: boolean = true
    searchingCustomers: boolean = true
    searchingDrivers: boolean = true

    barChartData = {
        data:{
            labels: [
                "Sunday",
                "Monday",
                "Tuesday",
                "Wednesday",
                "Thursday",
                "Friday",
            ],
            datasets: [
                { data: [60, 40, 65, 45, 75, 60], backgroundColor: 'rgba(255,0,0,0.3)' }
            ]
        },
        options: {
            scales: {
                xAxes: [
                    {
                        gridLines: {
                            display: false,
                        },
                    },
                ],
                yAxes: [
                    {
                        gridLines: {
                            display: false,
                        },
                        ticks: {
                            steps: 10,
                            stepValue: 10,
                            min: 0,
                        },
                    },
                ],
            },
        },
    };

    totalOpenOrdersCount = 0;
    openOrdersChartCounts = openOrders;
    doughnutChart: any;
    lineChartData: any[];

    // Invoice doughnut chart configuration
    invoiceStatsChart: any = {
        data: [],
        options: {
            responsive: true,
            maintainAspectRatio: false,
            cutout: '84%',
            hover: { mode: null },
            plugins: { tooltip: { enabled: false} },
            elements: {
                arc: { borderWidth: 0 },
            },
        },
    };

    constructor(
        private activityLogService: ActivityLogService,
        private customerService: CustomerService,
        public routesService: RoutesService,
        public orderService: OrderService,
        public driverService: DriverService,
        public accountingService: AccountingService,
    ) {
        this.doughnutChart = {
            data: [{
                data: map(this.openOrdersChartCounts, "count"),
                backgroundColor: map(this.openOrdersChartCounts, "color"),
                borderWidth: 3
            }],
            options: {
                responsive: true,
                maintainAspectRatio: false,
                cutout: '84%',
                hover: { mode: null },
                plugins: { tooltip: { enabled: false} },
                elements: {
                    arc: { borderWidth: 0 },
                },
            },
        }
    }

    activeUsers: ActivityLog[];

    customers: Customer[];
    customersRequest: any = {
        Page: 1,
        ItemsPerPage: 20
    };
    customers$: Subject<any> = new Subject();

    drivers: Routes[];
    driversRequest: any = {
        Page: 1,
        ItemsPerPage: 20
    };
    drivers$: Subject<any> = new Subject();

    /**
     * Driver counts
     */
    driverCounts$ = new BehaviorSubject({
        drivers: 0,
        routes: 0
    });

    /**
     * Customer counts
     */
    customerCounts$ = new BehaviorSubject({
        active: 0,
        total: 0
    });

    /** Invoice stats */
    invoiceStatistics$ = new BehaviorSubject({})

    ngOnInit() {
        this.getLineChartData();
        this.getOpenOrderStats();
        this.getInvoiceStats();
        this.activityLogService.getAll({
            Page: 1,
            ItemsPerPage: 20
        }).then((res) => {
            this.activeUsers = res?.list
            this.searchingActiveUsers = false
        }).catch((error) => {
            this.searchingActiveUsers = false
        })

        this.customers$
            .pipe(
                tap(() => this.searchingCustomers = true),
                tap(() => this.customers = []),
                debounceTime(300),
                untilDestroyed(this)
            ).subscribe(async (request: any) => {
                // Active customers
                const activeCustomers = await this.customerService.getAll(Object.assign({ activeCustomers: true }, this.customersRequest));

                // On route drivers
                const totalCustomers = await this.customerService.getAll(Object.assign({ activeCustomers: false }, this.customersRequest));

                /** Dashboard total/active customers counts */
                this.customerCounts$.next({
                    active: activeCustomers?.totalCount,
                    total: totalCustomers?.totalCount,
                });

                switch (this.customerTab) {
                    case 'activeCustomers':
                        this.customers = activeCustomers?.list || [];
                        break;
                    default:
                        this.customers = totalCustomers?.list || [];
                        break;
                }
                this.searchingCustomers = false;
            });
        this.customers$.next(true);

        this.drivers$
            .pipe(
                tap(() => this.searchingDrivers = true),
                tap(() => this.drivers = []),
                debounceTime(300),
                untilDestroyed(this)
            ).subscribe(async (request: any) => {

                // Available drivers
                const availableDrivers = await this.driverService.getAll(Object.assign({ driversOnRoute: false, DriverStatus: DriverStatusEnum.ACTIVE }, this.driversRequest));

                // On route drivers
                const routeDrivers = await this.driverService.getAll(Object.assign({ driversOnRoute: true, DriverStatus: DriverStatusEnum.ACTIVE }, this.driversRequest));

                /** Dashboard available/route driver counts */
                this.driverCounts$.next({
                    drivers: availableDrivers?.totalCount,
                    routes: routeDrivers?.totalCount
                });

                switch (this.driverTab) {
                    case 'drivers':
                        this.drivers = availableDrivers?.list || [];
                        break;
                    default:
                        this.drivers = routeDrivers?.list || [];
                        break;
                }
                this.searchingDrivers = false;
            });
        this.drivers$.next(true);
    }


    ngAfterViewInit() {
        let gradient = this.canvas.nativeElement.getContext('2d').createLinearGradient(0, 0, 0, 300);
        gradient.addColorStop(0, 'rgba(76, 183, 88, 0.3)');
        gradient.addColorStop(1, 'rgba(0, 186, 215, 0.3)');
         this.barChartData.data.datasets[0].backgroundColor = gradient
    }

    /**
     * Get daily orders statistic for chart UI
     *
     */
    async getLineChartData() {
        let days = [];
        for (let i = 0; i < 7; i++) {
            days.push({ day: moment().subtract(i, 'd').format('dddd'), count: 0 });
        }
        try {
            const orders = await this.orderService.getOrderStatistic();
            const dayByKeys = keyBy(orders, (element) => {
                return moment(element.date).format('dddd')
            })
            this.lineChartData = days.map((i: any) => {
                i.count = dayByKeys[i.day]?.count || 0
                return i;
            });
            this.barChartData.data.datasets[0].data = map(this.lineChartData, 'count').reverse();
            this.barChartData.data.labels = map(this.lineChartData, 'day').reverse();
            this.barChartData = cloneDeep(this.barChartData)
        } catch (error) {
            console.log('Error while getting daily orders statistic', error);
        }
    }

    /**
     * Get open order statistic for charts UI
     */
    async getOpenOrderStats() {
        try {
            // get open order statistics
            const openOrders = await this.orderService.getStats({ openOrders: true });
            this.openOrdersChartCounts = this.openOrdersChartCounts?.map((data) => {
                data.count = openOrders[data.type]
                return data
            });
            this.totalOpenOrdersCount = sumBy(this.openOrdersChartCounts, "count");
            if (this.totalOpenOrdersCount > 0) {
                this.doughnutChart.data[0].data = map(this.openOrdersChartCounts, "count");
                this.doughnutChart = cloneDeep(this.doughnutChart)
            }
        } catch (error) {
            console.log('Error while getting open orders statistic', error);
        }
    }

    /**
     * Get invoice statistic for charts UI
     */
    async getInvoiceStats() {
        try {
            const { total, paid, dueInNext30Days, outstanding } = (await this.accountingService.getInvoiceStats()).data;
            this.invoiceStatistics$.next({
                total,
                paid,
                dueInNext30Days,
                outstanding
            });

            // Invoice stats chart UI dataset
            this.invoiceStatsChart.data[0] = {
                data: [
                    paid,
                    dueInNext30Days,
                    outstanding
                ],
                backgroundColor: ['#4CB75C', '#FFCE1F', '#E38228'],
                borderWidth: 3,
            }
            this.invoiceStatsChart = cloneDeep(this.invoiceStatsChart)
        } catch (error) {
            console.log('Error while getting invoice statistic', error);
        }
    }

    customerFilter(value) {
        this.customerTab = value
        if (value == 'activeCustomers') {
            this.customersRequest.status = 0
        } else {
            delete this.customersRequest.status
        }
        this.customers$.next(true);
    }

    driverFilter(value) {
        this.driverTab = value
        this.drivers$.next(true);
    }
}
