import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CrudService } from './core/crud.service';
import { ApiResponse } from '@app/models/core/base';
import { ValidateOutsourceCompany } from '@app/models/outsource-company.model';
import { toParams } from 'src/utils/to-params';


@Injectable({
    providedIn: 'root'
})
export class OutsourceCompanyService extends CrudService<any>{

    override basePath = 'OutsourceCompanies';

    constructor(protected http: HttpClient) {
        super(http);
    }

    bulkDelete(ids: number[] | string[] | any) {
        return this.http.delete(`${this.basePath}`, { params: toParams({ ids }) }).toPromise()
    }

    validateOutsourceCompany(request?: any) {
        return this.http.post<ApiResponse<ValidateOutsourceCompany>>(`${this.basePath}/ValidateOutsourceCompany`, request).toPromise()
            .then((({ data }) => data));
    }

    createContact(id, request?: any) {
        return this.http.post<ApiResponse<any>>(`${this.basePath}/${id}/CreateContact`, request).toPromise()
            .then((({ data }) => data));
    }

    updateContact(id: string | number, request?: any) {
        return this.http.put<ApiResponse<any>>(`${this.basePath}/${id}/UpdateContact`, request).toPromise()
            .then((({ data }) => data));
    }

}
