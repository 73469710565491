import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DateTimeInputComponent } from './date-time-input/date-time-input.component';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { SharedModule } from '@app/shared/shared.module';


@NgModule({
  declarations: [
    DateTimeInputComponent,
  ],
  exports: [
    DateTimeInputComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMaterialTimepickerModule,
    BsDatepickerModule
  ]
})
export class DateTimeInputModule { }
