import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CrudService } from './core/crud.service';
import { TypeOfTruck } from '@app/models/type-of-truck.model';


@Injectable({
    providedIn: 'root'
})
export class TypeOfTrucksService extends CrudService<TypeOfTruck>{

    override basePath = 'TypeOfTrucks';

    constructor(protected http: HttpClient) {
        super(http);
    }

}
