import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ApiPaginationData } from '@app/models/core/base';
import { Customer } from '@app/models/customer.model';
import { AccountingService } from '@app/shared/services/accounting.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { concat, Subject } from 'rxjs';
import { debounceTime, take } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'app-customer-list',
  templateUrl: './customer-list.component.html',
  styleUrls: ['./customer-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomerListComponent implements OnInit {

  customers: ApiPaginationData<Customer>;
  customerRequest = {
    Page: 1,
    ItemsPerPage: 20,
    SearchTerm: ''
  };
  customers$: Subject<any> = new Subject();

  selectedCustomer: Customer;
  @Output('onSelect') onSelect = new EventEmitter<any>()

  constructor(
    private accountingService: AccountingService,
  ) { }

  ngOnInit(): void {

    this.accountingService.$selectedCustomer.pipe(untilDestroyed(this)).subscribe(
      (res: any) => {
        this.selectedCustomer = res
      }
    )

    const debounceAllButFirst$ = concat(
      this.customers$.pipe(take(1)),
      this.customers$.pipe(debounceTime(500))
    );

    debounceAllButFirst$
      .pipe(
        untilDestroyed(this)
      ).subscribe(async (request: any) => {
        request = Object.assign({}, this.customerRequest, request)
        this.customers = await this.accountingService.getAccountingCustomerBasicDetails(request)
        this.selectedCustomer = this.selectedCustomer ?? this.customers.list[0];
        this.onSelect.emit(this.selectedCustomer)
      });

    this.customers$.next(true)

    this.accountingService.$searchTermCustomers.pipe(untilDestroyed(this)).subscribe(
      (res: any) => {
        this.customers$.next({ SearchTerm: res })
      }
    );
  }


  selectCustomer(customer) {
    this.selectedCustomer = customer;
    this.onSelect.emit(this.selectedCustomer);
  }

  searchCustomer() {
    this.customerRequest.Page = 1;
    this.customers$.next(true)
  }

  pageChanged(event) {
    this.customerRequest.Page = event;
    this.customers$.next(true)
  }


  refresh() {
    this.customers$.next(true)
  }

}
