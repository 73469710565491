import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'select-customer-names',
    templateUrl: './select-customer-name.component.html',
    styleUrls: ['./select-customer-name.component.scss'],
})
export class SelectCustomerNameComponent {
    selectedCustomerName: string = '';

    private _customersNames: string[] = [];
    get customersNames(): string[] {
        return this._customersNames;
    }

    @Input() set customersNames(value: string[]) {
        this._customersNames = value;
        if (value && value.length > 0) {
            this.selectedCustomerName = value[0];
        } else {
            this.selectedCustomerName = '';
        }
    }

    constructor(public ngbActiveModal: NgbActiveModal) { }

    selectCustomerName(name: string) {
        this.selectedCustomerName = name;
    }

    confirm() {
        this.ngbActiveModal.close(this.selectedCustomerName);
    }
}
