import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RepeaterModule } from '@app/@shared/repeater/repeater.module';
import { AttachmentsModule } from '@app/@shared/attachments/attachments.module';
import { QtySelectModule } from '@app/@shared/qty-select/qty-select.module';
import { SharedModule } from '@app/shared/shared.module';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { OrderStopItemModule } from '../order-stop-item/order-stop-item.module';
import { EditPickupDialogModule } from '../edit-pickup-dialog/edit-pickup-dialog.module';
import { EditPickupItemDialogComponent } from './edit-pickup-item-dialog/edit-pickup-item-dialog.component';
import { FileUploadModule } from '@app/@shared/file-upload/file-upload.module';
import { DialogsModule } from '@app/@shared/dialogs/dialogs.module';
import { FormValidateModule } from '@app/@shared/form-validate/form-validate.module';

@NgModule({
  declarations: [
    EditPickupItemDialogComponent,
  ],
  exports: [
    EditPickupItemDialogComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    RepeaterModule,
    SharedModule,
    AttachmentsModule,
    FileUploadModule,
    QtySelectModule,
    NgbDropdownModule,
    OrderStopItemModule,
    EditPickupDialogModule,
    DialogsModule,
    FormValidateModule
  ]
})
export class EditPickupItemDialogModule { }
