import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ApiResponse } from '@app/models/core/base';
import { DocumentViewMode } from '@app/models/document.model';
import { toParams } from 'src/utils/to-params';
import { DomSanitizer } from '@angular/platform-browser';
@Injectable({
    providedIn: 'root'
})
export class DocumentService {


    private basePath = 'Documents'

    constructor(
        protected http: HttpClient,
        private sanitizer: DomSanitizer
    ) {

    }

    private handleBlob(mode: DocumentViewMode, res) {
        if (mode === DocumentViewMode.Pdf) {
            return Promise.resolve(new Blob([res], { type: 'application/pdf' }));
        } else if (mode === DocumentViewMode.Html) {
            return res.text().then(text => {
                return Promise.resolve(this.sanitizer.bypassSecurityTrustHtml(text));
            });
        }
    }

    downloadBillOfLanding(id: string | number, mode: DocumentViewMode, request?: any) {
        return this.http.get(`${this.basePath}/BillOfLanding/${id}/${mode}`, {
            headers: new HttpHeaders({
                'Accept': 'text/html/pdf',
                'Content-Type': 'application/json',
            }),
            responseType: 'blob',
            params: toParams(request)
        }).toPromise().then((res) => {
            return this.handleBlob(mode, res);
        });
    }

    downloadLabels(id: string | number, mode: DocumentViewMode) {
        return this.http.get(`${this.basePath}/Labels/${id}/${mode}`, {
            headers: new HttpHeaders({
                'Accept': 'text/html/pdf',
                'Content-Type': 'application/json',
            }),
            responseType: 'blob'
        }).toPromise().then((res) => {
            return this.handleBlob(mode, res)
        });
    }

    downloadQuote(id: string | number, mode: DocumentViewMode) {
        return this.http.get(`${this.basePath}/Quote/${id}/${mode}`, {
            headers: new HttpHeaders({
                'Accept': 'text/html/pdf',
                'Content-Type': 'application/json',
            }),
            responseType: 'blob'
        }).toPromise().then(((res) => {
            return this.handleBlob(mode, res);
        }));
    }

    downloadDispatchSheet(id: string | number, mode: DocumentViewMode) {
        return this.http.get(`${this.basePath}/DispatchSheet/${id}/${mode}`, {
            headers: new HttpHeaders({
                'Accept': 'text/html/pdf',
                'Content-Type': 'application/json',
            }),
            responseType: 'blob'
        })
            .toPromise().then((res) => {
                return this.handleBlob(mode, res);
            });
    }

    downloadInvoice(id: string | number, mode: DocumentViewMode) {
        return this.http.get(`${this.basePath}/Invoice/${id}/${mode}`, {
            headers: new HttpHeaders({
                'Accept': 'text/html/pdf',
                'Content-Type': 'application/json',
            }),
            responseType: 'blob'
        })
            .toPromise().then((res) => {
                return this.handleBlob(mode, res);
            });
    }

    emailBillOfLanding(id: string | number, request?: any) {
        return this.http.put<ApiResponse<any>>(`${this.basePath}/BillOfLanding/email/${id}`, request).toPromise();
    }

    emailQuote(id: string | number, email: string) {
        return this.http.put<ApiResponse<any>>(`${this.basePath}/Quote/email/${id}`, { email }).toPromise();
    }

    emailInvoice(id: string | number, email: string) {
        return this.http.put<ApiResponse<any>>(`${this.basePath}/Invoice/email/${id}`, { email }).toPromise();
    }

    emailDispatchSheet(id: string | number, email: string) {
        return this.http.put<ApiResponse<any>>(`${this.basePath}/DispatchSheet/email/${id}`, { email }).toPromise();
    }




}
