import { Directive, EventEmitter, Host, HostListener, Input, Output } from '@angular/core';
import { DatatableComponent } from './datatable/datatable.component';

@Directive({
    selector: '[rowToggleHandle]',
})
export class RowToggleHandle {

    @Input() rowToggleHandle: any
    @Output() onHandleClick = new EventEmitter<any>();

    constructor(
        @Host() private datatable: DatatableComponent,
    ) {

    }

    @HostListener('click', ['$event'])
    onClick(event) {
        event.preventDefault();
        event.stopPropagation();
        this.datatable.toggleDetailsRow(this.rowToggleHandle)
    }

}
