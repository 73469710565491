import { Component, Input, OnInit } from '@angular/core';
import { OrderItemSizes, pickupTypes } from '@app/data/order';
import { EntityTypes } from '@app/models/entity-type.model';
import { OrderItemStop } from '@app/models/order-item-stop.model';
import { OrderStop } from '@app/models/order-stop.model';
import { Order } from '@app/models/order.model';
import { PickupType, PickupTypeEnum } from '@app/models/pickup-type';
import { HelperService } from '@app/shared/services/helper.service';
import { OrderService } from '@app/shared/services/order.service';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { cloneDeep, filter, find, findIndex, includes, map } from 'lodash';
import { EditPickupDialogComponent } from '../../edit-pickup-dialog/edit-pickup-dialog.component';
import { OrderItemPackedInEnum, WeightUnitOfMeasureEnum } from '@app/models/order-item.model';
import { number, object, string } from 'yup';
import { FormValidateDirective } from '@app/@shared/form-validate/form-validate.directive';

@Component({
	selector: 'app-edit-pickup-item-dialog',
	templateUrl: './edit-pickup-item-dialog.component.html',
	styleUrls: ['./edit-pickup-item-dialog.component.scss']
})
export class EditPickupItemDialogComponent implements OnInit {

	pickupTypes: PickupType[] = [];
	EntityTypes = EntityTypes;
	PickupTypeEnum = PickupTypeEnum;
	isEditOrderItemStop: boolean = false;

	validationSchema = object().shape({
		orderItemType: string().nullable(),
		itemName: string().nullable()
			.test('is-unique', 'Name already exist, please add another name', (value) => {
				const currentItemId = this.orderItemStop?.orderItem?.orderItemId || null;
				const isExisting = this?.orderStop?.orderItemStops?.some(stop => stop?.orderItem?.itemName === value && stop?.orderItem?.orderItemId !== currentItemId);
				return !isExisting;
			}).required(),
		quantity: number().nullable().required(),
		wight: number().nullable().positive('`Weight must be a positive number'),
		wightUnitOfMeasure: string().nullable(),
		sizeLength: number().when('orderItemType', {
			is: orderItemType => this.checkOrderItemTypeValidation(orderItemType),
			then: (schema) => schema.nullable().required(`Size length is a required field`).positive('`Size length must be a positive number').min(0)
		}),
		sizeWidth: number().when('orderItemType', {
			is: orderItemType => this.checkOrderItemTypeValidation(orderItemType),
			then: (schema) => schema.nullable().required(`Size weight is a required field`).positive('`Size weight must be a positive number').min(0)
		}),
		sizeHight: number().when('orderItemType', {
			is: orderItemType => this.checkOrderItemTypeValidation(orderItemType),
			then: (schema) => schema.nullable().required(`Size height is a required field`).positive('`Size height must be a positive number').min(0)
		}),
		sizeUnitOfMeasure: string().when('orderItemType', {
			is: orderItemType => this.checkOrderItemTypeValidation(orderItemType),
			then: (schema) => schema.nullable().required(`Size unit of measure is a required field`)
		}),
		sizeType: string().when('orderItemType', {
			is: (orderItemType) => (orderItemType === PickupTypeEnum.PALLETS || orderItemType === PickupTypeEnum.FULL_LOAD),
			then: (schema) => schema.nullable().required(`Type is a required field`)
		}),
		itemsPackedIn: string().when('orderItemType', {
			is: (orderItemType) => orderItemType === PickupTypeEnum.FULL_LOAD,
			then: (schema) => schema.nullable().required(`Items packed in is a required field`)
		}),
	});

	initialOrderStop: OrderStop;
	initialOrder: Order;
	initialOrderItemStop: OrderItemStop;
	/*
	* Getter & Setter for ORDER
	*/
	private _order: Order;

	get order(): Order {
		return this._order;
	}
	@Input() set order(value: Order) {
		this._order = value;
		this.initialOrder = value;
	}

	/*
	* Getter & Setter for ORDER STOP
	*/
	private _orderStop: OrderStop;
	get orderStop(): OrderStop {
		return this._orderStop;
	}
	@Input() set orderStop(value: OrderStop) {
		this._orderStop = value;
		this.initialOrderStop = value;
	}

	orderItemStop: OrderItemStop;

	// orderItemStop: OrderItemStop = {
	// 	orderItem: {
	// 		wight: 0,
	// 		wightUnitOfMeasure: '',
	// 		sizeLength: 0,
	// 		sizeWidth: 0,
	// 		sizeHight: 0,
	// 		sizeUnitOfMeasure: '',
	// 		sizeType: '',
	// 	}
	// };

	palletSize = []
	fullLoadSize = []
	packedInOptions = []

	constructor(
		private orderService: OrderService,
		private ngbModal: NgbModal,
		private activeModal: NgbActiveModal,
		private helperService: HelperService,
	) {
		this.pickupTypes = pickupTypes

		this.palletSize = filter(OrderItemSizes, { itemType: PickupTypeEnum.PALLETS })
		this.fullLoadSize = filter(OrderItemSizes, { itemType: PickupTypeEnum.FULL_LOAD })
		this.packedInOptions = [
			{ value: OrderItemPackedInEnum.BOX_AND_CRATES, label: 'Box & Crates' },
			{ value: OrderItemPackedInEnum.BOXES, label: 'Boxes' },
			{ value: OrderItemPackedInEnum.OTHERS, label: 'Others' },
		]
	}

	ngOnInit(): void {
		this.setEditItem();
	}

	checkOrderItemTypeValidation(orderItemType) {
		if (orderItemType === PickupTypeEnum.BOXES_CRATES || orderItemType === PickupTypeEnum.OTHER) {
			return true
		}
		return false
	}

	handleItemChange(): void {
		this.setEditItem();
	}

	async deleteItem(item) {
		const index = findIndex(this.orderStop.orderItemStops, { orderItemStopId: item.orderItemStopId })
		item.orderItem.isActive = false;
		item.isActive = false;

		this.orderStop.orderItemStops[index] = item

		await this.saveOrder();
		if (this.orderItemStop?.orderItemStopId === item.orderItemStopId || this.isEditOrderItemStop) {
			this.orderItemStop = {}
			this.setEditItem();
			this.isEditOrderItemStop = false;
		}
	}

	editItem(orderItemStop: OrderItemStop) {
		this.isEditOrderItemStop = true;
		this.orderItemStop = cloneDeep(orderItemStop);
		this.initialOrderItemStop = cloneDeep(orderItemStop);
		this.setEditItem();
	}

	setEditItem() {
		if (!this.orderItemStop?.orderItemStopId) {
			this.orderItemStop = {
				// orderItemStopId: uuid() as any,  // TO-DO: Remove when save api call
				isActive: true,
				quantity: 1,
				quantityTrucks: 0,
				orderItem: {
					itemName: 'Freight',
					orderItemType: PickupTypeEnum.BOXES_CRATES,
					attachments: [],
					quantityToPick: 1,
					totalQuantity: 1,
					quantityOfTrucks: 1,
					pickedQuantity: 0,
					sizeUnitOfMeasure: null,
					wightUnitOfMeasure: null,
					totalWeightUnitOfMeasure: null,
					itemsPackedIn: OrderItemPackedInEnum.BOX_AND_CRATES,					
				},
			}
		}
	}

	addEditOrderStop() {
		this.activeModal.close();
		const activeModal = this.ngbModal.open(EditPickupDialogComponent, {
			scrollable: true,
			size: "xl",
			windowClass: 'custom-style'
		});
		activeModal.componentInstance.order = this.order;
		activeModal.componentInstance.orderStop = cloneDeep(this.orderStop);
	}

	handlePickupTypeSelect(pickupType) {
		this.orderItemStop.orderItem.orderItemType = pickupType.value;
		this.orderItemStop.orderItem.sizeType = null
	}

	/**
	 * Save order and close the modal
	 */
	async saveAndClose(form: FormValidateDirective) {
		if (!form.isValid) {
			return false;
		}
		try {
			this.saveOrderStopItems();
			await this.saveOrder();
		} catch (error) {
			console.log('Error while create / update order stop items', error);
			this.helperService.errorMessage(error);
		} finally {
			this.activeModal.close();
		}
	}

	/**
	 * Save order and add stop items
	 * 
	 */
	async saveAndAdd(form: FormValidateDirective, saveType?: string) {
		if (!form.isValid) {
			return false;
		}
		try {
			this.saveOrderStopItems();
			const order = await this.saveOrder();
			if (order) {
				this.orderItemStop = {};
				this.setEditItem();
				if (saveType == 'edit') {
					this.isEditOrderItemStop = false
				}
			}
		} catch (error) {
			console.log('Error while create / update order stop items', error);
			this.helperService.errorMessage(error);
		}
	}

	close() {
		this.activeModal.close();
	}

	cancel() {
		this.isEditOrderItemStop = false;
		this.orderItemStop = {};
		this.setEditItem();
	}

	/**
	 * Order Stop Items
	 */
	saveOrderStopItems() {
		const orderItemStops = this.orderStop.orderItemStops || [];

		const pickedQuantity = this.orderService.getPickedQty(this.order, this.orderItemStop.orderItem.orderItemId);

		this.orderItemStop.qtyAssinedToTrip = this.orderItemStop.quantity,
			this.orderItemStop.orderItem.quantityToPick = this.orderItemStop.quantity - pickedQuantity;
		this.orderItemStop.orderItem.totalQuantity = this.orderItemStop.quantity;
		this.orderItemStop.orderItem.pickedQuantity = pickedQuantity;

		const orderItemStopIndex = findIndex(orderItemStops, { orderItemStopId: this.orderItemStop.orderItemStopId });

		if (orderItemStopIndex >= 0) {
			orderItemStops.splice(orderItemStopIndex, 1, this.orderItemStop)
		} else {
			orderItemStops.push(this.orderItemStop);
		}

		this.orderStop.orderItemStops = cloneDeep(orderItemStops);
	}

	/**
 * Save order
 */
	async saveOrder(): Promise<Order> {
		this.helperService.isLoading = true;

		try {
			const updatedOrder = cloneDeep(this.order);
			const orderStopId = this.orderStop?.orderStopId;

			let orderStops = updatedOrder?.orderStops;
			const index = findIndex(updatedOrder?.orderStops, { orderStopId: orderStopId });

			if (orderStops?.length > 0 && index >= 0) {
				orderStops.splice(index, 1, this.orderStop)
				updatedOrder.orderStops = orderStops
			}

			const order = await this.orderService.saveOrder(updatedOrder);
			if (order) {
				this.order = order;
				this.orderStop = find(this.order.orderStops, { orderStopId: orderStopId })
			}

			return order;
		} catch (error) {
			this.helperService.errorMessage(error);
		} finally {
			this.helperService.isLoading = false;

			// If API failed to save, then remove order stop items
			this.orderStop.orderItemStops = this.orderStop.orderItemStops.filter(
				(orderItemStop: OrderItemStop) => !!orderItemStop.orderItemStopId
			);
		}
	}
}
