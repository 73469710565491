import { BaseModel } from '@models/base.model';

export type CustomerEmailType = 'Accounting' | 'Billing';

export interface CustomerEmail extends BaseModel {
    customerEmailId?: number;
    customerId?: number;
    emailType?: CustomerEmailType;
    email?: string;
    isActive?: boolean;
}

export enum CustomerEmailEnum {
    ACCOUNTING = 'Accounting',
    BILLING = 'Billing',
}