import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ApiResponse, ListRequest } from '@app/models/core/base';
import { toParams } from 'src/utils/to-params';
import { RecurringBilling } from '@app/models/payment-method.model';
import { DocumentViewMode } from '@app/models/document.model';

@Injectable({ providedIn: 'root' })
export class RecurringBillingService {

    private basePath = 'RecurringBilling'

    constructor(
        private http: HttpClient,
    ) {
    }

    private handleBlob(mode: DocumentViewMode, res) {
        if (mode === DocumentViewMode.Pdf) {
            return Promise.resolve(new Blob([res], { type: 'application/pdf' }));
        } else if (mode === DocumentViewMode.Html) {
            return res.text();
        }
    }

    getAll(request?: ListRequest) {
        return this.http.get<ApiResponse<RecurringBilling[]>>(`${this.basePath}/History`, { params: toParams(request) }).toPromise()
            .then((({ data }) => data));
    }

    downloadAllPayments(mode: DocumentViewMode, request?: ListRequest) {
        return this.http.get(`${this.basePath}/DownloadPayments/${mode}`, {
            headers: new HttpHeaders({
                'Accept': 'text/html/pdf',
                'Content-Type': 'application/json',
            }),
            responseType: 'blob',
            params: request
        }).toPromise().then((res) => {
            return this.handleBlob(mode, res)
        });
    }

    downloadPayment(id: string, mode: DocumentViewMode, request?: ListRequest) {
        return this.http.get(`${this.basePath}/DownloadPayment/${id}`, {
            headers: new HttpHeaders({
                'Accept': 'text/html/pdf',
                'Content-Type': 'application/json',
            }),
            responseType: 'blob',
            params: request
        }).toPromise().then((res) => {
            return this.handleBlob(mode, res)
        });
    }

}
