import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-drop-down-select',
  templateUrl: './drop-down-select.component.html',
  styleUrls: ['./drop-down-select.component.scss']
})
export class DropDownSelectComponent {
  selectedOptionsNames: [] = [];
  @Input() bgColor: string;
  @Input() textColor: string;
  @Input() titleSize: 'body-2' | 'caption' | 'input' = 'caption';
  @Input() label: string;
  @Input() autoCloseDropDown: boolean | string = true;
  @Output() selectedOptions = new EventEmitter<any>();

  private _options: any;
  get options(): any {
    return this._options;
  }
  @Input() set options(values: any) {
    this._options = values
    this.selectedOptionsNames = values?.filter(item => item?.selected);
  }

  constructor() { }

  optionSelectionUpdated() {
    const selectedOptions = this.options?.filter(r => r?.selected);
    this.selectedOptionsNames = selectedOptions;
    this.selectedOptions.emit(selectedOptions)
  }

}
