import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DataApiResponse, handleApiError, ListResponse } from '@app/helpers/api';
import { environment } from '@environments/environment';
import { catchError, map } from 'rxjs/operators';
import { UtilService } from '@app/services/util.service';
import { ChargeType } from '@models/charge-type.model';
import { BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { ShipmentType } from '@models/shipment-type.model';
import { OrderItemStop } from '@models/order-item-stop.model';
import { toParams } from 'src/utils/to-params';
import { ApiResponse } from '@app/models/core/base';

@Injectable({ providedIn: 'root' })
export class AccountingService {
    constructor(
        private http: HttpClient,
        private util: UtilService,
    ) {
    }

    private perStopChargeTypesSubject: BehaviorSubject<ChargeType[]> = new BehaviorSubject<ChargeType[]>([]);

    get perStopChargeTypes$(): Observable<ChargeType[]> {
        return this.perStopChargeTypesSubject.asObservable();
    }

    getPerStopChargeTypes() {
        return this.http.get<DataApiResponse<ChargeType[]>>(
            `${environment.apiBaseUrl}Accounting/chargeTypes/perStop`,
        ).pipe(map(data => {
                this.perStopChargeTypesSubject.next(data.data);
                return data.data;
            }),
            catchError(handleApiError));
    }

    getChargeType(id: number) {
        return this.http.get<DataApiResponse<ChargeType>>(
            `${environment.apiBaseUrl}Accounting/chargeTypes/${id}`,
        ).pipe(map(data => {
                return data.data;
            }),
            catchError(handleApiError));
    }

    updateChargeType(id: number, chargeType: ChargeType) {
        return this.http.put<DataApiResponse<ChargeType>>(
            `${environment.apiBaseUrl}Accounting/chargeTypes/${id}`, chargeType
        ).pipe(map(data => {
                return data.data;
            }),
            catchError(handleApiError));
    }

    createChargeType(chargeType: ChargeType) {
        return this.http.post<DataApiResponse<ChargeType>>(
            `${environment.apiBaseUrl}Accounting/chargeTypes`, chargeType
        ).pipe(map(data => {
                return data.data;
            }),
            catchError(handleApiError));
    }

    deleteChargeType(id: number) {
        return this.http.delete<DataApiResponse<ChargeType>>(
            `${environment.apiBaseUrl}Accounting/chargeTypes/${id}`,
        ).pipe(map(data => {
                return data.data;
            }),
            catchError(handleApiError));
    }

    /**
     * Get dashboard invoice stats
     */
    getInvoiceStats(request?: Partial<any>) {
        return this.http.get<ApiResponse<any>>(`${environment.apiBaseUrl}Accounting/InvoiceStats`, { params: toParams(request) }).toPromise();
    }
}
