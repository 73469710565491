import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Address, AddressCompanyInfo } from '@app/models/address.model';
import { OrderStop, OrderStopDateTypeEnum, OrderStopTimeTypeEnum, OrderStopTypeEnum } from '@app/models/order-stop.model';
import { Order } from '@app/models/order.model';
import { HelperService } from '@app/shared/services/helper.service';
import { ImagesService } from '@app/shared/services/images.service';
import { OrderService } from '@app/shared/services/order.service';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { chain, cloneDeep, find, findIndex, includes, map, maxBy, merge, some } from 'lodash';
import * as yup from 'yup';
import { EditDeliveryItemDialogComponent } from '../edit-delivery-item-dialog/edit-delivery-item-dialog/edit-delivery-item-dialog.component';
import { EntityTypes } from '@app/models/entity-type.model';
import { EntityType } from '@app/models/entity-type.model';
import { patterns } from '@app/@shared/regex/regex-patterns.const';
import { Customer } from '@app/models/customer.model';
import { FormValidateDirective } from '@app/@shared/form-validate/form-validate.directive';
import moment = require('moment');
import { CustomerService } from '@app/shared/services/customer.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CustomerContactService } from '@app/shared/services/customer-contact.service';
import { parsePhoneNumberAndExtension } from '@app/@shared/utils';
import { DialogService } from '@app/@shared/dialogs/dialog.service';
import { SelectCustomerNameComponent } from '../select-customer-name/select-customer-name.component';
import { PhoneExtensionInputComponent } from '@app/@shared/phone-extension-input/phone-extension-input/phone-extension-input.component';

@UntilDestroy()
@Component({
	selector: 'app-edit-delivery-dialog',
	templateUrl: './edit-delivery-dialog.component.html',
	styleUrls: ['./edit-delivery-dialog.component.scss']
})
export class EditDeliveryDialogComponent implements OnInit {
	EntityTypes = EntityTypes;
	address: Address;
	attachments = [];
	uploading: boolean;
	businessHours?: boolean;
	companiesNames: { address: Address, companyName: string, customerId: number }[] = [];
	@Input() isDispatch: boolean = false;
	@ViewChild('locationContactPhoneExtensionInput', {static: false}) locationContactPhoneExtensionInput: PhoneExtensionInputComponent;

	defaultAddress = {
		fullAddress: null,
		name: null,
		addressLine2: null,
		zip: null
	}

	recentUsedAddresses = [];
	recentUsedCustomerContactId = null;

	validationSchema = yup.object().shape({
		address: yup.object().shape({
			name: yup.string().label('Company Name').nullable(),
			fullAddress: yup.string().label('Select Address').nullable().required("Select address is a required field")
		}),
		locationContactNumber: yup.string()
			.label('Company Phone')
			.matches(patterns.phoneWithExtension, { excludeEmptyString: true, message: 'Company phone must be a number' }),
		locationContactEmail: yup.string()
			.label('Email')
			.email(),
		referenceNumber: yup.string().nullable().max(50),
		locationContactNumberExtension: yup.string().nullable().max(10),
	}).test('contact-methods', 'At least one of Company Phone or Email is required', function (value) {
		const { locationContactNumber, locationContactEmail } = value;
		if (!locationContactNumber && !locationContactEmail) {
			return this.createError({
				path: 'locationContactNumber',
				message: 'At least one of Company Phone or Email is required'
			});
		}
		return true;
	});

	needForDeliveryOptions = [
		"Liftgate",
		"Electric Jack",
		"Hand Loaded",
		"Pickup Check",
		"Pickup COD",
		"Custom",
	]

	driverSettingsOptions = [
		"Scan Items",
		"Photo of Items",
		"Manual Count",
	]

	@Input() index: number = 0;

	/*
	* Getter & Setter for ORDER
	*/
	private _order: Order;
	orderCustomer: Customer;

	get order(): Order {
		return this._order;
	}
	@Input() set order(value: Order) {
		this._order = value;
		this.getCustomer(value?.customerId)
	}

	/*
	* Getter & Setter for ORDER STOP
	*/
	private _orderStop: OrderStop;
	get orderStop(): OrderStop {
		return this._orderStop;
	}
	@Input() set orderStop(value: OrderStop) {
		if (!value?.orderStopId) {
			this._orderStop = merge({
				orderStopType: OrderStopTypeEnum.DELIVERY,
				isActive: true,
				address: { ...this.defaultAddress },
				orderItemStops: [],
				dateType: OrderStopDateTypeEnum.NONE,
				date: moment().startOf('day').toDate(),
				timeType: OrderStopTimeTypeEnum.NOON,
				time: moment.utc(15, "HH").toDate(),
				attachments: [],
				referenceNumber: null,
				blindCustomer: null,
				blindAddress: null,
				blindCustomerName: '',
				addressDetails: '',
				blindAddressFullAddress: '',
			}, value)
		} else {
			this._orderStop = cloneDeep({
				...value,
				blindAddress: null,
				blindAddressAddressId: null,
				blindCustomer: null,
				blindCustomerCustomerId: null,
				dateType: OrderStopDateTypeEnum.NONE,
			});
		}
		if (this.orderStop?.blindCustomer) {
			this.blindCustomer = this.orderStop?.blindCustomer;
		} else {
			this.blindCustomer = null;
		}
	}

	blindCustomer: Customer;


	selectedCompany: AddressCompanyInfo;
	addressCustomer: Customer;
	maxPickupDate: string;
	customerId: number;
	selectedCompanyData = []
	selectedContactOptions = {
		locationContactEmails: [],
		locationContactNumbers: [],
	}

	constructor(
		private readonly activeModal: NgbActiveModal,
		private readonly helperService: HelperService,
		private readonly imagesService: ImagesService,
		private readonly ngbModal: NgbModal,
		private readonly orderService: OrderService,
		private readonly customerService: CustomerService,
		private readonly customerContactService: CustomerContactService,
		private readonly dialogService: DialogService,
	) { }

	ngOnInit(): void {
		let pickupOrderStops = this.order?.orderStops?.filter((orderStop) => {
			return orderStop.orderStopType === OrderStopTypeEnum.PICKUP
		})
		const deliveryStopsLength = this.order?.orderStops?.filter((orderStop) => {
			return orderStop.orderStopType === OrderStopTypeEnum.DELIVERY
		})?.length
		//this condition for many pickups and many delivery and stop allocated
		if (pickupOrderStops?.length > 1 && deliveryStopsLength > 1 && this.orderStop?.orderItemStops?.length > 0) {
			const allocatedItemsIds = map(this.orderStop.orderItemStops, 'orderItemId')
			pickupOrderStops = pickupOrderStops?.filter((stop) => {
				return some(stop.orderItemStops, (orderItemStop) => {
					return includes(allocatedItemsIds, orderItemStop?.orderItemId)
				})
			})
		}
		const pickupOrderStopDates = map(pickupOrderStops, 'date')
		this.maxPickupDate = maxBy(pickupOrderStopDates) || null as any
		if (!this.orderStop.orderStopId && this.maxPickupDate) {
			this.orderStop = { ...this.orderStop, date: moment(this.maxPickupDate).startOf('day').toDate() as any }
		}

		if (this.orderStop?.address?.customerId) {
			if (!this.addressCustomer) {
				this.customerService.get(this.orderStop.address.customerId).then((data) => {
					this.addressCustomer = data
					this.getLocationContactData()
				});
			}
			if (!this.customerId) {
				this.customerId = this.orderStop?.address?.customerId;
			}
		}

		if (this.order?.customerId) {
			this.orderService.getRecentUsedAddresses(this.order.customerId).then(res => {
				this.recentUsedAddresses = res;
			})
		}
	}

	handleChangeContact($event) {
		const companyName = typeof $event === 'string' ? $event : $event?.companyName
		const selectedContact = find(this.selectedCompanyData, { companyName: companyName })
		if (selectedContact) {
			this.selectedContactOptions = {
				locationContactEmails: selectedContact?.emails.filter(Boolean) || [],
				locationContactNumbers: selectedContact?.phoneNumbers?.filter(Boolean) || [],
			}
			this.orderStop.locationContactName = companyName;
			this.orderStop.locationContactNumber = selectedContact?.phoneNumbers[0] || '';
			this.orderStop.locationContactEmail = selectedContact?.emails[0] || '';
		}
	}

	close() {
		this.activeModal.close();
	}
	getCustomer(customerId) {
		this.customerService.get(customerId).then((data) => {
			this.orderCustomer = data
		}).catch((error) => {
			this.helperService.errorMessage(error)
		})
	}

	async onSelectFile(event) {
		let files = event.target.files;
		if (files) {
			for (let file of files) {
				await this.handleUpload(file)
			}
		}
		event.target.value = null
	}

	async handleUpload(file) {
		if (file instanceof File) {
			this.uploading = true
			return await this.imagesService.upload({
				file
			}).then((data?: any) => {
				this.attachments.push(data?.data)
				this.uploading = false
			}).catch((error) => {
				this.uploading = false
				this.helperService.errorMessage(error, "File upload field, please try again")
			});
		}
	}

	removeSelectedAttachment(index) {
		this.attachments.splice(index, 1)
	}


	addEditItem() {
		const activeModal = this.ngbModal.open(EditDeliveryItemDialogComponent, {
			scrollable: true,
			size: "lg",
			windowClass: 'custom-style'
		});
		activeModal.componentInstance.order = this.order;
		activeModal.componentInstance.orderStop = cloneDeep(this.orderStop);
	}

	setBillingAddress() {
		const address: Address = this.orderCustomer?.addresses?.find(addr => addr.entityType === EntityType.Customer);
		this.orderStop.addressDetails = address?.addressDetails || ''
		this.addCustomerAddressCompanyInfo(address);
		this.setAddress(address)
		this.orderStop.isNewLocationCompanyName = false;
	}


	handleAddressChange(address) {
		this.orderStop.address = address;
		this.orderStop.addressDetails = address?.addressDetails || ''
		this.addCustomerAddressCompanyInfo(address);
		this.setAddress(address)
		this.orderStop.isNewLocationCompanyName = false;
	}

	handleRemoveAddressInfo(address) {
		if (address === this.defaultAddress || (typeof address === 'string')) {
			this.orderStop.address = { ...this.defaultAddress }
			this.orderStop.addressDetails = null
		}
	}

	handleSameAddressesUpdated(addresses: Address[]) {
		this.companiesNames = addresses.filter(x => x.name).map(x => {
			var companyName = x.name || "";

			var customerName = x?.addressCustomerInfo?.customerName;
			if (customerName && customerName != companyName)
				companyName += ` (${customerName})`;

			return { address: x, companyName, customerId: x.customerId };
		});

		for (const address of addresses) {
			this.setMainCompanyName(address);
		}

		var currentCustomerId = this.companiesNames.find(x => x.companyName.includes(this.orderStop.locationCompanyName))?.customerId;

		if(currentCustomerId){
			this.customerService.get(currentCustomerId).then((data) => {
				this.addressCustomer = data
				this.getLocationContactData()
			});
		}
	}

	setMainCompanyName(address: Address) {
		var addressCustomerName = address?.addressCustomerInfo?.customerName;
		if (addressCustomerName) {
			var existed = this.companiesNames.find(x => x.companyName == addressCustomerName);
			if (!existed)
				this.companiesNames.push({ address: null, companyName: addressCustomerName, customerId: address.customerId });
		}
	}

	addCustomerAddressCompanyInfo(address) {
		if (address?.addressId) {
			let addressName = address.name;
			let customerName = address.addressCustomerInfo?.customerName;

			this.orderStop.address.addressCompanyInfo = [];

			if (addressName) {
				this.orderStop.address.addressCompanyInfo.push({ companyName: addressName, phoneNumbers: [], emails: [] });
			}

			if (customerName != addressName) {
				this.orderStop.address.addressCompanyInfo.push({ companyName: customerName, phoneNumbers: [], emails: [] });
			}
		}
	}

	changeCustomerName(customerName) {
		this.orderStop.locationCompanyName = customerName;
		this.orderStop.isNewLocationCompanyName = true;
		this.addressCustomer = null;
	}

	changeCustomer({ address, companyName, customerId }) {
		this.customerId = customerId;
		var oldCompanyName = `${this.orderStop.locationCompanyName}`;
		let isOldCompanyName = companyName == this.orderStop.locationCompanyName ||
			this.companiesNames.find(x => x.address?.name == this.orderStop.locationCompanyName && x.address?.addressCustomerInfo?.customerName == companyName);

		this.orderStop.locationCompanyName = (address?.name && companyName?.includes(address.name)) ? address.name : companyName;

		if (address) {
			if (oldCompanyName == this.orderStop.locationCompanyName)
				this.orderStop.locationCompanyName += " ";
			else
				this.orderStop.isNewLocationCompanyName = true;

			this.setMainCompanyName(address);
			this.handleAddressChange(address);
		} else if (!isOldCompanyName) {
			this.orderStop.locationContactNumber = '';
			this.orderStop.locationContactEmail = '';
		}

		if (customerId) {
			this.customerService.get(customerId).then((data) => {
				this.addressCustomer = data
				this.getLocationContactData()
			});

			this.customerService.getCustomerLocations(customerId, address?.name).then((locations) => {
				if (locations) {
					this.companiesNames = locations;
				}
			});
		}
		else {
			this.addressCustomer = null;
		}
	}

	async setAddress(address: Address) {
		if (!this.orderStop.address) {
			this.orderStop.address = { ...this.defaultAddress }
		}

		var companyName = address?.addressCompanyInfo &&
			address?.addressCompanyInfo.length &&
			address.addressCompanyInfo[0].companyName;

		if (address.customerId && !this.addressCustomer) {
			if (!this.orderStop.locationCompanyName) {
				this.customerId = address.customerId;
			}

			let addressCustomer = await this.customerService.get(address.customerId);

			if (!this.orderStop.locationCompanyName) {
				this.addressCustomer = addressCustomer
				this.recentUsedCustomerContactId = await this.orderService.getRecentUsedCustomerContact(address.customerId);
			}

			this.orderStop.locationContactNumber = this.recentUsedCustomerContactId
				? addressCustomer?.customerContacts?.find(item => item.customerContactId === this.recentUsedCustomerContactId)?.phone
				: addressCustomer?.customerContacts?.filter(item => !!item.phone)[0]?.phone || null;

			this.orderStop.locationContactEmail = this.recentUsedCustomerContactId
				? addressCustomer?.customerContacts?.find(item => item.customerContactId === this.recentUsedCustomerContactId)?.email
				: addressCustomer?.customerContacts?.filter(item => !!item.email)[0]?.email;

			const contactNames = map(addressCustomer?.customerContacts, (item) => {
				return `${item?.firstName} ${item?.lastName}`;
			});
			address.email = this.orderStop.locationContactEmail;

			const recentContactName = this.recentUsedCustomerContactId
				? addressCustomer?.customerContacts?.find(item => item.customerContactId === this.recentUsedCustomerContactId)
				: null;

			this.orderStop.locationContactName = recentContactName
				? `${recentContactName.firstName}${recentContactName.lastName ? ` ${recentContactName.lastName}` : ''}`
				: contactNames[0] || '';

			address = this.setCustomerData(address, companyName);
			this.orderStop.address = address;
		} else {
			this.orderStop.address = address;
		}
	}

	cleanAll() {
		this.customerId = null;
		this.addressCustomer = null;
		
		// Retain the non-displayed data
		const retainedData = {
			orderStopId: this.orderStop.orderStopId,
			orderStopType: this.orderStop.orderStopType,
			isActive: this.orderStop.isActive,
			orderItemStops: this.orderStop.orderItemStops,
			attachments: this.orderStop.attachments,
			dateType: this.orderStop.dateType,
			date: this.orderStop.date,
			timeType: this.orderStop.timeType,
			time: this.orderStop.time
		};
	
		// Reset only the displayed fields
		this.orderStop = {
			...retainedData,
			address: { ...this.defaultAddress },
			referenceNumber: null,
			blindCustomer: null,
			blindAddress: null,
			blindCustomerName: '',
			blindAddressFullAddress: '',
			locationCompanyName: '',
			locationContactName: '',
			locationContactEmail: '',
			locationContactNumber: '',
			addressDetails: '',
			isAppointmentRequired: false,
			needForDelivery: [],
			driverSettings: [],
			appointmentEmail: ''
		};
	
		this.selectedCompanyData = [];
		this.selectedCompany = null;
		this.handleRemoveAddressInfo("");
		this.selectedContactOptions = {
			locationContactEmails: [],
			locationContactNumbers: [],
		};
		this.locationContactPhoneExtensionInput.clearValue();
	}

	getLocationContactData() {
		let contactData = []
		if (this.selectedCompany) {
			contactData = [{
				companyName: this.selectedCompany?.companyName,
				emails: this.selectedCompany?.emails || [],
				phoneNumbers: this.selectedCompany?.phoneNumbers || []
			}]
		}
		if (this.addressCustomer) {
			this.addressCustomer.customerContacts?.forEach((contact) => {
				const data = {
					companyName: `${contact?.firstName || ''} ${contact?.lastName || ''}`?.trim(),
					emails: contact?.email ? [contact?.email] : [],
					phoneNumbers: [contact?.phone] || []
				}
				const index = findIndex(contactData, { companyName: data?.companyName })
				if (index !== -1) {
					contactData.splice(index, 1, data)
				}
				else {
					contactData.push(data)
				}
			})
		}
		if (this.orderStop?.address?.name) {
			const index = findIndex(contactData, { companyName: this.orderStop?.address?.name })
			const data = {
				companyName: this.orderStop?.address.name,
				emails: this.orderStop?.address.email ? [this.orderStop?.address.email] : [],
				phoneNumbers: [this.orderStop?.address?.phoneNumber] || []
			}
			if (index !== -1) {
				let existedData = contactData[index];

				if	(!data.emails.length){
					data.emails = existedData.emails;
				}

				if	(!data.phoneNumbers.length){
					data.phoneNumbers = existedData.phoneNumbers;
				}

				contactData.splice(index, 1, data)
			}
			else {
				contactData.push(data)
			}
		}
		this.selectedCompanyData = contactData
		this.handleChangeContact(this.orderStop.locationContactName)
	}

	setCustomerData(address: Address, companyName?: string) {
		const selectedAddressCompanyInfo = address.addressCompanyInfo?.find(aci => aci.companyName === companyName);
		
		if (!this.orderStop.locationCompanyName) {
			this.selectedCompany = selectedAddressCompanyInfo;
			this.orderStop.locationCompanyName = address?.name || selectedAddressCompanyInfo?.companyName || '';
		}

		this.getLocationContactData()
		return address;
	}

	/**
	 * Save Delivery and close dialog
	 * 
	 * @param form 
	 */
	async saveAndClose(form: FormValidateDirective, addItems = false) {
		if (!form.isValid) {
			return false;
		}

		if (!!this.addressCustomer && this.orderStop.address?.customerId != this.addressCustomer?.customerId)
		{	
			this.dialogService.confirm({
				title: `You selected different address for company ${this.addressCustomer.customerName}?`,
				yesText: "Override address",
				noText: "Create new location"
			}).then(async () => {
				await this.saveAndCloseBase(addItems, '', true);
			}).catch(async () => {
				await this.selectCustomerName(addItems);
			})
		}
		else {
			await this.saveAndCloseBase(addItems);
		}
	}

	async saveAndCloseBase(addItems = false, newLocationName = "", overrideAddress = false) {
		this.helperService.isLoading = true;
		try {
			await this.saveOrder(newLocationName, overrideAddress);
			if (!this.isDispatch) {
				this.close();
			}

			this.resetOrderStops();

			if (addItems) {
				this.addEditItem();
			}

			this.order.orderStops = this.order.orderStops.filter((orderStop: OrderStop) => !!orderStop.orderStopId);
		} catch (error) {
			this.helperService.isLoading = false;
			const messages = error?.error?.messages;
			const message = messages ? messages.find(message => message.includes("This company already exists, do you want to add a new location")) : null;

			this.resetOrderStops();
			this.order.orderStops = this.order.orderStops.filter((orderStop: OrderStop) => !!orderStop.orderStopId);

			if (message) {
				try {
					await this.dialogService.confirm({
						title: message,
						hideNoButton: true,
						yesText: "Create new location"
					});
					await this.selectCustomerName(addItems);
				} catch (res) {
					// Handle the rejection if needed
				}
			}
			else {
				console.log('Error while save delivery stops', error);
				this.helperService.errorMessage(error);
			}
		}
		this.helperService.isLoading = false;
	}

	resetOrderStops() {
		if (this.orderStop){
			this.orderStop.isNewLocationCompanyName = false;
		}

		this.order.orderStops.forEach((orderStop: OrderStop) => {
			orderStop.isNewLocationCompanyName = false;
		});
	}

	async selectCustomerName(addItems = false) {
		const res = this.customerId ? await this.customerService.searchCustomerAddress(this.customerId, this.orderStop.address.fullAddress) : null;
		let addresses = res?.list || [];
		let companiesNames = addresses.filter(x => x.name)
			.map(x => {
				var companyName = x.name || "";
				return companyName;
			})
			.filter((v, i, self) => {
				return i == self.indexOf(v);
			});

		this.orderStop.isNewLocationCompanyName = false;

		const activeModal = this.ngbModal.open(SelectCustomerNameComponent, {
			scrollable: true,
			size: "sm",
			windowClass: 'custom-style'
		});

		activeModal.componentInstance.customersNames = companiesNames;

		const customerName = await activeModal.result;
		var newLocationName = customerName || " ";
		await this.saveAndCloseBase(addItems, newLocationName);
	}

	/**
	 * Save order with stops and items for stops
	 * 
	 * @param form 
	 * @returns 
	 */
	async saveOrder(newLocationName = "", overrideAddress = false) {
		if (!this.orderStop.address) {
			throw new Error('Please select pickup or delivery address');
		}

		this.orderStop.newLocationName = newLocationName;
		this.orderStop.overrideAddress = overrideAddress;

		if (this.orderStop.orderStopId) {
			let { orderStops = [] } = this.order;
			const { orderStopId } = this.orderStop;

			const index = findIndex(this.order.orderStops, { orderStopId: orderStopId });

			orderStops.splice(index, 1, this.orderStop);
			this.order.orderStops = orderStops;
			if (this.isDispatch) {
				this.order.isDispatchRouteStopItem = true
			}
			this.order = await this.orderService.saveOrder(this.order, null);
			this.orderStop = await find(this.order.orderStops, { orderStopId: orderStopId });
			if (this.isDispatch) {
				this.activeModal.close(this.orderStop);
			}
		} else {
			const { orderId } = this.order;

			// Create new order stop
			const orderStop = await this.orderService.createOrderStop(orderId, this.orderStop);
			if (orderStop) {
				delete orderStop.order
				this.orderStop = orderStop;
				this.order.orderStops.push(orderStop);
			}

			// Assign new order to the exit order page
			this.orderService.order = Object.assign({}, this.order);
		}
	}

	selectBlindCustomer(customer: Customer) {
		this.orderStop.blindCustomer = customer;
		this.orderStop.blindCustomerName = customer?.customerName;

		if (customer) {
			this.orderStop.blindAddress = customer?.addresses?.find(x => x.entityType === 1) ?? null;
		}
	}

	async addContact() {
		const phone = this.orderStop.locationContactNumber;
		const email = this.orderStop.locationContactEmail;

		if(!this.customerId){
			this.helperService.errorMessage("Company must be selected.");
			return;
		}

		if(!phone || !email){
			this.helperService.errorMessage("Contact Number and Contact Email are required.");
			return;
		}
		if (!patterns?.email?.test(email)) {
			this.helperService.errorMessage("Please enter a valid email.");
			return
		}
		if (!patterns?.phoneWithExtension?.test(phone)) {
			this.helperService.errorMessage("Please enter a valid contact number or extension.");
			return;
		}
		const chunks = !this.orderStop.locationContactName ? [] : this.orderStop.locationContactName.split(/\s+/);
		const [firstName = null, lastName = null] = [chunks.shift(), chunks.join(' ')];

		try {
			var res = await this.customerContactService.create({
				customerId: this.customerId,
				active: true,
				firstName: firstName,
				lastName: lastName,
				phone: phone,
				phoneExtension: this.orderStop.locationContactNumberExtension,
				email: email
			});

			this.helperService.successMessage("Customer Contact successfully created");
		} catch (error) {
			this.helperService.errorMessage(error);
		}
    }
}
