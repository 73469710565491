import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  public mainLoaderOn$ = new BehaviorSubject<boolean>(false);

  constructor() { }

  mainLoaderOn() {
    this.mainLoaderOn$.next(true);
  }

  mainLoaderOff() {
    this.mainLoaderOn$.next(false);
  }

}
