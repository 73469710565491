// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
}
:host ::ng-deep ngb-typeahead-window {
  max-height: 300px;
  overflow: auto;
}`, "",{"version":3,"sources":["webpack://./src/app/components/main/accounting/filters/filters.component.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;AACF;AAEI;EACE,iBAAA;EACA,cAAA;AAAN","sourcesContent":[":host {\n  display: block;\n\n  ::ng-deep {\n    ngb-typeahead-window {\n      max-height: 300px;\n      overflow: auto;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
