import { Component, ElementRef, EventEmitter, Input, OnInit, Output, SecurityContext, ViewChild } from '@angular/core';
import { Order } from '@models/order.model';
import { Invoice } from '@models/invoice.model';
import { CustomerEmailType } from '@models/customer-email.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CustomerService } from '@app/shared/services/customer.service';
import { DocumentService } from '@app/shared/services/document.service';
import { DocumentViewMode } from '@app/models/document.model';
import { HelperService } from '@app/shared/services/helper.service';
import { uniqBy } from 'lodash';
import { DomSanitizer } from '@angular/platform-browser';


interface EmailRecipient {
    email: string;
    name?: string;
    type?: string;
    label?: string;
}

export interface DocumentViewerResult {
    entity: Order | Invoice;
    email?: string;
}

@Component({
    selector: 'app-document-viewer',
    templateUrl: './document-viewer.component.html',
    styleUrls: ['./document-viewer.component.scss']
})
export class DocumentViewerComponent {

    @Output() downloadClick: EventEmitter<DocumentViewerResult> = new EventEmitter<DocumentViewerResult>();
    @Output() emailClick: EventEmitter<DocumentViewerResult> = new EventEmitter<DocumentViewerResult>();
    @Input() content: string;
    @Input() template: string;
    @Input() noResultsFound: boolean;
    //@Input() entity: Order | Invoice;
    @Input() customerEmailType: CustomerEmailType = null;
    @Input() showPrint: boolean;
    @Input() showSplitOrderStopItems: boolean;
    @Input() showHandleLabelItems: boolean;
    @Input() showHighlightItems: boolean;
    splitOrderStopItems: boolean;
    highlightItems: boolean;
    isLabel: boolean = true

    emailsList: EmailRecipient[] = [];

    private _emails: EmailRecipient[] = [];
    emailRequired: boolean = false;


    get emails(): EmailRecipient[] {
        return this._emails;
    }

    @Input() set emails(emails: EmailRecipient[]) {
        this._emails = emails;
        if (emails?.length > 0) {
            this.getEmailsList(emails)
        }
    }

    selectedEmail: string[] = [];
    isLoading: boolean = true;
    showCustomEmails: boolean = false;
    labelContent = '';
    labelLoading: boolean;
    @ViewChild('printFrame', { static: false }) printFrame: ElementRef;

    private _entity: Order | Invoice;

    get entity(): Order | Invoice {
        return this._entity;
    }

    @Input() set entity(value: Order | Invoice) {
        this._entity = value
        this.getOrderLabels()
    }

    /*
    * Getter & Setter for show email
    */
    private _showEmail: boolean;

    get showEmail(): boolean {
        return this._showEmail;
    }

    @Input() set showEmail(value: boolean) {
        this._showEmail = value;
        if (value) {
            this.getEmailsList()
        } else {
            this.isLoading = false;
        }
    }

    constructor(
        private activeModal: NgbActiveModal,
        private customerService: CustomerService,
        private documentService: DocumentService,
        private helperService: HelperService,
        private sanitizer: DomSanitizer
    ) {
    }

    getEmailsList(exitsEmails?: EmailRecipient[]) {
        this.customerService.getCustomerContactDetails(this.entity?.customer?.customerId).then((res) => {
            const emails = [
                ...res.customerEmails.map(ce => ({ email: ce.email, type: ce.emailType ? `[${ce.emailType}]` : '' })),
                ...res.customerContacts.map(cc => ({ email: cc.email, name: `${cc.firstName || ''} ${cc.lastName || ''}`.trim(), type: '[Contact]' })),
            ];

            const formantEmailsDetails = emails.map((email: any) => {
                const label = `${email.email || ''} ${email.name || ''} ${email.type || ''}`;
                return {
                    ...email,
                    label
                }
            });

            if (exitsEmails?.length > 0) {
                this.emailsList = uniqBy([...exitsEmails, ...formantEmailsDetails], 'email');
            } else {
                this.emailsList = uniqBy(formantEmailsDetails, 'email');

                if (this.emailsList.length > 0) {
                    if (this.customerEmailType != null) {
                        this.selectedEmail = [this.emailsList.find(em => em.type === this.customerEmailType).email ?? this.emailsList[0].email];
                    } else {
                        this.selectedEmail = [this.emailsList[0].email];
                    }
                }
            }
        }).catch((error) => {
            this.emailsList = exitsEmails || [];
        }).finally(() => {
            this.isLoading = false;
        })
    }

    getOrderLabels() {
        if (this.entity.orderId) {
            this.labelLoading = true
            this.documentService.downloadLabels(this.entity.orderId, DocumentViewMode.Html).then((resp) => {
                this.labelContent = resp
            }).catch((error) => {
                this.helperService.errorMessage(error)
            }).finally(() => {
                this.labelLoading = false
            })
        }
    }

    addEmailFn(items: any[], name: any) {
        const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
        if (regex.test(name)) {
            const isEmail = items.find(item => item.email === name);
            if (!isEmail) {
                const email = { email: name, name: name, type: '[Contact]', label: `${name} [Contact]` }
                items.push(email)
                return email;
            }
            return false;
        } else {
            return false;
        }
    }

    filterItems = (term: string, item: any) => {
        return item.email.toLowerCase().indexOf(term.toLowerCase()) > -1;
    }

    close() {
        this.activeModal.close()
    }

    download() {
        this.activeModal.close({
            entity: this.entity,
            isLabel: this.isLabel,
            highlightItems: this.highlightItems,
            splitOrderStopItems: this.splitOrderStopItems,
            type: 'download'
        })
    }

    checkEmailValidation(selectedEmail) {
        this.emailRequired = false;
    }

    sendEmail() {
        if (this.selectedEmail?.length > 0) {
            this.activeModal.close({ email: this.selectedEmail, entity: this.entity, type: 'sendEmail', highlightItems: this.highlightItems, splitOrderStopItems: this.splitOrderStopItems, });
        } else {
            this.emailRequired = true;
        }
    }

    async handleSplitOrderStopItems() {
        if (this.entity?.orderId && (this.showSplitOrderStopItems || this.showHighlightItems)) {
            this.isLoading = true;
            this.content = null;

            const request = {
                ...(this.splitOrderStopItems && { splitOrderStopItems: this.splitOrderStopItems }),
                ...(this.highlightItems && { highlightItems: this.highlightItems })
            }

            this.content = await this.documentService.downloadBillOfLanding(this.entity?.orderId, DocumentViewMode.Html, request)
            if (!this.content) {
                this.noResultsFound = true;
            }
            this.isLoading = false;
        }
    }

    async handlePrintContent() {
        if (this.labelLoading) {
            return
        }
        this.helperService.isLoading = true
        try {
            const doc = this.printFrame.nativeElement.contentDocument || this.printFrame.nativeElement.contentWindow;
            if (doc) {
                doc.open();

                const styles = `<style>
                    @page { 
                        size: auto;   /* auto is the initial value */
                        margin: 8mm;  /* this affects the margin in the printer settings */
                        /* You can also specify margins here if you want a specific one */
                    }
                    </style>`;
                doc.write(styles);

                let sanitizedContent = this.sanitizer.sanitize(SecurityContext.HTML,this.content);
                let sanitizedLabelContent = this.isLabel ? this.sanitizer.sanitize(SecurityContext.HTML, this.labelContent) : '';
                const content = `<html><head></head><body class="bol-document-print">${sanitizedContent}${sanitizedLabelContent}</body></html>`
                doc.write(content);
                // Clone the head from the main document and append it to the iframe
                const headClone = document.head.cloneNode(true);
                doc.head.appendChild(headClone);
                doc.close();

                // Wait for the content to be loaded before printing
                this.printFrame.nativeElement.onload = () => {
                    this.print();
                    this.helperService.isLoading = false
                };
            }
        } catch (error) {
            this.helperService.isLoading = false
        }

    }

    private print() {
        const iframeWindow = this.printFrame.nativeElement.contentWindow || this.printFrame.nativeElement.contentDocument['parentWindow'];
        if (iframeWindow) {
            iframeWindow.focus();
            iframeWindow.print();
        }
    }

}
