import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { stateOptions } from '@app/data/state';
import { CustomerContact } from '@app/models/customer-contact.model';
import { ImageUploadTypeEnum } from '@app/models/image.model';
import { HelperService } from '@app/shared/services/helper.service';
import { find, get } from 'lodash';
import { ValidationError, array, object, string } from 'yup';
import { parsePhoneNumberAndExtension, formatPhoneNumberAndExtension } from '@app/@shared/utils';
import { OutsourceCompany } from '@app/models/outsource-company.model';
import { OutsourceCompanyContactService } from '@app/shared/services/outsource-company-contact.service';

export interface CustomerContactItem extends CustomerContact {
    isEdit?: boolean,
    isDelete?: boolean,
}

@Component({
    selector: "app-outsource-company-contacts",
    templateUrl: "./outsource-company-contacts.component.html",
    styleUrls: ["./outsource-company-contacts.component.scss"],
})
export class OutsourceCompanyContactsComponent implements OnInit {

    private _outsourceCompany: OutsourceCompany = {};

    companyContacts: CustomerContactItem[] = [{
        email: '',
        firstName: '',
        lastName: '',
        phoneNumber: '',
    }];
    states = stateOptions;

    validationSchema = object().shape({
        companyContacts: array().of(
            object().shape({
                email: string().label('Email').nullable().max(256).email().required(),
                firstName: string().label('First Name').nullable().max(256).required(),
                lastName: string().label('Last Name').nullable().max(256).required(),
                phoneNumber: string().label('Contact Number').nullable().max(256).required()
            })
        ).test('unique-emails', 'Emails must be unique', function (arr: any[]) {
            const path = this.path;

            let errors = [];

            let emailValueCount = arr.reduce((acc, obj) => {
                acc[obj.email] = (acc[obj.email] || 0) + 1;
                return acc;
            }, {});

            let firstNameValueCount = arr.reduce((acc, obj) => {
                acc[obj.firstName] = (acc[obj.firstName] || 0) + 1;
                return acc;
            }, {});

            let lastNameValueCount = arr.reduce((acc, obj) => {
                acc[obj.lastName] = (acc[obj.lastName] || 0) + 1;
                return acc;
            }, {});

            let phoneNumberValueCount = arr.reduce((acc, obj) => {
                acc[obj.phoneNumber] = (acc[obj.phoneNumber] || 0) + 1;
                return acc;
            }, {});

            arr.forEach((obj, index) => {
                if (obj.email && emailValueCount[obj.email] > 1) {
                    const error = this.createError({ message: `Email is duplicated`, path: `${path}[${index}].email` });
                    errors.push(error);
                }
                if (obj.firstName && firstNameValueCount[obj.firstName] > 1) {
                    const error = this.createError({ message: `First Name is duplicated`, path: `${path}[${index}].firstName` });
                    errors.push(error);
                }
                if (obj.lastName && lastNameValueCount[obj.lastName] > 1) {
                    const error = this.createError({ message: `Last Name is duplicated`, path: `${path}[${index}].lastName` });
                    errors.push(error);
                }
                if (obj.phoneNumber && phoneNumberValueCount[obj.phoneNumber] > 1) {
                    const error = this.createError({ message: `Contact Number is duplicated`, path: `${path}[${index}].phoneNumber` });
                    errors.push(error);
                }
            });
            if (errors?.length > 0) {
                return new ValidationError(errors);
            }
            return true

        }),
    });

    ImageUploadTypeEnum = ImageUploadTypeEnum;

    @Input()
    public get outsourceCompany(): OutsourceCompany {
        return this._outsourceCompany;
    }

    public set outsourceCompany(value: OutsourceCompany) {
        this._outsourceCompany = value;
        let contacts = value?.companyContacts || [];

        let duplicatedContact = contacts.find(x => {
            let contactName = (x.firstName + (!!x.lastName ? ` ${x.lastName}` : ''));
            return contactName == value.outsourceCompanyName && x.phone == value.phone && x.email == value.firstEmail
        });

        if (duplicatedContact)
            contacts = contacts.filter(x => x.customerContactId != duplicatedContact.customerContactId);

        const updatedContacts = contacts?.map(contact => parsePhoneNumberAndExtension(contact));
        this.companyContacts = updatedContacts;
    }

    @Output() outsourceCompanyChange = new EventEmitter();

    constructor(
        private outsourceCompanyContactService: OutsourceCompanyContactService,
        private helperService: HelperService,
    ) {
    }

    ngOnInit(): void { }

    cancelEditContact(index) {
        const contact = this.companyContacts[index];
        if (contact && contact.customerContactId) {
            contact.isEdit = false;
            const oldContact = find(this.outsourceCompany.companyContacts, { customerContactId: contact.customerContactId })
            this.companyContacts.splice(index, 1, oldContact);
        } else {
            this.companyContacts.splice(index, 1);
        }
    }

    editContact(item) {
        item.isEdit = !item.isEdit;
    }

    async saveContact(isValid, index) {
        if (!get(isValid?.errors?.contacts, index, false)) {
            let contact = await formatPhoneNumberAndExtension(this.companyContacts[index]);
            if (contact && contact?.customerContactId) {
                this.outsourceCompanyContactService.update(contact.customerContactId, contact)
                    .then(async (data) => {
                        this.companyContacts[index].isEdit = false;
                        data = await parsePhoneNumberAndExtension(data);
                        this.companyContacts.splice(index, 1, data);
                        this.outsourceCompany.companyContacts = this.companyContacts
                        this.outsourceCompanyChange.emit(this.outsourceCompany)
                    }).catch((error) => {
                        this.helperService.errorMessage(error)
                    })
            } else {
                contact.outsourceCompanyId = this.outsourceCompany?.id
                this.outsourceCompanyContactService.create(contact)
                    .then(async (data) => {
                        this.companyContacts[index].isEdit = false;
                        data = await parsePhoneNumberAndExtension(data);
                        this.companyContacts.splice(index, 1, data);
                        this.outsourceCompany.companyContacts = this.companyContacts
                        this.outsourceCompanyChange.emit(this.outsourceCompany)
                    }).catch((error) => {
                        this.helperService.errorMessage(error)
                    })
            }
        }
    }

    deleteContact(index) {
        const contact = this.companyContacts[index]
        if (contact) {
            this.outsourceCompanyContactService.delete(contact.customerContactId)
                .then(() => {
                    this.companyContacts.splice(index, 1);
                    this.outsourceCompany.companyContacts = this.companyContacts
                    this.outsourceCompanyChange.emit(this.outsourceCompany)
                }).catch((error) => {
                    this.helperService.errorMessage(error)
                })
        } else {
            this.companyContacts.splice(index, 1);
        }

    }

    /**
     * Set primary contact as default
     * 
     * @param event 
     */
    async setPrimaryContact(index) {
        try {
            const contact = this.companyContacts[index];
            await this.outsourceCompanyContactService.update(contact?.customerContactId, {
                ...contact,
                isPrimary: true
            });
            this.companyContacts.forEach((res) => {
                res.isPrimary = res.customerContactId === contact.customerContactId;
            });
            this.outsourceCompany.companyContacts = this.companyContacts
            this.outsourceCompanyChange.emit(this.companyContacts)
        } catch (error) {
            console.log('Error while set customer contact as default primary contact', error);
            this.helperService.errorMessage(error);
        }
    }

}


