// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host .input-group {
  flex-wrap: nowrap;
}
:host .input-group .btn:disabled {
  border-color: inherit;
  border: none;
}
:host .input-group input {
  background-color: inherit;
}
:host .qty-input {
  max-width: 60px !important;
  padding: 0;
  width: 100%;
  text-align: center;
  min-width: 32px;
}`, "",{"version":3,"sources":["webpack://./src/app/@shared/qty-select/qty-select/qty-select.component.scss"],"names":[],"mappings":"AAKE;EACE,iBAAA;AAJJ;AAOM;EACE,qBAAA;EACA,YAAA;AALR;AASI;EACE,yBAAA;AAPN;AAYE;EACE,0BAAA;EACA,UAAA;EACA,WAAA;EACA,kBAAA;EACA,eAAA;AAVJ","sourcesContent":[":host {\n  .form-control {\n    // min-width: 40px;\n  }\n\n  .input-group {\n    flex-wrap: nowrap;\n\n    .btn {\n      &:disabled {\n        border-color: inherit;\n        border: none;\n      }\n    }\n\n    input {\n      background-color: inherit;\n      ;\n    }\n  }\n\n  .qty-input {\n    max-width: 60px !important;\n    padding: 0;\n    width: 100%;\n    text-align: center;\n    min-width: 32px;\n  }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
