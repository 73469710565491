import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Address, Country } from '@app/models/address.model';
import { CrudService } from './core/crud.service';
import { ApiResponse, ListRequest } from '@app/models/core/base';
import { toParams } from 'src/utils/to-params';


@Injectable({
    providedIn: 'root'
})
export class AddressService extends CrudService<Address> {

    override basePath = 'Addresses';

    constructor(protected http: HttpClient) {
        super(http);
    }

    getCountries(request?: ListRequest) {
        return this.http.get<ApiResponse<Country[]>>(`Countries`, { params: toParams(request) }).toPromise()
            .then((({data}) => data));
    }

}
