import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DatatableComponent } from '@app/@shared/datatable';
import { Zone } from '@app/models/zone.model';
import { HelperService } from '@app/shared/services/helper.service';
import { ZoneService } from '@app/shared/services/zone.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ZoneDetailsComponent } from '../zone-details/zone-details.component';
import { AppService } from '@app/shared/services/app.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { debounceTime } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'app-zone-list',
  templateUrl: './zone-list.component.html',
  styleUrls: ['./zone-list.component.scss']
})
export class ZoneListComponent implements OnInit, OnDestroy {

  title = "Zone"
  @ViewChild('datatable') datatable: DatatableComponent
  searchTerm: string;


  constructor(
    private zoneService: ZoneService,
    private helperService: HelperService,
    private ngbModal: NgbModal,
    private appService: AppService,
  ) { }

  ngOnInit() {
    this.appService.$searchTerm
      .pipe(
        untilDestroyed(this),
        debounceTime(500)
      )
      .subscribe((text) => {
        this.datatable.setSearchValue(text);
      })
  }

  ngOnDestroy() {
    this.appService.searchTerm = ''
  }

  getData = (request) => {
    this.zoneService.cancelPendingRequestGetAll()
    return this.zoneService.getAllWithCancelRequest(request)
  }

  openAddEdit(item?: Zone) {
    const ref = this.ngbModal.open(ZoneDetailsComponent, {
      size: 'md'
    })
    ref.componentInstance.zone = item ?? {}
    ref.closed.subscribe((isReload) => {
      if (isReload) {
        this.datatable.refresh()
      }
    })
  }

  onDelete(item: Zone) {
    this.zoneService.delete(item.zoneId).then(() => {
      this.helperService.successMessage(`${this.title} Deleted Successfully`);
      this.datatable.refresh()
    }).catch(error => {
      this.helperService.errorMessage(error);
    });
  }

}
