import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddressLookupComponent } from './address-lookup.component';
import { NgbDropdownModule, NgbModalModule, NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    AddressLookupComponent
  ],
  exports: [
    AddressLookupComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbTypeaheadModule,
    NgbModalModule,
    NgbDropdownModule,
  ]
})
export class AddressLookupModule { }
