import { AfterContentChecked, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { UtilService } from 'src/app/services/util.service';
import { ShipmentType } from '@models/shipment-type.model';
import { AccountingService } from '@app/services/accounting.service';
import { ChargeType } from '@models/charge-type.model';
import { ChargeTypeService } from '@app/shared/services/charge-type.service';
import { HelperService } from '@app/shared/services/helper.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ChargeTypeDetailsComponent } from '../charge-type-details/charge-type-details.component';
import { DatatableComponent } from '@app/@shared/datatable';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AppService } from '@app/shared/services/app.service';
import { debounceTime } from 'rxjs/operators';

@UntilDestroy()
@Component({
    selector: 'app-charge-type-list',
    templateUrl: './charge-type-list.component.html',
    styleUrls: ['./charge-type-list.component.scss'],
})
export class ChargeTypeListComponent implements AfterContentChecked, OnInit, OnDestroy {

    title = "Charge Type"

    @ViewChild('datatable') datatable: DatatableComponent

    data: any;
    searchDate: Date;
    searchTerm: string;

    constructor(
        private chargeTypeService: ChargeTypeService,
        private helperService: HelperService,
        private ngbModal: NgbModal,
        private appService: AppService,
        private readonly cdr: ChangeDetectorRef
    ) {
    }

    getData(request?: any) {
        this.chargeTypeService.cancelPendingRequestGetAll()
        return this.chargeTypeService.getAllWithCancelRequest(request)
            .then((data) => {
                this.data = data
            })
    }

    ngOnInit() {
        this.getData()
        this.appService.$searchTerm
            .pipe(
                untilDestroyed(this),
                debounceTime(500)
            )
            .subscribe((text) => {
                this.datatable.setSearchValue(text);
            })
    }

    ngAfterContentChecked() {
        this.cdr.detectChanges();
    }

    ngOnDestroy() {
        this.appService.searchTerm = ''
    }

    openAddEdit(item?: ShipmentType) {
        const ref = this.ngbModal.open(ChargeTypeDetailsComponent, {
            size: 'md'
        })
        ref.componentInstance.item = item

        ref.closed.pipe(untilDestroyed(this)).subscribe((isReload) => {
            if (isReload) {
                this.getData()
                this.datatable.refresh()
            }
        })
    }

    dateUpdated($event) {

    }

    onDelete(entity: ChargeType) {
        this.chargeTypeService.delete(entity.chargeTypeId).then(() => {
            this.helperService.successMessage(`${this.title} Deleted Successfully`);
            this.getData()
            this.datatable.refresh()
        }).catch(error => {
            this.helperService.errorMessage(error, 'An error has ocurred');
        });

    }
}
