import { Component, ViewChild } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ListTabsComponent } from '@app/@shared/list-tabs/list-tabs.component';
import { HelperService } from '@app/shared/services/helper.service';
import { Driver } from '@app/models/driver.model';
import { QuoteService } from '@app/shared/services/quote.service';
import { ActivatedRoute } from '@angular/router';
import { Order } from '@app/models/order.model';
import { titleCase } from '@app/@shared/utils';

@UntilDestroy()
@Component({
	selector: 'app-order-layout',
	templateUrl: './order-layout.component.html',
	styleUrls: ['./order-layout.component.scss'],
})

export class OrderLayoutComponent {

	@ViewChild('orderListTabs') orderListTabs: ListTabsComponent;
	@ViewChild('quoteListTabs') quoteListTabs: ListTabsComponent;

	isQuote: boolean;
	isOrder: boolean;
	dataType: string;

	constructor(
		private helperService: HelperService,
		private quoteService: QuoteService,
		private activatedRoute: ActivatedRoute,
	) {

	}

	ngOnInit() {
		this.activatedRoute
			.data
			.pipe(untilDestroyed(this))
			.subscribe((data) => {
				this.dataType = data?.type;
				if (data.type === 'order') {
					this.isOrder = true;
					this.isQuote = false;
				}
				if (data.type === 'quote') {
					this.isOrder = false;
					this.isQuote = true;
				}
			})
	}

	getTabTitle = (value: Order, key) => {
		return value?.orderNumber ? `#${value?.orderNumber}` : 'Loading...';
	}

	updateTabData(data) {
		if (this.isOrder) {
			const newTabId = data.orderId;
			const tabId = this.orderListTabs.activeTab;
			this.orderListTabs.updateTabId(tabId, newTabId)
			this.orderListTabs.setTab(newTabId, { data });
		} else {
			const newTabId = data.orderId;
			const tabId = this.quoteListTabs.activeTab;
			this.quoteListTabs.updateTabId(tabId, newTabId)
			this.quoteListTabs.setTab(newTabId, { data });
		}
	}

	loadTabData = async (orderId: string) => {
		return await this.quoteService.get(orderId).then(async (res) => {
			return res;
		}).catch(() => {
			this.helperService.errorMessage("Order not found.");
			const listTabs = this.isOrder ? this.orderListTabs : this.quoteListTabs;
			listTabs.closeTab(orderId, true);
		})
	}
}