import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DriverService } from '@app/shared/services/driver.service';
import { Driver } from '@app/models/driver.model';
import { HelperService } from '@app/shared/services/helper.service';
import { debounceTime, tap } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Subject } from 'rxjs';


@UntilDestroy()
@Component({
  selector: 'app-driver-details',
  templateUrl: './driver-details.component.html',
  styleUrls: ['./driver-details.component.scss']
})
export class DriverDetailsComponent implements OnInit {

  driver: Driver = {
    ratings: 0
  };
  driverId: number;
  activeTab: string = 'personalInformation';
  ratings$ = new Subject<string>();
  isLoading: boolean;

  private _data: Driver;
  @Input()
  public get data(): Driver {
    return this._data;
  }
  public set data(value: Driver) {
    this._data = value;
    this.driver = value;
  }

  @Output() dataChange = new EventEmitter<Driver>();

  constructor(
    private helperService: HelperService,
    private driverService: DriverService,
  ) { }

  ngOnInit() {
    this.ratings$
      .pipe(
        debounceTime(500),
        tap(() => this.updateDriver(this.driver)),
        untilDestroyed(this),
      )
      .subscribe();
  }

  uploadDriverImage() {
    this.updateDriver(this.driver)
  }

  async updateDriver(driver) {
    this.isLoading = true;
    await this.driverService.update(this.driver?.driverId, driver).then((res) => {
      this.driver = res;
      this.helperService.successMessage('Driver Details Successfully Updated.');
      this.isLoading = false;
    }).catch((error) => {
      this.isLoading = false;
      this.helperService.errorMessage(error);
    })
  }

}
