import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiResponse, DataApiResponse, DataListApiResponse, handleApiError, ListResponse } from '@app/helpers/api';
import { environment } from '@environments/environment';
import { catchError, map } from 'rxjs/operators';
import { UtilService } from '@app/services/util.service';
import { Warehouse } from '@models/warehouse.model';
import { BehaviorSubject, Observable } from 'rxjs';
import { Route } from '@models/route.model';

@Injectable({ providedIn: 'root' })
export class WarehouseService {
    constructor(
        private http: HttpClient,
        private util: UtilService,
    ) {
    }

    private listSubject: BehaviorSubject<Warehouse[]> = new BehaviorSubject<Warehouse[]>([]);

    public get warehouses$(): Observable<Warehouse[]> {
        return this.listSubject.asObservable();
    }

    list(search) {
        return this.http.get<DataListApiResponse<Warehouse[]>>(
            this.util.getUrlWithParams(`${environment.apiBaseUrl}Warehouses/`, search))
            .pipe(map(data => {
                    this.listSubject.next(data.data.list);
                    return data.data;
                }),
                catchError(handleApiError));
    }

    get(id: number) {
        return this.http.get<DataApiResponse<Warehouse>>(
            `${environment.apiBaseUrl}Warehouses/${id}`)
            .pipe(map(data => {
                    return data.data;
                }),
                catchError(handleApiError));
    }

    update(id: number, warehouse: Warehouse) {
        return this.http.put<DataApiResponse<Warehouse>>(
            `${environment.apiBaseUrl}Warehouses/${id}`, warehouse)
            .pipe(map(data => {
                    return data.data;
                }),
                catchError(handleApiError));

    }

    add(warehouse: Warehouse) {
        return this.http.post<DataApiResponse<Warehouse>>(
            `${environment.apiBaseUrl}Warehouses/`, warehouse)
            .pipe(map(data => {
                    return data.data;
                }),
                catchError(handleApiError));

    }

    remove(id: number) {
        return this.http.delete<ApiResponse>(
            `${environment.apiBaseUrl}Warehouses/${id}`)
            .pipe(map(data => {
                    return data.success;
                }),
                catchError(handleApiError));
    }

    loadList() {
        this.list({ page: 1, itemsPerPage: 20, SortBy: 'DEFAULT', SortDirection: 'Descending' }).subscribe(() => {});
    }

    moveToWarehouse(route: Route, warehouse: Warehouse) {
        return this.http.post<DataApiResponse<Route>>(
            `${environment.apiBaseUrl}Warehouses/${warehouse.warehouseId}/move`, route)
            .pipe(map(data => {
                    return data.data;
                }),
                catchError(handleApiError));
    }
}
