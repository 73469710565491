import { Location } from "@angular/common";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Customer } from "@app/models/customer.model";
import { OrderStop, OrderStopTypeEnum } from "@app/models/order-stop.model";
import { Order, OrderStatusEnum, OrderTypeEnum } from "@app/models/order.model";
import { ShipmentType } from "@app/models/shipment-type.model";
import { HelperService } from "@app/shared/services/helper.service";
import { QuoteService } from "@app/shared/services/quote.service";
import { ShipmentTypeService } from "@app/shared/services/shipment-type.service";
import { NgbDateStruct, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { chain, cloneDeep, filter, find, findIndex, isEqual, map, pick } from "lodash";
import { CdkDragDrop } from "@angular/cdk/drag-drop";
import { Subject } from "rxjs";
import { debounceTime } from "rxjs/operators";
import { saveAs } from 'file-saver';
import { OrderService } from "@app/shared/services/order.service";
import { environment } from "@environments/environment";
import { AddLocationComponent } from "../add-location/add-location.component";
import { CalenderDialogComponent } from "@app/@shared/calender-dialog/calender-dialog.component";
import { DriverService } from "@app/shared/services/driver.service";
import { Driver } from "@app/models/driver.model";
import moment = require("moment");
import { EntityType } from "@app/models/entity-type.model";
import { ImagesService } from "@app/shared/services/images.service";
import { Attachment } from "@app/models/attachment.model";
import { DateTimeService } from "@app/shared/services/datetime-service";
import { Route } from "@app/models/route.model";

@UntilDestroy()
@Component({
    selector: "app-add-edit-order",
    templateUrl: "./add-edit-order.component.html",
    styleUrls: ["./add-edit-order.component.scss"],
})
export class AddEditOrderComponent implements OnInit {

    OrderTypeEnum = OrderTypeEnum;
    OrderStatusEnum = OrderStatusEnum;

    isEditOrderRepeating: boolean;
    order: Order = {
        type: OrderTypeEnum.DEFAULT,
        shipmentType: null,
        orderStops: [],
        orderStatus: OrderStatusEnum.DRAFT
    };
    model: NgbDateStruct;
    date: { year: number; month: number };
    placement: 'top'
    contactDetails: [];
    today = new Date();
    orderId: number | string;
    selectedCustomer: Customer;
    searchingCustomer: boolean;
    switchBtn: boolean = false;
    selectedCustomerContact: any;
    shipmentTypes: ShipmentType[] = [];
    useTrimbleMaps: Boolean = environment.useTrimbleMaps;

    activeTab: string = 'orderDetails';
    portOrder: boolean
    isLoading: boolean;
    orderUpdate$ = new Subject()
    drivers: Driver[] = []
    orderAttachments: Attachment[] = [];

    private _isQuote: boolean;
    @Input()
    public get isQuote(): boolean {
        return this._isQuote;
    }
    public set isQuote(value: boolean) {
        this._isQuote = value;
        this.order.isOrder = value ? false : true;
    }

    private _data: Order;
    @Input()
    public get data(): Order {
        return this._data;
    }
    public set data(value: Order) {
        this._data = value;
        this.order = cloneDeep(value);
        this.orderService.dataStore.order = cloneDeep(value);
    }

    @Output() dataChange = new EventEmitter<Order>();

    constructor(
        private modalService: NgbModal,
        private activatedRoute: ActivatedRoute,
        private quoteService: QuoteService,
        private orderService: OrderService,
        private shipmentTypeService: ShipmentTypeService,
        private driverService: DriverService,
        private helperService: HelperService,
        private location: Location,
        private imagesService: ImagesService,
        private router: Router,
        private dateTimeService: DateTimeService
    ) {
    }

    ngOnInit() {
        this.shipmentTypes = []


        this.activatedRoute
            .data
            .pipe(untilDestroyed(this))
            .subscribe((data) => {
                this.isQuote = data?.type === 'quote';
                this.order.isOrder = !this.isQuote;
            })

        this.orderService.reloadOrderAttachments$
            .pipe(untilDestroyed(this))
            .subscribe((res) => {
                if (res && this.orderId) {
                    this.getAttachments(this.orderId);
                }
            })
        this.orderService.reloadRoutesData$
            .pipe(untilDestroyed(this))
            .subscribe(async (res) => {
                if (res && this.order?.orderId) {
                    this.order = await this.loadRoutesData(this.order)
                    if (res === 'update' && !isEqual(this._data.routes, this.order.routes)) {
                        this.orderService.updateCacheData.next(this.order);
                    }
                    this.orderService.reloadRoutesData.next(false)
                }
            })
        this.orderService.updateCacheData$
            .pipe(untilDestroyed(this))
            .subscribe(async (res) => {
                if (res && (res?.orderId === this._data?.orderId)) {
                    this.dataChange.emit(res);
                    this.orderService.updateCacheData.next(null)
                }
            })

        this.activatedRoute.queryParams.pipe(untilDestroyed(this))
            .subscribe(({ orderType }) => {
                if (orderType === 'port') {
                    this.portOrder = true
                } else {
                    this.portOrder = false
                }
            })
        this.orderService.order$
            .pipe(
                untilDestroyed(this),
            )
            .subscribe((order) => {
                if (order && (order?.orderId === this.order?.orderId)) {
                    this.updateOrderData(order);
                    this.dataChange.emit(order);
                    this.orderService.order = null
                }
            })

        this.orderUpdate$
            .pipe(
                untilDestroyed(this),
                debounceTime(1000)
            )
            .subscribe(() => {
                // this.saveOrder();
            })

        this.driverService.getAll({
            Page: 1,
            ItemsPerPage: 200,
            IsOrder: true,
        }).then((data) => {
            this.drivers = data.list
        })

        if (this.order.customer?.customerId !== this.order.customer?.customerId) {
            this.loadShipmentTypes();
        }
        if (this.order.orderId) {
            this.updateOrderData(this.order);
            this.getAttachments(this.order.orderId);
        }

    }

    orderPreviousNextRedirect(orderId: number) {
        this.getOrder(orderId)
    }

    reloadOrder() {
        this.getOrder(this.order?.orderId);
    }

    async handleOrderSave() {
        this.helperService.isLoading = true;
        try {
            await this.orderService.saveOrder(this.order, 'Pricing');
        } catch (error) {
            this.helperService.errorMessage(error)
        } finally {
            this.helperService.isLoading = false;
        }
    }

    async updateOrderData(order: Order) {
        this.orderId = parseInt(order.orderId + '')
        this.order = order;
        this.order = await this.loadRoutesData(this.order)
        if(this.order?.isOrder){
            this.order = await this.loadActivities(this.order)
        }      
        order.date = new Date(order.date) as any;
        order.orderStops.forEach(os => {
            os.dateTimeToDisplay = this.dateTimeService.combineDateAndTime(new Date(os.date), new Date(os.time));
            os.time = new Date(os.time) as any;
        });
        this.order.dateType = 'None'
    }



    getOrder(orderId) {
        if (orderId) {
            this.isLoading = true;
            this.quoteService.get(orderId).then(async (res) => {
                if (res) {
                    this.setCorrectOrderDate(res);
                }
                res = await this.loadRoutesData(res)
                if(res?.isOrder){
                    res = await this.loadActivities(res)
                }              
                this.orderService.order = res;
                this.dataChange.emit(res);
            }).finally(() => {
                this.isLoading = false;
            }).catch((error) => {
                this.orderService.order = null;
                this.router.navigateByUrl(this.isQuote ? 'app/quotes' : 'app/orders');
                this.helperService.errorMessage(`${this.isQuote ? 'Quote ' : 'Order '} not found.`);
            })
        } else {
            this.orderService.order = null;
        }
    }

    setCorrectOrderDate(order: Order) {
        for (let stop of order.orderStops) {
            stop.dateTimeToDisplay = this.dateTimeService.combineDateAndTime(new Date(stop.date), new Date(stop.time));
        }
    }

    openCalender() {
        const activeModal = this.modalService.open(CalenderDialogComponent, {
            windowClass: 'custom-calender-style'
        });

        const repeatOptionsMap = {
            recursSunday: 7,
            recursSaturday: 6,
            recursFriday: 5,
            recursThursday: 4,
            recursWednesday: 3,
            recursTuesday: 2,
            recursMonday: 1,
            recursEveryMonth: 'monthly',
            recursEveryOtherWeek: 'otherWeek',
        }

        const componentInstance = activeModal.componentInstance as CalenderDialogComponent
        componentInstance.min = this.order.date;

        const repeatOptions = []
        for (const key in repeatOptionsMap) {
            if (this.order[key]) {
                repeatOptions.push(repeatOptionsMap[key]);
            }
        }

        componentInstance.value = {
            repeatOptions,
            selectedDates: map(this.order.recurringOrderDays, ({ date }) => moment.utc(date).format('YYYY-MM-DD')),
            excludeDates: map(this.order.recurringOrderSkipDays, ({ date }) => moment.utc(date).format('YYYY-MM-DD')),
        }


        componentInstance.valueChange.subscribe((value) => {
            for (const key in repeatOptionsMap) {
                this.order[key] = value.repeatOptions.indexOf(repeatOptionsMap[key]) >= 0
            }

            this.order.recurringOrderDays = map(value.selectedDates, (date) => ({ date }))
            this.order.recurringOrderSkipDays = map(value.excludeDates, (date) => ({ date }));

        })

    }

    async loadRoutesData(order) {
        if (order?.orderId) {
            await this.quoteService.getOrderRoutes(order?.orderId).then((data: any) => {
                order.routes = data || []
            }).catch((error) => {
                order.routes = []
                this.helperService.errorMessage(error)
            })
            return order
        }
    }

    async loadActivities(order) {
        if (order?.orderId) {
            await this.orderService.getOrderActivities(order?.orderId).then((data: any) => {         
                const updatedOrderStops = chain(order.orderStops)
                    .map(orderStop => {
                        const activities = filter(data, { orderStopId: orderStop?.orderStopId });
                        if (activities) {
                            orderStop.activities = activities;
                        }
                        return orderStop;
                    })
                    .value();
                order.orderStops = updatedOrderStops;
            }).catch((error) => {
                order.routes = []
                this.helperService.errorMessage(error)
            })            
            return order
        }
    }

    async saveOrder(updateRequest?: Partial<Order>) {

        const order = {
            ...this.order,
            ...updateRequest,
        }

        this.helperService.isLoading = true;
        try {
            await this.orderService.saveOrder(order);
        } catch (error) {
            this.helperService.errorMessage(error)
        } finally {
            this.helperService.isLoading = false;
        }
    }

    loadShipmentTypes() {
        if (this.order?.customer?.customerId != null) {
            this.shipmentTypeService.getShipmentTypeList(this.order?.customer?.customerId).then((rep) => {
                this.shipmentTypes = rep || []
                if (this.order.shipmentType == null) {
                    this.order.shipmentType = this.shipmentTypes.find(st => st.customerId === this.order?.customer?.customerId) ?? this.shipmentTypes[0];
                }
            })
        }
    }

    handleDragAndDrop(event: CdkDragDrop<OrderStop, OrderStop>) {
        const fromOrderStop = event.previousContainer.data;
        const toOrderStop = event.container.data;

        const oldOrderStopIndex = findIndex(this.order.orderStops, { orderStopId: fromOrderStop.orderStopId })
        const newOrderStopIndex = findIndex(this.order.orderStops, { orderStopId: toOrderStop.orderStopId })

        const removedItems = this.order.orderStops[oldOrderStopIndex].orderItemStops.splice(event.previousIndex, 1)
        this.order.orderStops[newOrderStopIndex].orderItemStops.splice(event.currentIndex, 0, removedItems[0])

        this.order = this.order;

        this.saveOrder(this.order);
    }

    importStops(event) {
        let file = event.target.files[0];
        if (file.type === 'text/csv') {
            let formData = new FormData();
            formData.append('csvFile', file);
            formData.append('orderJson', JSON.stringify(this.order));
            this.quoteService.importOrderStops(formData)
                .then(data => {
                    if (data.success) {
                        this.getOrder(data.data.orderId);
                        // this.location.replaceState('/app/orders/edit/' + data.data.orderId);
                        this.router.navigate(['/app/orders'], { queryParams: { id: data.data.orderId } });
                        this.helperService.successMessage("The order stops successfully imported!")
                    }
                    else {
                        data.messages.forEach(msg => {
                            this.helperService.errorMessage(msg)
                        });
                    }
                });
        }
        else {
            this.helperService.errorMessage('Invalid file type, please select a CSV file.');
        }
    }

    getImportExample() {
        this.quoteService.getImportExample().subscribe(res => {
            saveAs(new Blob([res]), 'Import_order_stops_example.csv');
        });
    }


    addLocation() {
        const activeModal = this.modalService.open(AddLocationComponent, {
            scrollable: true,
            size: "lg",
        });
        activeModal.componentInstance.quote = this.order;

        activeModal.result.then(
            (result) => {
                if (result) {

                    // console.log(unionWith(this.order.orderStops, result, isEqual));
                    // this.order.orderStops = [...this.order.orderStops, result];
                    // console.log(this.order.orderStops, 'before stopItem add-edit-order');
                }
            },
            () => { }
        );
    }


    async getAttachments(orderId) {
        if (orderId) {
            try {
                this.orderAttachments = await this.imagesService.getListByOrderAttachments(orderId);
            } catch (error) {
                this.helperService.errorMessage(error)
            }
        }
    }

    async uploadOrderAttachments(files: any[]) {
        try {
            const orderId = this.orderId;
            for await (const file of files) {
                await this.imagesService.uploadAttachment(EntityType.Order, orderId, file);
            }
        } catch (error) {
            this.helperService.errorMessage(error)
        } finally {
            this.getAttachments(this.orderId);
        }
    }




}
