import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrderTripsComponent } from './order-trips.component';
import { AttachmentsModule } from '../attachments/attachments.module';
import { SharedModule } from '@app/shared/shared.module';
import { FileUploadModule } from '../file-upload/file-upload.module';

@NgModule({
	declarations: [
		OrderTripsComponent
	],
	imports: [
		CommonModule,
		SharedModule,
		AttachmentsModule,
		FileUploadModule
	],
	exports: [
		OrderTripsComponent
	]
})
export class OrderTripsModule { }
