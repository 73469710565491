// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
}
:host .btn:disabled {
  border: none;
  background-color: #f5f8fa !important;
}`, "",{"version":3,"sources":["webpack://./src/app/@shared/timepicker/timepicker.component.scss"],"names":[],"mappings":"AACA;EACI,cAAA;AAAJ;AAEI;EACI,YAAA;EACA,oCAAA;AAAR","sourcesContent":["@import \"var\";\n:host{\n    display: block;\n\n    .btn:disabled{\n        border: none;\n        background-color: $light !important;\n    }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
