import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { TruckService } from '@app/shared/services/truck.service';
import { HelperService } from '@app/shared/services/helper.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AddEditTruckComponent } from '../add-edit-truck/add-edit-truck.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DatatableComponent } from '@app/@shared/datatable';
import { AppService } from '@app/shared/services/app.service';
import { debounceTime } from 'rxjs/operators';

@UntilDestroy()
@Component({
    selector: 'app-truck-list',
    templateUrl: './truck-list.component.html',
    styleUrls: ['./truck-list.component.scss'],
})
export class TruckListComponent implements OnInit, OnDestroy {

    title = "Fleet"
    search = "";
    @ViewChild('datatable') datatable: DatatableComponent

    constructor(
        private ngbModal: NgbModal,
        private helperService: HelperService,
        private truckService: TruckService,
        private appService: AppService,
    ) { }

    ngOnInit() {
        this.appService.$searchTerm
            .pipe(
                untilDestroyed(this),
                debounceTime(500)
            )
            .subscribe((text) => {
                this.datatable.setSearchValue(text);
            })
    }

    ngOnDestroy() {
        this.appService.searchTerm = ''
    }

    getData = (filter?) => {
        this.truckService.cancelPendingRequestGetTrucksWithVehicles();
        return this.truckService.getTrucksWithVehicles(filter);
    }

    openAddEdit(item?) {
        const modalRef = this.ngbModal.open(AddEditTruckComponent, { size: 'lg' });
        if (item) {
            modalRef.componentInstance.item = item;
        }
        modalRef.closed
            .pipe(untilDestroyed(this))
            .subscribe((data) => {
                if (data) {
                    this.datatable.refresh()
                }
            });
    }

    onDelete(item) {
        this.truckService.delete(item?.truck?.truckId).then((res) => {
            this.helperService.successMessage('Deleted Successfully');
            this.datatable.refresh();
        }).catch((error) => {
            this.helperService.errorMessage(error);
        })
    }

}
