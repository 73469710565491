import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { toParams } from 'src/utils/to-params';
import { ApiPaginationResponse, ApiResponse, PaginationRequest } from '@app/models/core/base';
import { User } from '@app/models/user.model';


@Injectable({
    providedIn: 'root'
})
export class CreditInquiriesService {
    basePath = "CreditInquiries";

    constructor(
        private http: HttpClient
    ) {

    }

    getAll(request?: any) {
        return this.http.get<ApiPaginationResponse<any>>(`${this.basePath}`, { params: toParams(request) })
            .toPromise()
            .then(({ data }) => data);
    }

    create(request) {
        return this.http.post(`${this.basePath}`, request)
            .toPromise();
    }

}