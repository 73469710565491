import { Component, Input } from "@angular/core";
import { PipelineEnum } from "@app/models/customer.model";
import { CustomerService } from "@app/shared/services/customer.service";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
    selector: 'app-customer-information',
    templateUrl: './customer-information-dialog-component.html',
    styleUrls: ['./customer-information-dialog-component.scss']
})
export class CustomerInformationDialogComponent {
    @Input() customer: any

    constructor(
        public activeModal: NgbActiveModal,
        public customerService: CustomerService
    ) {
    }

    close() {
        this.activeModal.close();
    }

    async useForCustomer() {
        await this.customerService.updatePipeline(this.customer.customerId, PipelineEnum.CUSTOMER);

        this.activeModal.close(true);
    }
}