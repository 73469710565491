import { Component, ViewChild } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ListTabsComponent } from '@app/@shared/list-tabs/list-tabs.component';
import { HelperService } from '@app/shared/services/helper.service';
import { OutsourceCompanyService } from '@app/shared/services/outsource-company.service';
import { OutsourceCompany } from '@app/models/outsource-company.model';

@UntilDestroy()
@Component({
	selector: 'app-outsource-company-layout',
	templateUrl: './outsource-company-layout.component.html',
	styleUrls: ['./outsource-company-layout.component.scss'],

})

export class OutsourceCompanyLayoutComponent {

	@ViewChild('listTabs') listTabs: ListTabsComponent;

	constructor(
		private helperService: HelperService,
		public outsourceCompanyService: OutsourceCompanyService,
	) {

	}

	getTabTitle = (value: OutsourceCompany, key) => {
		return value?.outsourceCompanyName || 'Loading...';
	}

	updateTabData(data) {
		const tabId = this.listTabs.activeTab;
		this.listTabs.setTab(tabId, { data });
	}

	loadTabData = async (outsourceCompanyId: string) => {

		return await this.outsourceCompanyService.get(outsourceCompanyId).then((res) => {
			const outsourceCompany = res;
			if (outsourceCompany.companyEmails.length === 0) {
				outsourceCompany.companyEmails.push({
					isActive: true,
				});
			}
			return outsourceCompany;
		}).catch(() => {
			this.helperService.errorMessage("outsource not found.")
			this.listTabs.closeTab(outsourceCompanyId, true);
		})
	}
}