import { Company } from '@models/company.model';
import { Route } from '@models/route.model';
import { BaseModel } from '@models/base.model';
import { OutsourceCompany } from './outsource-company.model';

export interface Driver extends BaseModel {
    driverId?: number;
    companyId?: number;
    company?: Company;
    driverImage?: string;
    ratings?: number;
    fullName?: string;
    firstName?: string;
    lastName?: string;
    middleName?: string;
    phoneNumber?: string;
    cellNumber?: string;
    email?: string;
    addressLine1?: string;
    city?: string;
    countryId?: number | string;
    state?: string;
    zip?: string;
    ssn?: string;
    gender?: string;
    dob?: Date;
    insurancenCardImage?: string;
    licenseImage?: string;
    outsourceCompanyId?: number;
    outsourceCompany?: OutsourceCompany;
    licenseNumber?: string;
    licenseType?: string;
    licenseExpires?: Date;
    licenseCountry?: string;
    licenseState?: string;
    status?: DriverStatusEnum;
    quickBooksClassName?: string;
    telegramUserId?: string;
    routes?: Route[];
    createdTime?: string;
    createdUser?: string;
    updatedTime?: string;
    updatedUser?: string;
    payout?: DriverPayoutEnum;
    employment?: DriverEmploymentEnum;
    driverStatus?: DriverDriverStatusEnum;
    driverType?: DriverTypeEnum;
}


export enum DriverStatusEnum {
    ACTIVE = 'Active',
    INACTIVE = 'Inactive',
    DELETED = 'Deleted'
}

export enum DriverDriverStatusEnum {
    NEW = 'New',
    APPROVED = 'Approved',
    BLOCKED = 'Blocked'
}

export enum DriverTypeEnum {
    DEFAULT = 'Default',
    OUTSOURCE  = 'Outsource '
}

export enum DriverEmploymentEnum {
    FULL_TIME = "FullTime",
    PART_TIME = "PartTime",
    ONE_TIME = "OneTime"
}

export enum DriverPayoutEnum {
    HOURLY = "Hourly",
    FIXED_SALARY = "FixedSalary",
    DISTANCE = "Distance",
    FIX_DISTANCE = "FixDistance",
    FIX_HOURLY = "FixHourly"
}