import { Component, OnInit, ViewChild } from '@angular/core';
import { Expense, ExpenseSubtype, NewExpense } from '@models/expense.model';
import { ExpenseService } from '@app/shared/services/expense.service';
import { HelperService } from '@app/shared/services/helper.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ExpenseDetailsComponent } from '../expense-details/expense-details.component';
import { DatatableComponent } from '@app/@shared/datatable';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { map, omit } from 'lodash';
import { expensesData, expensesLinesData } from '@app/data/expenses';
import { Router } from '@angular/router';
import { Company } from '@app/models/company.model';
import { array, number, object, string } from 'yup';
import { removeBinding } from '@app/@shared/utils';
import { AuthService } from '@app/shared/services/auth/auth.service';
import { CompanyService } from '@app/shared/services/company.service';
import { TruckService } from '@app/shared/services/truck.service';
import { NewExpenseService } from '@app/shared/services/new-expense.service';
import moment = require('moment');

@UntilDestroy()
@Component({
    selector: 'app-expense-list',
    templateUrl: './expense-list.component.html',
    styleUrls: ['./expense-list.component.scss'],
})
export class ExpenseListComponent implements OnInit {
    company: Company = {
        truckMonthlyExpenses: [],
        truckMilesPerMonth: null,
    }

    validationSchema = object().shape({
        truckMonthlyExpenses: array().nullable()
            .of(
                object().shape({
                    name: string().label('Name').nullable().required(),
                    price: number().label('Price').nullable().positive().required(),
                    type: string().label('Type').nullable().required(),
                })
            ),
        truckMilesPerMonth: number().nullable().positive(),
    });


    truckExpensesTypes: string
    searchDate: Date;
    title = "Expense";
    activeTrucksTab = "finance";
    lineChart: any;
    @ViewChild('datatable') datatable: DatatableComponent
    activeTab = 'trucks';
    doughnutChart: any;
    expensesChartCounts = expensesData;
    expensesChartLines = expensesLinesData;
    expensesLabels = [
        {
            name: 'Truck',
            color: 'success',
        },
        {
            name: 'Drivers',
            color: 'yellow',
        },
        {
            name: 'Office',
            color: 'warning',
        },
        {
            name: 'Other',
            color: 'primary',
        },
    ]
    submitLoading: boolean;
    updatedExpenses: NewExpense[] = [];
    ExpenseSubtype = ExpenseSubtype;
    allTrucksExpenses: any;
    date: Date;
    @ViewChild('truckDatatable') truckDatatable: DatatableComponent

    constructor(
        private expenseService: ExpenseService,
        private newExpenseService: NewExpenseService,
        private router: Router,
        private helperService: HelperService,
        private companyService: CompanyService,
        private authService: AuthService,
        private ngbModal: NgbModal,
        private truckService: TruckService,
    ) {
        this.doughnutChart = {
            data: {
                datasets: [{
                    data: map(this.expensesChartCounts, "count"),
                    backgroundColor: map(this.expensesChartCounts, "color"),
                }]
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                cutout: '84%',
                hover: { mode: null },
                plugins: { tooltip: { enabled: false} },
                elements: {
                    arc: { borderWidth: 0 },
                },
            },
        };

        this.lineChart = {
            data: {
                labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                datasets: this.expensesChartLines
            },
            options: {
                responsive: false,
                maintainAspectRatio: false,
                elements: {
                    point: {
                        radius: 0,
                    },
                    line: {
                        tension: 0.5,
                        fill: false,
                        borderWidth: 1
                    }
                },
                scales: {
                    yAxes: [{
                        ticks: {
                            stepSize: 5000,
                            callback: function (value) {
                                var ranges = [
                                    { divider: 1e6, suffix: 'M' },
                                    { divider: 1e3, suffix: 'k' }
                                ];
                                function formatNumber(n) {
                                    for (var i = 0; i < ranges.length; i++) {
                                        if (n >= ranges[i].divider) {
                                            return (n / ranges[i].divider).toString() + ranges[i].suffix;
                                        }
                                    }
                                    return n;
                                }
                                return formatNumber(value);
                            }
                        }
                    }]
                }
            },
        }

        const now = new Date();
        this.date = new Date(now.getFullYear(), now.getMonth(), 10);
    }

    getData = (request?: any) => {
        return this.expenseService.getAll(request);
    }

    ngOnInit() {
        this.authService.$user
            .pipe(untilDestroyed(this))
            .subscribe(async (data) => {
                this.company = await removeBinding(data?.company);

                if (!this.company?.truckMonthlyExpenses) {
                    this.company.truckMonthlyExpenses = []
                }

            })

            this.loadAllTrucksExpenses();
    }

    onSubmit(f) {
        if (f?.isValid) {
            this.submitLoading = true;
            this.companyService.update(omit(this.company, 'companyId')).then((resp: any) => {
                this.company = resp;
                this.helperService.successMessage("Expense successfully updated");
                this.authService.user = {
                    ...this.authService.user,
                    company: this.company,
                }
                this.submitLoading = false;
            }).catch((error) => {
                this.helperService.errorMessage(error)
                this.submitLoading = false;
            });
        }
    }

    handleRowClick(row: Expense) {
        this.router.navigateByUrl('/app/settings/expenses/truck');
    }

    getFixedCostPerMile() {
        const sum = this.company?.truckMonthlyExpenses?.reduce((a, b) => a + b.price, 0) ?? 0;
        if (this.company?.truckMilesPerMonth != null) {
            return `\$${(sum / this.company?.truckMilesPerMonth).toFixed(2)}`;
        }
        return 'N/A';
    }


    openAddEdit(item?: Expense) {
        const ref = this.ngbModal.open(ExpenseDetailsComponent, {
            size: 'md'
        })
        ref.componentInstance.item = item

        ref.closed.pipe(untilDestroyed(this)).subscribe((isReload) => {
            if (isReload) {
                this.datatable.refresh()
            }
        })
    }

    getTrucksWithExpenses = (request?: any) => {
        return this.truckService.getTrucksWithExpenses(request).then((resp: any) => {
            return resp;
                
        }).catch((error) => {
            this.helperService.errorMessage(error)
        });
    }

    getTrucksExpenseAmount(expenses: any[], expenseType: ExpenseSubtype, truckId: number, date: Date): number {
        date = this.date;
    
        // Check if the updated expense exists in updatedExpenses
        const updatedExpense = this.updatedExpenses.find(exp =>
            exp.truckId === truckId &&
            exp.date && this.isSameYearAndMonth(exp.date, date) &&
            exp.subtype === expenseType
        );
    
        if (updatedExpense) {
            return updatedExpense.amount || 0;
        }
    
        // Check if the original expense exists in expenses
        const expense = expenses.find(exp =>
            exp.truckId === truckId &&
            exp.date && this.isSameYearAndMonth(exp.date, date) &&
            exp.subtype === expenseType
        );
    
        return expense ? expense.amount || 0 : 0;
    }

    loadAllTrucksExpenses() {   
        // TODO
        this.truckService.getAllTrucksExpenses({date: this.date.toISOString()}).then((resp: any) => {
            this.allTrucksExpenses = resp;
        }).catch((error) => {
            this.helperService.errorMessage(error)
        });
    }

    changeTrucksExpenseAmount(
        newAmount: number,
        subtype: ExpenseSubtype,
        expenses: NewExpense[],
        truckId: number,
        date: Date
    ) {
        date = this.date;

        // Helper function to find an expense in an array
        const findExpense = (arr: NewExpense[], expenseSubtype: ExpenseSubtype) => arr.find(exp =>
            exp.truckId === truckId &&
            exp.date && this.isSameYearAndMonth(exp.date, date) &&
            exp.subtype === expenseSubtype
        );

        // Look for the expense in the updatedExpenses array first
        let expense = findExpense(this.updatedExpenses, subtype);

        if (expense) {
            // If found in updatedExpenses, update the amount
            expense.amount = newAmount;
        } else {
            // If not found in updatedExpenses, look for it in the original expenses array
            expense = findExpense(expenses, subtype);

            if (expense) {
                // If found in original expenses, create a copy, update the amount, and add it to updatedExpenses
                const updatedExpense = { ...expense, amount: newAmount };
                this.updatedExpenses.push(updatedExpense);
            } else {
                // If not found in either array, create a new expense and add it to updatedExpenses
                const newExpense: NewExpense = {
                    truckId,
                    date,
                    subtype,
                    amount: newAmount,
                    // Add other properties if necessary
                };
                this.updatedExpenses.push(newExpense);
            }
        }

        if (subtype === ExpenseSubtype.OdometerStart || subtype === ExpenseSubtype.OdometerEnd) {
            const startExpense = findExpense(this.updatedExpenses, ExpenseSubtype.OdometerStart) || findExpense(expenses, ExpenseSubtype.OdometerStart);
            const endExpense = findExpense(this.updatedExpenses, ExpenseSubtype.OdometerEnd) || findExpense(expenses, ExpenseSubtype.OdometerEnd);

            if (startExpense && endExpense) {
                const milesAmount = (endExpense.amount || 0) - (startExpense.amount || 0);

                // Update the Miles value in updatedExpenses
                let milesExpense = this.updatedExpenses.find(exp =>
                    exp.truckId === truckId &&
                    this.isSameYearAndMonth(exp.date, date) &&
                    exp.subtype === ExpenseSubtype.TruckMiles
                );

                // If not found in updatedExpenses, look for it in the original expenses array
                if (!milesExpense) {
                    milesExpense = findExpense(expenses, ExpenseSubtype.TruckMiles);

                    if (milesExpense) {
                        // If found in original expenses, create a copy, update the amount, and add it to updatedExpenses
                        const updatedMilesExpense = { ...milesExpense, amount: milesAmount };
                        this.updatedExpenses.push(updatedMilesExpense);
                    } else {
                        // If not found in either array, create a new expense and add it to updatedExpenses
                        const newMilesExpense: NewExpense = {
                            truckId,
                            date,
                            subtype: ExpenseSubtype.TruckMiles,
                            amount: milesAmount,
                            // Add other properties if necessary
                        };
                        this.updatedExpenses.push(newMilesExpense);
                    }
                } else {
                    // If found in updatedExpenses, update the amount
                    milesExpense.amount = milesAmount;
                }
            }
        }
    }

    saveTrucksExpense(truckExpenses: any[]) {
        if (this.updatedExpenses.length > 0) {
            const truckIdsWithIncompleteOdometers = new Set<number>();

            // First pass: collect truck IDs with incomplete odometer data
            this.updatedExpenses.forEach(expense => {
                if (expense.subtype === ExpenseSubtype.OdometerStart || expense.subtype === ExpenseSubtype.OdometerEnd) {
                    const pairedExpenseType = expense.subtype === ExpenseSubtype.OdometerStart ? ExpenseSubtype.OdometerEnd : ExpenseSubtype.OdometerStart;

                    // Check in updatedExpenses first
                    const pairedExpenseInUpdated = this.updatedExpenses.some(exp =>
                        exp.truckId === expense.truckId &&
                        this.isSameYearAndMonth(exp.date, expense.date) &&
                        exp.subtype === pairedExpenseType
                    );

                    // Flatten truckExpenses to get all original expenses
                    const originalExpenses = truckExpenses.flatMap(truck => truck.expenses);

                    // Check in original expenses if not found in updatedExpenses
                    const pairedExpenseInOriginal = pairedExpenseInUpdated || originalExpenses.some(exp =>
                        exp.truckId === expense.truckId &&
                        this.isSameYearAndMonth(exp.date, expense.date) &&
                        exp.subtype === pairedExpenseType
                    );

                    if (!pairedExpenseInOriginal) {
                        truckIdsWithIncompleteOdometers.add(expense.truckId!);
                    }
                }
            });

            if (truckIdsWithIncompleteOdometers.size > 0) {
                // Show error message and return if there are incomplete odometer entries
                this.helperService.errorMessage("Both Odometer Start and Odometer End values must be provided for each truck.");
                return;
            }

            this.submitLoading = true;

            this.newExpenseService.addMultiple(this.updatedExpenses).then(res => {
                // Handle successful response
            })
                .catch(err => {
                // Handle error
                })
                .finally(() => {
                    this.submitLoading = false;
                    this.truckDatatable.refresh(true);
                    this.loadAllTrucksExpenses();
                    this.updatedExpenses = [];
                });
        }
    }

    getOverallExpenses(expenses: NewExpense[]) {
        const total = expenses
            .filter(exp => exp.date && this.isSameYearAndMonth(exp.date, this.date) &&
                exp.subtype !== ExpenseSubtype.TruckMiles && exp.subtype !== ExpenseSubtype.OdometerStart && exp.subtype !== ExpenseSubtype.OdometerEnd)
            .reduce((sum, expense) => sum + (expense.amount || 0), 0);
    
        return `$ ${total}`;
    }

    getEnumKey(enumValue: number): string {
        return ExpenseSubtype[enumValue];
    }

    changeMonth(offset: number) {
        this.date = new Date(this.date.setMonth(this.date.getMonth() + offset));
        this.onDateChange();
    }

    onDateChange() {
        this.truckDatatable.refresh(true);
        this.loadAllTrucksExpenses();
        this.updatedExpenses = [];
    }

    formatDateToMonthYear(date: Date): string {
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        return `${year}-${month}`;
    }

    setCurrentMonth() {
        const now = new Date();
        this.date = new Date(now.getFullYear(), now.getMonth(), 10);
        this.onDateChange();
    }

    private isSameYearAndMonth(date1: any, date2: any): boolean {
        const d1 = new Date(date1);
        const d2 = new Date(date2);
        return d1.getFullYear() === d2.getFullYear() && d1.getMonth() === d2.getMonth();
    }
}
