import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AddContactComponent } from './add-contact/add-contact.component';
import { Order } from '@app/models/order.model';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { cloneDeep } from 'lodash';
import { OrderStop, OrderStopTimeTypeEnum, OrderStopTypeEnum } from '@app/models/order-stop.model';

@Component({
  selector: 'app-route-order-detail',
  templateUrl: './route-order-detail.component.html',
  styleUrls: ['./route-order-detail.component.scss']
})
export class RouteOrderDetailComponent implements OnInit {

  private _order: Order;
  @Input()
  public get order(): Order {
    return this._order;
  }
  public set order(value: Order) {
    this._order = value;
    this.routes = value.orderStops
  }

  @Output() onOrderChange = new EventEmitter<Order>()


  defaultRoute: OrderStop = {
    address: {},
    arrivalTime: null,
    timeType: OrderStopTimeTypeEnum.CUSTOM,
    time: null,
    notes: null,
    orderStopType: OrderStopTypeEnum.PICKUP,
    locationCompanyName: null,
    locationContactNumber: null,
  }

  routes: OrderStop[] = []

  constructor(
    private modalService: NgbModal,
  ) { }

  ngOnInit(): void {
  }

  onRouteChange(event: CdkDragDrop<string[]>) {
    const array = cloneDeep(this.routes);
    moveItemInArray(array, event.previousIndex, event.currentIndex);
    this.routes = array;
  }

  saveOrder() {

    this.routes.map((item, index) => {
      if (index === 0) {
        item.orderStopType = OrderStopTypeEnum.PICKUP
      } else if (index === this.routes?.length - 1) {
        item.orderStopType = OrderStopTypeEnum.DELIVERY
      } else {
        item.orderStopType = OrderStopTypeEnum.TRANSFER
      }
      return item;
    })

    const order = {
      ...this.order,
      orderStops: this.routes,
    }
    this.onOrderChange.emit(order)
  }

  addContact(item: OrderStop) {
    const activeModal = this.modalService.open(AddContactComponent, {
      scrollable: true,
      size: "sm",
    });

    activeModal.componentInstance.contact = {
      name: item.locationCompanyName,
      phoneNumber: item.locationContactNumber,
    }

    activeModal.result.then(
      (contact) => {
        if (contact) {
          item.locationCompanyName = contact.name
          item.locationContactNumber = contact.phoneNumber
        }
      },
      () => { }
    );
  }
}
