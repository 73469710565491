import { CostType } from "@app/models/cost-line.model";
import { OrderStatusEnum, OrderTripStatusEnum } from "@app/models/order.model";
import { PickupType, PickupTypeEnum } from "@app/models/pickup-type";

export const pickupTypes: PickupType[] = [
  {
    label: 'Boxes & Crates',
    value: PickupTypeEnum.BOXES_CRATES,
    image: '/assets/images/b_and_c.png',
  },
  {
    label: 'Pallets',
    value: PickupTypeEnum.PALLETS,
    image: '/assets/images/pallet.png',
  },
  {
    label: 'Full Load',
    value: PickupTypeEnum.FULL_LOAD,
    image: '/assets/images/full_load.png',
  },
  // {
  //   label: 'CFS',
  //   value: PickupTypeEnum.CFS,
  //   image: '/assets/images/cfs.png',
  // },
  {
    label: 'Other',
    value: PickupTypeEnum.OTHER,
    image: '/assets/images/pickup-type-otherhand.png',
  }
];

export const costLines = [
  {
    type: CostType.FuleCharges,
    name: 'Fuel',
    // color: '#2ccc71',
  },
  {
    type: CostType.Accessories,
    name: 'Accessories',
    // color: '#cc0000',
  },
  {
    type: CostType.Cubic,
    name: 'Cubic',
    // color: '#00caff',
  },
  {
    type: CostType.DriverCost,
    name: 'Driver Cost',
    // color: '#5a51de',
  },
  {
    type: CostType.Fee,
    name: 'Fee',
    // color: '#f39c13',
  },
  {
    type: CostType.Toll,
    name: 'Tolls',
    // color: '#2ccc71',
  },
  {
    type: CostType.Other,
    name: 'Other',
    // color: '#cc0000',
  },
  {
    type: CostType.MinPrice,
    name: 'Min Price',
    // color: '#00caff',
  },
  {
    type: CostType.Weight,
    name: 'Weight',
    // color: '#5a51de',
  },
  {
    type: CostType.Surcharge,
    name: 'Surcharge',
    // color: '#f39c13',
  },
  {
    type: CostType.Milage,
    name: 'Millage',
    // color: '#2ccc71',
  },
  {
    type: CostType.Item,
    name: 'Item',
    // color: '#cc0000',
  },
  {
    type: CostType.FragileFee,
    name: 'Fragile Fee',
    // color: '#00caff',
  },
  {
    type: CostType.HazardousFee,
    name: 'Hazardous Fee',
    // color: '#5a51de',
  },
  {
    type: CostType.OverweightFee,
    name: 'Overweight Fee',
    // color: '#f39c13',
  },
  {
    type: CostType.RefrigeratedFee,
    name: 'Refrigerated Fee',
    // color: '#2ccc71',
  },
  {
    type: CostType.Time,
    name: 'Time',
    // color: '#cc0000',
  },
  {
    type: CostType.Truck,
    name: 'Truck',
    // color: '#5a51de',
  },
  {
    type: CostType.AdditionalCharges,
    name: 'Additional Charges',
    // color: '#5a51de',
  },
];

export const costLinesByType = new Map(costLines.map(object => {
  return [object.type, object];
}))


export const orderPortStatusData = [
  {
    status: 'Flight Departed',
    title: 'Flight Departed',
    icon: 'icon-order-flight-departed',
    count: 12
  },
  {
    status: 'Flight Arrived',
    title: 'Flight Arrived',
    icon: 'icon-order-flight-arrived',
    count: 12
  },
  {
    status: 'DOC Ready',
    title: 'DOC Ready',
    icon: 'icon-order-unassigned',
    count: 12
  },
  {
    status: 'Freight Ready',
    title: 'Freight Ready',
    icon: 'icon-order-on-route',
    count: 12
  },
  {
    status: 'Processed',
    title: 'Processed',
    icon: 'icon-order-dispatched',
    count: 12
  },
  {
    status: 'Picked Up',
    title: 'Picked Up',
    icon: 'icon-order-picked-up',
    count: 12
  },
]


export const orderStatusData = [
  {
    status: OrderStatusEnum.DRAFT,
    title: "Draft",
    count: 0,
    icon: "icon-order-draft",
    color: '#FDD836',
    type: 'draftsCount',
    filterType: ''
  },
  {
    status: OrderStatusEnum.UNASSIGNED,
    title: "Unassigned",
    count: 0,
    icon: "icon-order-unassigned",
    color: '#FF4E4E',
    type: 'unassignedOrdersCount',
    filterType: OrderTripStatusEnum.OPEN
  },
  {
    status: OrderStatusEnum.DISPATCHED,
    title: "Dispatched",
    count: 0,
    icon: "icon-order-dispatched",
    color: '#00BAD7',
    type: 'dispatchedOrdersCount',
    filterType: OrderTripStatusEnum.ASSIGNED
  },
  {
    status: OrderStatusEnum.ON_ROUTE,
    title: "On Route",
    count: 0,
    icon: "icon-order-on-route",
    color: '#4CB75C',
    type: 'onRouteCount',
    filterType: OrderTripStatusEnum.INTRANSIT
  },
  {
    status: OrderStatusEnum.CLOSED,
    title: "Complete",
    count: 0,
    icon: "icon-box",
    color: '#AAB8C1',
    type: 'completeCount',
    filterType: OrderTripStatusEnum.DELIVERED
  },
]

export const openOrders = [
  {
    status: OrderStatusEnum.UNASSIGNED,
    title: "Unassigned",
    count: 0,
    icon: "icon-order-unassigned",
    color: '#FF4E4E',
    type: 'unassignedOrdersCount'
  },
  {
    status: OrderStatusEnum.DISPATCHED,
    title: "Dispatched",
    count: 0,
    icon: "icon-order-dispatched",
    color: '#00BAD7',
    type: 'dispatchedOrdersCount'
  },
  {
    status: OrderStatusEnum.DRAFT,
    title: "Drafts",
    count: 0,
    icon: "icon-order-draft",
    color: '#E2C768',
    type: 'draftsCount'
  },
  {
    status: OrderStatusEnum.QUOTE,
    title: "Quotes",
    count: 0,
    icon: "icon-order-on-route",
    color: '#E38228',
    type: 'onRouteCount'
  },
]



export const OrderItemSizes = [
  {
    itemType: PickupTypeEnum.PALLETS,
    label: 'Standard - 40”L 48”W 75”H',
    value: 'PalletStandard',
  },
  {
    itemType: PickupTypeEnum.PALLETS,
    label: 'Small - 42”L 42”W 75”H',
    value: 'PalletSmall',
  },
  {
    itemType: PickupTypeEnum.PALLETS,
    label: 'Large - 48”L 48”W 75”H',
    value: 'PalletLarge',
  },
  {
    itemType: PickupTypeEnum.FULL_LOAD,
    label: '53\' Tractor Trailer',
    value: 'LoadTrailer',
  },
  {
    itemType: PickupTypeEnum.FULL_LOAD,
    label: '26\' Box Truck',
    value: 'LoadTruck',
  },
  {
    itemType: PickupTypeEnum.FULL_LOAD,
    label: 'Sprinter Van',
    value: 'LoadVan',
  }
]
