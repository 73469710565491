// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host .loading {
  height: 100%;
  width: 100%;
  border: 1px solid #e5e7eb;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}
:host .image {
  height: 100%;
  width: 100%;
  border-radius: 4px;
}`, "",{"version":3,"sources":["webpack://./src/app/@shared/attachment-preview/attachment-preview.component.scss"],"names":[],"mappings":"AAEI;EACI,YAAA;EACA,WAAA;EACA,yBAAA;EACA,kBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AADR;AAGI;EACI,YAAA;EACA,WAAA;EACA,kBAAA;AADR","sourcesContent":["@import \"var\";\n:host {\n    .loading {\n        height: 100%;\n        width: 100%;\n        border: 1px solid $divider;\n        border-radius: 4px;\n        display: flex;\n        align-items: center;\n        justify-content: center;\n    }\n    .image {\n        height: 100%;\n        width: 100%;\n        border-radius: 4px;      \n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
