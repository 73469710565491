// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host .form-section-title {
  color: var(---body-color);
  margin-bottom: 16px;
}
:host .sub-title {
  color: var(--body-color);
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0;
}
:host .sub-title h5 {
  color: inherit;
}`, "",{"version":3,"sources":["webpack://./src/app/components/main/settings/terms-conditions/add-terms-conditions-dialog/add-terms-conditions-dialog.component.scss"],"names":[],"mappings":"AACE;EACE,yBAAA;EACA,mBAAA;AAAJ;AAGE;EACE,wBAAA;EACA,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,gBAAA;AADJ;AAEI;EACE,cAAA;AAAN","sourcesContent":[":host {\n  .form-section-title {\n    color: var(---body-color);\n    margin-bottom: 16px;\n  }\n\n  .sub-title{\n    color: var(--body-color);\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    margin-bottom: 0;\n    h5{\n      color: inherit;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
