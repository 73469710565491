import { NgModule } from '@angular/core';
import { AngularIfieldsComponent } from './angular-ifields.component';



@NgModule({
  declarations: [AngularIfieldsComponent],
  imports: [
  ],
  exports: [AngularIfieldsComponent],
  schemas: []
})
export class AngularIfieldsModule { }
