import { Component, Input, OnInit } from '@angular/core';
import { HelperService } from '@app/shared/services/helper.service';
import { RoutesService } from '@app/shared/services/router.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-route-stop-transfer-dialog',
  templateUrl: './route-stop-transfer-dialog.component.html',
  styleUrls: ['./route-stop-transfer-dialog.component.scss']
})
export class RouteStopTransferDialogComponent implements OnInit {

  @Input() previousRoute: any;
  @Input() currentRoute: any;
  @Input() orderDetails: any;
  @Input() hasPickups: boolean = false;
  @Input() hasDeliveries: boolean = false;
  @Input() hasWholeOrder: boolean = false;
  stopTransferType: string = "SelectedItem";

  constructor(
    public helperService: HelperService,
    public activeModal: NgbActiveModal,
    public routesService: RoutesService,
  ) { }

  stopTransferOptions = [
    {
      value: "SelectedItem",
      name: "Only selected item",
    },
    {
      value: "Order",
      name: "Whole order",
    },
    {
      value: "Pickup",
      name: "Only all pickups",
    },
    {
      value: "Delivery",
      name: "Only all deliveries",
    }
  ]

  ngOnInit(): void {
  }

  checkDisabled(value: string): boolean {
    let disabled = false;  
    switch (value) {
      case 'Delivery':
        disabled = this.hasDeliveries !== true && this.hasDeliveries !== null;
        break;
      case 'Pickup':
        disabled = this.hasPickups !== true && this.hasPickups !== null;
        break;
      case 'Order':
        disabled = this.hasWholeOrder !== true && this.hasWholeOrder !== null;
        break;
      default:
        break;
    }  
    return disabled;
  }

  cancel() {
    this.activeModal.close();
  }

  confirm() {
    this.activeModal.close({ stopTransferType: this.stopTransferType });
  }


}
