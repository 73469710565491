import { BaseModel } from '@models/base.model';
import { Company } from './company.model';

export interface GetSavedSearchInput {
    type?: SavedSearchEntityTypeEnum,
}

export interface SavedSearch extends BaseModel {
    company?: Company,
    companyId?: number,
    createdUser?: string
    data?: any,
    entityType?: SavedSearchEntityTypeEnum,
    name?: string,
    savedSearchId?: number,
    updatedUser?: string,
}


export enum SavedSearchEntityTypeEnum {
    QUOTES = 'Quotes',
    ORDERS = 'Orders'
}