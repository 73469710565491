import { Directive, Input, HostListener, TemplateRef } from '@angular/core';
import { DialogService } from '../dialog.service';
import { Button, CustomComponent, CustomData } from '../custom/custom.component';


@Directive({
    selector: '[customDialog]'
})
export class CustomDirective {

    dataStore: CustomData = {};

    @Input() set template(value: TemplateRef<any>) {
        this.dataStore.template = value;
    }

    @Input() set buttons(value: Button[]) {
        this.dataStore.buttons = value;
    }
    @Input() set title(value) {
        this.dataStore.title = value;
    }

    @Input() set data(value) {
        this.dataStore = { ...this.dataStore, ...value };
    }

    constructor(private dialogService: DialogService) {

    }

    @HostListener('click', ['$event']) onClick($event) {
        $event.stopPropagation();
        this.dialogService.open(CustomComponent, this.dataStore, { size: 'sm' });
    }
}
