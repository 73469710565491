import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    HostBinding,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { UtilService } from 'src/app/services/util.service';
import { EntityTypes } from '@models/entity-type.model';
import { Route, Routes, RouteStatusEnum } from '@models/route.model';
import { RouteItemStatus } from '@models/route-item.model';
import { RouteService } from '@app/services/route.service';
import { Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SamsaraService } from '@app/services/samsara.service';
import { DecimalPipe } from '@angular/common';
import * as equal from 'fast-deep-equal';
import { Subject } from 'rxjs/internal/Subject';
import { Driver } from '@models/driver.model';
import { RouteItemDisplayStatus, RouteItemStop } from '@models/route-item-stop.model';
import { RouteStopGroup } from '@models/route-stop-group.model';
import { AccountingService } from '@app/services/accounting.service';
import { ChargeType } from '@models/charge-type.model';
import { OrderStopType, RouteBox } from '@models/route-box.model';
import { RouteBoxUIGroup } from '@app/helpers/route-box-ui-group';
import { Warehouse } from '@models/warehouse.model';
import { WarehouseService } from '@app/services/warehouse.service';
import { Truck } from '@models/truck.model';
import { haversineDistance } from '@app/helpers/util';
import * as moment from 'moment';
import DirectionsResult = google.maps.DirectionsResult;
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ChatHistoryViewerComponent } from '@app/@shared/chat-history-viewer/chat-history-viewer.component';
import { HelperService } from '@app/shared/services/helper.service';
import { AddEditRouteDialogComponent } from '../add-edit-route-dialog/add-edit-route-dialog.component';
import { MarkerInfo } from '@app/@shared/route-map/route-map.component';
import { RoutesService } from '@app/shared/services/router.service';
//import { RoutesService } from '@app/shared/services/router.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { WaypointInfo } from '@app/models/map.model';
import { AddUnassignedStopsComponent } from './add-unassigned-stops/add-unassigned-stops.component';
import { ConfirmStopDialogComponent } from './confirm-stop-dialog/confirm-stop-dialog.component';
import { environment } from '@environments/environment';
import { CopyDetailsToDriverComponent } from '@app/@shared/copy-details-to-driver/copy-details-to-driver.component';
import { RouteStopTransferDialogComponent } from '../route-details/route-stop-transfer-dialog/route-stop-transfer-dialog.component';
import { chain, cloneDeep, find, flatten, get, isArray, isEqual } from 'lodash';
import { moveItemInArray, transferArrayItem } from 'src/utils/array-menage';
import { ChargeTypeService } from '@app/shared/services/charge-type.service';
import { CdkDragDrop } from '@angular/cdk/drag-drop';

@UntilDestroy()
@Component({
    selector: 'app-routes-status-block',
    templateUrl: './routes-status-block.component.html',
    styleUrls: ['./routes-status-block.component.scss']
})
export class RoutesStatusBlockComponent implements OnInit, OnDestroy {
    @ViewChild('confirmStopDialog') confirmStopDialog: ConfirmStopDialogComponent;
    @HostBinding('class.no-map') isNoMap = false;
    @HostBinding('class.standalone') isStandalone = false;

    @Input() routes: Routes[] = [];
    @Input() name: string;
    @Input() totalStopsCount: number;
    @Input() dateRange: any[] = [];
    @Input() completedStopsCount: number;
    @Input() vehicleFreeCapacity: number;
    @Input() vehicleUsedCapacity: number;
    @Input() nextStopData: any;
    @Input() previousStopData: any;
    @Input() previousStop: string;
    @Input() nextStop: string;
    @Input() driver: Driver;
    @Input() readonly = false;
    @Input() viewType: 'grid' | 'list' = 'grid';
    @Input() isOrder: boolean = false;
    @Input() stopsShown: boolean = false;
    @Input() route: Route = {
        routeId: 0,
        routeStops: [],
        routeStopGroups: []
    };

    @Output() routeDetailHistory = new EventEmitter<void>();

    isSelected = false;
    routeStatus: 'Waiting' | 'Done' | 'On Route' = 'Waiting';

    OrderStopType = OrderStopType;
    EntityTypes = EntityTypes;
    RouteStatusEnum = RouteStatusEnum;

    public modalRef: BsModalRef;
    stopToAssign: any;
    routeTransfer: any;

    routeItemStatus = RouteItemStatus;

    search: any = {
        SearchTerm: '',
        Page: 1,
        ItemsPerPage: 25,
        IsOrder: false,
        SortBy: 'DEFAULT',
        SortDirection: 'Descending',
        Date: ''
    };

    stopStatusTypes: Record<RouteItemDisplayStatus, { class: string; text: string }> = {
        [RouteItemDisplayStatus.OnTime]: { class: 'on-time', text: 'On Time' },
        [RouteItemDisplayStatus.PickedUp]: { class: 'picked-up', text: 'Picked Up' },
        [RouteItemDisplayStatus.PickedUpFromTransfer]: { class: 'picked-up', text: 'Picked Up (T)' },
        [RouteItemDisplayStatus.Late]: { class: 'late', text: 'Late' },
        [RouteItemDisplayStatus.Delivered]: { class: 'delivered', text: 'Delivered' },
        [RouteItemDisplayStatus.DeliveredToTransfer]: { class: 'delivered', text: 'Delivered (T)' },
    }

    directions: DirectionsResult;
    chargeTypes: ChargeType[];

    private locationsSubscription: Subscription = null;
    private destroyed$: Subject<boolean> = new Subject();
    private stopToRouteAssignedSubscription: Subscription = null;
    useTrimbleMaps: Boolean = environment.useTrimbleMaps;
    warehouses: Warehouse[] = [];
    private warehousesSubscription: Subscription;
    selectedRouteBox: RouteBox;
    historyShown = false;
    showPlaceholderDropItemId: number;
    isMerged: boolean;

    constructor(
        private warehouseService: WarehouseService,
        public util: UtilService,
        public routeService: RouteService,
        public samsaraService: SamsaraService,
        private decimalPipe: DecimalPipe,
        public accountingService: AccountingService,
        public chargeTypeService: ChargeTypeService,
        private modalService: NgbModal,
        private helperService: HelperService,
        private routesService: RoutesService,
        // private routeService: RouteService,
        private readonly cdr: ChangeDetectorRef,
    ) {
    }





    @HostBinding('class.collapsed') isCollapsed = false;
    routeMarkers: MarkerInfo[] = [];
    routeStopsStatus: WaypointInfo[];

    setRouteStops() {
        if (this.completedStopsCount === undefined) {
            this.completedStopsCount = this.route.routeStopGroups.reduce<number>(
                (sum, rsg) => sum + rsg.routeItemStops
                    .filter(ris => ris.status === 1).length ?? 0, 0);
        }
        if (this.totalStopsCount === undefined) {
            this.totalStopsCount = this.route.routeStopGroups.reduce<number>(
                (sum, rsg) => sum + rsg.routeItemStops.length ?? 0, 0);

        }
    }

    ngOnInit() {
        if (this.route) {
            if (this.totalStopsCount > 0 && this.totalStopsCount !== this.completedStopsCount) {
                this.routeStatus = 'On Route'
            }
            else if (this.totalStopsCount > 0 && this.totalStopsCount === this.completedStopsCount) {
                this.routeStatus = 'Done'
            }
            else if (this.totalStopsCount === 0) {
                this.routeStatus = 'Waiting'
            }
        }

        this.warehousesSubscription = this.warehouseService.warehouses$.pipe(takeUntil(this.destroyed$)).subscribe(warehouses => {
            this.warehouses = warehouses;
        });

        this.routesService.$currentRoute.pipe(untilDestroyed(this)).subscribe(
            (res: any) => {
                if (this.route !== res) {
                    this.isSelected = false;
                }
            }
        );
        this.routesService.$openedRoutes.pipe(untilDestroyed(this)).subscribe(
            async (res: any) => {
                const data = get(res, this.route.routeId, null)
                if (data) {
                    this.historyShown = data.isHistory
                    this.stopsShown = data.isOpen
                }
            }
        );
        this.routesService.$mergedRouteBlocks.pipe(untilDestroyed(this)).subscribe(
            async (res: any) => {
                this.isMerged = get(res, this.route.routeId, false)
            }
        );

        this.stopToRouteAssignedSubscription = this.routeService.stopToRouteAssigned$
            .pipe(takeUntil(this.destroyed$))
            .subscribe(({ routeId, stop }) => {
                if (routeId === this.route.routeId) {
                    this.assignStop(stop);
                }
            });

        this.locationsSubscription = this.samsaraService.locations
            .pipe(takeUntil(this.destroyed$))
            .subscribe(
                (res) => {
                    if (this.route?.truck != null) {
                        this.route.truck.location = res.find(loc => loc.id === this.route?.truck?.samsaraId);
                        if (this.route.truck.location != null) {
                            this.setRouteMarkers();
                        }
                    }
                }
            );
        this.setupMap();
        this.chargeTypeService.perStopChargeTypes$
            .subscribe(chargeTypes => this.chargeTypes = chargeTypes);
        this.setHostClass();

        this.routesService.$currentRouteBox.pipe(untilDestroyed(this)).subscribe(
            (res: any) => {
                this.selectedRouteBox = this.routesService.getRouteBox(res)
            }
        );
        this.setRouteStops();
    }

    switchAvoidTolls(route: Route) {
        this.routesService.switchAvoidTolls(route.routeId, !route.avoidTolls).then((res) => {
            if (res.success) {
                this.routesService.reloadRoutes = true;
            }
        });
    }

    editRoute(route: Route, $event) {
        $event.stopPropagation();
        const activeModal = this.modalService.open(AddEditRouteDialogComponent, {
            scrollable: true,
            size: 'sm'
        });
        activeModal.componentInstance.route = route;
        activeModal.result.then(
            (result) => {
                if (result) {
                    this.routesService.reloadRoute = { routeId: route?.routeId }
                }
            },
            () => { }
        );
    }

    private setHostClass() {
        if (this.readonly) {
            this.isNoMap = false;
            this.isStandalone = true;
        } else {
            this.isNoMap = this.route.directions == null;
            this.isStandalone = false;
        }
    }

    ngOnDestroy() {
        this.destroyed$.next(true);
        this.destroyed$.unsubscribe();
        this.locationsSubscription?.unsubscribe();
        this.stopToRouteAssignedSubscription?.unsubscribe();
        this.warehousesSubscription?.unsubscribe();
    }

    showAddUnassignedStopsDialog(stopType: OrderStopType, event, orderNumber = '') {
        event.stopPropagation()
        event.preventDefault()
        const activeModal = this.modalService.open(AddUnassignedStopsComponent, {
            // size: 'lg',
            scrollable: true,
        })
        activeModal.componentInstance.route = this.route;
        activeModal.componentInstance.routes = this.routes;
        activeModal.componentInstance.search = {
            ...(stopType && { StopType: stopType }),
            ...(orderNumber && { OrderNumber: orderNumber }),
            page: 1,
            itemsPerPage: 20
        }

        activeModal.result.then((result) => {
            if (result) {
            }
        }, () => {

        });
    }

    private splitStopsBeforeUpdate(newContainer, previousContainer) {
        newContainer.routeStopGroups = newContainer?.routeStopGroups?.map((group) => {
            group.routeItemStops = flatten(group?.routeItemStops)
            return group
        })

        previousContainer.routeItemStops = flatten(previousContainer?.routeItemStops)
    }
    async drop(event: CdkDragDrop<any, any>, stop: RouteItemStop, newContainer) {
        if (!event?.isPointerOverContainer) {
            return
        }
        this.helperService.isLoading = true;
        if (event?.item?.data?.type === 'Unassigned') {
            this.dragInUn(event);
        }
        else {
            let previousContainer = event?.item?.data?.routeGroup
            const stopData = event?.item?.data?.stop
            const isMergedStops = isArray(stopData)
            const request = {
                Page: 1,
                ItemsPerPage: 999,
                routeId: previousContainer?.routeId,
            }

            let filteredRouteItemStops: any[]
            if (previousContainer?.routeId !== newContainer?.routeId && !isMergedStops) {
                await this.routesService.getRouteItemGroups(request).then(async (res: any) => {
                    filteredRouteItemStops = chain(res.list)
                        .flatMap('routeItemStops')
                        .filter(res =>
                            res?.orderId === stopData?.orderId && res?.orderNumber === stopData?.orderNumber
                        )
                        .value();
                })
            }

            const hasMoreThenOneItem: boolean = filteredRouteItemStops?.length > 1;

            if (isEqual(previousContainer?.routeItemStops, newContainer?.routeItemStops)) {
                const routeItemGroup: RouteStopGroup = newContainer;
                const previousIndex = routeItemGroup.routeItemStops.indexOf(stopData);
                const currentIndex = routeItemGroup.routeItemStops.indexOf(stop);
                if (previousIndex !== currentIndex) {
                    moveItemInArray(newContainer.routeItemStops, previousIndex, currentIndex);
                    // Stops split if merged
                    this.splitStopsBeforeUpdate(newContainer, previousContainer)
                    this.routesService.updateRouteStops(newContainer.routeId, [newContainer]).then(() => {
                        //this.routesService.reloadRoutes = true
                        // Find and replace route data 
                        this.routesService.reloadRoute = { routeId: newContainer?.routeId }
                        this.helperService.successMessage("Route stop successfully updated.");
                    }).catch((error) => {
                        this.helperService.errorMessage(error)
                    }).finally(() => {
                        this.helperService.isLoading = false
                    })
                } else {
                    this.helperService.isLoading = false
                }
            } else if (previousContainer?.routeId === newContainer?.routeId) {

                const prevGroup = previousContainer?.routeItemStops;
                const curGroup = newContainer?.routeItemStops
                    ?? this.route.routeStopGroups[this.route.routeStopGroups.length - 1].routeItemStops;
                const previousIndex = prevGroup.indexOf(stopData);
                const currentIndex = stop != null ? curGroup.indexOf(stop) : curGroup.length;
                transferArrayItem(prevGroup, curGroup, previousIndex, currentIndex);

                // Stops split if merged
                this.splitStopsBeforeUpdate(newContainer, previousContainer)

                this.routesService.updateRouteStops(newContainer.routeId,
                    [previousContainer, ...newContainer.routeStopGroups]).then((res) => {
                        // Find and replace route data 
                        this.routesService.reloadRoute = { routeId: newContainer?.routeId }
                        this.helperService.successMessage("Route stop successfully updated.");
                    }).catch((error) => {
                        this.helperService.errorMessage(error)
                    }).finally(() => {
                        if (this.stopsShown) {
                            // this.stopsShown = false;
                            // this.cdr.detectChanges();
                            // this.stopsShown = true;
                            // this.cdr.detectChanges();
                        }
                        this.helperService.isLoading = false;
                    })
            } else if (hasMoreThenOneItem) {
                const pickups = await filteredRouteItemStops?.filter(item => item.type === 'Pickup' || item.type === 'PickupFromTransfer');
                const deliveries = await filteredRouteItemStops?.filter(item => item.type === 'Delivery' || item.type === 'DeliveryToTransfer');
                const hasPickups: boolean = hasMoreThenOneItem && pickups.length > 0;
                const hasDeliveries: boolean = hasMoreThenOneItem && deliveries.length > 0;
                const hasWholeOrder: boolean = hasPickups && hasDeliveries;

                const previousRouteDetails = this.routes.find(({ route }) => route?.routeId === previousContainer?.routeId);
                const previousRoute = previousRouteDetails
                    ? {
                        routeId: previousRouteDetails?.route?.routeId,
                        driverName: `${previousRouteDetails?.route?.driver?.firstName || ''} ${previousRouteDetails?.route?.driver?.lastName || ''}`,
                        truckNameNumber: `${previousRouteDetails?.route?.truck?.plateNumber || ''} ${previousRouteDetails?.route?.truck?.name || ''}`
                    }
                    : null;

                const currentRouteDetails = this.routes.find(({ route }) => route?.routeId === newContainer?.routeId);
                const currentRoute = currentRouteDetails
                    ? {
                        routeId: currentRouteDetails.route?.routeId,
                        driverName: `${currentRouteDetails?.route?.driver?.firstName || ''} ${currentRouteDetails?.route?.driver?.lastName || ''}`,
                        truckNameNumber: `${currentRouteDetails?.route?.truck?.plateNumber || ''} ${currentRouteDetails?.route?.truck?.name || ''}`
                    }
                    : null;

                const orderDetails = {
                    orderId: stopData?.orderId || '',
                    orderNumber: stopData?.orderNumber || '',
                    type: stopData?.type || ''
                }
                this.helperService.isLoading = false
                const activeModal = this.modalService.open(RouteStopTransferDialogComponent);
                activeModal.componentInstance.previousRoute = previousRoute;
                activeModal.componentInstance.currentRoute = currentRoute;
                activeModal.componentInstance.orderDetails = orderDetails;
                activeModal.componentInstance.hasPickups = hasPickups;
                activeModal.componentInstance.hasDeliveries = hasDeliveries;
                activeModal.componentInstance.hasWholeOrder = hasWholeOrder;
                activeModal.result.then((res) => {
                    this.helperService.isLoading = true
                    if (res?.stopTransferType === 'SelectedItem') {
                        this.routeStopTransferOnlySelectedItem(stopData, previousContainer, newContainer);
                    } else if (['Order', 'Pickup', 'Delivery'].includes(res?.stopTransferType)) {
                        const request: any = {
                            oldRouteId: previousRoute?.routeId,
                            newRouteId: currentRoute?.routeId,
                            orderId: orderDetails?.orderId,
                        }
                        if (res?.stopTransferType !== 'Order') {
                            request.routeItemType = res?.stopTransferType;
                        }
                        this.changeOrderRoute(request, orderDetails?.orderNumber);
                    } else {
                        this.helperService.isLoading = false;
                    }
                })
            } else {
                this.routeStopTransferOnlySelectedItem(stopData, previousContainer, newContainer);
            }
        }
    }

    changeOrderRoute(request: any, orderNumber?: number) {
        const type = request?.routeItemType ? (request?.routeItemType === 'Pickup' ? 'pickups' : 'deliveries') : 'items';
        this.routesService.changeOrderRoute(request).then((res: any) => {
            // Find and replace route data for new and old container
            this.routesService.reloadRoute = { routeId: request?.oldRouteId }
            this.routesService.reloadRoute = { routeId: request?.newRouteId }
            //this.routesService.reloadRoutes = true;
            if (res?.otherMessages?.length > 0) {
                this.helperService.addToast(res?.otherMessages[0]?.message, 'Max quantity', 'warning');
            } else {
                this.helperService.successMessage(`Moved the entire order #${orderNumber} (all ${type}) from route #${request?.oldRouteId} to route #${request?.newRouteId}.`);
            }
        }).catch((error) => {
            this.helperService.errorMessage(error);
        }).finally(() => {
            this.helperService.isLoading = false;
        })
    }

    async routeStopTransferOnlySelectedItem(stopData, previousContainer, newContainer) {
        await this.routesService.getRouteItemGroups({ routeId: this.route.routeId }).then((res) => {
            this.route.routeStopGroups = res.list
        })
        const prevGroup = previousContainer?.routeItemStops;
        const curGroup = newContainer?.routeItemStops ?? this.route.routeStopGroups[this.route.routeStopGroups.length - 1].routeItemStops;
        const previousIndex = prevGroup.indexOf(stopData);
        const currentIndex = stop != null ? curGroup.indexOf(stop) : curGroup.length;
        transferArrayItem(prevGroup, curGroup, previousIndex, currentIndex);

        // Stops split if merged
        this.splitStopsBeforeUpdate(newContainer, previousContainer)

        if (this.route?.routeStopGroups || newContainer) {
            this.routesService.updateRouteStops(this.route?.routeId ?? newContainer?.routeId,
                [
                    this.route?.routeStopGroups[this.route?.routeStopGroups?.length - 1] ?? newContainer
                ]).then(() => {
                    //  Find and replace route data for new  container
                    this.routesService.reloadRoute = { routeId: newContainer?.routeId ?? this.route.routeId }
                    if (previousContainer) {
                        this.routesService.updateRouteStops(previousContainer?.routeId, [previousContainer]).then(() => {
                            // Find and replace route data for old container
                            this.routesService.reloadRoute = { routeId: previousContainer.routeId }
                            // this.routesService.reloadRoutes = true
                            this.helperService.successMessage("Route stop successfully updated.");
                        }).catch((error) => {
                            this.helperService.errorMessage(error)
                        }).finally(() => {
                            this.helperService.isLoading = false;
                        })
                    }
                }).catch((error) => {
                    this.helperService.isLoading = false;
                    this.routesService.reloadRoutes = true;
                    this.helperService.errorMessage(error);
                })
        }
    }

    dragInUn(event: CdkDragDrop<any, any>) {
        const unassignedStopsGroups: RouteBoxUIGroup[] = this.routesService.unassignedStopsGroups
        const stop = cloneDeep(event?.item?.data.stop)
        const stopData = find(unassignedStopsGroups, { _id: stop?._id })
        if (!stopData) {
            return;
        }
        this.assignStop(stopData);
    }

    private assignStop(stop: RouteBoxUIGroup) {
        stop.items.forEach(element => {
            element.selected = true;
        });
        this.UploadRouteStop(stop);
    }

    async UploadRouteStop(stop: RouteBoxUIGroup) {
        this.route.routeStopGroups = []

        // process Pickup
        for (const box of stop.pickupRouteBoxes.filter(b => b != null)) {
            this.routesService.processNewPickupStop(box, box.items.filter(i => i.selected), this.route);
        }
        // process DropOff
        for (const box of stop.dropOffRouteBoxes.filter(b => b != null)) {
            this.routesService.processNewPickupStop(box, box.items.filter(i => i.selected), this.route);
        }

        this.routesService.updateRouteStops(this.route.routeId, this.route?.routeStopGroups, true).then(() => {
            this.helperService.successMessage("Trip assigned driver successfully.");
            this.routesService.reloadRoute = { routeId: this.route.routeId }
            // this.routesService.reloadRoutes = true
            this.routesService.reloadUnassigned = true
        }).catch((error) => {
            this.helperService.errorMessage(error)
        }).finally(() => {
            this.helperService.isLoading = false
        })
    }

    async routeText(route, $event?: MouseEvent) {
        $event.stopPropagation();

        const activeModal = this.modalService.open(CopyDetailsToDriverComponent, {
            scrollable: true,
            size: "dialog-centered",
        });
        activeModal.componentInstance.title = 'Route details copied to clipboard';
        activeModal.componentInstance.route = this.route;
        activeModal.componentInstance.isGroup = this.isMerged;
        activeModal.componentInstance.routeStopGroupsSearch = {
            routeId: route.routeId,
            Page: 1,
            status: 0,
            ItemsPerPage: 20
        };
    }

    getRouteStart(route: Route) {
        const stops = route?.routeStopGroups?.flatMap(rsg => rsg.routeItemStops);
        if (stops?.length > 0 && stops[0]?.status === 0) {
            return stops[0].address.fullAddress;
        }
        return null;
    }

    setRouteMarkers() {
        const speed = this.decimalPipe.transform(this.route.truck?.location?.speed, '1.2-2');
        if (this.routeMarkers.length === 0) {
            this.routeMarkers.push({
                index: 0,
                title: `${this.route.truck?.location?.location} (${speed}mph)`,
                titleSave: `${this.route.truck?.location?.location} (${speed}mph)`,
                isTitleOpen: false,
                longitude: this.route.truck?.location?.longitude,
                latitude: this.route.truck?.location?.latitude,
                useInDirections: true,
            }
            );
            const firstStop = this.getRouteStart(this.route);
            if (firstStop) {
                this.routeMarkers.push({
                    index: 1,
                    title: '',
                    isTitleOpen: false,
                    longitude: null,
                    latitude: null,
                    useInDirections: true,
                    location: firstStop,
                }
                );
            }
        } else {
            this.routeMarkers[0].title = `${this.route.truck?.location?.location} (${speed}mph)`;
            this.routeMarkers[0].latitude = this.route.truck?.location?.latitude;
            this.routeMarkers[0].longitude = this.route.truck?.location?.longitude;
            this.routeMarkers[0].useInDirections = true;
        }
    }

    setupMap() {
        if (!equal(this.directions, this.route.directions)) {
            this.directions = this.route.directions;
        }
        this.routeStopsStatus = this.getRouteStopsStatus();
    }


    getRouteStopsStatus(): WaypointInfo[] {
        const all = this.route?.routeStopGroups?.flatMap(rsg => rsg.routeItemStops);
        if (all) {
            all.sort((a, b) => a.sortOrder - b.sortOrder);
            return all.map(
                (ris) => ({
                    index: ris.sortOrder,
                    address: ris.address.fullAddress,
                    status: Boolean(ris.status),
                })
            );
        }
    }

    getVehicleUsedCapacity() {
        const totalVehicleCapacity = this.vehicleFreeCapacity + this.vehicleUsedCapacity
        if (totalVehicleCapacity == 0 && totalVehicleCapacity == null) {
            return '0%';
        }
        return `${Math.round(this.vehicleUsedCapacity / totalVehicleCapacity * 100)}%`;
    }

    deleteRoute(route: Route) {
        if (route?.routeStopGroups?.flatMap(rsg => rsg.routeItemStops).some(ris => ris.status === 0)) {
            this.helperService.errorMessage('Could not delete route having non-complete items');
        } else {
            this.routesService.delete(route.routeId).then(() => {
                this.helperService.successMessage('The route has been deleted');
                this.routesService.reloadRoutes = true
            }).catch((error) => {
                if (error?.error?.messages) {
                    this.helperService.errorMessage(error);
                }
                else {
                    this.helperService.errorMessage(error.messages != null ? error.messages : 'An error has occurred', 'Error');
                }
            })
        }
    }

    routeClicked() {
        this.isSelected = !this.isSelected;
        if (this.isSelected) {
            this.routesService.currentRoute = this.route
        } else {
            this.routesService.currentRoute = null
        }
    }

    moveToWarehouse(route: Route, warehouse: Warehouse) {
        this.util.mainLoaderOn();
        this.warehouseService.moveToWarehouse(route, warehouse).subscribe(
            res => {
                this.util.mainLoaderOff();
                console.log(res);
                this.routeService.reloadRoutes();
            },
            error => {
                this.util.mainLoaderOff();
                console.log(error.messages)
                this.util.showError(error.messages != null ? error.messages : 'An error has occurred', 'Error');
            }
        );
    }

    hasPickups(route: Route) {
        return route?.routeStopGroups?.flatMap(rsg => rsg.routeItemStops.filter(
            ris => ris.status === 1 && ris.orderStop?.orderStopType === 'Pickup' && ris.orderStop.isActive)).length > 0;
    }

    onShowHistory($event) {
        $event.stopPropagation()
        // this.historyShown = !this.historyShown
        // const historyShown = !this.historyShown
        // this.stopsShown = true;
        this.routesService.toggleRoute(this.route?.routeId, true, !this.historyShown)
        this.cdr.detectChanges();
    }

    routeDetailsHistory(route) {
        this.routeDetailHistory.emit(route);
    }

    toggleStops($event) {
        $event.stopPropagation()
        //this.stopsShown = !this.stopsShown;
        this.routesService.toggleRoute(this.route?.routeId, !this.stopsShown)
        this.cdr.detectChanges();
    }

    setCurrentTruck($event, truck: Truck) {
        $event.stopPropagation()
        this.routesService.currentTruck = truck
    }

    get timeToBox(): string {
        const dist = this.distanceToBox;
        if (dist != null) {
            const time = dist / 50;
            const duration = moment.duration(time, 'hours');
            return `${duration.humanize()}`;
        }
        return '';
    }

    get distanceToBox(): number | null {
        if (this.selectedRouteBox != null && this.route?.truck?.location != null && this.selectedRouteBox?.waypoint?.point != null) {
            return haversineDistance({
                lat: this.route.truck.location.latitude,
                lng: this.route.truck.location.longitude
            }, this.selectedRouteBox?.waypoint?.point);
        }
        return null;
    }

    get isSuggested(): boolean {
        return this.distanceToBox < 10;
    }

    openChatHistory(route: Route, event) {
        event.stopPropagation();
        event.preventDefault();
        const activeModal = this.modalService.open(ChatHistoryViewerComponent, {
            scrollable: true,
            size: 'lg'
        })
        activeModal.componentInstance.driverId = route.driver?.driverId;
        activeModal.componentInstance.type = 'chat';
        activeModal.result.then((result) => {
            if (result) {
            }
        }, () => {
        });
    }

    openBotHistory(route: Route, event) {
        event.stopPropagation();
        event.preventDefault();
        const activeModal = this.modalService.open(ChatHistoryViewerComponent, {
            scrollable: true,
            size: 'lg'
        })
        activeModal.componentInstance.driverId = route.driver?.driverId;
        activeModal.componentInstance.type = 'bot';
        activeModal.result.then((result) => {
            if (result) {
            }
        }, () => {

        });

    }

    handleAllCloseRoutes() {
        if (this.route?.routeId) {
            this.helperService.isLoading = true;
            this.routesService.closeAllItemsRoutes(this.route?.routeId).then(() => {
                this.routesService.reloadRoute = { routeId: this.route?.routeId }
                this.helperService.successMessage("Routes stop status successfully updated.")
            }).catch((error) => {
                this.helperService.errorMessage(error)
                this.routesService.reloadRoute = { routeId: this.route?.routeId }
            }).finally(() => {
                this.helperService.isLoading = false;
            })
        }
    }
    handleMergeToggle(route) {
        this.routesService.toggleMergedRouteBlock(route?.routeId)
    }

    updateRouteStatus(updateStatus){
        if (this.route?.routeId) {
            this.helperService.isLoading = true;
            this.routesService.updateRouteStatus(this.route?.routeId, updateStatus).then(() => {
                this.routesService.reloadRoutes = true;
                this.helperService.successMessage("Routes status successfully updated.")
            }).catch((error) => {
                this.helperService.errorMessage(error)
                this.routesService.reloadRoutes = true;
            }).finally(() => {
                this.helperService.isLoading = false;
            })
        }
    }

    canDrop = () => {
        return this.route?.status !== RouteStatusEnum.COMPLETED;
    }
}
