import { Component, OnDestroy, ViewChild, type OnInit } from '@angular/core';
import { DatatableComponent } from '@app/@shared/datatable';
import { TypeOfTruck, TypeOfTruckTypeEnum } from '@app/models/type-of-truck.model';
import { AppService } from '@app/shared/services/app.service';
import { HelperService } from '@app/shared/services/helper.service';
import { TypeOfTrucksService } from '@app/shared/services/type-of-truck.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { debounceTime } from 'rxjs/operators';
import { AddEditTypeOfTruckDialogComponent } from '../add-edit-type-of-truck-dialog/add-edit-type-of-truck-dialog.component';

@UntilDestroy()
@Component({
    selector: 'app-type-of-truck-list',
    templateUrl: './type-of-truck-list.component.html',
    host: {
        '[class.page-container]': "true"
    }
})
export class TypeOfTruckListComponent implements OnInit, OnDestroy {

    @ViewChild('datatable') datatable: DatatableComponent;
    searchTerm: string;
    data: any;

    constructor(
        private typeOfTrucksService: TypeOfTrucksService,
        private helperService: HelperService,
        private appService: AppService,
        private ngbModal: NgbModal,
    ) { }

    ngOnInit(): void {
        this.getData();
        this.appService.$searchTerm
            .pipe(
                untilDestroyed(this),
                debounceTime(500)
            )
            .subscribe((text) => {
                this.datatable.setSearchValue(text);
            })
    }

    getData(request?: any) {
        this.typeOfTrucksService.cancelPendingRequestGetAll();
        return this.typeOfTrucksService.getAllWithCancelRequest(request)
            .then((data) => {
                this.data = data
            })
    }

    addEditTypeOfTruck(row?: TypeOfTruck) {
            const ref = this.ngbModal.open(AddEditTypeOfTruckDialogComponent, {
                size: 'md'
            })
            ref.componentInstance.item = row
    
            ref.closed.pipe(untilDestroyed(this)).subscribe((isReload) => {
                if (isReload) {
                    this.getData()
                    this.datatable.refresh()
                }
            })
     
    }

    onDelete(typeOfTruck: TypeOfTruck) {
        this.typeOfTrucksService.delete(typeOfTruck.typeOfTruckId).then(() => {
            this.helperService.successMessage(`Type of Truck Deleted Successfully`);
            this.getData()
            this.datatable.refresh()
        }).catch(error => {
            this.helperService.errorMessage(error, 'An error has ocurred');
        });

    }

    getTypeName(type) {
        switch (type) {
            case TypeOfTruckTypeEnum.PickupTruck:
                return 'Pickup Truck';
            case TypeOfTruckTypeEnum.BoxTruck:
                return 'Box Truck';
            case TypeOfTruckTypeEnum.SemiTruck:
                return 'Semi Truck';
            case TypeOfTruckTypeEnum.RefrigeratorTruck:
                return 'Refrigerator Truck';
            case TypeOfTruckTypeEnum.FurnitureTruck:
                return 'Furniture Truck';
            case TypeOfTruckTypeEnum.FlatbedTruck:
                return 'Flatbed Truck';
        }
    }

    ngOnDestroy() {
        this.appService.searchTerm = ''
    }
}
