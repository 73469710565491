// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.message {
  width: 100%;
}
.message pre {
  margin: 5px;
  padding: 5px;
  border: 1px solid black;
}

.main {
  padding: 10px;
  margin: 10px;
}
.main .container {
  max-height: 600px;
  overflow-y: auto;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/auxiliary/chat-history/chat-history-viewer.component.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;AACJ;AAAI;EACI,WAAA;EACA,YAAA;EACA,uBAAA;AAER;;AAGA;EACI,aAAA;EACA,YAAA;AAAJ;AACI;EACI,iBAAA;EACA,gBAAA;AACR","sourcesContent":[".message {\n    width: 100%;\n    pre {\n        margin: 5px;\n        padding: 5px;\n        border: 1px solid black;\n        //width: 500px;\n    }\n}\n\n.main {\n    padding: 10px;\n    margin: 10px;\n    .container {\n        max-height: 600px;\n        overflow-y: auto;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
