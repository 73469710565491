// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host .title {
  font-size: 40px;
}
:host .form-label {
  font-size: 14px;
}
:host .form-control {
  min-height: 48px;
  font-size: 14px;
  border: 2px solid #aab8c1;
}
:host .btn {
  justify-content: center;
  width: 100%;
  font-size: 16px;
  line-height: 1rem;
  padding: 14px 20px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/auth/forgot-password/forgot-password.component.scss"],"names":[],"mappings":"AACI;EACI,eAAA;AAAR;AAGI;EACI,eAAA;AADR;AAGI;EACI,gBAAA;EACA,eAAA;EACA,yBAAA;AADR;AAII;EACI,uBAAA;EACA,WAAA;EACA,eAAA;EACA,iBAAA;EACA,kBAAA;AAFR","sourcesContent":[":host {\n    .title {\n        font-size: 40px;\n    }\n\n    .form-label {\n        font-size: 14px;\n    }\n    .form-control {\n        min-height: 48px;\n        font-size: 14px;\n        border: 2px solid #aab8c1;\n    }\n\n    .btn {\n        justify-content: center;\n        width: 100%;\n        font-size: 16px;\n        line-height: 1rem;\n        padding: 14px 20px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
