import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-previous-next-redirect-buttons',
    templateUrl: './previous-next-redirect-buttons.component.html',
    styleUrls: ['./previous-next-redirect-buttons.component.scss'],
})
export class PreviousNextRedirectButtonsComponent implements OnInit {

    @Output() onRedirect = new EventEmitter();
    @Input() isDisable: boolean;
    @Input() previousId: number;
    @Input() nextId: number;
    @Input() title: string;
    @Input() previousReferenceData: any;
    @Input() nextReferenceData: any;

    constructor() { }

    ngOnInit(): void {
    }

    onPreviousNextRedirect(id, referenceData) {
        this.onRedirect.emit({id, referenceData})
    }
}
