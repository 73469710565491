import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DateFormatterParams, CalendarView, CalendarEvent } from 'angular-calendar';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-basic-datepicker',
  templateUrl: './basic-datepicker.component.html',
  styleUrls: ['./basic-datepicker.component.scss'],
  providers: [DatePipe]
})
export class BasicDatepickerComponent implements OnInit {
  @Input() dateType: string;
  @Input() date: Date;
  @Output() dateTypeUpdated = new EventEmitter<any>();
  @Output() dateUpdated = new EventEmitter<any>();

  public isOpened = false;

  view: CalendarView = CalendarView.Month;
  viewDate: Date = new Date();
  events: CalendarEvent[] = [];
  clickedDate: Date;

  constructor(
    private _datePipe: DatePipe
  ) { }

  ngOnInit() {
  }

  public monthViewColumnHeader({date, locale}: DateFormatterParams): string {
    return this._datePipe.transform(date, 'EEEEEE', null, locale);
  }

  // onBlur() {
  //   setTimeout(() => {
  //     this.isOpened = !this.isOpened;
  //   }, 500);
  // }

  setDateType(type) {
    this.dateType = type;
    this.date = null;
    this.dateTypeUpdated.emit(this.dateType);
    this.dateUpdated.emit(null);
    this.isOpened = false;
  }

  dayClicked(date){
    this.dateType = 'Custom';
    this.dateTypeUpdated.emit('Custom');
    this.dateUpdated.emit(date);
    this.isOpened = false;
    this.clickedDate = date;
  }
}
