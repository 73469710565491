import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { patterns } from '@app/@shared/regex/regex-patterns.const';
import { stateOptions } from '@app/data/state';
import { Country } from '@app/models/address.model';
import { Driver } from '@app/models/driver.model';
import { OutsourceCompany } from '@app/models/outsource-company.model';
import { AddressService } from '@app/shared/services/address.service';
import { HelperService } from '@app/shared/services/helper.service';
import { object, string } from 'yup';

@Component({
  selector: 'app-driver-personal-information',
  templateUrl: './driver-personal-information.component.html',
  styleUrls: ['./driver-personal-information.component.scss']
})
export class DriverPersonalInformationComponent implements OnInit {
  title = 'Personal Information'

  private _driver: Driver = {
    firstName: "",
    middleName: "",
    lastName: "",
    phoneNumber: "",
    cellNumber: "",
    email: "",
    addressLine1: "",
    city: "",
    state: "",
    zip: null,
    ssn: "",
    gender: "",
    dob: null,
    countryId: null,
  };
  @Input() isLoading: boolean = false;
  countries: Country[];
  @Input()
  public get driver(): Driver {
    return this._driver;
  }
  public set driver(value: Driver) {
    this._driver = value
    if (value?.outsourceCompanyId && value?.outsourceCompanyId != this.selectedOutsourceCompany?.id) {
			this.processOutsourceCompany(this.driver.outsourceCompany);
		}
  }
  states = stateOptions;
  selectedOutsourceCompany: OutsourceCompany;

  validationSchema = object().shape({
    firstName: string().nullable().max(256).required(),
    lastName: string().nullable().max(256).required(),
    middleName: string().nullable().max(256),
    phoneNumber: string().nullable().matches(patterns.phone, { excludeEmptyString: true, message: 'Phone must be a number' }),
    cellNumber: string().nullable().matches(patterns.phone, { excludeEmptyString: true, message: 'Cell must be a number' }),
    email: string().label('Email').nullable().email().max(256),
    addressLine1: string().label("Address").nullable().max(256),
    zip: string().label("Zip code").nullable().max(32),
  });

  @Output() driverChange = new EventEmitter()

  constructor(
    private helperService : HelperService,
    private addressService : AddressService,
  ) { }

  ngOnInit(): void {
    this.addressService.getCountries().then(res => {
			this.countries = res;
		}).catch((error) => {
			this.countries = [];
			this.helperService.errorMessage(error)
		})
  }

  onOutsourceCompanySelect(outsourceCompany: OutsourceCompany) {
    this.driver.outsourceCompany = outsourceCompany
    this.driver.outsourceCompanyId = outsourceCompany?.id
  }

  async processOutsourceCompany(outsourceCompany: OutsourceCompany) {
    if (outsourceCompany) {
      this.selectedOutsourceCompany = outsourceCompany;
      this.driver.outsourceCompany = outsourceCompany;
      this.driver.outsourceCompanyId = outsourceCompany?.id || outsourceCompany?.outsourceCompanyId;
    } else {
      this.selectedOutsourceCompany = {};
      this.driver.outsourceCompany = null
      this.driver.outsourceCompanyId = null
    }
  }

  onSubmit(f) {
    if (f.isValid) {
      this.driverChange.emit(this.driver)
    }
  }


}
