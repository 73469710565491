import { ListRequest } from "@app/models/core/base";
import { RequestData } from "./datatable.interface";

export const getValue = function (obj, path, defaultValue) {

  /**
   * If the path is a string, convert it to an array
   * @param  {String|Array} path The path
   * @return {Array}             The path array
   */
  var stringToPath = (path) => {

    // If the path isn't a string, return it
    if (typeof path !== 'string') return path;

    // Create new array
    var output = [];

    // Split to an array with dot notation
    path.split('.').forEach((item, index) => {

      // Split to an array with bracket notation
      item.split(/\[([^}]+)\]/g).forEach((key) => {

        // Push to the new array
        if (key.length > 0) {
          output.push(key);
        }

      });

    });

    return output;

  };

  // Get the path as an array
  path = stringToPath(path);

  // Cache the current object
  var current = obj;

  // For each item in the path, dig into the object
  for (var i = 0; i < path.length; i++) {

    // If the item isn't found, return the defaultValue (or null)
    if (!current[path[i]]) return defaultValue;

    // Otherwise, update the current  value
    current = current[path[i]];

  }

  return current;

};


export function mapDatatableRequestToApi(request: RequestData) {
  const listRequest: ListRequest = {
    ...request,
    Page: request.page,
    ItemsPerPage: request.limit,
  }

  delete listRequest.page;
  delete listRequest.limit;

  if (request.order) {
    listRequest.SortBy = request.order.name;
    listRequest.SortDirection = request.order.direction === 'asc' ? 'Ascending' : 'Descending';

    delete listRequest.order;
  }


  if (request.filterValue) {
    listRequest.SearchTerm = request.filterValue
    delete listRequest.filterValue;
  }

  return listRequest
}


export function mapApiRequestToDatatable(request: ListRequest) {


  const newRequest: any = {
    ...request,
    page: request.Page,
    limit: request.ItemsPerPage,
  }

  delete newRequest.Page;
  delete newRequest.ItemsPerPage;

  if (request.SortBy) {

    newRequest.order = {
      name: request.SortBy,
      direction: newRequest.SortDirection == 'Descending' ? 'desc' : 'asc',
    }
    delete newRequest.SortBy;
    delete newRequest.SortDirection;
  }

  if (request.SearchTerm) {
    newRequest.filterValue = request.SearchTerm;
    delete newRequest.SearchTerm;
  }

  return newRequest as RequestData;
}