import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@app/shared/shared.module';
import { ChargeTypesRoutingModule } from './charge-types-routing.module';
import { ChargeTypeDetailsComponent } from './charge-type-details/charge-type-details.component';
import { ChargeTypeListComponent } from './charge-type-list/charge-type-list.component';
import { DatatableModule } from '@app/@shared/datatable';
import { DialogsModule } from '@app/@shared/dialogs/dialogs.module';
import { FormValidateModule } from '@app/@shared/form-validate/form-validate.module';

@NgModule({
  declarations: [
    ChargeTypeListComponent,
    ChargeTypeDetailsComponent
  ],
  exports:[
    ChargeTypeDetailsComponent
  ],
  imports: [
    CommonModule,
    ChargeTypesRoutingModule,
    SharedModule,
    DatatableModule,
    DialogsModule,
    FormValidateModule
  ]
})
export class ChargeTypesModule { }
