import { Component, ElementRef, EventEmitter, forwardRef, Input, OnInit, Output, Renderer2, ViewChild, ViewEncapsulation } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { CustomerService } from '@app/shared/services/customer.service';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { merge, Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'app-typeahead-select',
  templateUrl: './typeahead-select.component.html',
  styleUrls: ['./typeahead-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TypeaheadSelectComponent),
      multi: true
    }
  ],
  encapsulation: ViewEncapsulation.Emulated,
  host: {
    '(click)': 'onDropdownShow()'
  }
})
export class TypeaheadSelectComponent implements OnInit {

  @ViewChild('instance', { static: true }) instance: NgbTypeahead;

  disabled: boolean;

  focus$ = new Subject<string>();
  click$ = new Subject<string>();

  @Input() searching: boolean;
  @Input() labelKay: string = 'name';
  @Input() valueKay: string = 'value';
  @Input() initialLoading: boolean = false;
  @Input() options: any[] = [];
  @Input() disableAutocomplete: boolean = false;
  @Input() placeholder: string;
  @Input() filterDropdown: boolean = true;
  @Input() highlightKay: string;
  @Input() isSearchEnabled: boolean = false

  onChange = (value: string) => { };
  onTouched = () => { };

  @Output() onSelect = new EventEmitter<any>();
  @Output() onChangeValue = new EventEmitter<any>();

  private _customValueDisplay: string | any;
  @Input()
  get customValueDisplay(): string | any {
    return this._customValueDisplay;
  }
  set customValueDisplay(updateValue: string | any) {
    this._customValueDisplay = updateValue;
  }

  private _value: string | any;
  @Input()
  get value(): string | any {
    return this._value;
  }
  set value(updateValue: string | any) {
    this._value = updateValue;

    if (typeof updateValue == "string") {
      this.onChangeValue.emit(updateValue);
      this.inputSubject.next(updateValue);
    }

    this.onChange(updateValue);
    this.onTouched();
  }

  private inputSubject = new Subject<string>();

  constructor(
    private renderer: Renderer2,
    private el: ElementRef,
    private customerService: CustomerService){

  }

  searchCustomers(value) {
    this.searching = true;
    this.customerService.search(value)
      .then(response => {
        const list = response.list || [];
        const firstMatch = list.find(item => item.customerName.toLowerCase() === value.toLowerCase()) || null;

        let options = list.map((x: any) => ({
          address: x.mainAddress,
          companyName: x.customerName,
          customerId: x.customerId,
        }));

        if (firstMatch) {
          const addressNames = firstMatch.addresses.map((address: any) => address.name);

          options = options.filter(
            option => option.customerId !== firstMatch.customerId &&
              !addressNames.includes(option.companyName)
          );

          const addressOptions = firstMatch.addresses.map((address: any) => ({
            address: address,
            companyName: `${address.name} (${firstMatch.customerName})`,
            customerId: firstMatch.customerId,
          }));

          this.options = [
            {
              address: firstMatch.mainAddress,
              companyName: firstMatch.customerName,
              customerId: firstMatch.customerId,
            },
            ...addressOptions,
            ...options
          ];
        } else {
          this.options = options;
        }

        this.click$.next(value);
        this.onDropdownShow();
      })
      .finally(() => {
        this.searching = false;
      });
  }

  inputFormatter = (item: any) => {
    if (this.customValueDisplay){
      return this.customValueDisplay
    }
    return typeof item === 'string' ? item : item ? item[this.labelKay] : null;
  }
  resultFormatter = (item: any) => {
    return typeof item === 'string' ? item : item ? item[this.labelKay] : null;
  }

  search = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(
      debounceTime(200),
      distinctUntilChanged()
    );
    const clicksWithClosedPopup$ = this.click$.pipe(filter(() => (Boolean(this.options) || this.initialLoading) && !this.instance?.isPopupOpen()));
    const inputFocus$ = this.focus$.pipe(filter(() => Boolean(this.options) || this.initialLoading));

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$)
      .pipe(
        untilDestroyed(this),
        map(term => {
          if (!this.filterDropdown){
            return this.options;
          }
          let data: any[] = [];
          if (!term || term === '') {
            data = this.options
          } else {
            data = this.options?.filter((v) => {
              return v[this.labelKay]?.toLowerCase().indexOf(term?.toLowerCase()) > -1
            })
          }

          return data;
        }),
      );
  }

  ngOnInit(): void {
    this.inputSubject
    .pipe(debounceTime(500))
    .subscribe(value => {
      if (this.isSearchEnabled) {
        this.searchCustomers(value);
      }
    });
  }


  clearValue() {
    const value = null
    this.value = value;
    this.onChange(value);
    this.onChangeValue.emit(value);
    this.onSelect.emit(value);
  }

  selectItem($e?: any) {
    $e.preventDefault();
    const value = $e.item;
    this.value = value
    this.onSelect.emit(value);
  }

  writeValue(value: string) {
    this._value = value
  }

  registerOnChange(fn: (value: string) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onDropdownShow() {
    // Timeout to ensure dropdown is already in the DOM
    setTimeout(() => {
      const inputWidth = this.el.nativeElement.offsetWidth;
      // Find the dropdown in the DOM. 
      // Note: This method assumes there's only one ngbTypeahead dropdown open at a time.
      const dropdown = document.querySelector('.typeahead-select');

      if (dropdown) {
        this.renderer.setStyle(dropdown, 'width', `${inputWidth}px`);
      }
    });
  }
}
