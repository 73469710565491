import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { SelectMultipleAddressComponent } from './select-multiple-address/select-multiple-address.component';
import { LoadingModule } from '@app/shared/modules/loading/loading.module';

@NgModule({
	declarations: [
		SelectMultipleAddressComponent
	],
	exports: [
		SelectMultipleAddressComponent
	],
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		NgSelectModule,
		LoadingModule
	]
})
export class AddressSelectModule { }
