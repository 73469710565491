import { HttpParams } from "@angular/common/http";
import { isJsObject } from "./object";

export function toParams(query?: any) {

  let params: HttpParams = new HttpParams();
  if (!query) {
    return params;
  }
  Object.keys(query).map((key) => {
    if (isJsObject(query[key])) {
      params = toSubParams(params, key, query[key]);
    } else {
      params = params.append(key.toString(), query[key]);
    }
  });
  return params;
}

function toSubParams(params: HttpParams, key: string, object: any) {
  Object.keys(object).map((childKey) => {
    if (isJsObject(object[childKey])) {
      params = toSubParams(params, `${key}[${childKey}]`, object[childKey]);
    } else {
      params = params.append(`${key}[${childKey}]`, object[childKey]);
    }
  });

  return params;
}
