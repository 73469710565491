// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host .header {
  font-size: 12px;
  padding-left: 0;
}
:host .header .col-md-2 {
  padding-right: 10px;
  padding-left: 0;
}
:host .header hr {
  margin-top: 8px;
  margin-bottom: 0;
  border-top: 2px dashed grey;
}
:host .text-concat {
  display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-height: 1.3;
}`, "",{"version":3,"sources":["webpack://./src/app/components/main/routes/route-details-brief/route-details-brief.component.scss"],"names":[],"mappings":"AACI;EACI,eAAA;EACA,eAAA;AAAR;AACQ;EACI,mBAAA;EACA,eAAA;AACZ;AACQ;EACI,eAAA;EACA,gBAAA;EACA,2BAAA;AACZ;AAEI;EACI,oBAAA;EACA,eAAA;EACA,qBAAA;EACA,4BAAA;EACA,gBAAA;EACA,gBAAA;AAAR","sourcesContent":[":host {\n    .header {\n        font-size: 12px;\n        padding-left: 0;\n        .col-md-2 {\n            padding-right: 10px;\n            padding-left: 0;\n        }\n        hr {\n            margin-top: 8px;\n            margin-bottom: 0;\n            border-top: 2px dashed grey;\n        }\n    }\n    .text-concat {\n        display: -webkit-box;\n        max-width: 100%;\n        -webkit-line-clamp: 2;\n        -webkit-box-orient: vertical;\n        overflow: hidden;\n        line-height: 1.3;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
