import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DriverAppRoutingModule } from './driver-app-routing.module';
import { DriverAppComponent } from './driver-app.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { SharedModule } from '@app/shared/shared.module';


@NgModule({
  declarations: [
    DriverAppComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    DriverAppRoutingModule,
    DragDropModule
  ]
})
export class DriverAppModule { }
