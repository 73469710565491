// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.company-logo .table-image {
  max-height: 70px;
}

.company-view-more {
  font-size: 12px;
  margin-top: 7px;
}

:host .table {
  max-height: calc(100% - 220px);
}

.dropdown-menu .dropdown-item {
  padding: 10px 14px 10px 6px;
}
.dropdown-menu .dropdown-item i {
  display: inline-block;
  font-size: 16px;
  text-align: center;
  width: 34px;
}

.new-transaction .dropdown-menu {
  overflow: hidden;
  padding: 0;
}
.new-transaction .dropdown-toggle::after {
  display: none;
}
.new-transaction .dropdown-item {
  border-bottom: 2px solid #f1f1f1;
  padding: 10px 6px;
}
.new-transaction .dropdown-item:last-of-type {
  border-bottom: none;
}
.new-transaction .dropdown-item i {
  display: inline-block;
  font-size: 16px;
  text-align: center;
  width: 34px;
}
.new-transaction .qb-icon {
  margin: 0 auto;
}`, "",{"version":3,"sources":["webpack://./src/app/components/main/accounting/accounting-details/accounting-details.component.scss"],"names":[],"mappings":"AAGA;EACI,gBAAA;AAFJ;;AAMA;EACI,eAAA;EACA,eAAA;AAHJ;;AAMA;EACI,8BAAA;AAHJ;;AAOI;EACI,2BAAA;AAJR;AAMQ;EACI,qBAAA;EACA,eAAA;EACA,kBAAA;EACA,WAAA;AAJZ;;AAUI;EACI,gBAAA;EACA,UAAA;AAPR;AAUI;EACI,aAAA;AARR;AAWI;EACI,gCAAA;EACA,iBAAA;AATR;AAWQ;EACI,mBAAA;AATZ;AAYQ;EACI,qBAAA;EACA,eAAA;EACA,kBAAA;EACA,WAAA;AAVZ;AAcI;EACI,cAAA;AAZR","sourcesContent":["\n\n\n.company-logo .table-image {\n    max-height: 70px;\n}\n\n\n.company-view-more {\n    font-size: 12px;\n    margin-top: 7px;\n}\n\n:host .table {\n    max-height: calc(100% - 220px);\n}\n\n.dropdown-menu {\n    .dropdown-item {\n        padding: 10px 14px 10px 6px;\n\n        i {\n            display: inline-block;\n            font-size: 16px;\n            text-align: center;\n            width: 34px;\n        }\n    }\n}\n\n.new-transaction {\n    .dropdown-menu {\n        overflow: hidden;\n        padding: 0;\n    }\n\n    .dropdown-toggle::after {\n        display: none;\n    }\n\n    .dropdown-item {\n        border-bottom: 2px solid #f1f1f1;\n        padding: 10px 6px;\n\n        &:last-of-type {\n            border-bottom: none;\n        }\n\n        i {\n            display: inline-block;\n            font-size: 16px;\n            text-align: center;\n            width: 34px;\n        }\n    }\n\n    .qb-icon {\n        margin: 0 auto;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
