import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UserListComponent } from './user-list/user-list.component';
import { RoleListComponent } from './user-setting/role-list/role-list.component';
import { PermissionListComponent } from './user-setting/permission-list/permission-list.component';

const routes: Routes = [
  {
    path: '',
    component: UserListComponent,

  },
  {
    path: 'roles',
    component: RoleListComponent,
  },
  {
    path: 'permissions',
    component: PermissionListComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UsersRoutingModule { }
