import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiPaginationResponse, ApiResponse, ListRequest } from '@app/models/core/base';
import { Company } from '@app/models/company.model';
import { BehaviorSubject, Observable } from 'rxjs';
import { TermsAndCondition } from '@app/models/terms-and-condition.model';
import { toParams } from 'src/utils/to-params';
import { CrudService } from './core/crud.service';


@Injectable({
    providedIn: 'root'
})
export class TermsAndConditionsService extends CrudService<TermsAndCondition> {

    protected basePath = 'TermsAndConditions'

    constructor(
        http: HttpClient
    ) {
        super(http)
    }

    getAll(request?: any) {
        return this.http.get<ApiResponse<any>>(`${this.basePath}`, { params: toParams(request) })
            .toPromise()
            .then(({ data }) => data);
    }




}
