export const expensesData = [
    {
        title: "Truck",
        count: 56000,
        icon: "icon-orders",
        color: '#4CB75C',
    },
    {
        title: "Drivers",
        count: 138560,
        icon: "icon-driver",
        color: '#E2C768',
    },
    {
        title: "Office",
        count: 15944,
        icon: "icon-office",
        color: '#E38228',
    },
    {
        title: "Other",
        count: 11000,
        icon: "icon-dollar-coin",
        color: '#00BAD7',
    },
]

export const expensesLinesData = [
    {
        data: [4000, 15000, 6000, 7000, 6000, 8000, 5000, 7000, 9000, 5000, 4000, 3500],
        label: 'Truck',
        borderColor: '#4CB75C',
    },
    {
        data: [3000, 4000, 5000, 6000, 5000, 7000, 4000, 6000, 8000, 4000, 3000, 2500],
        label: 'Drivers',
        borderColor: '#E2C768',
    },
    {
        data: [2000, 4000, 4000, 5000, 4000, 6000, 3000, 5000, 7000, 3000, 2000, 1500],
        label: 'Office',
        borderColor: '#E38228',
    },
    {
        data: [1000, 4000, 3000, 4000, 3000, 5000, 2000, 4000, 6000, 2000, 1000, 750],
        label: 'Other',
        borderColor: '#00BAD7',
    },
]

export const expensesTruckData = [
    {
        title: "Gas",
        count: 758,
        icon: "icon-gas",
        color: '#1C812A',
    },
    {
        title: "Insurance",
        count: 1142,
        icon: "icon-insurance",
        color: '#4AA055',
    },
    {
        title: "Maint.",
        count: 560,
        icon: "icon-maintanence",
        color: '#8DC462',
    },
]

export const expensesTruckLinesData = [
    {
        data: [4000, 15000, 6000, 7000, 6000, 8000, 5000, 7000, 9000, 5000, 4000, 3500],
        label: 'Gas',
        borderColor: '#1C812A',
    },
    {
        data: [3000, 4000, 5000, 6000, 5000, 7000, 4000, 6000, 8000, 4000, 3000, 2500],
        label: 'Insurance',
        borderColor: '#4AA055',
    },
    {
        data: [2000, 4000, 4000, 5000, 4000, 6000, 3000, 5000, 7000, 3000, 2000, 1500],
        label: 'Maint.',
        borderColor: '#8DC462',
    },
]