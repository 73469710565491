import { CurrencyPipe as NgCurrencyPipe } from '@angular/common';
import { Pipe, PipeTransform, } from '@angular/core';

@Pipe({
  name: 'currency'
})
export class CurrencyPipe extends NgCurrencyPipe implements PipeTransform {

  transform(value: any) {
    return super.transform(value, '$', '$ ') as any;
  }

}
