import { Component, Input } from '@angular/core';
import { saveAs } from 'file-saver';

import { Attachment } from '@models/attachment.model';
import { UtilService } from '@app/services/util.service';
import { AttachmentService } from '@app/services/attachment.service';
import { EntityType, EntityTypes } from '@models/entity-type.model';
import { ImagesService } from '@app/shared/services/images.service';
import { Order } from '@app/models/order.model';
import * as moment from 'moment';
import { HelperService } from '@app/shared/services/helper.service';


@Component({
    selector: 'app-attachment-drag-drop',
    templateUrl: './attachment-drag-drop.component.html',
    styleUrls: ['./attachment-drag-drop.component.scss'],
})
export class AttachmentDragDropComponent {
    @Input() order: Order;
    @Input() entityType: EntityType = EntityType.Order;
    @Input() files: Attachment[];

    constructor(
        public util: UtilService,
        private attachmentService: AttachmentService,
        private imagesService: ImagesService,
        private helperService: HelperService,
    ) {
    }

    ngOnInit() {
        this.loadOrder();
    }

    private async loadOrder() {
        if (this.order?.orderId != null) {
            try {
                this.files = await this.imagesService.getListByOrderAttachments(this.order.orderId);
            } catch (error) {
                this.helperService.errorMessage(error)
            }
        }
    }

    openAttachment(attachment: Attachment) {
        this.helperService.isLoading = true
		this.imagesService.downloadAttachment(attachment).then((res) => {
			this.helperService.isLoading = false
			saveAs(new Blob([res]), attachment.name);
		}).catch((error) => {
			this.helperService.isLoading = false
			this.helperService.errorMessage(error)
		})
    }

    uploadOrderAttachments(files) {
        for (let index = 0; index < files.length; index++) {
            const file = files[index];
            this.imagesService.uploadAttachment(EntityTypes.Order, this.order?.orderId, file).then((res) => {
                this.loadOrder();
            })
        }
    }

    fileSize(size: number, extension = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']) {
        const i = Math.floor(Math.log(size) / Math.log(1024))
        return `${parseFloat((size / Math.pow(1024, i)).toFixed(2))} ${extension[i]} || undefine`
    }

    dateTime(date) {
        return moment(date).format('l LTS')
    }

}
