export enum EntityType {
    Customer = 1,
    Order = 2,
    OrderItem = 3,
    OrderStop = 4,
    CustomerContact = 5,
    CustomerPaymentMethod = 6,
    RouteTransfer = 7,
    Quotes = 8,
    Orders = 9,
    Warehouse = 10,
    Driver = 11,
    TenantPaymentMethod = 12,
    Payment = 13,
    Invoice = 14,
    Route = 15,
    Truck = 16,
    OutsourceCompany = 17,
    OutsourceCompanyContact = 18,
}


export const EntityTypes = Object.freeze({
    Customer: EntityType.Customer,
    Order: EntityType.Order,
    OrderItem: EntityType.OrderItem,
    OrderStop: EntityType.OrderStop,
    CustomerContact: EntityType.CustomerContact,
    CustomerPaymentMethod: EntityType.CustomerPaymentMethod,
    RouteTransfer: EntityType.RouteTransfer,
    Quotes: EntityType.Quotes,
    Orders: EntityType.Orders,
    Warehouse: EntityType.Warehouse,
    Driver: EntityType.Driver,
    TenantPaymentMethod: EntityType.TenantPaymentMethod,
    Payment: EntityType.Payment,
    Invoice: EntityType.Invoice,
    Route: EntityType.Route,
    Truck: EntityType.Truck,
});
