import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { patterns } from '@app/@shared/regex/regex-patterns.const';
import { formatPhoneNumberAndExtension, parsePhoneNumberAndExtension } from '@app/@shared/utils';
import { countryOptions } from '@app/data/countries';
import { customerEmailTypes } from '@app/data/customer';
import { stateOptions } from '@app/data/state';
import { Country } from '@app/models/address.model';
import { PipelineEnum } from '@app/models/customer.model';
import { EntityType } from '@app/models/entity-type.model';
import { ImageUploadTypeEnum } from '@app/models/image.model';
import { OutsourceCompany } from '@app/models/outsource-company.model';
import { Vehicle } from '@app/models/samsara/vehicle.model';
import { TypeOfTruck } from '@app/models/type-of-truck.model';
import { AddressService } from '@app/shared/services/address.service';
import { CustomerService } from '@app/shared/services/customer.service';
import { HelperService } from '@app/shared/services/helper.service';
import { TruckService } from '@app/shared/services/truck.service';
import { TypeOfTrucksService } from '@app/shared/services/type-of-truck.service';
import { cloneDeep } from 'lodash';
import { object, string } from 'yup';

@Component({
    selector: 'app-outsource-company-information',
    templateUrl: './outsource-company-information.component.html',
    styleUrls: ['./outsource-company-information.component.scss']
})
export class OutsourceCompanyInformationComponent implements OnInit {

    defaultEmailItem = {
        isActive: true,
        emailType: null,
        email: '',
    }

    private _outsourceCompany: OutsourceCompany = {};
    customerEmailTypes = customerEmailTypes;
    states = stateOptions;
    users: any[];
    ImageUploadTypeEnum = ImageUploadTypeEnum;
    PipelineEnum = PipelineEnum;
    vehicles: Vehicle[];

    validationSchema = object().shape({
        outsourceCompanyName: string().nullable().max(256).required(),
        phoneNumber: string().nullable().matches(patterns.phone, { excludeEmptyString: true, message: 'Phone must be a number' }),
        cellNumber: string().nullable().matches(patterns.phone, { excludeEmptyString: true, message: 'Cell must be a number' }),
        email: string().label('Email').nullable().email().max(256),
        addressLine1: string().label("House/Office").nullable().max(256),
        addressLine2: string().label("Street").nullable().max(256),
        gate: string().nullable().max(256),
        city: string().nullable().max(256),
        state: string().nullable().max(256),
        country: string().nullable().max(256),
        zip: string().label("Zip code").nullable().max(32),
    });

    @Output() outsourceCompanyChange = new EventEmitter()
    typeOfTrucks: any;

    @Input()
    public get outsourceCompany(): any {
        return this._outsourceCompany;
    }
    public set outsourceCompany(value: any) {
        this._outsourceCompany = cloneDeep(parsePhoneNumberAndExtension(value));
    }
    countries: Country[];
    constructor(
        public customerService: CustomerService,
        public helperService: HelperService,
        public addressService: AddressService,
        private typeOfTrucksService: TypeOfTrucksService,
    ) {
    }

    ngOnInit() {
        this.typeOfTrucksService.getAll()
            .then((res) => {
                this.typeOfTrucks = res;
            }).catch((error) => {
                this.helperService.errorMessage(error)
            })

        this.addressService.getCountries()
            .then(res => {
                this.countries = res;
            }).catch((error) => {
                this.countries = [];
                this.helperService.errorMessage(error)
            })
    }

    setTypeOfTruckId(value, index) {
        this.outsourceCompany.companyTypeOfTrucks[index].typeOfTruckId = Number(value);
    }

    mainAddress(): any {
        if (!this.outsourceCompany?.addresses?.find(x => x?.entityType === EntityType?.OutsourceCompany)) {
            return this.outsourceCompany = { addresses: [{ entityType: EntityType.OutsourceCompany, name: 'Main', addressLine1: null, city: null, state: null, zip: null }] }
        } else {
            return this.outsourceCompany?.addresses?.find(x => x?.entityType === EntityType.OutsourceCompany);
        }
    }

    handleSubmit(f) {
        if (f.isValid) {
            this.outsourceCompanyChange.emit(formatPhoneNumberAndExtension(this.outsourceCompany))
        }
    }

}
