import { Component, Input } from '@angular/core';
import { OrderPriorityEnum } from '@app/models/order.model';
import { HelperService } from '@app/shared/services/helper.service';
import { OrderService } from '@app/shared/services/order.service';

@Component({
  selector: 'app-order-priority',
  templateUrl: './order-priority.component.html',
  styleUrls: ['./order-priority.component.scss'],
})
export class OrderPriorityComponent {

  @Input() orderId: string | number;
  @Input() orderStopId: string | number;
  @Input() type: 'order' | 'orderStop' = 'order';
  private _priority: OrderPriorityEnum = OrderPriorityEnum.MIDDLE;
  @Input() isTextInput: boolean = false;
  @Input() isTextCaption: boolean = false;

  @Input()
  set priority(value: OrderPriorityEnum) {
    if (value !== null) {
      this._priority = value;
    } else {
      this._priority = OrderPriorityEnum.MIDDLE;
    }
  }

  get priority(): OrderPriorityEnum {
    return this._priority;
  }

  OrderPriorityEnum = OrderPriorityEnum

  constructor(
    private orderService: OrderService,
    private helperService: HelperService,
  ) {
  }

  handleChangePriority(event) {
    event.stopPropagation();
    if (!this.priority) {
      this.priority = OrderPriorityEnum.MIDDLE;
    }
    let newPriority: OrderPriorityEnum;
    const oldPriority = this.priority;
    switch (this.priority) {
      case OrderPriorityEnum.LOWEST:
        newPriority = OrderPriorityEnum.LOW;
        break;
      case OrderPriorityEnum.LOW:
        newPriority = OrderPriorityEnum.MIDDLE;
        break;
      case OrderPriorityEnum.MIDDLE:
        newPriority = OrderPriorityEnum.HIGH;
        break;
      case OrderPriorityEnum.HIGH:
        newPriority = OrderPriorityEnum.HIGHEST;
        break;
      case OrderPriorityEnum.HIGHEST:
        newPriority = OrderPriorityEnum.LOWEST;
        break;
      default:
        newPriority = OrderPriorityEnum.MIDDLE;
    }
    this.priority = newPriority;
    if (this.orderStopId && this.type === 'orderStop') {
      this.orderService.updateOrderStopPriority(this.orderStopId, newPriority).then((res) => {
        this.helperService.successMessage("Order stop priority successfully updated.");
      }).catch((error) => {
        this.priority = oldPriority;
        this.helperService.errorMessage(error);
      })
    } else if (this.orderId && this.type === 'order') {
      this.orderService.updateOrderPriority(this.orderId, newPriority).then((res) => {
        this.helperService.successMessage("Order priority successfully updated.");
      }).catch((error) => {
        this.priority = oldPriority;
        this.helperService.errorMessage(error);
      })
    }
  }

  getTitleAndCssPriority(isTitle: boolean = false) {
    switch (this.priority) {
      case OrderPriorityEnum.LOWEST:
        return isTitle ? 'text-gray-400 icon-lowest' : 'Lowest';

      case OrderPriorityEnum.LOW:
        return isTitle ? 'text-gray-400 icon-low' : 'Low';

      case OrderPriorityEnum.MIDDLE:
        return isTitle ? 'text-primary icon-medium' : 'Middle';

      case OrderPriorityEnum.HIGH:
        return isTitle ? 'text-danger icon-high' : 'High';

      case OrderPriorityEnum.HIGHEST:
        return isTitle ? 'text-danger icon-highest' : 'Highest';

      default:
        return isTitle ? 'text-primary icon-medium' : 'Middle';
    }
  }

}
