import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard  {
    constructor(
        private router: Router,
        private authService: AuthService,
    ) { }

    async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

        if (route.queryParams.token) {
            this.authService.setAuth(route.queryParams.token);
        }
        if (route.queryParams?.jwt) {
            this.authService.setAuth(route.queryParams?.jwt);
        }

        return new Promise((resolve, reject) => {
            return this.authService.$isLoggedIn.subscribe((login) => {
                if (this.authService.dataStore.isInit) {
                    if (login) {
                        resolve(true);
                    } else {
                        reject();
                    }
                }

            });
        })
            .then(() => true)
            .catch((error) => {
                this.router.navigate(['/auth/login'], { queryParams: { returnUrl: state.url, login: true } });
                return false;
            });
    }
}


