// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
  padding: 20px;
  position: relative;
  margin-bottom: 15px;
  width: 100%;
  min-height: 200px;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.67);
}

.spinner {
  box-sizing: unset;
  width: 50px;
  height: 50px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -25px;
  margin-left: -25px;
  border: 5px solid #77c5e2;
  border-radius: 50%;
}
.spinner:before, .spinner:after {
  content: "";
  position: absolute;
  display: block;
  width: 5px;
  background-color: #828d92;
  border-radius: 2.5px;
  transform-origin: 50% 0%;
}
.spinner:before {
  height: 15px;
  left: 22.5px;
  top: 50%;
  animation: spin 2000ms linear infinite;
}
.spinner:after {
  height: 20px;
  left: 22.5px;
  top: 50%;
  animation: spin 500ms linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}`, "",{"version":3,"sources":["webpack://./src/app/components/global/spinner/spinner.component.scss"],"names":[],"mappings":"AAMA;EACI,cAAA;EACA,aAAA;EACA,kBAAA;EACA,mBAAA;EACA,WAAA;EACA,iBAAA;EACA,YAAA;EACA,MAAA;EACA,OAAA;EACA,qCAAA;AALJ;;AAQA;EACI,iBAAA;EACA,WAAA;EACA,YAAA;EACA,kBAAA;EACA,QAAA;EACA,SAAA;EACA,iBAAA;EACA,kBAAA;EACA,yBAAA;EACA,kBAAA;AALJ;AAOI;EAEI,WAAA;EACA,kBAAA;EACA,cAAA;EACA,UAAA;EACA,yBArCA;EAsCA,oBAAA;EACA,wBAAA;AANR;AASI;EACI,YAAA;EACA,YAAA;EACA,QAAA;EACA,sCAAA;AAPR;AAUI;EACI,YAAA;EACA,YAAA;EACA,QAAA;EACA,qCAAA;AARR;;AAYA;EACI;IACI,yBAAA;EATN;AACF","sourcesContent":["$color: #828d92;\n$color2: #77c5e2;\n$r: 25;\n$d: $r * 2;\n$t: 2000;\n\n:host {\n    display: block;\n    padding: 20px;\n    position: relative;\n    margin-bottom: 15px;\n    width: 100%;\n    min-height: 200px;\n    height: 100%;\n    top: 0;\n    left: 0;\n    background: rgba(255, 255, 255, 0.67);\n}\n\n.spinner {\n    box-sizing: unset;\n    width: #{$d}px;\n    height: #{$d}px;\n    position: absolute;\n    top: 50%;\n    left: 50%;\n    margin-top: -#{calc($d/2)}px;\n    margin-left: -#{calc($d/2)}px;\n    border: #{calc($d/10)}px solid $color2;\n    border-radius: 50%;\n\n    &:before,\n    &:after {\n        content: \"\";\n        position: absolute;\n        display: block;\n        width: #{calc($d/10)}px;\n        background-color: $color;\n        border-radius: #{calc(($d/10)/2)}px;\n        transform-origin: 50% 0%;\n    }\n\n    &:before {\n        height: #{calc($r - ($d/10) - (($d/10)))}px;\n        left: #{calc($r - (($d/10)/2))}px;\n        top: 50%;\n        animation: spin #{$t}ms linear infinite;\n    }\n\n    &:after {\n        height: #{calc($r - ($d/10))}px;\n        left: #{calc($r - (($d/10)/2))}px;\n        top: 50%;\n        animation: spin #{calc($t/4)}ms linear infinite;\n    }\n}\n\n@keyframes spin {\n    to {\n        transform: rotate(360deg);\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
