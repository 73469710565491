import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@app/shared/shared.module';
import { ZonesRoutingModule } from './zones-routing.module';
import { ZoneDetailsComponent } from './zone-details/zone-details.component';
import { NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { DatatableModule } from '@app/@shared/datatable';
import { DialogsModule } from '@app/@shared/dialogs/dialogs.module';
import { ZoneListComponent } from './zone-list/zone-list.component';
import { NgxSelectModule } from '@app/@shared/ngx-select';
import { FormValidateModule } from '@app/@shared/form-validate/form-validate.module';

@NgModule({
  declarations: [
    ZoneListComponent,
    ZoneDetailsComponent
  ],
  imports: [
    CommonModule,
    ZonesRoutingModule,
    SharedModule,
    NgbPaginationModule,
    DatatableModule,
    DialogsModule,
    NgxSelectModule,
    FormValidateModule
  ]
})
export class ZonesModule { }
