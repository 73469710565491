import { BaseModel, CompanyBase, TenantBase } from '@models/base.model';

export interface TypeOfTruck extends BaseModel, TenantBase, CompanyBase {
    typeOfTruckId?: number;
    type?: TypeOfTruckTypeEnum;
    isActive?: boolean;
    name?: string;
}

export enum TypeOfTruckTypeEnum {
    PickupTruck = 1,
    BoxTruck = 2,
    SemiTruck = 3,
    RefrigeratorTruck = 4,
    FurnitureTruck = 5,
    FlatbedTruck = 6
}