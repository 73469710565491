import { BaseModel } from '@models/base.model';
import { Customer } from '@models/customer.model';

export const LoadTypes = ['PalletStandard', 'PalletSmall', 'PalletLarge', 'LoadTrailer', 'LoadTruck', 'LoadVan'] as const;

export type LoadType = typeof LoadTypes[number];

export interface LoadTypePrice {
    loadType: LoadType;
    price: number;
    from: number;
}

export interface PerMilePrice {
    from: number;
    price: number;
}

export interface PerHourPrice {
    from: number;
    price: number;
}

export interface PerLbPrice {
    from: number;
    price: number;
}

export interface PerCftPrice {
    from: number;
    price: number;
}

export interface ShipmentType extends BaseModel {
    shipmentTypeId?: number;
    name?: string;

    surcharge?: number;
    fee?: number;
    markup?: number;

    minCharge?: number;
    pricePerHour?: number;
    pricePerMile?: number;

    pricePerWeightLb?: number;
    pricePerWeightKg?: number;

    pricePerCbm?: number;
    pricePerCft?: number;

    feeFragile?: number;
    feeHazardous?: number;
    feeRefrigerated?: number;
    feeOverweight?: number;
    feeOther?: number;

    pricesPerLoadType?: LoadTypePrice[];
    perMilePrices?: PerMilePrice[];
    perHourPrices?: PerHourPrice[];
    perLbPrices?: PerLbPrice[];
    perCftPrices?: PerCftPrice[];
    pricesPerLoadTypeMap?: Record<LoadType, LoadTypePrice[]>;
    customerId: number;
    customer?: Customer;
}
