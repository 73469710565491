import { CreditCardTypeEnum, ICardType, IPaymentType, PaymentTypeEnum } from "@app/models/payment-type.model";

export const PAYMENT_TYPES: IPaymentType[] = [
   {
      name: 'Card',
      type: PaymentTypeEnum.CREDIT_CARD,
      logo: "/assets/images/payment-logo/credit-card.svg",
      isActive: true
   },
   {
      name: 'ACH',
      type: PaymentTypeEnum.ACH,
      logo: "/assets/images/payment-logo/ach.svg",
      isActive: false
   },
   {
      name: 'PayPal',
      type: PaymentTypeEnum.PAYPAL,
      logo: "/assets/images/payment-logo/paypal.svg",
      isActive: false
   },
  //  {
  //     name: 'Cash',
  //     type: PaymentTypeEnum.CASH,
  //     logo: "/assets/images/payment-logo/ach.svg",
  //     isActive: false
  //  }
];



export const CREDIT_CARD_TYPES: ICardType[] = [
  {
    name: 'Visa',
    type: CreditCardTypeEnum.VISA,
    logo: '/assets/images/payment-logo/visa-card',
  },
  {
    name: 'American Express',
    type: CreditCardTypeEnum.AMERICAN_EXPRESS,
    logo: '',
  },
  {
    name: 'Master Card',
    type: CreditCardTypeEnum.MASTER,
    logo: 'https://www.freepnglogos.com/uploads/discover-png-logo/mastercard-discover-logo-png-22.png',
  },
  {
    name: 'Discover',
    type: CreditCardTypeEnum.DISCOVER,
    logo: 'https://www.freepnglogos.com/uploads/discover-png-logo/discover-careers-pmg-logo-0.png',
  },
  {
    name: 'Gift Card',
    type: CreditCardTypeEnum.GIFT_CARD,
    logo: '',
  },
]