import { AddressInfoComponent } from "./address-info/address-info.component";
import { ChatHistoryViewerComponent } from "./chat-history/chat-history-viewer.component";
import { FilesViewerComponent } from "./files-viewer/files-viewer.component";
import { PageNotFoundComponent } from "./page-not-found/page-not-found.component";
import { ToggleComponent } from "./toggle/toggle.component";
import { UserInfoComponent } from "./user-info/user-info.component";

export const AuxiliaryComponents = [
  AddressInfoComponent,
  ChatHistoryViewerComponent,
  FilesViewerComponent,
  PageNotFoundComponent,
  ToggleComponent,
  UserInfoComponent
]
