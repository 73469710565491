import { Component, Input } from '@angular/core';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { saveAs } from 'file-saver';

import { Attachment } from '@models/attachment.model';
import { UtilService } from '@app/services/util.service';
import { Attachable, AttachmentService } from '@app/services/attachment.service';
import { EntityType } from '@models/entity-type.model';

@Component({
    selector: 'app-attachments',
    templateUrl: './attachments.component.html',
    styleUrls: ['./attachments.component.scss'],
})
export class AttachmentsComponent {
    @Input() entity: Attachable;
    @Input() size: 'largeIcon' | 'smallIcon' = 'largeIcon';
    @Input() entityType: EntityType;
    @Input() label: string;

    constructor(
        public util: UtilService,
        private attachmentService: AttachmentService,
    ) {
    }

    uploadOrderStopAttachmentFile(event: Event) {
        this.attachmentService.uploadAttachmentFile(event, this.entityType, this.entity);
    }

    openAttachment(attachment: Attachment, myDrop: NgbDropdown) {
        myDrop.close();
        this.attachmentService.downloadAttachment(attachment).subscribe(res => {
            saveAs(new Blob([res]), attachment.name);
        }, err => this.util.showError(err.messages));
    }
}
