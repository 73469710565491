import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingComponent implements OnInit {

  @Input('type') type: 'inline' | 'block' = 'block';

  @Input('size') size: 'small' | 'medium' | 'large' = 'medium'

  constructor() { }

  ngOnInit() {
  }

}
