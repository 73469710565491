import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { DateRangePickerComponent } from './date-range-picker.component';

@NgModule({
    declarations: [
        DateRangePickerComponent,
    ],
    exports: [
        DateRangePickerComponent
    ],
    imports: [
        CommonModule,
        NgbDropdownModule,
        NgbDatepickerModule
    ]
})
export class DateRangePickerModule { }
