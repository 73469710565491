import { Component, OnInit, ViewChild } from '@angular/core';
import { Expense } from '@models/expense.model';
import { ExpenseService } from '@app/shared/services/expense.service';
import { HelperService } from '@app/shared/services/helper.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ExpenseDetailsComponent } from '../expense-details/expense-details.component';
import { DatatableComponent } from '@app/@shared/datatable';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { map } from 'lodash';
import { expensesTruckData, expensesTruckLinesData } from '@app/data/expenses';

@UntilDestroy()
@Component({
    selector: 'app-truck-expenses-list',
    templateUrl: './truck-expenses-list.component.html',
    styleUrls: ['./truck-expenses-list.component.scss'],
})
export class TruckExpenseListComponent implements OnInit {

    truckExpensesTypes: string
    searchDate: Date;
    title = "Truck Expenses";
    lineChart: any;
    @ViewChild('datatable') datatable: DatatableComponent
    activeTab = 'gas';
    doughnutChart: any;
    expensesTruckChartCounts = expensesTruckData;
    expensesChartLines = expensesTruckLinesData;

    constructor(
        private expenseService: ExpenseService,
        private helperService: HelperService,
        private ngbModal: NgbModal,
    ) {
        this.doughnutChart = {
            data:{
                datasets:[{
                     data: map(this.expensesTruckChartCounts, "count"),
                     backgroundColor: map(this.expensesTruckChartCounts, "color"),
                }]
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                cutout: '84%',
                hover: { mode: null },
                plugins: { tooltip: { enabled: false} },
                elements: {
                    arc: { borderWidth: 0 },
                },
            },
        };

        this.lineChart = {
            data: {
                labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                datasets: this.expensesChartLines
            },
            options: {
                responsive: false,
                maintainAspectRatio: false,
                elements: {
                    point: {
                        radius: 0,
                    },
                    line: {
                        tension: 0.5,
                        fill: false,
                        borderWidth: 1
                    }
                },
                scales: {
                    yAxes: [{
                        ticks: {
                            stepSize: 50,
                            callback: function (value) {
                                var ranges = [
                                    { divider: 1e6, suffix: 'M' },
                                    { divider: 1e3, suffix: 'k' }
                                ];
                                function formatNumber(n) {
                                    for (var i = 0; i < ranges.length; i++) {
                                        if (n >= ranges[i].divider) {
                                            return (n / ranges[i].divider).toString() + ranges[i].suffix;
                                        }
                                    }
                                    return n;
                                }
                                return "$ " + formatNumber(value);
                            }
                        }
                    }]
                }
            },
        }
    }

    getData = (request?: any) => {
       return  this.expenseService.getAll(request)
    }

    ngOnInit() {
    }

    openAddEdit(item?: Expense) {
        const ref = this.ngbModal.open(ExpenseDetailsComponent, {
            size: 'md'
        })
        ref.componentInstance.item = item

        ref.closed.pipe(untilDestroyed(this)).subscribe((isReload) => {
            if (isReload) {
                this.datatable.refresh()
            }
        })
    }

}
