// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host .optionTag {
  padding: 6px 8px;
  font-size: 10px;
  font-weight: 600;
  background-color: #e1e8ed;
}

::ng-deep .drop-down-select-option {
  max-width: 222px;
  max-height: 260px;
  overflow-y: auto;
}
::ng-deep .items-size {
  font-size: 12px !important;
}`, "",{"version":3,"sources":["webpack://./src/app/@shared/enum-flags-drop-down-select/enum-flags-drop-down-select.component.scss"],"names":[],"mappings":"AACE;EACI,gBAAA;EACA,eAAA;EACA,gBAAA;EACA,yBAAA;AAAN;;AAKE;EACI,gBAAA;EACA,iBAAA;EACA,gBAAA;AAFN;AAKE;EACI,0BAAA;AAHN","sourcesContent":[":host {\n  .optionTag {\n      padding: 6px 8px;\n      font-size: 10px;\n      font-weight: 600;\n      background-color: #e1e8ed;\n  }\n}\n\n::ng-deep {\n  .drop-down-select-option {\n      max-width: 222px;\n      max-height: 260px;\n      overflow-y: auto;\n  }\n\n  .items-size{\n      font-size: 12px !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
