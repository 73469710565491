import { Injectable } from "@angular/core";

@Injectable({ providedIn: 'root' })
export class DateTimeService {
    combineDateAndTime(date?: Date, time?: Date): string | undefined {
        if (!date) {
            return undefined;
        }
        else if (!time) {
            return date.toISOString();
        }
        else {
            const combinedDate = new Date(date);
            combinedDate.setUTCHours(
              date.getUTCHours() + time.getUTCHours(),
              date.getUTCMinutes() + time.getUTCMinutes(),
              date.getUTCSeconds() + time.getUTCSeconds()
            );
            return combinedDate.toISOString();
        }
    }
}
