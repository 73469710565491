import { Component, Input, OnInit } from '@angular/core';
import { EntityTypes } from '@app/models/entity-type.model';
import { OrderItemStop } from '@app/models/order-item-stop.model';
import { OrderStopTypeEnum } from '@app/models/order-stop.model';
import { Order } from '@app/models/order.model';
import { HelperService } from '@app/shared/services/helper.service';
import { OrderService } from '@app/shared/services/order.service';

@Component({
  selector: 'app-package-details',
  templateUrl: './package-details.component.html',
  styleUrls: ['./package-details.component.scss']
})
export class PackageDetailsComponent implements OnInit {

  selectedId = null

  EntityTypes = EntityTypes
  OrderStopTypeEnum = OrderStopTypeEnum

  private _order: Order;
  @Input()
  public get order(): Order {
    return this._order;
  }
  public set order(value: Order) {
    this._order = value;
    if (this._order?.orderId) {
      this.getOrderTrips()
    }
  }

  items: OrderItemStop[] = []
  loading: boolean;

  constructor(
    private orderService: OrderService,
    private helperService: HelperService,
  ) {

  }

  ngOnInit(): void { }
  toggleOpenClose(value) {
    if (this.selectedId === value) {
      this.selectedId = null
    } else {
      this.selectedId = value
    }
  }
  getOrderTrips() {
    this.loading = true
    this.orderService.getOrderTrips(this.order?.orderId).then((resp) => {
      this.items = resp
    }).catch((error) => {
      this.helperService.errorMessage(error)
    }).finally(() => {
      this.loading = false
    })
  }

}
