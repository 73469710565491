import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormValidateDirective } from '@app/@shared/form-validate/form-validate.directive';
import { CREDIT_CARD_TYPES, PAYMENT_TYPES } from '@app/data/payment-method';
import { stateOptions } from '@app/data/state';
import { Customer } from '@app/models/customer.model';
import { PaymentMethod } from '@app/models/payment-method.model';
import { IPaymentType, PaymentTypeEnum } from '@app/models/payment-type.model';
import { UtilService } from '@app/services/util.service';
import { CustomerService } from '@app/shared/services/customer.service';
import { HelperService } from '@app/shared/services/helper.service';
import { find } from 'lodash';
import { number, object, string } from 'yup';

declare let getTokens: any;

export interface PaymentMethodItem extends PaymentMethod {
	isEdit?: boolean;
	paymentTypeName?: string;
	expiryDisplayDate?: string;
	paymentTypeLogo?: string;
}

@Component({
	selector: 'app-payments',
	templateUrl: './payments.component.html',
	styleUrls: ['./payments.component.scss']
})
export class PaymentsComponent implements OnInit {

	private _customer: Customer;
	defaultPaymentMethodId: number;

	private _paymentMethods: PaymentMethodItem[] = [];
	public get paymentMethods(): PaymentMethodItem[] {
		return this._paymentMethods;
	}
	public set paymentMethods(value: PaymentMethodItem[]) {
		console.log(value);
		value?.map((item) => {
			item.expiryDisplayDate = item?.expDate ? `${item?.expDate.slice(0, 2)} / 20${item?.expDate.slice(2, 2)}` : ""
			if (item.paymentType == PaymentTypeEnum.CREDIT_CARD) {
				const type = find(PAYMENT_TYPES, { type: PaymentTypeEnum.CREDIT_CARD })
				item.paymentTypeLogo = type?.logo
				item.paymentTypeName = type?.name;
			} else {
				const cardType = find(CREDIT_CARD_TYPES, { type: item.cardType })
				item.paymentTypeName = cardType?.name;
				item.paymentTypeLogo = cardType?.logo
			}
			return item;
		})
		this._paymentMethods = value;
	}

	@Input()
	public get customer(): Customer {
		return this._customer;
	}
	public set customer(value: Customer) {
		this._customer = value;
		this.paymentMethods = value.customerPaymentMethods
		this.defaultPaymentMethodId = find(value.customerPaymentMethods, { isDefault: true })?.customerPaymentMethodId;
	}

	@Output() customerChange = new EventEmitter()

	states = stateOptions;
	paymentTypes = this.util.paymentTypes;

	validationSchema = object().shape({
		//maskedNumber: number().nullable().label("Card Number").required().max(16),
		//cvv: number().nullable().label("Cvv").required().max(4),
		expiryMonth: number().nullable().positive().min(1).max(12).required(),
		expiryYear: number().nullable().positive().min(23).max(99).required(),
		name: string().nullable().required(),
		address: string().nullable().required(),
		city: string().nullable().required(),
		state: string().nullable().required(),
		zipCode: string().nullable().required().matches(/^[0-9]/, { message: 'Zip code must be a number' }).max(6),
	});

	/**
	 * Months list
	 */
	months = [
		{ title: 'January - 01', value: '01' },
		{ title: 'February - 02', value: '02' },
		{ title: 'March - 03', value: '03' },
		{ title: 'April - 04', value: '04' },
		{ title: 'May - 05', value: '05' },
		{ title: 'June - 06', value: '06' },
		{ title: 'July - 07', value: '07' },
		{ title: 'August - 08', value: '08' },
		{ title: 'September - 09', value: '09' },
		{ title: 'October - 10', value: '10' },
		{ title: 'November - 11', value: '11' },
		{ title: 'December - 12', value: '12' },
	];

	/**
	 * Years list
	 */
	years = [];

	/**
	 * Default payment methods
	 */
	payments: typeof PAYMENT_TYPES = PAYMENT_TYPES;
	activePayment: IPaymentType = this.payments[0];

	constructor(
		private readonly customerService: CustomerService,
		private readonly helperService: HelperService,
		private readonly util: UtilService,
	) {}

	ngOnInit(): void {
		const currentYear = new Date().getFullYear();
		for (let index = 0; index < 11; index++) {

			const fullYear = currentYear + index;
			const fullYearStr = fullYear.toString();
			const shortYear = fullYearStr.substring(2);

			this.years.push({ title: fullYear, value: shortYear });
		}
	}

	addPaymentMethod(payment: IPaymentType) {
		if (payment.isActive) {
			this.paymentMethods.splice(0, 0, {
				isEdit: true,
				address: {},
				customerId: this.customer.customerId,
			});
		}
	}

	/**
	 * Cancel Edit Payment Method
	 * 
	 * @param paymentMethod 
	 * @param index 
	 */
	cancelEditPaymentMethod(paymentMethod: PaymentMethod, index: number) {
		const address = this.paymentMethods[index];
		if (address && address.customerPaymentMethodId) {
			paymentMethod['isEdit'] = false;
			const oldAddress = find(this.customer.customerPaymentMethods, { customerPaymentMethodId: address.customerPaymentMethodId })
			this.paymentMethods.splice(index, 1, oldAddress);
		} else {
			this.paymentMethods.splice(index, 1);
		}
	}

	/**
	 * Edit payment method
	 * 
	 * @param paymentMethod 
	 * @param index 
	 */
	editPaymentMethod(paymentMethod: PaymentMethod) {
		paymentMethod['isEdit'] = true;
	}

	/**
	 * Save customer payment methods
	 * 
	 * @param form
	 * @param index 
	 */
	async savePaymentMethod(form: FormValidateDirective , index: number) {
		if (form.isValid) {
			const paymentMethod = this.paymentMethods[index];
			getTokens(async (success: any) => {
				const paymentMethodRequest = {
					customerId: paymentMethod.customerId,
					name: paymentMethod.name,
					paymentType: this.activePayment.type,
					maskedNumber: paymentMethod.maskedNumber,
					cvv: paymentMethod.cvv,
					expDate: paymentMethod.expiryMonth + paymentMethod.expiryYear,
					address: paymentMethod.address
				}
				try {
					if (paymentMethod.paymentMethodId) {
						await this.customerService.updatePaymentMethod( paymentMethod.paymentMethodId, paymentMethodRequest );
					} else {
						await this.customerService.createPaymentMethod(this.customer.customerId, paymentMethodRequest);
					}

					paymentMethod.isEdit = false;
					this.customerChange.emit(true);
				} catch (error) {
					console.log('Error while creating / updating payment method', error);
					this.helperService.errorMessage(error);
				}
			}, (error: any) => {
				console.log('Error while create/update customer payment method', error);
				this.helperService.errorMessage(error);
			}, 30000);
		}
	}

	/**Delete Payment Method
	 * 
	 * 
	 * @param paymentMethod 
	 * @param index 
	 */
	async deletePaymentMethod(paymentMethod: PaymentMethod, index: number) {
		try {
			if (paymentMethod) {
				await this.customerService.deletePaymentMethod(paymentMethod.customerPaymentMethodId);
			}
			this.paymentMethods.splice(index, 1);
		} catch (error) {
			console.log('Error while deleting customer payment method', error);
			this.helperService.errorMessage(error);
		}
	}

	/**
	 * Set payment method as default
	 * 
	 * @param event 
	 */
	async setDefaultCard(customerPaymentMethodId: number, paymentMethod: PaymentMethod) {
		try {
			const paymentMethod = await this.customerService.updatePaymentMethod(customerPaymentMethodId, {
				isDefault: true
			});
			console.log(paymentMethod);
		} catch (error) {
			console.log('Error while set card as default payment method', error);
			this.helperService.errorMessage(error);
		}
	}
}
