// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  position: relative;
  display: block;
  width: 100%;
}
:host .clear-selection-button {
  position: absolute;
  right: 4px;
  top: 50%;
  transform: translateY(-50%);
}
:host .form-control {
  padding-right: 40px;
}
:host ::ng-deep .dropdown-menu,
:host ::ng-deep .show {
  max-height: 300px;
  overflow-y: auto;
  max-width: 300px;
}
:host ::ng-deep .dropdown-menu .dropdown-item,
:host ::ng-deep .show .dropdown-item {
  color: #82949f;
}
:host ::ng-deep .dropdown-menu .dropdown-item.active, :host ::ng-deep .dropdown-menu .dropdown-item:hover,
:host ::ng-deep .show .dropdown-item.active,
:host ::ng-deep .show .dropdown-item:hover {
  color: #fff;
}

::ng-deep .address-selection-option {
  max-width: 450px;
  display: block;
  word-break: break-word;
}`, "",{"version":3,"sources":["webpack://./src/app/@shared/address-lookup/address-lookup.component.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,cAAA;EACA,WAAA;AACJ;AACI;EACI,kBAAA;EACA,UAAA;EACA,QAAA;EACA,2BAAA;AACR;AAEI;EACI,mBAAA;AAAR;AAKQ;;EAGI,iBAAA;EACA,gBAAA;EACA,gBAAA;AAJZ;AAMY;;EACI,cAAA;AAHhB;AAKgB;;;EAEI,WAAA;AAFpB;;AASI;EACI,gBAAA;EACA,cAAA;EACA,sBAAA;AANR","sourcesContent":[":host {\n    position: relative;\n    display: block;\n    width: 100%;\n\n    .clear-selection-button {\n        position: absolute;\n        right: 4px;\n        top: 50%;\n        transform: translateY(-50%);\n    }\n\n    .form-control {\n        padding-right: 40px;\n    }\n\n    ::ng-deep {\n\n        .dropdown-menu,\n        .show {\n            // max-width: 206px;\n            max-height: 300px;\n            overflow-y: auto;\n            max-width: 300px;\n\n            .dropdown-item {\n                color: #82949f;\n\n                &.active,\n                &:hover {\n                    color: #fff;\n                }\n            }\n        }\n    }\n}\n::ng-deep {\n    .address-selection-option {\n        max-width: 450px;\n        display: block;\n        word-break: break-word;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
