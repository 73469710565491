// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host .hidden-date-input {
  height: 100%;
  width: 0;
  overflow: hidden;
}
:host .hidden-date-input input {
  height: 100%;
}
:host .custom-day {
  text-align: center;
  padding: 0.185rem 0.25rem;
  border-radius: 0.25rem;
  height: calc(100% - 4px);
  width: auto;
  margin: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
}
:host .custom-day.focused {
  background-color: #e6e6e6;
}
:host .custom-day.range,
:host .custom-day:hover {
  background-color: var(--bs-primary);
  color: white;
}
:host .custom-day.faded {
  background-color: var(--bs-primary);
}

::ng-deep .ngb-dp-day:focus-visible {
  outline: none !important;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/form/date-range-picker/date-range-picker.component.scss"],"names":[],"mappings":"AACE;EACE,YAAA;EACA,QAAA;EACA,gBAAA;AAAJ;AAEI;EACE,YAAA;AAAN;AAIE;EACE,kBAAA;EACA,yBAAA;EACA,sBAAA;EACA,wBAAA;EACA,WAAA;EACA,WAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AAFJ;AAKE;EACE,yBAAA;AAHJ;AAME;;EAEE,mCAAA;EACA,YAAA;AAJJ;AAQE;EACE,mCAAA;AANJ;;AAaI;EACE,wBAAA;AAVN","sourcesContent":[":host {\n  .hidden-date-input {\n    height: 100%;\n    width: 0;\n    overflow: hidden;\n\n    input {\n      height: 100%;\n    }\n  }\n\n  .custom-day {\n    text-align: center;\n    padding: 0.185rem 0.25rem;\n    border-radius: 0.25rem;\n    height: calc(100% - 4px);\n    width: auto;\n    margin: 2px;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n  }\n\n  .custom-day.focused {\n    background-color: #e6e6e6;\n  }\n\n  .custom-day.range,\n  .custom-day:hover {\n    background-color: var(--bs-primary);\n    color: white;\n\n  }\n\n  .custom-day.faded {\n    background-color: var(--bs-primary);\n  }\n  \n}\n\n::ng-deep{\n  .ngb-dp-day{\n    &:focus-visible {\n      outline: none !important;\n  }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
