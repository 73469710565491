export const style = {
  input: {
    'padding': '8px 12px',
    'width': '100%',
    'height': '40px',
    'box-sizing': 'border-box',
    'outline': 'none',
    'box-shadow': 'none',
    'border': '1px solid #e5e7eb',
    'font-size': '14px',
    'font-weight': '400',
    'line-height': '1.5',
    'color': '#82949f',
    'background-color': '#fff',
  },
  inputFocus: {
    // 'border': '2px solid #80bcff',
    // 'box-shadow': '0 0 0 0.2rem rgba(0, 120, 255, 0.25)'
  }
};

export enum IFieldTypeEnum {
  ACH = 'ach',
  CARD = 'card',
  CVV = 'cvv',
}