import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@app/shared/shared.module';
import { TrucksRoutingModule } from './trucks-routing.module';
import { TruckListComponent } from './truck-list/truck-list.component';
import { AddEditTruckComponent } from './add-edit-truck/add-edit-truck.component';
import { DatatableModule } from '@app/@shared/datatable';
import { DialogsModule } from '@app/@shared/dialogs/dialogs.module';
import { TruckTypeListComponent } from './truck-type-list/truck-type-list.component';
import { FormValidateModule } from '@app/@shared/form-validate/form-validate.module';

@NgModule({
  declarations: [
    TruckListComponent,
    AddEditTruckComponent,
    TruckTypeListComponent
  ],
  imports: [
    CommonModule,
    TrucksRoutingModule,
    SharedModule,
    DatatableModule,
    DialogsModule,
    FormValidateModule
  ]
})
export class TrucksModule { }
