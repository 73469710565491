import { Component, OnInit, Input, TemplateRef, ContentChildren, QueryList, AfterContentInit } from '@angular/core';

import { BodyTemplate } from './body-template';

@Component({
    selector: 'column',
    templateUrl: './column.component.html',
    styleUrls: ['./column.component.scss']
})
export class ColumnComponent implements OnInit, AfterContentInit {

    isFixWidth = false;

    private _width: string = null;
    @Input()
    public get width(): string {
        return this._width;
    }
    public set width(value: string) {
        this._width = value;
        if (value && !value.endsWith('%')) {
            this.isFixWidth = true;
        }
    }

    @Input() class: string | ((colType: 'body' | 'header') => string)
    @Input() name: string;
    @Input() header: string;
    @Input() sortable = true;
    @Input() searchable = true;
    @Input() dataType: 'string' | 'number' | 'date' = 'string';

    @ContentChildren(BodyTemplate) templates: QueryList<any>;

    public headerTemplate: TemplateRef<any>;
    public bodyTemplate: TemplateRef<any>;

    ngOnInit(): void {

    }

    getClass(colType: 'body' | 'header'): string {
        const classes: string[] = [
            this.isFixWidth ? 'fix-width' : null
        ]
        if (this.class) {
            if (typeof this.class === 'function') {
                classes.push(this.class(colType));
            } else {
                classes.push(this.class);
            }
        }
        return classes.join(' ');
    }

    ngAfterContentInit(): void {
        this.templates.forEach((item) => {
            switch (item.getType()) {
                case 'header':
                    this.headerTemplate = item.template;
                    break;

                case 'body':
                    this.bodyTemplate = item.template;
                    break;

                default:
                    this.bodyTemplate = item.template;
                    break;
            }
        });

    }

}
