import { AssetsPath } from './image';
import { FilterPipe } from './filter.pipe';
import { SafeHtmlPipe, TruncatePipe, Nl2BrPipe } from './text';
import { StrToDatePipe } from './str-to-date.pipe';
import { SafeUrlPipe } from './safe-url.pipe';
import { FileSizePipe } from './file-size.pipe';
import { CurrencyPipe } from './currency.pipe';
import { DecimalNumber } from './decimal-number.pipe';
import { CustomDatePipe, CustomDateTimePipe, CustomTimePipe } from './custom-date.pipe';
import { OrderStopTimePipe } from './order-stop-time.pipe';
import { OrderStopDatePipe } from './order-stop-date.pipe';
import { CamelCaseToTitleCasePipe } from './camel-case-to-title-case.pipe';
import { ReverseAmountPipe } from './reverse-amount.pipe';

const Pipes = [
    AssetsPath,
    FilterPipe,
    SafeHtmlPipe,
    TruncatePipe,
    StrToDatePipe,
    Nl2BrPipe,
    SafeUrlPipe,
    FileSizePipe,
    CurrencyPipe,
    DecimalNumber,
    CustomDatePipe,
    CustomTimePipe,
    CustomDateTimePipe,
    CamelCaseToTitleCasePipe,
    OrderStopTimePipe,
    OrderStopDatePipe,
    ReverseAmountPipe
];

export { Pipes };
