// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host ::ng-deep .ql-container {
  min-height: 300px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/main/orders/components/info-order-dialog/info-order-dialog.component.scss"],"names":[],"mappings":"AAIQ;EACI,iBAAA;AAHZ","sourcesContent":["@import \"var\";\n\n:host {\n    ::ng-deep {\n        .ql-container {\n            min-height: 300px;\n        }\n    }\n}\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
