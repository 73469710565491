import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { ModalModule } from 'ngx-bootstrap/modal';
import { CookieModule } from 'ngx-cookie';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { CommonModule, DecimalPipe } from '@angular/common';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { ToastrModule } from 'ngx-toastr';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ApiService } from './services/api.service';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AddressPipe } from './shared/components/auxiliary/pipes';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/moment';
import { AgmCoreModule, GoogleMapsScriptProtocol } from '@agm/core';
import { environment } from '@environments/environment';
import { QuillModule } from 'ngx-quill';
import { SpinnerModule } from './components/global/spinner/spinner.module';
import * as moment from 'moment';
import { SharedModule } from './shared/shared.module';
import { NgChartsModule } from 'ng2-charts';
import { NgbTooltipConfig, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';


export function momentAdapterFactory() {
    return adapterFactory(moment);
};

@NgModule({
    declarations: [
        AppComponent,
    ],
    imports: [
        CommonModule,
        BrowserModule,
        AppRoutingModule,
        BsDropdownModule.forRoot(),
        PopoverModule.forRoot(),
        ModalModule.forRoot(),
        AccordionModule.forRoot(),
        CookieModule.withOptions(),
        PaginationModule.forRoot(),
        SweetAlert2Module.forRoot(),
        BrowserAnimationsModule,
        BsDatepickerModule.forRoot(),
        TypeaheadModule.forRoot(),
        TooltipModule.forRoot(),
        SharedModule.forRoot(),
        CalendarModule.forRoot({ provide: DateAdapter, useFactory: momentAdapterFactory }),
        NgxMaterialTimepickerModule,
        AgmCoreModule.forRoot({
            apiKey: environment.googleKey,
            libraries: ['map'],
            language: 'en',
            protocol: GoogleMapsScriptProtocol.HTTPS,
        }),
        QuillModule.forRoot(),
        ToastrModule.forRoot({
            preventDuplicates: true,
          }),
        SpinnerModule,
        NgChartsModule,
        NgbTooltipModule
    ],
    providers: [
        ApiService,
        DecimalPipe,
        AddressPipe,
        NgbTooltipConfig
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
    constructor(config: NgbTooltipConfig) {
		config.triggers = 'hover';
	}
}
