import { Injectable } from '@angular/core';

export interface CatchDataStore {
    filter?: any;
    openRows?: any[]
}

@Injectable({ providedIn: 'root' })
export class DatatableCacheService {

    private dataStore: {
        datatableCache?: CatchDataStore,
    }

    constructor() {
        this.dataStore = {
            datatableCache: {},
        }
    }

    getCache(key: string, defaultValue = {}): CatchDataStore {
        return this.dataStore.datatableCache[key] || defaultValue;
    }

    setCache(key: string, value: CatchDataStore) {
        this.dataStore.datatableCache[key] = value;
    }

    updateCache(key: string, value: Partial<CatchDataStore>) {
        const oldValue = this.dataStore.datatableCache[key]
        this.dataStore.datatableCache[key] = {
            ...oldValue,
            ...value
        };
    }

    clearCache(key: string) {              
        delete this.dataStore.datatableCache[key];
    }

}
