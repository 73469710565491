import { AfterViewInit, Component, EventEmitter, forwardRef, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { Customer, CustomerSearchRequest } from '@app/models/customer.model';
import { CustomerService } from '@app/shared/services/customer.service';
import { HelperService } from '@app/shared/services/helper.service';

@UntilDestroy()
@Component({
	selector: 'app-select-multiple-customer',
	templateUrl: './select-multiple-customer.component.html',
	styleUrls: ['./select-multiple-customer.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => SelectMultipleCustomerComponent),
			multi: true
		}
	],
	encapsulation: ViewEncapsulation.Emulated,
})
export class SelectMultipleCustomerComponent implements AfterViewInit, OnInit {

	// default enabled dropdown for customer multiple select
	disabled: boolean = false;
	loading: boolean = false;

	onChange = (value: string) => { };
	onTouched = () => { };

	@Input() type: 'customer' | 'accounting';
	@Input() OrderStatus: any;
	@Input() OrderDateRange: {start: any, end: any};
	@Output() customerChange = new EventEmitter<any>();

	private _value: string | any;
	public get value(): string | any {
		return this._value;
	}
	public set value(updateValue: string | any) {
		this._value = updateValue;
		this.onChange(updateValue);
		this.onTouched();
	}

	public customers: Customer[] = [];
	public searchTerm$: BehaviorSubject<string | null> = new BehaviorSubject(null);

	constructor(
		private readonly customerService: CustomerService,
		private readonly helperService: HelperService
	) { }

	ngOnInit(): void {
		this.searchTerm$.next(null);

		this.customerService.$reloadCustomers.pipe(untilDestroyed(this)).subscribe(
            (res: any) => {
                if (res) {
                    this.getCustomers();
                }
            }
        );
	}

	ngAfterViewInit() {
		this.searchTerm$
			.pipe(
				debounceTime(300), // Delay between API calls
				distinctUntilChanged(), // Only call API when the search term changes
				tap(() => this.getCustomers()),
				untilDestroyed(this)
			)
			.subscribe();
	}

	/**
	 * Custom search function for ng-select
	 * @param term The search term
	 * @param item The item to search
	 * @returns Whether the item matches the search term
	 */
	customSearchCustomerFn(term: string, item: any): boolean {
		// Implement your custom search logic here
		term = term.toLowerCase();

		const fieldsToSearch = ['customerName', 'firstEmail', 'phone'];

		for (const field of fieldsToSearch) {
			const fieldValue = item[field]?.toLowerCase(); // Get the value of the current field

			if (fieldValue && fieldValue.includes(term)) {
				return true; // Return true if the field value contains the search term
			}
		}

		return false; // Return false if none of the fields match the search term
	}

	/**
	 * Handle customer search in the self multiple dropdown
	 * @param item The search item containing the term entered by the user
	 */
	onSearchCustomer(item: any) {
		if (item) {
			this.searchTerm$.next(item?.term);
		}
	}

	/**
	 * Fetch the list of customers based on the provided filter
	 * @param filter Optional filter to apply to the request
	 */
	private async getCustomers(filter?: Partial<CustomerSearchRequest>) {
		this.loading = true;
		// customer search term
		const searchTerm = this.searchTerm$.getValue() || null;
		// customer filter request with pagination & search term
		const request = {
			// ToDo make it pagination dynamic
			Page: 1,
			ItemsPerPage: 20,
			...(searchTerm ? { SearchTerm: searchTerm } : {}),
			...(this.OrderStatus && { OrderStatus: this.OrderStatus }),
			...(this.OrderDateRange && { OrderDateRangeStart: this.OrderDateRange.start, OrderDateRangeEnd: this.OrderDateRange.end }),
			...filter,
		}

		try {
			this.customers = [];
			this.customerService.cancelPendingRequestGetAll();
			const response = await this.customerService.getAllWithCancelRequest(request);
			this.customers = response?.list;
		} catch (error) {
			console.error('Error while retrieving customer list!', error);
			this.helperService.errorMessage(error);
		} finally {
			this.loading = false;
		}
	}

	/**
	 * Handle the selection of customers from the multiple dropdown
	 * @param event The event object containing the selected item
	 */
	selectedCustomers($e: any) {
		$e.preventDefault();
		this.value = $e.item;
		this.customerChange.emit(this.value);
	}

	/**
	 * Write a new value to the component
	 * @param value The new value to set
	 */
	writeValue(value: string | any) {
		this._value = value
	}

	/**
	 * Register a function to call when the value changes
	 * @param fn The function to call when the value changes
	 */
	registerOnChange(fn: (value: string) => void): void {
		this.onChange = fn;
	}

	/**
	 * Register a function to call when the component is touched
	 * @param fn The function to call when the component is touched
	 */
	registerOnTouched(fn: () => void): void {
		this.onTouched = fn;
	}

	/**
	 * Set the disabled state of the component
	 * @param isDisabled Whether the component should be disabled
	 */
	setDisabledState(isDisabled: boolean): void {
		this.disabled = isDisabled;
	}
}
