// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    basePath: '',
    production: false,
    staging: false,
    apiBaseUrl: 'https://apidev.rushhourapp.com/api/V1/',
    cookie: 'RHCompanyApiToken-Local',
    cardnoxKey: 'ifields_selfdevd8d2c3a2affd42b980c964f17a0279',
    Scripts: [
        { name: 'cardnoxCDN', src: 'https://cdn.cardknox.com/ifields/2.5/ifields.min.js' },
    ],
    googleKey: 'AIzaSyATOhWSk804FUmlWLIp1erppAw-V2d0tvk',
    trimbleMapsApiKey: "15EEEF363FE37F4C8EF73B2DB5E4303D",
    useTrimbleMaps: false,
    quickbooksClientId: 'AB3rWfe4OPkzrlwilemWldfUVruxXUcHpkCj6O3ej4stwg4jzo',
    quickbooksRedirectUrl: 'https://dev.rushhourapp.com/app/settings/quickbooksauthorization',
    imagePreviewUrl: 'https://apidev.rushhourapp.com/api/V1/Attachments/View/'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
