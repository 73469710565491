import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CreateCustomerComponent } from './create-customer/create-customer.component';
import { CustomerLayoutComponent } from './customer-layout/customer-layout.component';
import { TabRedirectGuard } from '@app/@shared/list-tabs/tab-redirect-guard';

const routes: Routes = [
  {
    path: '',
    component: CustomerLayoutComponent,
  },
  {
    path: 'add',
    component: CreateCustomerComponent,
  },
  {
    path: 'edit/:id',
    data: {
      redirectTo: `/app/customers`,
      paramsKey: 'id',
      queryParamsKey: 'id'
    },
    canActivate: [TabRedirectGuard],
    component: CustomerLayoutComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CustomersRoutingModule { }
