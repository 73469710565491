import { Component, OnInit } from '@angular/core';
import { HelperService } from '@app/shared/services/helper.service';
import { SettingService } from '@app/shared/services/setting.service';
import { NgbActiveModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-telegram',
  templateUrl: './telegram.component.html',
  styleUrls: ['./telegram.component.scss']
})
export class TelegramComponent implements OnInit {

  isCodeSent = false
  loading = false;

  telegram: any = {}

  constructor(
    private activeModal: NgbActiveModal,
    private settingService: SettingService,
    private helperService: HelperService,
  ) { }


  ngOnInit(): void {
  }

  close() {
    this.activeModal.close();
  }

  telegramSendCode() {
    this.loading = true
    this.settingService.telegramSendCode(this.telegram).then((res) => {
      this.helperService.successMessage('The code has been sent')
      this.isCodeSent = true
      this.loading = false
    }).catch((error) => {
      this.helperService.errorMessage(error);
      this.loading = false
    });
  }

  telegramSignIn() {
    this.loading = true
    this.settingService.telegramSignIn(this.telegram).then((res) => {
      this.helperService.successMessage('Success');
      this.close()
      this.loading = false
    }).catch((error) => {
      this.helperService.errorMessage(error);
      this.loading = false
    });
  }

}
