import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Clipboard } from '@angular/cdk/clipboard';
import { OrderService } from '@app/shared/services/order.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { OrderStopCallOutcomeResultEnum } from '@app/models/order-stop.model';
import { QuoteService } from '@app/shared/services/quote.service';
import { HelperService } from '@app/shared/services/helper.service';

@UntilDestroy()
@Component({
    selector: 'app-unassigned-trip-grid-item',
    templateUrl: './unassigned-trip-grid-item.component.html',
    styleUrls: ['./unassigned-trip-grid-item.component.scss']
})
export class UnassignedTripGridItemComponent {
    tooltipHovered = false;

    @Input() stop: any

    @Output() onDelete = new EventEmitter();

    constructor(
        private clipboard: Clipboard,
        private orderService: OrderService,
        private quoteService: QuoteService,
        private helperService: HelperService,
    ) {
    }

    ngOnInit() {
        this.orderService.orderStopPriority$
            .pipe(
                untilDestroyed(this),
            )
            .subscribe((orderStopPriority) => {
                if (orderStopPriority?.orderStopId && orderStopPriority?.priority) {
                    if (this.stop?.uniqPickupRouteBoxes?.length > 0) {
                        this.stop?.uniqPickupRouteBoxes?.map((box) => {
                            if (box?.orderStopId === orderStopPriority?.orderStopId) {
                                box.orderStop.priority = orderStopPriority.priority
                            }
                            return box;
                        })
                    }
                    if (this.stop?.uniqDropOffRouteBoxes?.length > 0) {
                        this.stop?.uniqDropOffRouteBoxes?.map((box) => {
                            if (box?.orderStopId === orderStopPriority?.orderStopId) {
                                box.orderStop.priority = orderStopPriority.priority
                            }
                            return box;
                        })
                    }
                }
            })
    }



    openTooltip(tooltip, text, additionalTextNotCopy ='') {
        if (!tooltip.isOpen() && text) {
            tooltip.open({ text, tooltip, additionalTextNotCopy });
        }
    }

    closeTooltip(tooltip, forceClose = false) {
        setTimeout(() => {
            if (forceClose) {
                this.tooltipHovered = false
            }
            if (!this.tooltipHovered) {
                if (tooltip.isOpen()) {
                    tooltip.close();
                }
            }
        }, 200);
    }

    copyToClipboard($event, tooltip, text) {
        $event?.stopPropagation()
        $event?.preventDefault()
        this.clipboard.copy(text)
        this.closeTooltip(tooltip, true)
    }

    handleDelete(data) {
        this.onDelete.emit(data);
    }

    getCallOutcomeIconColor(callOutcomeResult) {
        switch (callOutcomeResult) {
          case OrderStopCallOutcomeResultEnum.DoNotDeliver:
            return 'text-danger';
          case OrderStopCallOutcomeResultEnum.Confirmed:
            return 'text-success';
          default:
            return 'text-gray-500';
        }
    }

    getCallOutcomeText(callOutcomeResult) {      
        switch (callOutcomeResult) {
          case OrderStopCallOutcomeResultEnum.DoNotDeliver:
            return 'Do not deliver';
          case OrderStopCallOutcomeResultEnum.Confirmed:
            return 'Confirmed';
          default:
            return '';
        }
    }
      
      updateCallOutcome(event, orderStop, callOutcomeResult) {
        event?.stopPropagation();
        event?.preventDefault();
      
        if (!callOutcomeResult) {
          callOutcomeResult = OrderStopCallOutcomeResultEnum.CallNotMade;
        }

        let newCallOutcomeResult: OrderStopCallOutcomeResultEnum;
        const oldCallOutcomeResult = callOutcomeResult;
        switch (callOutcomeResult) {
          case OrderStopCallOutcomeResultEnum.CallNotMade:
            newCallOutcomeResult = OrderStopCallOutcomeResultEnum.DoNotDeliver;
            break;
          case OrderStopCallOutcomeResultEnum.DoNotDeliver:
            newCallOutcomeResult = OrderStopCallOutcomeResultEnum.Confirmed;
            break;
          case OrderStopCallOutcomeResultEnum.Confirmed:
            newCallOutcomeResult = OrderStopCallOutcomeResultEnum.CallNotMade;
            break;
          default:
            newCallOutcomeResult = OrderStopCallOutcomeResultEnum.CallNotMade;
        }
      
        orderStop.callOutcomeResult = newCallOutcomeResult
        this.quoteService.updateCallOutcome(orderStop?.orderStopId, newCallOutcomeResult).then((res) => {
          this.helperService.successMessage("Order stop call outcome successfully updated.");
        }).catch((error) => {
          orderStop.callOutcomeResult = oldCallOutcomeResult
          this.helperService.errorMessage(error);
        })
      }

}
