import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CustomersRoutingModule } from './customers-routing.module';
import { CustomerListComponent } from './customer-list/customer-list.component';
import { CustomerDetailsComponent } from './customer-details/customer-details.component';
import { SharedModule } from '@app/shared/shared.module';
import { DatatableModule } from '@app/@shared/datatable';
import { DialogsModule } from '@app/@shared/dialogs/dialogs.module';
import { RatingModule } from '@app/@shared/rating/rating.module';
import { NgChartsModule } from 'ng2-charts';
import { LocationsComponent } from './customer-details/locations/locations.component';
import { NgxSelectModule } from '@app/@shared/ngx-select';
import { ContactsComponent } from './customer-details/contacts/contacts.component';
import { PaymentsComponent } from './customer-details/payments/payments.component';
import { OrdersComponent } from './customer-details/orders/orders.component';
import { TimepickerModule } from '@app/@shared/timepicker/timepicker.module';
import { CompanyInformationComponent } from './customer-details/company-information/company-information.component';
import { CreditComponent } from './customer-details/credit/credit.component';
import { RepeaterModule } from '@app/@shared/repeater/repeater.module';
import { CardInputsModule } from '@app/@shared/card-inputs/card-inputs.module';
import { CreateCustomerComponent } from './create-customer/create-customer.component';
import { FormValidateModule } from '@app/@shared/form-validate/form-validate.module';
import { FormPhoneInputModule } from '@app/@shared/forms';
import { DirectivesModule } from '@app/@shared/directives/directives.module';
import { CustomerInformationDialogComponent } from './customer-details/customer-information-dialog/customer-information-dialog-component';
import { CustomerExportComponent } from './customer-list/customer-export/customer-export.component';
import { ListTabsModule } from '@app/@shared/list-tabs/list-tabs.module';
import { CustomerLayoutComponent } from './customer-layout/customer-layout.component';
import { DropDownSelectModule } from '@app/@shared/drop-down-select/drop-down-select.module';
import { PhoneExtensionInputModule } from '@app/@shared/phone-extension-input/phone-extension-input.module';
import { NotesComponent } from './customer-details/notes/notes.component';

@NgModule({
  declarations: [
    CustomerListComponent,
    CustomerDetailsComponent,
    LocationsComponent,
    ContactsComponent,
    NotesComponent,
    PaymentsComponent,
    OrdersComponent,
    CompanyInformationComponent,
    CreditComponent,
    CreateCustomerComponent,
    CustomerInformationDialogComponent,
    CustomerExportComponent,
    CustomerLayoutComponent
  ],
  imports: [
    CommonModule,
    CustomersRoutingModule,
    SharedModule,
    DatatableModule,
    DialogsModule,
    RatingModule,
    NgChartsModule,
    NgxSelectModule,
    TimepickerModule,
    RepeaterModule,
    CardInputsModule,
    FormValidateModule,
    FormPhoneInputModule,
    DirectivesModule,
    ListTabsModule,
    DropDownSelectModule,
    PhoneExtensionInputModule,
  ]
})
export class CustomersModule { }
