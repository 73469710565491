// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host .status {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 12px;
  font-weight: 500;
  width: -moz-fit-content;
  width: fit-content;
}
:host .status .border {
  padding: 4px;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/status-badge/status-badge.component.scss"],"names":[],"mappings":"AAEI;EACI,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,iBAAA;EACA,gBAAA;EACA,uBAAA;EAAA,kBAAA;AADR;AAEQ;EACI,YAAA;AAAZ","sourcesContent":["@import \"var\";\n:host {\n    .status {\n        display: flex;\n        align-items: center;\n        justify-content: center;\n        padding: 8px 12px;\n        font-weight: 500;\n        width: fit-content;\n        .border {\n            padding: 4px;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
