import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { catchError } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';
import { CompanyService } from '../company.service';
import { ActivatedRoute } from '@angular/router';

@Injectable()
export class CustomHttpInterceptor implements HttpInterceptor {
    companyId: any
    constructor(
        private authService: AuthService,
        private companyService: CompanyService,
        private activatedRoute: ActivatedRoute,
    ) {
    }

    apiUrl(path?: string) {
        return environment.apiBaseUrl.replace(/(^\/)|(\/$)/g, '') + '/' + path.replace(/(^\/)|(\/$)/g, '');
    }


    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const token = this.authService.token;
        this.companyId = this.companyService?.selectedCompany?.companyId
        let options: any = {
            url: req.url.startsWith('http') ? req.url : this.apiUrl(req.url)
        };
        let headers = req.headers;
        if (this.companyId) {
            headers = headers.set('Company', this.companyId + '')
        }
        else {
            headers = headers.delete('Company')
        }

        if (token !== undefined && token !== null && token !== '' && token !== 'null') {
            headers = headers.set('Authorization', `Bearer ${token}`)
        }

        options = {
            ...options,
            headers: headers,
        };


        const reqClone = req.clone(options);
        return next.handle(reqClone)
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    if (error.status === 401) {
                        if (this.authService.isLoggedIn) {
                            this.authService.logout();
                        }
                    } else {
                        return throwError(error);
                    }
                })
            );
    }
}
