import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AddEditOrderComponent } from './add-edit-order/add-edit-order.component';
import { TabRedirectGuard } from '@app/@shared/list-tabs/tab-redirect-guard';


const routes: Routes = [
  {
    path: 'add',
    component: AddEditOrderComponent
  },
  {
    path: 'edit/:id',
    data: {
      redirectTo: `/app/orders`,
      paramsKey: 'id',
      queryParamsKey: 'id',
      isOrderOrQuote: true,
    },
    canActivate: [TabRedirectGuard],
    component: AddEditOrderComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AddEditOrderRoutingModule { }
