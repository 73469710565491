// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 100px;
  color: #fff;
  background-color: #E2C768;
}
:host img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
:host.size-small {
  height: 32px;
  width: 32px;
  font-size: 16px;
}
:host.size-medium {
  height: 48px;
  width: 48px;
  font-size: 20px;
}
:host.size-large {
  height: 56px;
  width: 56px;
  font-size: 24px;
}
:host.size-extra-large {
  height: 96px;
  width: 96px;
  font-size: 48px;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/avatar/avatar.component.scss"],"names":[],"mappings":"AACA;EACE,oBAAA;EACA,uBAAA;EACA,mBAAA;EACA,gBAAA;EACA,oBAAA;EACA,WAAA;EACA,yBAAA;AAAF;AACE;EACE,YAAA;EACA,WAAA;EACA,iBAAA;AACJ;AACE;EACE,YAAA;EACA,WAAA;EACA,eAAA;AACJ;AACE;EACE,YAAA;EACA,WAAA;EACA,eAAA;AACJ;AACE;EACE,YAAA;EACA,WAAA;EACA,eAAA;AACJ;AACE;EACE,YAAA;EACA,WAAA;EACA,eAAA;AACJ","sourcesContent":["\n:host {\n  display: inline-flex;\n  justify-content: center;\n  align-items: center;\n  overflow: hidden;\n  border-radius: 100px;\n  color: #fff;\n  background-color: #E2C768; //var(--muted);\n  img {\n    height: 100%;\n    width: 100%;\n    object-fit: cover;\n  }\n  &.size-small {\n    height: 32px;\n    width: 32px;\n    font-size: 16px;\n  }\n  &.size-medium {\n    height: 48px;\n    width: 48px;\n    font-size: 20px;\n  }\n  &.size-large {\n    height: 56px;\n    width: 56px;\n    font-size: 24px;\n  }\n  &.size-extra-large {\n    height: 96px;\n    width: 96px;\n    font-size: 48px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
