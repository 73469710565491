import { Injectable } from '@angular/core';
import { ApiPaginationResponse, ApiResponse } from '@app/models/core/base';
import { toParams } from 'src/utils/to-params';
import { Customer, CustomerContactDetails, PipelineEnum, ValidateCustomer } from '@app/models/customer.model';
import { CrudService } from './core/crud.service';
import { PaymentMethod } from '@app/models/payment-method.model';
import { HttpClient } from '@angular/common/http';
import { toFormData } from 'src/utils/to-form-data';
import { BehaviorSubject, Observable } from 'rxjs';


@Injectable({
    providedIn: 'root'
})
export class CustomerService extends CrudService<Customer> {

    basePath = 'Customers'

    private _reloadCustomers: BehaviorSubject<any>;

    constructor(
        protected http: HttpClient
    ) {
        super(http)

        this._reloadCustomers = new BehaviorSubject(null);
    }

    public get $reloadCustomers(): Observable<boolean> {
        return this._reloadCustomers.asObservable();
    }

    public set reloadCustomers(value: boolean) {
        this._reloadCustomers.next(value);
    }

    buildDelete(ids: number[] | string[] | any) {
        return this.http.delete(`${this.basePath}`, { params: toParams({ ids }) }).toPromise()
    }

    createPaymentMethod(customerId: string | number, request?: any) {
        return this.http.post<ApiResponse<PaymentMethod>>(`${this.basePath}/${customerId}/PaymentMethods`, request).toPromise()
            .then((({ data }) => data));
    }

    updatePaymentMethod(paymentMethodId: string | number, request?: any) {
        return this.http.put<ApiResponse<PaymentMethod>>(`${this.basePath}/PaymentMethods/${paymentMethodId}`, request).toPromise()
            .then((({ data }) => data));
    }

    deletePaymentMethod(paymentMethodId: string | number) {
        return this.http.delete(`${this.basePath}/PaymentMethods/${paymentMethodId}`).toPromise();
    }

    uploadFile(request: any) {
        console.log(request);

        return this.http.post<ApiResponse<any>>(`${this.basePath}/import`, toFormData(request)).toPromise()
    }

    exportCustomer(request: any) {
        this.downloadFile(`${this.basePath}/export`, {
            params: request,
            name: 'customers.csv'
        })
    }

    customExport(request: any) {
        this.downloadFile(`${this.basePath}/CustomExport`, {
            params: request,
            name: 'customers.csv'
        })
    }

    exportCustomerSample() {
        this.downloadFile(`${this.basePath}/export`, {
            params: { sample: 'true' },
            name: 'customers.csv'
        })
    }

    searchCustomerAddress(customerId, searchTerm) {
        return this.http.get<ApiPaginationResponse<PaymentMethod>>(`${this.basePath}/${customerId}/lookup_address`, {
            params: toParams({
                SearchTerm: searchTerm,
                Page: 1,
                ItemsPerPage: 30,
            })
        })
            .toPromise()
            .then((({ data }) => data));
    }

    search(searchTerm) {
        return this.http.get<ApiPaginationResponse<any>>(`${this.basePath}`, {
            params: toParams({
                SearchTerm: searchTerm,
                Page: 1,
                ItemsPerPage: 10,
            })
        })
            .toPromise()
            .then((({ data }) => data));
    }

    getCustomerContactDetails(id: string | number) {
        return this.http.get<ApiResponse<CustomerContactDetails>>(`${this.basePath}/${id}/contacts`).toPromise()
            .then((({ data }) => data));
    }

    validateCustomer(request?: any) {
        return this.http.post<ApiResponse<ValidateCustomer>>(`${this.basePath}/ValidateCustomer`, request).toPromise()
            .then((({ data }) => data));
    }

    updatePipeline(id: string | number, pipeline: PipelineEnum) {
        return this.http.put<ApiResponse<any>>(`${this.basePath}/${id}/${pipeline}`, {}).toPromise()
            .then((({ data }) => data));
    }

    getCustomerLocations(id: string | number, customerName: string) {
        return this.http.get<ApiResponse<{ address: any, companyName: string, customerId: number }[]>>(`${this.basePath}/${id}/locations?customerName=${customerName}`)
            .toPromise()
            .then(({ data }) => data);
    }
}
