import { Component, Input, OnInit, } from '@angular/core';
import { ImagesService } from '@app/shared/services/images.service';

@Component({
  selector: 'app-attachment-preview',
  templateUrl: './attachment-preview.component.html',
  styleUrls: ['./attachment-preview.component.scss']
})
export class AttachmentPreviewComponent implements OnInit {

  isLoading: boolean = true;
  imageUrl: any;
  /*
  * Getter & Setter for attachmentId
  */
  _attachmentId: string;
  get attachmentId(): string {
    return this._attachmentId;
  }
  @Input() set attachmentId(value: string) {
    this.generateImageUrl(value);
    this._attachmentId = value;
  }

  constructor(private imagesService: ImagesService) { }

  ngOnInit(): void {
  }

  async generateImageUrl(attachmentId) {
    try {
      this.imageUrl = await this.imagesService.previewAttachment(attachmentId)
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      console.log(error);
    }
  }

}
