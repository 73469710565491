import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgbDropdownModule, NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { LoadingModule } from '@app/shared/modules/loading/loading.module';
import { DriverSelectComponent } from './driver-select.component';
import { RatingModule } from '../rating/rating.module';
import { SelectMultipleDriverComponent } from './select-multiple-driver/select-multiple-driver.component';


@NgModule({
	declarations: [
		DriverSelectComponent,
		SelectMultipleDriverComponent
	],
	exports: [
		DriverSelectComponent,
		SelectMultipleDriverComponent
	],
	imports: [
		CommonModule,
		FormsModule,
		NgbTypeaheadModule,
		NgbDropdownModule,
		LoadingModule,
		RatingModule,
		NgSelectModule
	]
})
export class DriverSelectModule { }
