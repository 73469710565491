import { Component, OnInit, ViewChild } from '@angular/core';
import { TruckService } from '@app/shared/services/truck.service';
import { HelperService } from '@app/shared/services/helper.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { DatatableComponent } from '@app/@shared/datatable';
import { Vehicle } from '@app/models/samsara/vehicle.model';

@UntilDestroy()
@Component({
    selector: 'app-truck-type-list',
    templateUrl: './truck-type-list.component.html',
    styleUrls: ['./truck-type-list.component.scss'],
})
export class TruckTypeListComponent implements OnInit {

    title = "Truck Types"
    @ViewChild('datatable') datatable: DatatableComponent
    vehicles: Vehicle[];
    loading: boolean = true;

    constructor(
        private truckService: TruckService,
        private helperService: HelperService,
    ) { }

    ngOnInit() {
        this.loadSamsaraFleet();
    }

    private loadSamsaraFleet() {
        this.truckService.getSamsaraFleets()
            .then(res => {
                this.vehicles = res;
                this.loading = false
            }).catch((error) => {
                this.loading = false
                this.helperService.errorMessage(error)
            })
    }

    openAddEdit() {
    }

    onDelete(row) {
    }

}
