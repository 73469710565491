// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host .paper {
  position: relative;
}
:host .paper .loading {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 9;
  display: flex;
  justify-content: center;
  align-items: center;
}
:host .paper .table thead tr {
  border: none;
}
:host .paper .table thead tr th {
  border: none;
}`, "",{"version":3,"sources":["webpack://./src/app/components/main/settings/trucks/truck-type-list/truck-type-list.component.scss"],"names":[],"mappings":"AAEI;EACI,kBAAA;AADR;AAEQ;EACI,kBAAA;EACA,MAAA;EACA,OAAA;EACA,QAAA;EACA,SAAA;EACA,0CAAA;EACA,UAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AAAZ;AAIgB;EACI,YAAA;AAFpB;AAGoB;EACI,YAAA;AADxB","sourcesContent":["@import \"var\";\n:host {\n    .paper {\n        position: relative;\n        .loading {\n            position: absolute;\n            top: 0;\n            left: 0;\n            right: 0;\n            bottom: 0;\n            background-color: rgba($paper, 0.7);\n            z-index: 9;\n            display: flex;\n            justify-content: center;\n            align-items: center;\n        }\n        .table {\n            thead {\n                tr {\n                    border: none;\n                    th {\n                        border: none;\n                    }\n                }\n            }\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
