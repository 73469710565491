import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ExpenseListComponent } from './expense-list/expense-list.component';
import { TruckExpenseListComponent } from './truck-expenses/truck-expenses-list.component';

const routes: Routes = [
  {
    path: '',
    component: ExpenseListComponent,

  },
  {
    path: 'truck',
    component: TruckExpenseListComponent,
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ExpensesRoutingModule { }
