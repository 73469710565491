import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbDropdownModule, NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';
import { LoadingModule } from '@app/shared/modules/loading/loading.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { WarehouseSelectComponent } from './warehouse-select.component';
import { LocationsModule } from '@app/components/main/settings/locations/locations.module';


@NgModule({
  declarations: [
    WarehouseSelectComponent
  ],
  exports: [
    WarehouseSelectComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    NgbTypeaheadModule,
    NgbDropdownModule,
    LoadingModule,
    NgSelectModule,
    LocationsModule,
    ReactiveFormsModule,
  ]
})
export class WarehouseSelectModule { }
