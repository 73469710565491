import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Customer } from '@app/models/customer.model';
import { CustomerContactService } from '@app/shared/services/customer-contact.service';
import { HelperService } from '@app/shared/services/helper.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { formatPhoneNumberAndExtension } from '@app/@shared/utils';
import { object, string } from 'yup';
import { patterns } from '@app/@shared/regex/regex-patterns.const';
@Component({
	selector: 'add-customer-contact-dialog',
	templateUrl: './add-customer-contact-dialog.component.html',
	styleUrls: ['./add-customer-contact-dialog.component.scss']
})
export class AddCustomerContactDialogComponent implements OnInit {
	private _customer: Customer;
	get customer(): Customer {
		return this._customer;
	}
	@Input() set customer(value: Customer) {
		this._customer = value;
	}

	contact = {
		contactName: '',
		phoneNumber: null,
		phoneExtension: null,
		email: ''
	}

	validationSchema = object().shape({
		phoneNumber: string().matches(patterns.phone, { excludeEmptyString: true, message: 'Phone must be a number' }).required().max(20),
		contactName: string().label('Name').required(),
		phoneExtension: string().label('Ext').nullable().max(7),
		email: string().email().label('Email').nullable().max(256),
	});

	constructor(
		private readonly activeModal: NgbActiveModal,
		private readonly fb: FormBuilder,
		private readonly customerContactService: CustomerContactService,
		private readonly helperService: HelperService,
	) { }

	ngOnInit(): void { }

	/**
	 * 
	 */
	async onSubmit(form) {
		if (!form.isValid) {
			return;
		}
		try {
			const { contactName, phoneNumber, email, phoneExtension } = form.values;

			const chunks = contactName.split(/\s+/);
			const [firstName = null, lastName = null] = [chunks.shift(), chunks.join(' ')];
			const contact = await this.customerContactService.create({
				customerId: this.customer.customerId,
				active: true,
				firstName: firstName,
				lastName: lastName,
				phone: formatPhoneNumberAndExtension({ phoneNumber, phoneExtension })?.phone,
				email: email
			});

			this.activeModal.close(contact);
		} catch (error) {
			console.log('Error while creating new customer contact', error);
			this.helperService.errorMessage(error);
		}
	}

	close() {
		this.activeModal.close();
	}
}
