// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
  height: 50vh;
}
:host .input-container {
  position: relative;
}
:host .input-container .form-control {
  width: 100%;
  padding-right: 40px;
  box-sizing: border-box;
}
:host .input-container .dropdown-button {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  border: none;
  background: none;
  padding: 0;
  margin: 0;
}
:host .input-container .icon-down-arrow {
  font-size: 16px;
}

::ng-deep .customer-names-dropdown {
  width: 330px;
  max-height: 30vh;
  overflow-y: auto;
}`, "",{"version":3,"sources":["webpack://./src/app/components/main/orders/components/select-customer-name/select-customer-name.component.scss"],"names":[],"mappings":"AAEA;EACI,cAAA;EACA,YAAA;AADJ;AAGI;EACI,kBAAA;AADR;AAGQ;EACI,WAAA;EACA,mBAAA;EACA,sBAAA;AADZ;AAIQ;EACI,kBAAA;EACA,QAAA;EACA,QAAA;EACA,2BAAA;EACA,YAAA;EACA,gBAAA;EACA,UAAA;EACA,SAAA;AAFZ;AAKQ;EACI,eAAA;AAHZ;;AASI;EACI,YAAA;EACA,gBAAA;EACA,gBAAA;AANR","sourcesContent":["@import \"var\";\n\n:host {\n    display: block;\n    height: 50vh;\n\n    .input-container {\n        position: relative;\n\n        .form-control {\n            width: 100%;\n            padding-right: 40px;\n            box-sizing: border-box;\n        }\n\n        .dropdown-button {\n            position: absolute;\n            right: 0;\n            top: 50%;\n            transform: translateY(-50%);\n            border: none;\n            background: none;\n            padding: 0;\n            margin: 0;\n        }\n\n        .icon-down-arrow {\n            font-size: 16px;\n        }\n    }\n}\n\n::ng-deep {\n    .customer-names-dropdown {\n        width: 330px;\n        max-height: 30vh;\n        overflow-y: auto;\n    }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
