import { CustomerContact } from '@models/customer-contact.model';
import { CustomerEmail } from '@models/customer-email.model';
import { PaymentMethod } from '@models/payment-method.model';
import { BaseModel, CompanyBase, TenantBase } from '@models/base.model';
import { Address } from '@models/address.model';
import { Driver } from './driver.model';

export interface OutsourceCompany extends BaseModel, CompanyBase, TenantBase {
    id?: number;
    outsourceCompanyId?: number;
    address?: Address;
    companyContact?: CustomerContact;
    ratings?: number;
    status?: OutsourceCompanyStatusEnum;
    startShift?: string;
    endShift?: string;
    outsourceCompanyName?: string;
    notes?: string;
    drivers?: Driver[];
    companyEmails?: CustomerEmail[];
    companyContacts?: CustomerContact[];
    companyFinances?: OutsourceCompanyFinance[];
    companyTypeOfTrucks?: OutsourceCompanyTypeOfTruck[];
    customerId?: number;
    rating?: number;
    phone?: string;
    phoneNumber?: string; //this is temporary field 
    phoneExtension?: string; //this is temporary field 
    logoUrl?: string;
    addresses?: Address[];
    customerPaymentMethods?: PaymentMethod[];
    invoices?: string;
    payments?: string;
    firstEmail?: string;
    mainAddress?: Address;
    defaultCC?: string;
    openBalance?: number;
    createdUser?: string;
    updatedUser?: string;
}

export interface OutsourceCompanyFinance extends BaseModel, TenantBase, CompanyBase {
    createdUser?: string;
    updatedUser?: string;
    outsourceCompanyFinanceId?: number;
    outsourceCompanyId?: number;
    outsourceCompany?: OutsourceCompany;
    discount?: number;
    costFixed?: any;
    costDistance?: any;
    costHourly?: any;
    costPallets?: any;
    discountType?: OutsourceCompanyFinanceDiscountTypeEnum;
    freeWaitingTimeMinutes?: any;
    typeOfWork?: OutsourceCompanyFinanceTypeOfWorkEnum;
    isActive?: boolean;
    notes?: string;
}

export interface OutsourceCompanyTypeOfTruck extends BaseModel, TenantBase, CompanyBase {
    createdUser?: string;
    updatedUser?: string;
    outsourceCompanyTypeOfTruckId?: number;
    typeOfTruckId?: number;
    outsourceCompanyId?: number;
    outsourceCompany?: OutsourceCompany;
    park?: number;
    isActive?: boolean;
    typeOfTruck?: OutsourceCompanyTruckTypeEnum;
}

export enum OutsourceCompanyFinanceTypeOfWorkEnum {
    Fixed = 1,
    Distance = 2,
    Hourly = 4,
    Pallets = 8,
}

export enum OutsourceCompanyTruckTypeEnum {
    PickupTruck = 1,
    BoxTruck = 2,
    SemiTruck = 3,
    RefrigeratorTruck = 4,
    FurnitureTruck = 5,
    FlatbedTruck = 6,
}

export enum OutsourceCompanyLocation {
    Office = 0,
    House = 1,
}

export enum OutsourceCompanyFinanceDiscountTypeEnum {
    Value = 0,
    Percent = 1,
}

export enum OutsourceCompanyStatusEnum {
    Active = 0,
    Inactive = 1,
    Deleted = 2
}

export interface OutsourceCompanyContactDetails {
    companyEmails: CustomerEmail[];
    companyContacts: CustomerContact[];
}

export interface ValidateOutsourceCompany {
    existedName: boolean;
    existedPhone: boolean;
    existedEmailIndex: [];
}

