import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@app/shared/shared.module';
import { UsersRoutingModule } from './users-routing.module';
import { UserListComponent } from './user-list/user-list.component';
import { UserDetailsComponent } from './user-details/user-details.component';
import { DatatableModule } from '@app/@shared/datatable/datatable.module';
import { NgxSelectModule } from '@app/@shared/ngx-select';
import { RepeaterModule } from '@app/@shared/repeater/repeater.module';
import { DialogsModule } from '@app/@shared/dialogs/dialogs.module';
import { FormValidateModule } from '@app/@shared/form-validate/form-validate.module';
import { FormPhoneInputModule } from '@app/@shared/forms';
import { RoleListComponent } from './user-setting/role-list/role-list.component';
import { PermissionListComponent } from './user-setting/permission-list/permission-list.component';
import { AddEditPermissionDialogComponent } from './user-setting/add-edit-permission-dialog/add-edit-permission-dialog.component';
import { DropDownSelectModule } from '@app/@shared/drop-down-select/drop-down-select.module';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { AdminSetPasswordComponent } from './admin-set-password/admin-set-password.component';

@NgModule({
  declarations: [
    UserListComponent,
    UserDetailsComponent,
    RoleListComponent,
    PermissionListComponent,
    AddEditPermissionDialogComponent,
    ChangePasswordComponent,
    AdminSetPasswordComponent,
  ],
  imports: [
    CommonModule,
    UsersRoutingModule,
    SharedModule,
    DatatableModule,
    NgxSelectModule,
    RepeaterModule,
    DialogsModule,
    FormValidateModule,
    FormPhoneInputModule,
    DropDownSelectModule
  ]
})
export class UsersModule { }
