import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiPaginationResponse, ApiResponse, ListRequest } from '@app/models/core/base';
import { Truck } from '@app/models/truck.model';
import { toParams } from 'src/utils/to-params';
import { Tenant } from '@app/models/tenant.model';

@Injectable({
    providedIn: 'root'
})

export class TenantService {

    constructor(
        private http: HttpClient
    ) {
    }

    // Tenant Payment Methods

    getTenants(request?: any) {
        return this.http.get<ApiPaginationResponse<Tenant>>(`Tenants`, { params: toParams(request) }).toPromise()
            .then((({ data }) => data));
    }


    getTenantPaymentMethods(request?: any) {
        return this.http.get<ApiResponse<any>>(`TenantPaymentMethods`, { params: toParams(request) }).toPromise()
            .then((({ data }) => data));
    }

    createTenantPaymentMethods(request?: ListRequest) {
        return this.http.post<ApiResponse<any>>(`TenantPaymentMethods`, request).toPromise()
            .then((({ data }) => data));
    }

    updateTenantPaymentMethods(request?: ListRequest) {
        return this.http.put<ApiResponse<any>>(`TenantPaymentMethods`, request).toPromise()
            .then((({ data }) => data));
    }

    deleteTenantPaymentMethods() {
        return this.http.delete(`TenantPaymentMethods`).toPromise();
    }

    // Tenant Plans

    getTenantPlans(request?:any) {
        return this.http.get<ApiResponse<any>>(`TenantPlans`, { params: toParams(request) }).toPromise()
            .then((({ data }) => data));
    }
}
