import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiPaginationData, ApiPaginationResponse, ApiResponse, ListRequest } from '@app/models/core/base';
import { toParams } from 'src/utils/to-params';
import { Driver } from '@app/models/driver.model';
import { toFormData } from 'src/utils/to-form-data';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';


@Injectable({
    providedIn: 'root'
})
export class DriverService {

    private basePath = 'Drivers'
    private cancelRequestGetAll$ = new Subject<void>();

    constructor(
        private http: HttpClient
    ) {

    }

    uploadImage(request?: any) {
        return this.http.post('Images/upload', toFormData(request)).toPromise();
    }

    get(id: string | number) {
        return this.http.get<ApiResponse<Driver>>(`${this.basePath}/${id}`).toPromise()
            .then((({ data }) => data));
    }

    getAll(request?) {
        return new Promise<ApiPaginationData<Driver>>((resolve, reject) => {
            this.http.get<ApiPaginationResponse<Driver>>(`${this.basePath}`, { params: toParams(request) })
                .pipe(
                    takeUntil(this.cancelRequestGetAll$)
                )
                .subscribe(
                    ({ data }: any) => {
                        resolve(data);
                    },
                    error => {
                        reject(error);
                    }
                );
        });
    }

    cancelPendingRequestGetAll() {
        // Call this method to cancel pending requests
        this.cancelRequestGetAll$.next();
    }

    create(request?: ListRequest) {
        return this.http.post<ApiResponse<Driver>>(`${this.basePath}`, request).toPromise()
            .then((({ data }) => data));
    }

    update(id: string | number, request?: ListRequest) {
        return this.http.put<ApiResponse<Driver>>(`${this.basePath}/${id}`, request).toPromise()
            .then((({ data }) => data));
    }

    delete(id: string | number) {
        return this.http.delete(`${this.basePath}/${id}`).toPromise();
    }

    permanentDelete(id: string | number) {
        return this.http.delete(`${this.basePath}/Permanent/${id}`).toPromise();
    }

    restore(id: string | number) {
        return this.http.put(`${this.basePath}/Restore/${id}`, {}).toPromise();
    }

    bulkDelete(ids: any) {
        return this.http.delete(`${this.basePath}`, { body: ids }).toPromise();
    }

}
