import { Component, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { UtilService } from 'src/app/services/util.service';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { distinctUntilChanged } from 'rxjs/internal/operators/distinctUntilChanged';
import { Subject } from 'rxjs/internal/Subject';
import { haversineDistance } from '@app/helpers/util';
import { RouteService } from '@app/services/route.service';
import { RouteBoxUIGroup } from '@app/helpers/route-box-ui-group';
import { SamsaraService } from '@app/services/samsara.service';
import { VehicleLocation } from '@models/samsara/vehicle-location.model';
import { Route } from '@models/route.model';
import { RouteBox } from '@models/route-box.model';
import { RouteDetailsComponent } from '@app/components/main/routes/route-details/route-details.component';
import * as moment from 'moment';
import { HelperService } from '@app/shared/services/helper.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RoutesService } from '@app/shared/services/router.service';
import { RouteBoxGroup } from '@app/models/route-box-group.model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { QuoteService } from '@app/shared/services/quote.service';
import { AttemptDialogComponent } from './attempt-dialog/attempt-dialog.component';
import { DateTimeService } from '@app/shared/services/datetime-service';
import { ActivatedRoute } from '@angular/router';
import { moveItemInArray } from 'src/utils/array-menage';
import { PickupTypeEnum } from '@app/models/pickup-type';
import { OrderService } from '@app/shared/services/order.service';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { isArray } from 'lodash';

enum GeneralTabs {
    unassignedTrips,
    overview
}

enum UnassignedTrips {
    pickupAndDelivery,
    pickup,
    delivery,
    warehouse,
}

enum OverviewTabs {
    tripOverview,
    recentActivity
}
@UntilDestroy()
@Component({
    selector: 'app-unassigned-trips',
    templateUrl: './unassigned-trips.component.html',
    styleUrls: ['./unassigned-trips.component.scss']
})
export class UnassignedTripsComponent implements OnInit {
    unassignedStatusTypes = [
        {
            title: 'Pickup & Delivery',
            value: UnassignedTrips.pickupAndDelivery
        },
        {
            title: 'Pickup',
            value: UnassignedTrips.pickup
        },
        {
            title: 'Delivery',
            value: UnassignedTrips.delivery
        },
        {
            title: 'Warehouse',
            value: UnassignedTrips.warehouse
        },
    ]
    loading: boolean = true;
    PickupTypeEnum: PickupTypeEnum;
    // activeBtn: string;
    viewType: 'grid' | 'list' = 'grid';
    constructor(
        public util: UtilService,
        private routeService: RouteService,
        private routesService: RoutesService,
        private samsaraService: SamsaraService,
        public helperService: HelperService,
        private modalService: NgbModal,
        private quoteService: QuoteService,
        private dateTimeService: DateTimeService,
        public activatedRoute: ActivatedRoute,
        public orderService: OrderService,
    ) {
    }

    get isCurrentRouteShown(): boolean {
        return this.routeDetails?.route != null;
    }
    // @ViewChild('attemptDialog') attemptDialog: AttemptDialogComponent;
    @ViewChild('routeDetails') routeDetails: RouteDetailsComponent;

    public generalTabs = GeneralTabs
    public generalActiveTab = 0;
    public unassignedTrips = UnassignedTrips;
    public unassignedActiveTab = UnassignedTrips.pickupAndDelivery;
    public overviewTabs = OverviewTabs;
    public overviewActiveTab = 0;

    unassignedStopsAll: RouteBoxUIGroup[] = [];
    subscription: Subscription;
    selectedRoute: Route;

    searchTerm = '';
    term = '';
    searchTextModelChanged: Subject<string> = new Subject<string>();
    searchTextModelChangeSubscription: Subscription
    private destroyed$: Subject<boolean> = new Subject();
    private locations: VehicleLocation[];
    private routes: Route[];
    isToday = false;
    isTomorrow = false;
    today: Date = moment().startOf('day').toDate();
    tomorrow: Date = moment().add(1, 'days').startOf('day').toDate();

    private static getRouteBox(stop: RouteBoxUIGroup): RouteBox {
        if (stop?.uniqPickupRouteBoxes?.length === 1) {
            return stop?.uniqPickupRouteBoxes[0];
        } else if (stop?.uniqDropOffRouteBoxes?.length === 1) {
            return stop?.uniqDropOffRouteBoxes[0];
        }
        return null;
    }

    unassignedRoutes: any;
    defaultFilter = {
        SearchTerm: [],
        DateRange: [],
        StopType: [],
        page: 1,
        itemsPerPage: 5,
    }
    unassignedRoutesRequest: any = {
        page: 1,
        itemsPerPage: 5,
    };
    unassignedRoutes$: Subject<any> = new Subject();

    ngOnInit() {
        this.activatedRoute.queryParams.pipe(untilDestroyed(this))
            .subscribe((queryParams) => {
                const orderId = queryParams?.orderId
                const date = queryParams?.Date
                if (date) {
                    this.dateChanged(queryParams.Date)
                } else if (!date && this.unassignedRoutesRequest.DateRange) {
                    this.dateChanged(null)
                }

                if (orderId) {
                    this.searchTerm = orderId
                    this.unassignedRoutesRequest.SearchTerm = orderId
                }
            })



        this.unassignedRoutes$
            .pipe(
                debounceTime(500),
                untilDestroyed(this)
            ).subscribe(async (request: any) => {
                request = Object.assign({}, this.unassignedRoutesRequest, request)
                this.loading = true
                try {
                    this.routesService.cancelPendingRequestUnassignedRoutes();
                    this.unassignedRoutes = await this.routesService.getUnassignedRoutes(request)
                    this.setCorrectDateForBox();
                    this.loading = false
                } catch (error) {
                    this.helperService.errorMessage(error)
                    this.unassignedRoutes = []
                    this.loading = false
                }

                // this.unassignedStopsAll = RouteBoxUIGroup.createGroups(this.unassignedRoutes?.list);

            });
        this.unassignedRoutes$.next(true)


        this.routesService.$unassignedStopsGroups
            .pipe(
                untilDestroyed(this)
            ).subscribe((list: any) => {
                if (list) {
                    this.unassignedStopsAll = list;
                }
            });

        this.routesService.$routeViewType.pipe(untilDestroyed(this),)
            .subscribe((view) => {
                this.viewType = view
            })


        // this.subscription = this.routeService.reloadUnassigned$.pipe(
        //     takeUntil(this.destroyed$)
        // ).subscribe(status => {
        //     if (status) {
        //         this.unassignedRoutes$.next(true)
        //     }
        // });

        this.searchTextModelChangeSubscription = this.searchTextModelChanged
            .pipe(
                debounceTime(400),
                distinctUntilChanged(),
                takeUntil(this.destroyed$)
            )
            .subscribe(newText => {
                this.term = newText;
            });

        this.routesService.$reloadUnassigned.pipe(untilDestroyed(this)).subscribe(
            (res: any) => {
                if (res) {
                    this.unassignedRoutes$.next(true);
                }
            }
        );

        this.routesService.$allRoutes.pipe(untilDestroyed(this)).subscribe(
            (res: any) => {
                this.routes = res?.map(resp => resp?.route);
            }, (error) => {
                this.helperService.errorMessage(error);
            }
        );


        this.samsaraService.locations.pipe(
            takeUntil(this.destroyed$)
        ).subscribe(locations => {
            this.locations = locations;
        });

        this.routesService.$currentRoute.pipe(untilDestroyed(this)).subscribe(
            (res: any) => {
                this.selectedRoute = res;
            }
        );
    }

    handleUnassignedActiveTabChange(value: UnassignedTrips) {
        let typeChange: string;
        switch (value) {
            case UnassignedTrips.pickupAndDelivery:
                typeChange = "";
                break;
            case UnassignedTrips.pickup:
                typeChange = "Pickup";
                break;
            case UnassignedTrips.delivery:
                typeChange = "Delivery";
                break;
            case UnassignedTrips.warehouse:
                typeChange = "Transfer";
                break;
            default:
                typeChange = "";
        }
        this.typeChanged(typeChange);
        this.unassignedActiveTab = value;
    }

    searchTermChanged(search) {
        this.unassignedRoutesRequest.SearchTerm = search || [];
        this.unassignedRoutesRequest.page = 1;
        this.unassignedRoutes$.next(true);
    }

    dateChanged(date) {
        if (date) {
            this.unassignedRoutesRequest.DateRange = [moment(date).startOf('day').toISOString(), moment(date).endOf('day').toISOString()];
        } else {
            this.unassignedRoutesRequest.DateRange = [];
        }
        this.unassignedRoutesRequest.page = 1;
        this.unassignedRoutes$.next(true);
    }

    pageChanged(event) {
        this.unassignedRoutesRequest.page = event;
        this.unassignedRoutes$.next(true);
    }

    typeChanged(event) {
        this.unassignedRoutesRequest.StopType = event || [];
        this.unassignedRoutesRequest.page = 1;
        this.unassignedRoutes$.next(true);
    }

    changeQtyDown(selectedStopItem) {
        if (!selectedStopItem.pickQuantity) {
            selectedStopItem.pickQuantity = 0;
        } else {
            selectedStopItem.pickQuantity = Math.max(0, selectedStopItem.pickQuantity - 1);
        }
    }

    changeQtyUp(selectedStopItem) {
        if (!selectedStopItem.pickQuantity) {
            selectedStopItem.pickQuantity = 1;
        } else {
            if (selectedStopItem.pickQuantity < selectedStopItem.quantityToPick) {
                selectedStopItem.pickQuantity++;
            }
        }
    }

    private moveToUnassignedList(stopData, value, previousContainer, index = 0) {
        const isMergedStops = isArray(stopData)
        if (isMergedStops && index >= stopData.length) {
            return; // Or perform some final action
        }
        const stop = isMergedStops ? stopData[index] : stopData;
        this.routeService.moveToUnassigned(stop, value).subscribe(() => {
            const isLast = (!isMergedStops || (stopData?.length - 1 === index))
            if (isLast) {
                this.routesService.reloadRoute = { routeId: previousContainer?.routeId }
                this.helperService.isLoading = false
                this.unassignedRoutes$.next(true)
                this.helperService.successMessage(`Route ${isMergedStops ? 'Stops' : 'Stop'} Move To Unassigned Successfully.`);
            }
            else {
                this.moveToUnassignedList(stopData, value, previousContainer, index + 1)
            }
        }, error => {
            this.moveToUnassignedList(stopData, value, previousContainer, index + 1)
            this.helperService.isLoading = false
            this.helperService.errorMessage(error);
        });
    }

     drop(event: CdkDragDrop<any, any>) {
        if (!event?.isPointerOverContainer) {
            return
        }
        const previousContainer = event?.item?.data?.routeGroup
        const stopData = event?.item?.data?.stop
        const type = event?.item?.data?.type
        if (type !== 'Unassigned') {
            const activeModal = this.modalService.open(AttemptDialogComponent, {
                scrollable: true,
                size: 'sm'
            });
            activeModal.result.then(
                (result) => {
                    if (result?.isConfirmed) {
                        this.helperService.isLoading = true
                        this.moveToUnassignedList(stopData, result?.value, previousContainer)
                    }
                },
                () => { }
            );
        } else if (type === 'Unassigned') {
            const previousIndex = event?.previousIndex
            moveItemInArray(this.unassignedStopsAll, previousIndex, event.currentIndex);
        }
    }

    getRoutesWithDistances(stop: RouteBoxUIGroup) {
        const routeBox: RouteBox = UnassignedTripsComponent.getRouteBox(stop);

        if (routeBox?.waypoint?.point != null && this.routes != null) {
            const sorted = this.routes
                /*
                                .filter(route => route.routeStopGroups.flatMap(rsg => rsg.routeItemStops)
                                    .filter(ris => ris.date === routeBox.date)
                                    .every(ris => ris.status === 1))
                */
                .map(route => {
                    const pendingCount = route.routeStopGroups.flatMap(rsg => rsg.routeItemStops)
                        .filter(ris => ris.date === routeBox.date && ris.status === 0)
                        .length;
                    route.truck.location = this.locations.find(loc => loc.id === route?.truck?.samsaraId);
                    if (route.truck.location != null) {
                        const dist = haversineDistance({
                            lat: route.truck.location.latitude,
                            lng: route.truck.location.longitude
                        }, routeBox?.waypoint?.point);
                        return {
                            route, dist, pendingCount
                        };
                    }
                    return null;
                }).filter(Boolean).sort(
                    (a, b) => a.pendingCount - b.pendingCount || a.dist - b.dist);
            if (stop.selectedRoute == null && sorted.length > 0) {
                stop.selectedRoute = sorted[0].route.routeId;
            }

            return sorted;
        }
    }

    assignStop(stop: RouteBoxUIGroup, routeId: number) {
        this.routeService.assignStopToRoute(routeId, stop);
    }

    setCurrentRouteBox(stop: RouteBoxUIGroup) {
        this.unassignedStopsAll.filter(s => s !== stop).forEach(s => s.isSelected = false);
        stop.isSelected = !stop.isSelected;
        if (stop.isSelected) {
            this.routesService.currentRouteBox = stop
        } else {
            this.routesService.currentRouteBox = null
        }
    }

    timeToBox(stop: RouteBoxUIGroup): string {
        const dist = this.distanceToBox(stop);
        if (dist != null) {
            const time = dist / 50;
            const duration = moment.duration(time, 'hours');
            return `${duration.humanize()}`;
        }
        return '';
    }

    distanceToBox(stop: RouteBoxUIGroup): number | null {
        if (this.selectedRoute?.truck?.location != null && UnassignedTripsComponent.getRouteBox(stop)?.waypoint?.point != null) {
            return haversineDistance({
                lat: this.selectedRoute.truck.location.latitude,
                lng: this.selectedRoute.truck.location.longitude
            }, UnassignedTripsComponent.getRouteBox(stop)?.waypoint?.point);
        }
        return null;
    }

    isSuggested(stop: RouteBoxUIGroup): boolean {
        return this.distanceToBox(stop) < 10;
    }

    onDelete(stop) {
        if (stop.orderId) {
            this.quoteService.delete(stop.orderId).then((res) => {
                this.helperService.successMessage("Order Deleted Successfully");
                this.unassignedRoutes$.next(true)
            }).catch((error) => {
                this.helperService.errorMessage(error);
            })
        }
    }

    private setCorrectDateForBox() {
        if (this.unassignedRoutes?.list) {
            for (let box of this.unassignedRoutes?.list as RouteBoxGroup[]) {
                if (box.pickupRouteBox) {
                    box.pickupRouteBox.date = this.dateTimeService.combineDateAndTime(new Date(box.pickupRouteBox.date), new Date(box.pickupRouteBox.time));
                }
                if (box.dropOffRouteBox) {
                    box.dropOffRouteBox.date = this.dateTimeService.combineDateAndTime(new Date(box.dropOffRouteBox.date), new Date(box.dropOffRouteBox.time));
                }
            }
        }
    }
}


