import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CrudService } from './core/crud.service';
import { CustomerContact } from '@app/models/customer-contact.model';


@Injectable({
    providedIn: 'root'
})
export class OutsourceCompanyContactService extends CrudService<CustomerContact>{

    override basePath = 'OutsourceCompanyContacts';

    constructor(protected http: HttpClient) {
        super(http);
    }

}