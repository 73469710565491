import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@app/shared/shared.module';
import { LocationsRoutingModule } from './locations-routing.module';
import { LocationsListComponent } from './locations-list/locations-list.component';
import { AddEditLocationsComponent } from './add-edit-locations/add-edit-locations.component';
import { NgbDropdownModule, NgbModalModule, NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';
import { DialogsModule } from '@app/@shared/dialogs/dialogs.module';
import { FormValidateModule } from '@app/@shared/form-validate/form-validate.module';
import { AddressLookupModule } from '@app/@shared/address-lookup/address-lookup.module';

@NgModule({
  declarations: [
    LocationsListComponent,
    AddEditLocationsComponent
  ],
  imports: [
    CommonModule,
    LocationsRoutingModule,
    SharedModule,
    NgbTypeaheadModule,
    NgbModalModule,
    NgbDropdownModule,
    DialogsModule,
    FormValidateModule,
    AddressLookupModule
  ],
  exports: [AddEditLocationsComponent],

})
export class LocationsModule { }
