import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ChargeType } from '@models/charge-type.model';
import { ApiResponse, ListRequest } from '@app/models/core/base';
import { toParams } from 'src/utils/to-params';
import { CrudService } from './core/crud.service';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ChargeTypeService extends CrudService<ChargeType>{

    basePath = 'Accounting/chargeTypes'

    constructor(
        protected http: HttpClient
    ) {
        super(http)
    }

    private perStopChargeTypesSubject: BehaviorSubject<ChargeType[]> = new BehaviorSubject<ChargeType[]>([]);

    get perStopChargeTypes$(): Observable<ChargeType[]> {
        return this.perStopChargeTypesSubject.asObservable();
    }

    getPerStopChargeTypes(request?: any) {
        return this.http.get<ApiResponse<ChargeType[]>>(`${this.basePath}/perStop`, { params: toParams(request) })
            .toPromise()
            .then(({ data }) => {
                this.perStopChargeTypesSubject.next(data);
                return data;
            });
    }

    get(id: number) {
        return this.http.get<ApiResponse<ChargeType>>(`${this.basePath}/${id}`,)
            .toPromise()
            .then(({ data }) => data);
    }


    getWithoutPagination(request?: any) {
        return this.http.get<ApiResponse<ChargeType[]>>(`${this.basePath}`, { params: toParams(request) }).toPromise()
            .then((({ data }) => {
                return data
            }));
    }


    update(id: number, chargeType: ChargeType) {
        return this.http.put<ApiResponse<ChargeType>>(`${this.basePath}/${id}`, chargeType)
            .toPromise()
            .then(({ data }) => data);
    }

    create(chargeType: ChargeType) {
        return this.http.post<ApiResponse<ChargeType>>(`${this.basePath}`, chargeType)
            .toPromise()
            .then(({ data }) => data);
    }

    delete(id: number) {
        return this.http.delete<ApiResponse<any>>(`${this.basePath}/${id}`)
            .toPromise()
            .then(({ data }) => data);
    }

}
