import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouteOrderDetailComponent } from './route-order-detail.component';
import { NgbDropdownModule, NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { AddContactComponent } from './add-contact/add-contact.component';
import { SharedModule } from '@app/shared/shared.module';
import { FormsModule } from '@angular/forms';
import { FormValidateModule } from '@app/@shared/form-validate/form-validate.module';
import { RepeaterModule } from '@app/@shared/repeater/repeater.module';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { FormPhoneInputModule } from '@app/@shared/forms';



@NgModule({
  declarations: [
    RouteOrderDetailComponent,
    AddContactComponent
  ],
  exports: [
    RouteOrderDetailComponent,
  ],
  imports: [
    CommonModule,
    NgbDropdownModule,
    NgbModalModule,
    FormsModule,
    FormValidateModule,
    RepeaterModule,
    SharedModule,
    DragDropModule,
    FormPhoneInputModule
  ]
})
export class RouteOrderDetailModule { }
