import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Warehouse } from '@app/models/warehouse.model';
import { CrudService } from './core/crud.service';

@Injectable({
    providedIn: 'root'
})

export class WarehouseService extends CrudService<Warehouse> {

     basePath = 'Warehouses'

    constructor(
        protected http: HttpClient
    ) {
        super(http)
    }

}
