import { Component, Input, OnInit } from '@angular/core';
import { Warehouse } from '@models/warehouse.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { HelperService } from '@app/shared/services/helper.service';
import { CustomerService } from '@app/shared/services/customer.service';
import { WarehouseService } from '@app/shared/services/warehouse.service';
import { number, object, string } from 'yup';
import { removeBinding } from '@app/@shared/utils';

@Component({
    selector: 'app-settings-add-edit-locations',
    templateUrl: './add-edit-locations.component.html',
    styleUrls: ['./add-edit-locations.component.scss'],
})
export class AddEditLocationsComponent implements OnInit {

    /*
    * Getter & Setter for Location
    */
    private _location: Warehouse;

    get location(): Warehouse {
        return this._location;
    }
    @Input() set location(value: Warehouse) {
        this._location = removeBinding(value);
    }

    defaultAddress = {
        fullAddress: null,
        name: null,
        zip: null
    }

    validationSchema = object().shape({
        name: string().nullable().required(),
        address: object().shape({
            fullAddress: string().label('Address').nullable().required("Please select to address from list."),
            zip: number().label('Zip Code').nullable(),
        }),
    });

    saveLoading = false;

    constructor(
        private activeModalService: NgbActiveModal,
        private warehouseService: WarehouseService,
        private helperService: HelperService,
        private customerService: CustomerService,

    ) { }

    ngOnInit() {
    }

    selectAddress(item): void {
        this.location.address = item;
        this.location.address.zip = item?.zip;
    }

    onSubmit(f) {
        if (f.isValid) {
            this.saveLoading = true;
            if (this.location?.warehouseId && this.location?.warehouseId != null) {
                this.warehouseService.update(this.location?.warehouseId, this.location).then((res) => {
                    this.helperService.successMessage("Location successfully updated.")
                    this.activeModalService.close(res);
                    this.saveLoading = false;
                }).catch((error) => {
                    this.helperService.errorMessage(error)
                    this.saveLoading = false;
                });
            } else {
                this.warehouseService.create(this.location).then((res) => {
                    this.helperService.successMessage("Location successfully add.")
                    this.activeModalService.close(res);
                    this.saveLoading = false;
                }).catch((error) => {
                    this.helperService.errorMessage(error)
                    this.saveLoading = false;
                });
            }
        }
    }

    close() {
        this.activeModalService.close();
    }

}
