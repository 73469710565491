
export function objectToParams(object: any): string {
    return Object.keys(object).map((key) => {
        if (isJsObject(object[key])) {
            return subObjectToParams(encodeURIComponent(key), object[key]);
        } else {
            if (object[key] != null) {
                return `${encodeURIComponent(key)}=${encodeURIComponent(object[key])}`;
            } else {
                return `${encodeURIComponent(key)}=`;
            }
        }
    }
    ).join('&');
}

export function subObjectToParams(key: any, object: any): string {
    return Object.keys(object).map((childKey) => {

        if (isJsObject(object[childKey])) {
            return subObjectToParams(`${key}[${encodeURIComponent(childKey)}]`, object[childKey]);
        } else {
            return `${key}[${encodeURIComponent(childKey)}]=${encodeURIComponent(object[childKey])}`;
        }
    }
    ).join('&');
}

export function isJsObject(object: any) {
    const type: string = typeof object;
    return (object && type === 'object');
}
