import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Attachment } from '@app/models/attachment.model';
import { EntityType } from '@app/models/entity-type.model';
import { HelperService } from '@app/shared/services/helper.service';
import { ImagesService } from '@app/shared/services/images.service';
import mime from 'mime';
import { saveAs } from 'file-saver';
import { environment } from '@environments/environment';
import { OrderService } from '@app/shared/services/order.service';

@Component({
	selector: 'app-file-upload',
	templateUrl: './file-upload.component.html',
	styleUrls: ['./file-upload.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => FileUploadComponent),
			multi: true
		}
	]
})
export class FileUploadComponent implements OnInit {

	onChange: any = () => { };
	onTouched: any = () => { };
	uploading: boolean;


	@Input() files: any;
	@Input() multiple: boolean = false;
	@Input() accept: string = 'image/*';

	@Input() apiType: 'upload' | 'attachment' = 'upload';
	@Input() entityType: EntityType;

	@Input() buttonType: 'icon-only' | 'with-label' | 'icon-label' = 'with-label'
	imageUrl = environment.imagePreviewUrl

	validEntityTypes = [2, 3, 4, 9];
	/*
	* Getter & Setter for entity ID
	*/
	_entityId: number;
	get entityId(): number {
		return this._entityId;
	}
	@Input() set entityId(value: number) {
		this._entityId = value;
	}

	/*
	* Getter & Setter for dynamic placeholder
	*/
	_placeholder: string;
	get placeholder(): string {
		return this._placeholder;
	}
	@Input() set placeholder(value: string) {
		this._placeholder = value;
	}

	/*
	* Getter & Setter for dynamic attachments
	*/
	private _attachments: string[] | string;
	get attachments(): string[] | string {
		return this._attachments || [];
	}
	set attachments(attachments: string[] | string) {
		this._attachments = attachments;
		this.onChange(attachments);
		this.onTouched(attachments);
	}

	constructor(
		private helperService: HelperService,
		private imagesService: ImagesService,
		private orderService: OrderService
	) { }

	ngOnInit(): void {
		if (this.files && !this.multiple) {
			this.attachments = (this.files?.length > 0) ? this.files[0] : this.files;
		} else if (!this.files && this.multiple) {
			this.attachments = []
		} else {
			this.attachments = this.files
		}

	}

	async onSelectFile(event: any) {
		let files = event.target.files;
		if (files) {
			for (let file of files) {
				await this.handleUpload(file)
				// let reader = new FileReader();
				// reader.onload = (e: any) => {
				//   this.attachments.push(e.target.result);
				// }
				// reader.readAsDataURL(file);
			}
		}
		event.target.value = null
	}

	removeAttachment(index) {
		if (this.attachments instanceof Array && this.multiple) {
			const attachmentId = index?.attachmentId
			this.imagesService.deleteAttachment(attachmentId).then((res) => {
				if (this.validEntityTypes.includes(this.entityType)) {
					this.orderService.reloadOrderAttachments.next(true);
				}
				(this.attachments as string[]).splice(index, 1)
			}).catch((error) => {
				this.helperService.errorMessage(error)
			})
		} else {
			this.attachments = null
		}
	}

	viewAttachment(attachment) {
		this.helperService.isLoading = true
		this.imagesService.downloadAttachment(attachment).then((res) => {
			const blob = new Blob([res], { type: mime.getType(attachment.name) });
			const fileURL = window.URL.createObjectURL(blob);
			this.helperService.isLoading = false
			window.open(fileURL, '_blank');
		}).catch((error) => {
			this.helperService.isLoading = false
			this.helperService.errorMessage(error)
		})
	}

	downloadAttachment(event, attachment) {
		event.preventDefault();
		event.stopPropagation();
		this.helperService.isLoading = true
		this.imagesService.downloadAttachment(attachment).then((res) => {
			this.helperService.isLoading = false
			saveAs(new Blob([res]), attachment.name);
		}).catch((error) => {
			this.helperService.isLoading = false
			this.helperService.errorMessage(error)
		})
	}

	async handleUpload(file: File) {
		if (file instanceof File) {
			try {
				let uploaded: Attachment | any;
				switch (this.apiType) {
					case 'attachment':
						uploaded = await this.imagesService.uploadAttachment(
							this.entityType,
							this.entityId || 0,
							file
						);
						break;
					default:
						uploaded = await this.imagesService.upload({ file });
						break;
				}
				if (typeof this.attachments === 'object' && this.multiple) {
					this.attachments.push(uploaded?.data);
				} else {
					this.attachments = uploaded.data;
				}

			
				if (this.validEntityTypes.includes(this.entityType)) {
					this.orderService.reloadOrderAttachments.next(true);
				}

			} catch (error) {
				this.helperService.errorMessage(error, "File upload field, please try again");
			} finally {			
				this.uploading = false;
			}
		}
	}

	writeValue(value: string[] | string) {
		this._attachments = value
	}

	registerOnChange(fn: (attachments: string[] | string) => void): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: () => void): void {
		this.onTouched = fn;
	}
}
