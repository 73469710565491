import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@app/shared/shared.module';
import { CostLinesComponent } from './cost-lines.component';
import { NgChartsModule } from 'ng2-charts';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [CostLinesComponent],
  exports: [CostLinesComponent],
  imports: [
    CommonModule,
    SharedModule,
    NgChartsModule,
    NgbModalModule,
  ]
})
export class CostLinesModule { }
