// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host .route-option {
  border: 1px solid #e5e7eb;
  border-radius: 4px;
  cursor: pointer;
}
:host .route-option img {
  max-height: 50px;
  max-width: 65px;
}
:host .route-option .open .fa-chevron-down {
  transform: rotate(180deg);
}
:host .options-list {
  position: absolute;
  z-index: 10;
  border: 1px solid #e5e7eb;
  margin-top: 4px;
  width: 100%;
}
:host .options-list .select-zone-option iframe {
  border-radius: 4px;
}
:host .options-list .select-driver-option .driver-img img {
  border-radius: 99px;
  height: 40px;
  width: 40px;
}
:host .options-list .select-truck-option .truck-img img {
  width: 70px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/main/routes/add-edit-route-dialog/add-edit-route-dialog.component.scss"],"names":[],"mappings":"AAGI;EACI,yBAAA;EACA,kBAAA;EACA,eAAA;AAFR;AAGQ;EACI,gBAAA;EACA,eAAA;AADZ;AAKY;EACI,yBAAA;AAHhB;AAQI;EACI,kBAAA;EACA,WAAA;EACA,yBAAA;EACA,eAAA;EACA,WAAA;AANR;AAQY;EACI,kBAAA;AANhB;AAWgB;EACI,mBAAA;EACA,YAAA;EACA,WAAA;AATpB;AAegB;EACI,WAAA;AAbpB","sourcesContent":["@import \"var\";\n:host {\n    \n    .route-option {\n        border: 1px solid $divider;\n        border-radius: 4px;\n        cursor: pointer;\n        img {\n            max-height: 50px;\n            max-width: 65px;\n        }\n\n        .open {\n            .fa-chevron-down {\n                transform: rotate(180deg);\n            }\n        }\n    }\n\n    .options-list {\n        position: absolute;\n        z-index: 10;\n        border: 1px solid $divider;\n        margin-top: 4px;\n        width: 100%;\n        .select-zone-option {\n            iframe {\n                border-radius: 4px;\n            }\n        }\n        .select-driver-option {\n            .driver-img {\n                img {\n                    border-radius: 99px;\n                    height: 40px;\n                    width: 40px;\n                }\n            }\n        }\n        .select-truck-option {\n            .truck-img {\n                img {\n                    width: 70px;\n                }\n            }\n        }\n    }\n\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
