import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators, AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { CompanyUser } from '@app/models/company-user.model';
import { HelperService } from '@app/shared/services/helper.service';
import { AuthService } from '@app/shared/services/auth/auth.service';

@Component({
    selector: 'app-admin-set-password',
    templateUrl: './admin-set-password.component.html',
    styleUrls: ['./admin-set-password.component.scss'],
})
export class AdminSetPasswordComponent implements OnInit {

    @Input() user: CompanyUser;
    errorMessage: any = null;
    setPasswordForm: FormGroup;
    showPassword = false;
    showConfirmPassword = false;
    isSubmitting: boolean = false;

    constructor(
        private formBuilder: FormBuilder,
        private readonly activeModal: NgbActiveModal,
        private readonly authService: AuthService,
        private readonly helperService: HelperService,
    ) {
        this.setPasswordForm = this.formBuilder.group({
            password: ['', Validators.compose([
                Validators.minLength(8),
                Validators.required,
                this.strongPasswordValidator()
            ])],
            confirmPassword: ['', [Validators.required, this.confirmPasswordValidator]],
        });
    }

    ngOnInit(): void {
        console.log(this.user);
    }

    confirmPasswordValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
        if (!control.parent || !control) {
            return null;
        }

        const password = control.parent.get('password');
        const passwordConfirm = control.parent.get('confirmPassword');

        if (!password || !passwordConfirm) {
            return null;
        }

        if (passwordConfirm.value === '') {
            return null;
        }

        if (password.value === passwordConfirm.value) {
            return null;
        }

        return { passwordsNotMatching: true };
    };

    close() {
        this.activeModal.close();
    }

    suggestPassword() {
        const generatedPassword = this.generateSecurePassword();

        this.setPasswordForm.patchValue({
            password: generatedPassword,
            confirmPassword: generatedPassword,
        });

        this.copyToClipboard(generatedPassword);

        this.helperService.successMessage('Password generated and copied to clipboard');
    }

    generateSecurePassword(): string {
        const lowercase = 'abcdefghijklmnopqrstuvwxyz';
        const uppercase = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
        const digits = '0123456789';
        const specialChars = '!@#$%^&*()_+{}:"<>?|[];,./-=';

        const allChars = lowercase + uppercase + digits + specialChars;
        const passwordLength = 12;
        let password = '';

        // Ensure the password contains at least one character from each required set
        password += lowercase[Math.floor(Math.random() * lowercase.length)];
        password += uppercase[Math.floor(Math.random() * uppercase.length)];
        password += digits[Math.floor(Math.random() * digits.length)];
        password += specialChars[Math.floor(Math.random() * specialChars.length)];

        // Generate the remaining characters randomly
        for (let i = 4; i < passwordLength; i++) {
            const randomIndex = Math.floor(Math.random() * allChars.length);
            password += allChars[randomIndex];
        }

        // Shuffle the password to avoid predictable patterns
        return this.shufflePassword(password);
    }

    shufflePassword(password: string): string {
        const array = password.split('');
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
        return array.join('');
    }

    copyToClipboard(text: string) {
        navigator.clipboard.writeText(text).then(() => {
            console.log('Password copied to clipboard successfully!');
        }).catch((err) => {
            console.error('Failed to copy password to clipboard:', err);
        });
    }

    onSubmit() {
        this.errorMessage = null;
        this.isSubmitting = true;

        if (this.setPasswordForm.valid) {
            const request = {
                userId: this.user.id,
                newPassword: this.setPasswordForm.value.password,
            };

            this.authService.adminSetPassword(request)
                .then((res) => {
                    this.helperService.successMessage('Password has been set successfully');

                    this.copyToClipboard(this.setPasswordForm.value.password);

                    this.activeModal.close(true);
                })
                .catch((error) => {
                    this.errorMessage = this.helperService.errorMessage(error, 'Something went wrong, please try again.');
                })
                .finally(() => {
                    this.isSubmitting = false;
                });
        }
    }

    strongPasswordValidator(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const value = control.value;
    
            if (!value) {
                return null;
            }
    
            const errors: ValidationErrors = {};
    
            if (!/[A-Z]/.test(value)) {
                errors.noUpperCase = 'Passwords must have at least one uppercase letter (A-Z).';
            }
    
            if (!/[a-z]/.test(value)) {
                errors.noLowerCase = 'Passwords must have at least one lowercase letter (a-z).';
            }
    
            if (!/[^a-zA-Z0-9]/.test(value)) {
                errors.noNonAlphanumeric = 'Passwords must have at least one non-alphanumeric character.';
            }
    
            return Object.keys(errors).length > 0 ? errors : null;
        };
    }
}
