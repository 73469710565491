import { BaseModel, CompanyBase, CustomerBase, TenantBase } from '@models/base.model';
import { Order } from '@models/order.model';
import { InvoiceLine } from '@models/invoice-line.model';
import { SentEmail } from '@models/sent-email.model';
import { Customer } from './customer.model';
import { PaymentMethod } from './payment-method.model';

export enum TnxStatus {
    Draft = 'Draft',
    Approved = 'Approved',
    Open = 'Open',
    Paid = 'Paid',
    Overdue = 'Overdue',
    PartialPaid = 'PartialPaid',
    Deleted = 'Deleted',
}

export enum TnxType {
    Invoice = 'Invoice',
    Payment = 'Payment',
}

export enum PaymentMethodType {
    CHECK = 0,
    CASH = 1,
    PAYMENTMETHOD = 2
}
export interface Invoice extends BaseModel, CustomerBase, CommonInvoiceFields {
    dateType?: any;
    invoiceId?: number;
    previousInvoiceId?: number;
    nextInvoiceId?: number;
    orderId?: number;
    order?: Order;
    lines?: InvoiceLine[];
    dueDate?: Date;
    previousReferenceData?: any;
    nextReferenceData?: any;
    paymentStatus?: TnxStatus;
    transactionPaymentStatus?: TnxStatus;
}

export interface InvoicePayment extends BaseModel, TenantBase, CompanyBase, CustomerBase, CommonInvoiceFields {
    createdUser?: string;
    updatedUser?: string;
    paymentAmount?: number;
    paymentMethod?: PaymentMethod;
    gatewayRef?: any;
    refrence?: any;
    paymentMethodId?: number;
    paymentMethodType?: PaymentMethodType;
    paymentId?: number;
}

export interface CommonInvoiceFields {
    appliedPayments?: appliedPayments[];
    totalAmount?: number;
    amountPaid?: number;
    creditAmount?: number;
    date?: Date;
    companyId?: number;
    billToDetails?: string;
    status?: TnxStatus;
    inQb?: boolean;
    number?: string;
    notes?: string;
    tnxType?: TnxType;
    id?: number;
    referenceId?: number;
    lineCount?: number;
    balance?: number;
    sentEmails?: SentEmail[];
    sentEmailStatus?: InvoiceSentEmailStatus;
    exportQbStatus?: InvoiceExportQbStatus;
}
export interface appliedPayments extends BaseModel, TenantBase, CompanyBase {
    invoice?: Invoice;
    amount?: number;
    invoiceId?: number;
    appliedPaymentId?: number;
    paymentId?: number;
    payment?: InvoicePayment;
}

export enum InvoiceSentEmailStatus {
    Sent = 1,
    NotSent = 2,
    Edited = 4,
    EditedAndSent = 5,
    EditedAndNotSent = 6,
}

// Enums for ExportQbStatus
export enum InvoiceExportQbStatus {
    Exported = 1,
    NotExported = 2,
    Edited = 4,
    EditedAndExported = 5,
    EditedAndNotExported = 6,
}



