import { Component, Input } from '@angular/core';
import { Order } from "@app/models/order.model";
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { QuoteService } from '@app/shared/services/quote.service';
import { OrderService } from '@app/shared/services/order.service';
import { HelperService } from '@app/shared/services/helper.service';
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
  selector: 'pickup-order-detail-dialog',
  templateUrl: './pickup-order-detail-dialog.component.html',
  styleUrls: ['./pickup-order-detail-dialog.component.scss']
})
export class PickupOrderDetailDialogComponent {

  @Input() orderId: number;

  order: Order;

  constructor(
    private activeModal: NgbActiveModal,
    private quoteService: QuoteService,
    private orderService: OrderService,
    private helperService: HelperService,
  ) { }

  ngOnInit() {
    this.orderService.order$
      .pipe(
        untilDestroyed(this),
      )
      .subscribe((order) => {
        if (order) {
          this.order = order;
        }
      })

    if (this.orderId) {
      this.quoteService.get(this.orderId).then((res) => {
        if (res) {
          this.order = res
        }
      })
    }
  }

  async saveOrder(updateRequest?: Partial<Order>) {

    const order = {
      ...this.order,
      ...updateRequest,
    }

    this.helperService.isLoading = true;
    try {
      await this.orderService.saveOrder(order);
    } catch (error) {
      this.helperService.errorMessage(error)
    } finally {
      this.helperService.isLoading = false;
    }
  }

  close() {
    this.activeModal.close();
  }
}
