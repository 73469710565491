import { Component, forwardRef, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';

@Component({
    selector: 'app-timepicker',
    templateUrl: './timepicker.component.html',
    styleUrls: ['./timepicker.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => TimepickerComponent),
            multi: true
        }
    ],
    encapsulation: ViewEncapsulation.Emulated
})
export class TimepickerComponent implements OnInit {
    selectedTime: string = null;
    @Input() disabled: boolean;

    @Input() placeholder = ''

    onChange = (value: string) => { };
    onTouched = () => { };

    private _value: NgbTimeStruct;

    get value(): NgbTimeStruct {
        return this._value;
    }
    set value(updateValues: NgbTimeStruct) {
        this._value = updateValues;
        if (updateValues) {
            this.selectedTime = moment().set('hour', updateValues.hour).set('minute', updateValues.minute).set('second', updateValues.second).format('HH:mm:ss')
            this.onChange(this.selectedTime);
        } else {
            this.selectedTime = null;
            this.onChange(null);
        }

        this.onTouched();
    }

    constructor() {
    }

    ngOnInit() {
    }

    clearTime($event) {
        $event.stopPropagation();
        $event.preventDefault();
        this.value = null
    }

    writeValue(value: string) {

        if (value) {
            const date = moment(value, 'HH:mm:ss')
            this._value = {
                hour: date.get('minute'),
                minute: date.get('second'),
                second: date.get('second'),
            }
            this.selectedTime = value
        }

    }

    registerOnChange(fn: (value: string) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

}
