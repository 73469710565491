import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DatatableComponent } from '@app/@shared/datatable';
import { Driver, DriverStatusEnum } from '@app/models/driver.model';
import { Order } from '@app/models/order.model';
import { OutsourceCompany } from '@app/models/outsource-company.model';

@Component({
  selector: 'app-outsource-company-drivers',
  templateUrl: './outsource-company-drivers.component.html',
  styleUrls: ['./outsource-company-drivers.component.scss']
})
export class OutsourceCompanyDriversComponent implements OnInit {

  @ViewChild("datatable") datatable: DatatableComponent;
  orders: Order[] = [];

  @Input() outsourceCompany: OutsourceCompany = {};

  constructor(
    private router: Router,
  ) {
  }

  ngOnInit(): void {
  }

  getStatusLabel(status) {
    if (status === DriverStatusEnum.INACTIVE) {
      return 'Disabled';
    } else {
      return status;
    }
  }

  handleRowClick(row: Driver) {
    this.router.navigate(['/app/drivers/edit', row.driverId]);
  }

}





