import { Component, OnInit } from '@angular/core';
import { ApiService } from '@app/services/api.service';
import { environment } from '@environments/environment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TelegramComponent } from './telegram/telegram.component';

enum QuickbooksIntegrationStatus {
  NotConnected,
  Connected,
  ConnectionExpired
}

interface Integration {
  image: string,
  name: string,
  description: string,
  isInstalled: boolean,
  buttonText: string,
  reconnectNeeded: boolean
  onClick?: any;
}

@Component({
  selector: 'app-integrations',
  templateUrl: './integrations.component.html',
  styleUrls: ['./integrations.component.scss']
})
export class IntegrationsComponent implements OnInit {

  title = "Integrations"
  activeTab = "all"
  quickbooksIntegrationStatus: QuickbooksIntegrationStatus


  redirectQuickbooks = () => {
    const quickbooksAuthUrl = `https://appcenter.intuit.com/connect/oauth2?client_id=${environment.quickbooksClientId}&redirect_uri=${environment.quickbooksRedirectUrl}&scope=com.intuit.quickbooks.accounting&response_type=code&state=rushhour`;
    window.location.href = quickbooksAuthUrl;
  }

  telegramDialog = () => {
    this.ngbModal.open(TelegramComponent, {
      scrollable: false,
      size: "sm",
    })
  }


  data: Integration[] = [
    {
      image: '/assets/images/samsara.png',
      name: 'Samsara',
      description: 'Details',
      isInstalled: true,
      buttonText: 'View',
      reconnectNeeded: false
    },
    {
      image: '/assets/images/motive.png',
      name: 'Motive',
      description: 'Details',
      isInstalled: false,
      buttonText: 'Install',
      reconnectNeeded: false
    },
    {
      image: '/assets/images/gps-insights.png',
      name: 'GPS Insights',
      description: 'Details',
      isInstalled: false,
      buttonText: 'Install',
      reconnectNeeded: false
    },
    {
      image: '/assets/images/solera.png',
      name: 'Solera',
      description: 'Details',
      isInstalled: false,
      buttonText: 'Install',
      reconnectNeeded: false
    },
    {
      image: '/assets/images/quickbooks.svg',
      name: 'Quickbooks',
      description: 'Details',
      isInstalled: false,
      buttonText: 'Install',
      reconnectNeeded: false,
      onClick: this.redirectQuickbooks
    },
    {
      image: '/assets/images/telegram.svg',
      name: 'Telegram',
      description: 'Details',
      isInstalled: false,
      buttonText: 'Install',
      reconnectNeeded: false,
      onClick: this.telegramDialog
    }
  ]

  constructor(
    private api: ApiService,
    private ngbModal: NgbModal
  ) { }

  ngOnInit(): void {
    this.api.getQuickbooksIntegrationStatus(success => {
      this.quickbooksIntegrationStatus = success.status;
      if (this.quickbooksIntegrationStatus === QuickbooksIntegrationStatus.Connected) {
        this.data.filter(x => x.name === 'Quickbooks')[0].buttonText = 'View'
      }
      else if (this.quickbooksIntegrationStatus === QuickbooksIntegrationStatus.ConnectionExpired) {
        this.data.filter(x => x.name === 'Quickbooks')[0].buttonText = 'Reconnect'
        this.data.filter(x => x.name === 'Quickbooks')[0].reconnectNeeded = true
      }
    }, error => {
      this.data.filter(x => x.name === 'Quickbooks')[0].buttonText = 'Install'
    });
  }

}
