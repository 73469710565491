import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CrudService } from './core/crud.service';

@Injectable({ providedIn: 'root' })
export class ActivityLogService extends CrudService<any>{

    basePath = 'UserActivities'
    constructor(
        protected http: HttpClient
    ) {
        super(http)
    } 

}
