// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host .customer-image {
  max-width: 60px;
  max-height: 60px;
}
:host .common-fields {
  padding: 16px 12px;
}
:host .pipeline {
  min-width: 192px;
}
:host .source {
  min-width: 148px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/main/customers/customer-list/customer-list.component.scss"],"names":[],"mappings":"AAEI;EACI,eAAA;EACA,gBAAA;AADR;AAII;EACI,kBAAA;AAFR;AAKI;EACI,gBAAA;AAHR;AAKI;EACI,gBAAA;AAHR","sourcesContent":[":host{\n\n    .customer-image{\n        max-width: 60px;\n        max-height: 60px;\n    }\n\n    .common-fields {\n        padding: 16px 12px;\n    }\n\n    .pipeline {\n        min-width: 192px;\n    }\n    .source {\n        min-width: 148px;\n    }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
