import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Order } from '@models/order.model';
import { Invoice } from '@models/invoice.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TelegramService } from '@app/services/telegram.service';
import { TelegramBotMessage, TelegramChatMessage } from '@app/models/telegram.model';

export interface DocumentViewerResult {
    entity: Order | Invoice;
    email?: string;
}

@Component({
    selector: 'app-chat-history-viewer',
    templateUrl: './chat-history-viewer.component.html',
    styleUrls: ['./chat-history-viewer.component.scss']
})
export class ChatHistoryViewerComponent implements OnInit {
    @Output() downloadClick: EventEmitter<DocumentViewerResult> = new EventEmitter<DocumentViewerResult>();
    @Output() emailClick: EventEmitter<DocumentViewerResult> = new EventEmitter<DocumentViewerResult>();
    @Input() driverId: number;
    @Input() type: 'chat' | 'bot';
    search: string;
    searchLoading: boolean = true;
    messages: (TelegramChatMessage | TelegramBotMessage)[] = [];

    constructor(
        private activeModal: NgbActiveModal,
        private telegramService: TelegramService,
    ) {
    }

    ngOnInit(): void {
        if (this.type === 'chat') {
            this.telegramService.searchChatHistory(this.driverId, this.search).subscribe(res => {
                this.messages = res;
                this.searchLoading = false;
            }, err => {
                console.log(err);
                this.searchLoading = false;
            });
        } else {
            this.telegramService.searchBotHistory(this.driverId, this.search).subscribe(res => {
                this.messages = res;
                this.searchLoading = false;
            }, err => {
                console.log(err);
                this.searchLoading = false;
            });
        }
    }

    close() {
        this.activeModal.close()
    }

    getChatMessage(message: TelegramChatMessage | TelegramBotMessage): TelegramChatMessage {
        return message as TelegramChatMessage;
    }

    getBotMessage(message: TelegramChatMessage | TelegramBotMessage): TelegramBotMessage {
        return message as TelegramBotMessage;
    }
}
