import { Pipe, PipeTransform } from '@angular/core';
import { OrderStopTimeTypeEnum } from '@app/models/order-stop.model';
import { CustomTimePipe } from './custom-date.pipe';

@Pipe({
  name: 'orderStopTime'
})
export class OrderStopTimePipe extends CustomTimePipe implements PipeTransform {


  transform(value, timeType?: OrderStopTimeTypeEnum, ...args: any[]) {

    switch (timeType) {
      case OrderStopTimeTypeEnum.MORNING:
        return "Morning 6:00 AM - 12:00 PM";

      case OrderStopTimeTypeEnum.NOON:
        return " Afternoon 12:00 PM - 5:00 PM";

      case OrderStopTimeTypeEnum.EVENING:
        return "Evening 5:00 PM - 12:00 AM";

      default:
        return value ? super.transform(value, ...args) : ''

    }
  }

}
