import { BaseModel } from '@models/base.model';

export enum EmailType {
    Quote = 0,
    BillOfLanding = 1,
    Invoice = 2,
    Customer = 3,
}

export interface SentEmail extends BaseModel {
    isSelected?: boolean;
    sentEmailId?: number;
    to?: string;
    subject?: string;
    emailType?: EmailType;
}
