import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiPaginationResponse, ApiResponse, ListRequest } from '@app/models/core/base';
import { toParams } from 'src/utils/to-params';

@Injectable({ providedIn: 'root' })
export class SubscriptionPlansService {

    private basePath = 'SubscriptionPlans'

    constructor(
        private http: HttpClient,
    ) {
    }    

    getAll(request?: ListRequest) {
        return this.http.get<ApiPaginationResponse<any>>(`${this.basePath}`, { params: toParams(request) }).toPromise()
            .then((({ data }) => data));
    }

    update(id: number, request: any) {
        return this.http.put<ApiResponse<any>>(`${this.basePath}/${id}`, request)
            .toPromise()
            .then(({ data }) => data);
    }

    create(request: any) {
        return this.http.post<ApiResponse<any>>(`${this.basePath}`, request)
            .toPromise()
            .then(({ data }) => data);
    }

    delete(id: number) {
        return this.http.delete<ApiResponse<any>>(`${this.basePath}/${id}`)
            .toPromise()
            .then(({ data }) => data);
    }

}
