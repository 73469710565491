import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PlanLayoutRoutingModule } from './plan-layout-routing.module';
import { PlanLayoutComponent } from './plan-layout.component';
import { PlanComponent } from './plan/plan.component';
import { PaymentComponent } from './payment/payment.component';
import { SharedModule } from '@app/shared/shared.module';
import { CardInputsModule } from '@app/@shared/card-inputs/card-inputs.module';
import { FormValidateModule } from '@app/@shared/form-validate/form-validate.module';
import { DialogsModule } from '@app/@shared/dialogs/dialogs.module';


@NgModule({
  declarations: [
    PlanLayoutComponent,
    PlanComponent,
    PaymentComponent
  ],
  imports: [
    CommonModule,
    PlanLayoutRoutingModule,
    SharedModule,
    CardInputsModule,
    FormValidateModule,
    DialogsModule
  ]
})
export class PlanLayoutModule { }
