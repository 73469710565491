import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { CustomData } from '../custom/custom.component';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';


export interface PromptData {
    title?: string;
    label?: string;
    placeholder?: string;
    message?: string;
    inputType?: 'text' | 'checkbox' | 'radio' | 'select' | 'password' | 'email' | 'textarea';
    okText?: string;
    cancelText?: string;
    options?: any[];
    labelKey?: string;
    valueKey?: string;
}


@Component({
    selector: 'app-prompt',
    templateUrl: './prompt.component.html',
    styleUrls: ['./prompt.component.scss']
})
export class PromptComponent implements OnInit {
    input: any;

    form: FormGroup;

    @Input() data: PromptData;

    constructor(
        public activeModal: NgbActiveModal,
        private fb: FormBuilder,
    ) {
        this.form = this.fb.group({
            input: ['', Validators.required],
        });

        console.log(this.form)
    }

    ngOnInit() {
    }

    submit() {
        if (this.form.valid) {
            this.activeModal.close(this.form.get('input').value);
        }
    }

}
