import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { CustomerService } from '@app/shared/services/customer.service';
import { HelperService } from '@app/shared/services/helper.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from 'src/app/services/api.service';
import { UtilService } from 'src/app/services/util.service';

@Component({
    selector: 'app-customer-import',
    templateUrl: './customer-import.component.html',
    styleUrls: ['./customer-import.component.scss']
})
export class CustomerImportComponent implements OnInit {

    @Output() action = new EventEmitter();
    file: any;
    status: string = "ready";

    constructor(
        public activeModal: NgbActiveModal,
        private api: ApiService,
        private util: UtilService,
        private helperService: HelperService,
        private customerService: CustomerService,
    ) { }

    uploadFile(event) {
        const element = event[0];
        this.file = element;
    }

    upload() {
        this.status = "uploading";
        this.customerService.uploadFile({file: this.file}).then((res) => {
            this.status = "success";
        }).catch((error) => {
            this.status = "ready";
            this.helperService.errorMessage(error);
        })
    }

    close() {
        this.activeModal.close();
    }

    closePopup() {
        this.activeModal.close();
        this.action.emit();
    }

    downloadSample() {
        this.api.ExportCustomerSample();
    }

    deleteAttachment() {
        this.file = null;//.splice(index, 1)
    }

    ngOnInit() {
    }

}
