import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { CalendarEvent, CalendarView, DateFormatterParams } from 'angular-calendar';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-pickup-datepicker',
  templateUrl: './pickup-datepicker.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./pickup-datepicker.component.scss'],
  providers: [DatePipe]
})
export class PickupDatepickerComponent implements OnInit {
  public isOpened: boolean = false;
  public calendarVisible: boolean = false;

  @Input() dateType: string;
  @Input() date: Date;
  @Output() dateTypeChange = new EventEmitter<any>();
  @Output() dateChange = new EventEmitter<any>();
  
  view: CalendarView = CalendarView.Month;
  viewDate: Date = new Date();
  events: CalendarEvent[] = [];
  clickedDate: Date;
  clickedColumn: number;

  constructor(
    private _datePipe: DatePipe
  ) { }

  ngOnInit() {}

  public monthViewColumnHeader({date, locale}: DateFormatterParams): string {
    return this._datePipe.transform(date, 'EEEEEE', null, locale);
  }

  public showCalendar() {
    this.calendarVisible = !this.calendarVisible;
  }

  setDateType(type) {
    this.dateType = type;
    this.date = null;
    this.dateTypeChange.emit(this.dateType);
    this.dateChange.emit(null);
    this.isOpened = false;
  }

  dayClicked(date){
    if(date > new Date()){
      this.dateType = 'Custom';
      this.dateTypeChange.emit('Custom');
      this.dateChange.emit(date);
      this.calendarVisible = false;
      this.isOpened = false;
      this.clickedDate = date;
    }
  }
}
