import { Component, forwardRef, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { EntityTypes } from '@app/models/entity-type.model';
import { OrderTrip } from '@app/models/order-trip.model';

@Component({
	selector: 'app-order-trips',
	templateUrl: './order-trips.component.html',
	styleUrls: ['./order-trips.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => OrderTripsComponent),
			multi: true
		}
	],
})
export class OrderTripsComponent implements OnInit, OnChanges {

	/*
	* Getter & Setter for order trips 
	*/
	_trips: OrderTrip[] = [
		{
			pickupOrderStop: {
				attachments: []
			},
			deliveryOrderStop: {
				attachments: []
			}
		}
	];
	get trips(): OrderTrip[] {
		return this._trips;
	}
	@Input() set trips(trips: OrderTrip[]) {
		this._trips = trips;
	}

	EntityTypes = EntityTypes;

	tripStatusTypes = new Map([
		['Open', { class: 'is-opened' }],
		['Assigned', { class: 'is-assigned' }],
		['InTransit', { class: 'is-in-transit' }],
		['Delivered', { class: 'is-delivered' }],
	]);

	constructor() { }

	ngOnInit(): void { }

	ngOnChanges(changes: SimpleChanges): void { }
}
